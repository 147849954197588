import { Link } from "react-router-dom";
import Logo from "../assets/img/lavie.png";
import LogoSM from "../assets/img/logo_sm_branca.png";
import LogoFKL from "../assets/img/logoFkl.png";
import { getSessionData } from "../utils/storageUtils";
import { useEffect, useState } from "react";
import ApiGet from "../api/endPoints/useGet";
import { toast } from "react-toastify";
import { ResumoFinanceiro } from "./faturamento/resumo/resumoFinanceiro";

const LeftBar = (): JSX.Element => {
  const token = getSessionData("MultClinicaWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  const perfil = getSessionData("DadosPerfilID") || "0";

  const [loading, setLoading] = useState<boolean>(false);
  const [textoLoading, setTextoLoading] = useState<string>("");

  const [permAdminUser, setPermAdminUser] = useState<boolean>(false);
  const [permCadFilialClinica, setPermCadFilialClinica] =
    useState<boolean>(false);
  const [permCadFilialSalasAtend, setPermCadFilialSalasAtend] =
    useState<boolean>(false);

  const [permsCadFilialAgenSalas, setPermsCadFilialAgenSalas] =
    useState<boolean>(false);

  const [permCadProfProfissionais, setPermCadProfProfissionais] =
    useState<boolean>(false);

  const [permCadProcProcedimento, setPermCadProcProcedimento] =
    useState<boolean>(false);

  const [permCadConvConvenio, setPermCadConvConvenio] =
    useState<boolean>(false);
  const [permCadConvProcConvenio, setPermCadConvProcConvenio] =
    useState<boolean>(false);
  const [permCadPacientePreCadastro, setPermCadPacientePreCadastro] =
    useState<boolean>(false);
  const [permCadPacientePaciente, setPermCadPacientePaciente] =
    useState<boolean>(false);
  const [permCadPacienteAnamnese, setPermCadPacienteAnamnese] =
    useState<boolean>(false);



  const [permissaoConsultas, setPermissaoConsultas] = useState(false)
  const [permissaoAgenda, setPermissaoAgenda] = useState(false)

  const [permissaoAutorizacao, setPermissaoAutorizacao] = useState(false)

  const [permissaoPendenteDeFaturamento, setPermissaoPendenteDeFaturamento] = useState(false)
  const [permissaoFaturamento, setPermissaoFaturamento] = useState(false)
  const [permissaoLancamentoDespesas, setPermissaoLancamentoDespesas] = useState(false)
  const [permissaoResumoFinanceiro, setPermissaoResumoFinanceiro] = useState(false)


  const [permissaoProntuario, setPermissaoProntuario] = useState(false)
  const [permissaoKpi, setPermissaoKpi] = useState(false)

  const buscarDadosPermissao = async (perfil: string) => {
    setLoading(true);
    setTextoLoading("Aguarde, Verificando Permissões...");
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/perfil/carregar/:id_perfil",
      parametros: {
        id_perfil: perfil,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        const retornoApi = retorno[0];

        if (
          retornoApi.admin_user_incluir === "1" ||
          retornoApi.admin_user_editar === "1" ||
          retornoApi.admin_user_excluir === "1" ||
          retornoApi.admin_user_visu === "1" ||
          isSuper === "1"
        ) {
          setPermAdminUser(true);
        } else {
          setPermAdminUser(false);
        }

        if (
          retornoApi.cad_filiais_clinica_incluir === "1" ||
          retornoApi.cad_filiais_clinica_editar === "1" ||
          retornoApi.cad_filiais_clinica_excluir === "1" ||
          retornoApi.cad_filiais_clinica_visu === "1" ||
          isSuper === "1"
        ) {
          setPermCadFilialClinica(true);
        } else {
          setPermCadFilialClinica(false);
        }

        if (
          retornoApi.cad_filiais_sala_atend_incluir === "1" ||
          retornoApi.cad_filiais_sala_atend_editar === "1" ||
          retornoApi.cad_filiais_sala_atend_excluir === "1" ||
          retornoApi.cad_filiais_sala_atend_visu === "1" ||
          isSuper === "1"
        ) {
          setPermCadFilialSalasAtend(true);
        } else {
          setPermCadFilialSalasAtend(false);
        }

        if (
          retornoApi.cad_filiais_age_sala_incluir === "1" ||
          retornoApi.cad_filiais_age_sala_editar === "1" ||
          retornoApi.cad_filiais_age_sala_excluir === "1" ||
          retornoApi.cad_filiais_age_sala_visu === "1" ||
          isSuper === "1"
        ) {
          setPermsCadFilialAgenSalas(true);
        } else {
          setPermsCadFilialAgenSalas(false);
        }

        if (
          retornoApi.cad_prof_profissional_incluir === "1" ||
          retornoApi.cad_prof_profissional_editar === "1" ||
          retornoApi.cad_prof_profissional_excluir === "1" ||
          retornoApi.cad_prof_profissional_visu === "1" ||
          isSuper === "1"
        ) {
          setPermCadProfProfissionais(true);
        } else {
          setPermCadProfProfissionais(false);
        }
        if (
          retornoApi.cad_proc_procedimento_incluir === "1" ||
          retornoApi.cad_proc_procedimento_editar === "1" ||
          retornoApi.cad_proc_procedimento_excluir === "1" ||
          retornoApi.cad_proc_procedimento_visu === "1" ||
          isSuper === "1"
        ) {
          setPermCadProcProcedimento(true);
        } else {
          setPermCadProcProcedimento(false);
        }

        if (
          retornoApi.cad_conv_convenio_incluir === "1" ||
          retornoApi.cad_conv_convenio_editar === "1" ||
          retornoApi.cad_conv_convenio_excluir === "1" ||
          retornoApi.cad_conv_convenio_visu === "1" ||
          isSuper === "1"
        ) {
          setPermCadConvConvenio(true);
        } else {
          setPermCadConvConvenio(false);
        }

        if (
          retornoApi.cad_conv_procconvenio_incluir === "1" ||
          retornoApi.cad_conv_procconvenio_editar === "1" ||
          retornoApi.cad_conv_procconvenio_excluir === "1" ||
          retornoApi.cad_conv_procconvenio_visu === "1" ||
          isSuper === "1"
        ) {
          setPermCadConvProcConvenio(true);
        } else {
          setPermCadConvProcConvenio(false);
        }

        if (
          retornoApi.cad_paciente_precadastro_incluir === "1" ||
          // retornoApi.cad_paciente_precadastro_editar === "1" ||
          // retornoApi.cad_paciente_precadastro_excluir === "1" ||
          retornoApi.cad_paciente_precadastro_visu === "1" ||
          isSuper === "1"
        ) {
          setPermCadPacientePreCadastro(true);
        } else {
          setPermCadPacientePreCadastro(false);
        }
        if (
          retornoApi.cad_paciente_paciente_incluir === "1" ||
          retornoApi.cad_paciente_paciente_editar === "1" ||
          retornoApi.cad_paciente_paciente_excluir === "1" ||
          retornoApi.cad_paciente_paciente_visu === "1" ||
          isSuper === "1"
        ) {
          setPermCadPacientePaciente(true);
        } else {
          setPermCadPacientePaciente(false);
        }
        if (
          retornoApi.cad_paciente_anamnese_incluir === "1" ||
          retornoApi.cad_paciente_anamnese_editar === "1" ||
          retornoApi.cad_paciente_anamnese_excluir === "1" ||
          retornoApi.cad_paciente_anamnese_visu === "1" ||
          isSuper === "1"
        ) {
          setPermCadPacienteAnamnese(true);
        } else {
          setPermCadPacienteAnamnese(false);
        }


        if (retornoApi.visualizarconsultas === "1" || isSuper === "1") {

          setPermissaoConsultas(true)
        }

        if (retornoApi.visualizaragenda === "1" || isSuper === "1") {

          setPermissaoAgenda(true)
        }

        if (retornoApi.visualizarautorizacao === "1" || isSuper === "1") {
          setPermissaoAutorizacao(true)
        }


        if (retornoApi.visualizarpendentedefaturamento === "1" || isSuper === "1") {

          setPermissaoPendenteDeFaturamento(true)
        }

        if (retornoApi.visualizarfaturamento === "1" || isSuper === "1") {

          setPermissaoFaturamento(true)
        }

        if (retornoApi.visualizarlancamentodespesas === "1" || isSuper === "1") {

          setPermissaoLancamentoDespesas(true)
        }

        if (retornoApi.visualizarresumofinanceiro === "1" || isSuper === "1") {

          setPermissaoResumoFinanceiro(true)
        }

        if (retornoApi.visualizarprontuario === "1" || isSuper === "1") {

          setPermissaoProntuario(true)
        }

        if (retornoApi.visualizarkpi === "1" || isSuper === "1") {

          setPermissaoKpi(true)
        }
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    if (perfil !== "0" && perfil !== "") buscarDadosPermissao(perfil);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perfil]);

  return (
    <div className="leftside-menu">
      <a href="/" className="logo text-center ">
        <span className="logo-lg">
          <img src={LogoFKL} alt="" style={{ height: "48px" }} />
        </span>
        <span className="logo-sm">
          <img src={LogoSM} alt="" style={{ height: "24px" }} />
        </span>
      </a>

      <div className="h-100" id="leftside-menu-container" data-simplebar>
        <ul className="side-nav">
          {(isSuper === "1" || permAdminUser) && (
            <li className="side-nav-item">
              <a
                data-bs-toggle="collapse"
                href="#Administrativo"
                aria-expanded="false"
                aria-controls="Administrativo"
                className="side-nav-link"
              >
                <span
                  className="iconify"
                  data-icon="clarity:administrator-solid"
                  data-width="22"
                ></span>
                <span style={{ margin: "15px" }}> Administrativo </span>
                <span className="menu-arrow"></span>
              </a>
              <div className="collapse" id="Administrativo">
                <ul className="side-nav-second-level">
                  {isSuper === "1" && (
                    <li>
                      <Link to={"/administrativo/empresa"}>
                        Empresa (Holding)
                      </Link>
                    </li>
                  )}
                  {isSuper === "1" && (
                    <li>
                      <Link to={"/administrativo/permisao"}>
                        Perfil / Permissão
                      </Link>
                    </li>
                  )}
                  {permAdminUser && (
                    <li>
                      <Link to={"/administrativo/usuario"}>Usuário</Link>
                    </li>
                  )}

                  {isSuper === "1" && (
                    <li>
                      <Link to={"/administrativo/codistema"}>
                        Código do Sistema
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )}
          {(permCadFilialClinica ||
            permCadFilialSalasAtend ||
            permsCadFilialAgenSalas ||
            permCadProfProfissionais ||
            permCadProcProcedimento ||
            permCadConvConvenio ||
            permCadConvProcConvenio ||
            permCadPacientePreCadastro ||
            permCadPacientePaciente ||
            permCadPacienteAnamnese) && (
              <li className="side-nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#Cadastro"
                  aria-expanded="false"
                  aria-controls="Cadastro"
                  className="side-nav-link"
                >
                  <span
                    className="iconify"
                    data-icon="fluent:people-list-24-filled"
                    data-width="22"
                  ></span>
                  <span style={{ margin: "15px" }}> Cadastro </span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="collapse" id="Cadastro">
                  <ul className="side-nav-second-level">
                    {(permCadFilialClinica ||
                      permCadFilialSalasAtend ||
                      permsCadFilialAgenSalas) && (
                        <li className="side-nav-item">
                          <a
                            data-bs-toggle="collapse"
                            href="#Filiais"
                            aria-expanded="false"
                            aria-controls="Filiais"
                          >
                            <span> Filiais </span>
                            <span className="menu-arrow"></span>
                          </a>
                          <div className="collapse" id="Filiais">
                            <ul className="side-nav-third-level">
                              {permCadFilialClinica && (
                                <li>
                                  <Link to={"/cadastro/filiais/clinicas"}>
                                    Clinicas
                                  </Link>
                                </li>
                              )}
                              {permCadFilialSalasAtend && (
                                <li>
                                  <Link to={"/cadastro/filiais/salas"}>
                                    Salas de Atendimento
                                  </Link>
                                </li>
                              )}
                              {permsCadFilialAgenSalas && (
                                <li>
                                  <Link to={"/cadastro/filiais/agendasalas"}>
                                    Agenda Sala
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </div>
                        </li>
                      )}
                    {permCadProfProfissionais && (
                      <li className="side-nav-item">
                        <a
                          data-bs-toggle="collapse"
                          href="#Profissionais"
                          aria-expanded="false"
                          aria-controls="Profissionais"
                        >
                          <span> Profissionais </span>
                          <span className="menu-arrow"></span>
                        </a>
                        <div className="collapse" id="Profissionais">
                          <ul className="side-nav-third-level">
                            {permCadProfProfissionais && (
                              <li>
                                <Link
                                  to={"/cadastro/profissionais/profissionais"}
                                >
                                  Profissionais
                                </Link>
                              </li>
                            )}
                          </ul>
                        </div>
                      </li>
                    )}
                    {permCadProcProcedimento && (
                      <li className="side-nav-item">
                        <a
                          data-bs-toggle="collapse"
                          href="#Procedimento"
                          aria-expanded="false"
                          aria-controls="Procedimento"
                        >
                          <span> Procedimentos </span>
                          <span className="menu-arrow"></span>
                        </a>
                        <div className="collapse" id="Procedimento">
                          {permCadProcProcedimento && (
                            <ul className="side-nav-third-level">
                              <li>
                                <Link
                                  to={"/cadastro/procedimentos/procedimentos"}
                                >
                                  Procedimento
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={
                                    "cadastro/procedimentos/procedimentoxprofissional"
                                  }
                                >
                                  Procedimento x Profissional
                                </Link>
                              </li>
                            </ul>
                          )}
                        </div>
                      </li>
                    )}
                    {(permCadConvConvenio || permCadConvProcConvenio) && (
                      <li className="side-nav-item">
                        <a
                          data-bs-toggle="collapse"
                          href="#Convenio"
                          aria-expanded="false"
                          aria-controls="Convenio"
                        >
                          <span> Convênio </span>
                          <span className="menu-arrow"></span>
                        </a>
                        <div className="collapse" id="Convenio">
                          <ul className="side-nav-third-level">
                            {permCadConvConvenio && (
                              <li>
                                <Link to={"/cadastro/convenios/convenios"}>
                                  Convênio
                                </Link>
                              </li>
                            )}
                            {/*
                            {permCadConvProcConvenio && (
                              <li>
                                <Link
                                  to={"/cadastro/convenios/procedimentoconvenios"}
                                >
                                  Procedimento Convênio
                                </Link>
                              </li>
                            )}
                            */}
                          </ul>
                        </div>
                      </li>
                    )}

                    {(permCadPacientePreCadastro ||
                      permCadPacientePaciente ||
                      permCadPacienteAnamnese) && (
                        <li className="side-nav-item">
                          <a
                            data-bs-toggle="collapse"
                            href="#Pacientes"
                            aria-expanded="false"
                            aria-controls="Pacientes"
                          >
                            <span> Paciente </span>
                            <span className="menu-arrow"></span>
                          </a>
                          <div className="collapse" id="Pacientes">
                            <ul className="side-nav-third-level">
                              {permCadPacientePreCadastro && (
                                <li>
                                  <Link to={"/cadastro/pacientes/precadastro"}>
                                    Pré-Cadastro
                                  </Link>
                                </li>
                              )}
                              {permCadPacientePaciente && (
                                <li>
                                  <Link to={"/cadastro/pacientes/pacientes"}>
                                    Paciente
                                  </Link>
                                </li>
                              )}
                              {permCadPacienteAnamnese && (
                                <li>
                                  <Link to={"/cadastro/pacientes/anamneses"}>
                                    Anamnese
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </div>
                        </li>
                      )}
                  </ul>
                </div>
              </li>
            )}
          {permissaoAgenda == true || permissaoConsultas == true ?
            <li className="side-nav-item">
              <a
                data-bs-toggle="collapse"
                href="#Agendamento"
                aria-expanded="false"
                aria-controls="Agendamento"
                className="side-nav-link"
              >
                <span
                  className="iconify"
                  data-icon="ant-design:schedule-outlined"
                  data-width="22"
                ></span>
                <span style={{ margin: "15px" }}> Agendamento </span>
                <span className="menu-arrow"></span>
              </a>
              <div className="collapse" id="Agendamento">
                <ul className="side-nav-second-level">
                  {permissaoConsultas == true ? <li>
                    <Link to={"/agendamento/salasatendimento"}>Consultas</Link>
                  </li> : <></>}
                  {permissaoAgenda == true ? <li>
                    <Link to={"/agendamento/agenda"}>Agenda</Link>
                  </li> : <></>}
                  {/*
                  <li>
                    <Link to={"#"}>Sessões</Link>
                  </li>
                  <li>
                    <Link to={"#"}>Outro Procedimento</Link>
                  </li>
                  */}
                </ul>
              </div>
            </li> : <></>}
          {permissaoAutorizacao == true ? <li className="side-nav-item">
            <a
              data-bs-toggle="collapse"
              href="#Autorizacao"
              aria-expanded="false"
              aria-controls="Autorizacao"
              className="side-nav-link"
            >
              <span
                className="iconify"
                data-icon="raphael:checked"
                data-width="22"
              ></span>
              <span style={{ margin: "15px" }}> Autorizacões </span>
              <span className="menu-arrow"></span>
            </a>
            <div className="collapse" id="Autorizacao">
              <ul className="side-nav-second-level">
                <li>
                  <Link to={"/autorizacoes/autorizacao"}>Autorização</Link>
                </li>
                {/*
                <li>
                  <Link to={"/autorizacoes/autorizacao"}>Lote</Link>
                </li>
                */}

                {/*
                <li className="side-nav-item">
                  <a
                    data-bs-toggle="collapse"
                    href="#Solicitar"
                    aria-expanded="false"
                    aria-controls="Solicitar"
                  >
                    <span> Solicitar </span>
                    <span className="menu-arrow"></span>
                  </a>
                  <div className="collapse" id="Solicitar">
                    <ul className="side-nav-third-level">
                      <li>
                        <Link to={"#"}>Incluir Solicitação por Integração</Link>
                      </li>
                      <li>
                        <Link to={"#"}>Incluir Autorização Manual</Link>
                      </li>
                      <li>
                        <Link to={"#"}>Pendente</Link>
                      </li>
                      <li>
                        <Link to={"#"}>Autorizado</Link>
                      </li>
                    </ul>
                  </div>
                </li>
                */}
              </ul>
            </div>
          </li> : <></>}
          {/*AQUI PERMISSAO DE FATURAMENTO */}
          {permissaoPendenteDeFaturamento == true
            || permissaoFaturamento == true
            || permissaoLancamentoDespesas == true
            || permissaoResumoFinanceiro == true ?
            <li className="side-nav-item">
              <a
                data-bs-toggle="collapse"
                href="#Faturamentos"
                aria-expanded="false"
                aria-controls="Faturamentos"
                className="side-nav-link"
              >
                <span
                  className="iconify"
                  data-icon="la:file-invoice-dollar"
                  data-width="22"
                ></span>
                <span style={{ margin: "15px" }}> Financeiro </span>
                <span className="menu-arrow"></span>
              </a>

              <div className="collapse" id="Faturamentos">
                <ul className="side-nav-second-level">
                  {permissaoPendenteDeFaturamento == true ? <li>
                    <Link to={"/faturamento/pendentes"}>Pendentes de Faturamento</Link>
                  </li> : <></>}
                  {permissaoFaturamento == true ?
                    <li>
                      <Link to={"/faturamento/receitas"}>Faturamento</Link>
                    </li> : <></>}
                  {permissaoLancamentoDespesas == true ?
                    <li>
                      <Link to={"/faturamento/despesas"}>Lançamento de Despesas/Outras Receitas</Link>
                    </li> : <></>}
                  {permissaoResumoFinanceiro == true ?
                    <li>
                      <Link to={"/faturamento/resumo"}>Resumo Financeiro</Link>
                    </li> : <></>}
                </ul>
              </div>
            </li> : <></>}

          {permissaoProntuario == true ? <li className="side-nav-item">
            <a
              data-bs-toggle="collapse"
              href="#Prontuarios"
              aria-expanded="false"
              aria-controls="Prontuarios"
              className="side-nav-link"
            >
              <span
                className="iconify"
                data-icon="clarity:list-line"
                data-width="22"
              ></span>
              <span style={{ margin: "15px" }}> Prontuário </span>
              <span className="menu-arrow"></span>
            </a>
            <div className="collapse" id="Prontuarios">
              <ul className="side-nav-second-level">
                <li>
                  <Link to={"/prontuario/paciente"}>Prontuário</Link>
                </li>
              </ul>
            </div>
          </li> : <></>}
          {permissaoKpi == true ? <li className="side-nav-item">
            <a
              data-bs-toggle="collapse"
              href="#KPI"
              aria-expanded="false"
              aria-controls="KPI"
              className="side-nav-link"
            >
              <span
                className="iconify"
                data-icon="carbon:dashboard"
                data-width="22"
              ></span>
              <span style={{ margin: "15px" }}> KPI's </span>
              <span className="menu-arrow"></span>
            </a>
            <div className="collapse" id="KPI">
              <ul className="side-nav-second-level">
                <li>
                  <Link to={"/kpi/dashboard/dashboard"}>Dashboard</Link>
                </li>
              </ul>
            </div>
          </li> : <></>}
        </ul>
      </div>
    </div>
  );
};

export { LeftBar };
