import { useState, useEffect } from 'react';
import axios from 'axios'; // Importe o axios ou a biblioteca que você está usando para fazer requisições HTTP
import { Api } from "../api/api";

function useSelectOptions(endpoint) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Api + endpoint);
        setOptions(response.data);
      } catch (error) {
        console.error('Erro ao carregar opções:', error);
      }
    };

    fetchData();
  }, [endpoint]);

  return options;
}

export default useSelectOptions;
