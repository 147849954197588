import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../../../components/Formularios/Modal/ModalLoading";
import React, { useState, useEffect } from "react";
import ApiGet from "../../../../../api/endPoints/useGet";
import { toast } from "react-toastify";
import { Card, Col, Form, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../components/Formularios/Inputs/InputsSemBorda";

import {
  Tab,
  Box,
  Switch,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button } from "../../../../../components/Formularios/Buttons/Button";
import { mdiCheckCircle, mdiKeyboardReturn } from "@mdi/js";
import ApiPost from "../../../../../api/endPoints/usePost";
import ApiPut from "../../../../../api/endPoints/usePut";
import { getSessionData } from "../../../../../utils/storageUtils";
import {
  itemsPermissaoAdministrativo,
  itemsPermissaoAgendamento,
  itemsPermissaoAutorizacao,
  itemsPermissaoProntuario,
  itensPermissaoCadastroConvenio,
  itensPermissaoCadastroFiliais,
  itensPermissaoCadastroPaciente,
  itensPermissaoCadastroProcedimentos,
  itensPermissaoCadastroProfissionais,
} from "../itensPermissao";
import { TextoFormatoCaptalize } from "../../../../../components/Auxiliar/ConvTextoCaptalize";

interface FormPerfilProps {
  idperfil?: string;
  acao?: string;
}

const FormPerfil = ({ idperfil, acao }: FormPerfilProps) => {
  const token = getSessionData("MultClinicaWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  const usuario = getSessionData("DadosUsuario") || "";
  const navigate = useNavigate();

  //#region Controla as guias
  const [guia, setGuia] = useState("1");

  const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
    setGuia(novaGuia);
  };
  //#endregion
  //#region Controla as guias permissoes
  const [guiaPermissao, setGuiaPermissao] = useState("1");

  const capturaGuiaPermissao = (
    event: React.SyntheticEvent,
    novaGuiaPermissao: string
  ) => {
    setGuiaPermissao(novaGuiaPermissao);
  };
  //#endregion

  const [id_perfil, setIdPerfil] = useState<string>("");
  const [nome_perfil, setNomePerfil] = useState<string>("");
  const [ativo, setAtivo] = useState<boolean>(true);
  const [master, setMaster] = useState<boolean>(false);

  //#region ===>  PERMISSAO MENU ADMINISTRATIVO

  //DADOS PERMISSAO EMPRESA(HOLDING)
  const [admin_01_EmpresaHolding_1, setAdmin_01_EmpresaHolding_1] =
    useState<boolean>(false);
  const [admin_01_EmpresaHolding_2, setAdmin_01_EmpresaHolding_2] =
    useState<boolean>(false);
  const [admin_01_EmpresaHolding_3, setAdmin_01_EmpresaHolding_3] =
    useState<boolean>(false);
  const [admin_01_EmpresaHolding_4, setAdmin_01_EmpresaHolding_4] =
    useState<boolean>(false);
  const [admin_01_EmpresaHolding_5, setAdmin_01_EmpresaHolding_5] =
    useState<boolean>(false);

  //DADOS PERMISSAO PERFIL(PERMISSOES)
  const [admin_02_PerfilPermissoes_1, setAdmin_02_PerfilPermissoes_1] =
    useState<boolean>(false);
  const [admin_02_PerfilPermissoes_2, setAdmin_02_PerfilPermissoes_2] =
    useState<boolean>(false);
  const [admin_02_PerfilPermissoes_3, setAdmin_02_PerfilPermissoes_3] =
    useState<boolean>(false);
  const [admin_02_PerfilPermissoes_4, setAdmin_02_PerfilPermissoes_4] =
    useState<boolean>(false);
  const [admin_02_PerfilPermissoes_5, setAdmin_02_PerfilPermissoes_5] =
    useState<boolean>(false);

  //DADOS PERMISSAO USUARIO
  const [admin_03_Usuario_1, setAdmin_03_Usuario_1] = useState<boolean>(false);
  const [admin_03_Usuario_2, setAdmin_03_Usuario_2] = useState<boolean>(false);
  const [admin_03_Usuario_3, setAdmin_03_Usuario_3] = useState<boolean>(false);
  const [admin_03_Usuario_4, setAdmin_03_Usuario_4] = useState<boolean>(false);
  const [admin_03_Usuario_5, setAdmin_03_Usuario_5] = useState<boolean>(false);

  //DADOS CODIGOS DO SISTEMA
  const [admin_04_CodigoSistema_1, setAdmin_04_CodigoSistema_1] =
    useState<boolean>(false);
  const [admin_04_CodigoSistema_2, setAdmin_04_CodigoSistema_2] =
    useState<boolean>(false);
  const [admin_04_CodigoSistema_3, setAdmin_04_CodigoSistema_3] =
    useState<boolean>(false);
  const [admin_04_CodigoSistema_4, setAdmin_04_CodigoSistema_4] =
    useState<boolean>(false);
  const [admin_04_CodigoSistema_5, setAdmin_04_CodigoSistema_5] =
    useState<boolean>(false);

  //#endregion

  //#region ===> PERMISSAO MENU CADASTRO

  //#region PERMISSAO CADASTRO FILIAIS
  //DADOS PERMISSAO CADASTRO FILIAIS -> CLINICAS
  const [cad_01_Clinicas_1, setCad_01_Clinicas_1] = useState<boolean>(false);
  const [cad_01_Clinicas_2, setCad_01_Clinicas_2] = useState<boolean>(false);
  const [cad_01_Clinicas_3, setCad_01_Clinicas_3] = useState<boolean>(false);
  const [cad_01_Clinicas_4, setCad_01_Clinicas_4] = useState<boolean>(false);
  const [cad_01_Clinicas_5, setCad_01_Clinicas_5] = useState<boolean>(false);

  //DADOS PERMISSAO CADASTRO FILIAIS -> SALAS DE ATENDIMENTO
  const [cad_02_SalasAtendimento_1, setCad_02_SalasAtendimento_1] =
    useState<boolean>(false);
  const [cad_02_SalasAtendimento_2, setCad_02_SalasAtendimento_2] =
    useState<boolean>(false);
  const [cad_02_SalasAtendimento_3, setCad_02_SalasAtendimento_3] =
    useState<boolean>(false);
  const [cad_02_SalasAtendimento_4, setCad_02_SalasAtendimento_4] =
    useState<boolean>(false);
  const [cad_02_SalasAtendimento_5, setCad_02_SalasAtendimento_5] =
    useState<boolean>(false);

  //DADOS PERMISSAO CADASTRO FILIAIS -> AGENDA SALA
  const [cad_03_AgendaSala_1, setCad_03_AgendaSala_1] =
    useState<boolean>(false);
  const [cad_03_AgendaSala_2, setCad_03_AgendaSala_2] =
    useState<boolean>(false);
  const [cad_03_AgendaSala_3, setCad_03_AgendaSala_3] =
    useState<boolean>(false);
  const [cad_03_AgendaSala_4, setCad_03_AgendaSala_4] =
    useState<boolean>(false);
  const [cad_03_AgendaSala_5, setCad_03_AgendaSala_5] =
    useState<boolean>(false);

  //#endregion

  //#region PERMISSAO CADASTRO PROFISSIONAIS

  //DADOS PERMISSAO CADASTRO PROFISSIONAIS -> PROFISSIONAIS
  const [cad_04_Profissionais_1, setCad_04_Profissionais_1] =
    useState<boolean>(false);
  const [cad_04_Profissionais_2, setCad_04_Profissionais_2] =
    useState<boolean>(false);
  const [cad_04_Profissionais_3, setCad_04_Profissionais_3] =
    useState<boolean>(false);
  const [cad_04_Profissionais_4, setCad_04_Profissionais_4] =
    useState<boolean>(false);
  const [cad_04_Profissionais_5, setCad_04_Profissionais_5] =
    useState<boolean>(false);

  //#endregion

  //#region PERMISSAO CADASTRO PROCEDIMENTOS

  //DADOS PERMISSAO CADASTRO PROCEDIMENTOS -> PROCEDIMENTOS
  const [cad_05_Procedimentos_1, setCad_05_Procedimentos_1] =
    useState<boolean>(false);
  const [cad_05_Procedimentos_2, setCad_05_Procedimentos_2] =
    useState<boolean>(false);
  const [cad_05_Procedimentos_3, setCad_05_Procedimentos_3] =
    useState<boolean>(false);
  const [cad_05_Procedimentos_4, setCad_05_Procedimentos_4] =
    useState<boolean>(false);
  const [cad_05_Procedimentos_5, setCad_05_Procedimentos_5] =
    useState<boolean>(false);

  //#endregion

  //#region PERMISSAO CADASTRO CONVENIO

  //DADOS PERMISSAO CADASTRO CONVENIO -> CONVENIO
  const [cad_06_Convenio_1, setCad_06_Convenio_1] = useState<boolean>(false);
  const [cad_06_Convenio_2, setCad_06_Convenio_2] = useState<boolean>(false);
  const [cad_06_Convenio_3, setCad_06_Convenio_3] = useState<boolean>(false);
  const [cad_06_Convenio_4, setCad_06_Convenio_4] = useState<boolean>(false);
  const [cad_06_Convenio_5, setCad_06_Convenio_5] = useState<boolean>(false);

  //DADOS PERMISSAO CADASTRO CONVENIO -> PROCEDIMENTO CONVENIO
  const [cad_06_ProcedimentoConvenio_1, setCad_06_ProcedimentoConvenio_1] =
    useState<boolean>(false);
  const [cad_06_ProcedimentoConvenio_2, setCad_06_ProcedimentoConvenio_2] =
    useState<boolean>(false);
  const [cad_06_ProcedimentoConvenio_3, setCad_06_ProcedimentoConvenio_3] =
    useState<boolean>(false);
  const [cad_06_ProcedimentoConvenio_4, setCad_06_ProcedimentoConvenio_4] =
    useState<boolean>(false);
  const [cad_06_ProcedimentoConvenio_5, setCad_06_ProcedimentoConvenio_5] =
    useState<boolean>(false);

  //#endregion

  //#region PERMISSAO CADASTRO PACIENTE

  //DADOS PERMISSAO CADASTRO PACIENTE -> PRE-CADASTRO
  const [cad_07_PreCadastro_1, setCad_07_PreCadastro_1] =
    useState<boolean>(false);
  const [cad_07_PreCadastro_2, setCad_07_PreCadastro_2] =
    useState<boolean>(false);
  const [cad_07_PreCadastro_3, setCad_07_PreCadastro_3] =
    useState<boolean>(false);
  const [cad_07_PreCadastro_4, setCad_07_PreCadastro_4] =
    useState<boolean>(false);
  const [cad_07_PreCadastro_5, setCad_07_PreCadastro_5] =
    useState<boolean>(false);

  //DADOS PERMISSAO CADASTRO PACIENTE -> PACIENTE
  const [cad_08_Paciente_1, setCad_08_Paciente_1] = useState<boolean>(false);
  const [cad_08_Paciente_2, setCad_08_Paciente_2] = useState<boolean>(false);
  const [cad_08_Paciente_3, setCad_08_Paciente_3] = useState<boolean>(false);
  const [cad_08_Paciente_4, setCad_08_Paciente_4] = useState<boolean>(false);
  const [cad_08_Paciente_5, setCad_08_Paciente_5] = useState<boolean>(false);

  //DADOS PERMISSAO CADASTRO PACIENTE -> ANAMNESE
  const [cad_09_Anamnese_1, setCad_09_Anamnese_1] = useState<boolean>(false);
  const [cad_09_Anamnese_2, setCad_09_Anamnese_2] = useState<boolean>(false);
  const [cad_09_Anamnese_3, setCad_09_Anamnese_3] = useState<boolean>(false);
  const [cad_09_Anamnese_4, setCad_09_Anamnese_4] = useState<boolean>(false);
  const [cad_09_Anamnese_5, setCad_09_Anamnese_5] = useState<boolean>(false);

  //#endregion

  //#endregion

  //#region REALIZA REQUISIÇÃO PARA EXIBIR OS DADOS DO USUÁRIO
  const [loading, setLoading] = useState<boolean>(false);
  const carregarDados = async (idperfil: any) => {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/perfil/carregar/:idperfil",
      parametros: {
        idperfil: idperfil,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setIdPerfil(retorno[0].id_perfil);
        setNomePerfil(retorno[0].nome_perfil);
        if (retorno[0].situacao === 1) {
          setAtivo(true);
        } else {
          setAtivo(false);
        }
        if (retorno[0].master === 1) {
          setMaster(true);
        } else {
          setMaster(false);
        }

        //PERMISSOES ADMINISTRATIVO -> EMPRESA HOLDING
        setAdmin_01_EmpresaHolding_1(
          retorno[0].admin_empr_visu === "1" ? true : false
        );
        setAdmin_01_EmpresaHolding_2(
          retorno[0].admin_empr_incluir === "1" ? true : false
        );
        setAdmin_01_EmpresaHolding_3(
          retorno[0].admin_empr_editar === "1" ? true : false
        );
        setAdmin_01_EmpresaHolding_4(
          retorno[0].admin_empr_excluir === "1" ? true : false
        );

        //PERMISSOES ADMINISTRATIVO -> PERFIL PERMISSOES
        setAdmin_02_PerfilPermissoes_1(
          retorno[0].admin_perfil_visu === "1" ? true : false
        );
        setAdmin_02_PerfilPermissoes_2(
          retorno[0].admin_perfil_incluir === "1" ? true : false
        );
        setAdmin_02_PerfilPermissoes_3(
          retorno[0].admin_perfil_editar === "1" ? true : false
        );
        setAdmin_02_PerfilPermissoes_4(
          retorno[0].admin_perfil_excluir === "1" ? true : false
        );

        //PERMISSOES ADMINISTRATIVO -> USUARIO
        setAdmin_03_Usuario_1(
          retorno[0].admin_user_visu === "1" ? true : false
        );
        setAdmin_03_Usuario_2(
          retorno[0].admin_user_incluir === "1" ? true : false
        );
        setAdmin_03_Usuario_3(
          retorno[0].admin_user_editar === "1" ? true : false
        );
        setAdmin_03_Usuario_4(
          retorno[0].admin_user_excluir === "1" ? true : false
        );

        //PERMISSOES ADMINISTRATIVO -> CODIGO DO SISTEMA
        setAdmin_04_CodigoSistema_1(
          retorno[0].admin_codsis_visu === "1" ? true : false
        );
        setAdmin_04_CodigoSistema_2(
          retorno[0].admin_codsis_incluir === "1" ? true : false
        );
        setAdmin_04_CodigoSistema_3(
          retorno[0].admin_codsis_editar === "1" ? true : false
        );
        setAdmin_04_CodigoSistema_4(
          retorno[0].admin_codsis_excluir === "1" ? true : false
        );

        //PERMISSOES CADASTRO -> FILIAIS -> CLINICAS
        setCad_01_Clinicas_1(
          retorno[0].cad_filiais_clinica_visu === "1" ? true : false
        );
        setCad_01_Clinicas_2(
          retorno[0].cad_filiais_clinica_incluir === "1" ? true : false
        );
        setCad_01_Clinicas_3(
          retorno[0].cad_filiais_clinica_editar === "1" ? true : false
        );
        setCad_01_Clinicas_4(
          retorno[0].cad_filiais_clinica_excluir === "1" ? true : false
        );

        //PERMISSOES CADASTRO -> FILIAIS -> SALAS DE ATENDIMENTO
        setCad_02_SalasAtendimento_1(
          retorno[0].cad_filiais_sala_atend_visu === "1" ? true : false
        );
        setCad_02_SalasAtendimento_2(
          retorno[0].cad_filiais_sala_atend_incluir === "1" ? true : false
        );
        setCad_02_SalasAtendimento_3(
          retorno[0].cad_filiais_sala_atend_editar === "1" ? true : false
        );
        setCad_02_SalasAtendimento_4(
          retorno[0].cad_filiais_sala_atend_excluir === "1" ? true : false
        );

        //PERMISSOES CADASTRO -> FILIAIS -> AGENDA SALA
        setCad_03_AgendaSala_1(
          retorno[0].cad_filiais_age_sala_visu === "1" ? true : false
        );
        setCad_03_AgendaSala_2(
          retorno[0].cad_filiais_age_sala_incluir === "1" ? true : false
        );
        setCad_03_AgendaSala_3(
          retorno[0].cad_filiais_age_sala_editar === "1" ? true : false
        );
        setCad_03_AgendaSala_4(
          retorno[0].cad_filiais_age_sala_excluir === "1" ? true : false
        );

        //PERMISSOES CADASTRO -> PROFISSIONAIS -> PROFISSIONAIS
        setCad_04_Profissionais_1(
          retorno[0].cad_prof_profissional_visu === "1" ? true : false
        );
        setCad_04_Profissionais_2(
          retorno[0].cad_prof_profissional_incluir === "1" ? true : false
        );
        setCad_04_Profissionais_3(
          retorno[0].cad_prof_profissional_editar === "1" ? true : false
        );
        setCad_04_Profissionais_4(
          retorno[0].cad_prof_profissional_excluir === "1" ? true : false
        );

        //PERMISSOES CADASTRO -> PROCEDIMENTOS -> PROCEDIMENTOS
        setCad_05_Procedimentos_1(
          retorno[0].cad_proc_procedimento_visu === "1" ? true : false
        );
        setCad_05_Procedimentos_2(
          retorno[0].cad_proc_procedimento_incluir === "1" ? true : false
        );
        setCad_05_Procedimentos_3(
          retorno[0].cad_proc_procedimento_editar === "1" ? true : false
        );
        setCad_05_Procedimentos_4(
          retorno[0].cad_proc_procedimento_excluir === "1" ? true : false
        );

        //PERMISSOES CADASTRO -> CONVENIO -> CONVENIO
        setCad_06_Convenio_1(
          retorno[0].cad_conv_convenio_visu === "1" ? true : false
        );
        setCad_06_Convenio_2(
          retorno[0].cad_conv_convenio_incluir === "1" ? true : false
        );
        setCad_06_Convenio_3(
          retorno[0].cad_conv_convenio_editar === "1" ? true : false
        );
        setCad_06_Convenio_4(
          retorno[0].cad_conv_convenio_excluir === "1" ? true : false
        );

        //PERMISSOES CADASTRO -> CONVENIO -> PROCEDIMENTO CONVENIO
        setCad_06_ProcedimentoConvenio_1(
          retorno[0].cad_conv_procconvenio_visu === "1" ? true : false
        );
        setCad_06_ProcedimentoConvenio_2(
          retorno[0].cad_conv_procconvenio_incluir === "1" ? true : false
        );
        setCad_06_ProcedimentoConvenio_3(
          retorno[0].cad_conv_procconvenio_editar === "1" ? true : false
        );
        setCad_06_ProcedimentoConvenio_4(
          retorno[0].cad_conv_procconvenio_excluir === "1" ? true : false
        );

        //PERMISSOES CADASTRO -> PACIENTE -> PRE-CADASTRO
        setCad_07_PreCadastro_1(
          retorno[0].cad_paciente_precadastro_visu === "1" ? true : false
        );
        setCad_07_PreCadastro_2(
          retorno[0].cad_paciente_precadastro_incluir === "1" ? true : false
        );
        setCad_07_PreCadastro_3(
          retorno[0].cad_paciente_precadastro_editar === "1" ? true : false
        );
        setCad_07_PreCadastro_4(
          retorno[0].cad_paciente_precadastro_excluir === "1" ? true : false
        );

        //PERMISSOES CADASTRO -> PACIENTE -> PACIENTE
        setCad_08_Paciente_1(
          retorno[0].cad_paciente_paciente_visu === "1" ? true : false
        );
        setCad_08_Paciente_2(
          retorno[0].cad_paciente_paciente_incluir === "1" ? true : false
        );
        setCad_08_Paciente_3(
          retorno[0].cad_paciente_paciente_editar === "1" ? true : false
        );
        setCad_08_Paciente_4(
          retorno[0].cad_paciente_paciente_excluir === "1" ? true : false
        );

        //PERMISSOES CADASTRO -> PACIENTE -> ANAMNESE
        setCad_09_Anamnese_1(
          retorno[0].cad_paciente_anamnese_visu === "1" ? true : false
        );
        setCad_09_Anamnese_2(
          retorno[0].cad_paciente_anamnese_incluir === "1" ? true : false
        );
        setCad_09_Anamnese_3(
          retorno[0].cad_paciente_anamnese_editar === "1" ? true : false
        );
        setCad_09_Anamnese_4(
          retorno[0].cad_paciente_anamnese_excluir === "1" ? true : false
        );

        //AGENDAMENTO - CONSULTAS
        setCriarConsultas(retorno[0].criarconsultas === "1" ? true : false)
        setVisualizarConsultas(retorno[0].visualizarconsultas === "1" ? true : false)
        setEditarConsultas(retorno[0].editarconsultas === "1" ? true : false)
        setExcluirConsultas(retorno[0].excluirconsultas === "1" ? true : false)

        if (retorno[0].criarconsultas === "1" && retorno[0].visualizarconsultas === "1"
          && retorno[0].editarconsultas === "1" && retorno[0].excluirconsultas === "1") {

          setAcessoTotalConsultas(true)
        }

        setCriarAgenda(retorno[0].criaragenda === "1" ? true : false)
        setVisualizarAgenda(retorno[0].visualizaragenda === "1" ? true : false)
        setEditarAgenda(retorno[0].editaragenda === "1" ? true : false)
        setExcluirAgenda(retorno[0].excluiragenda === "1" ? true : false)

        if (retorno[0].criaragenda === "1" && retorno[0].visualizaragenda === "1"
          && retorno[0].editaragenda === "1" && retorno[0].excluiragenda === "1") {

          setAcessoTotalAgenda(true)
        }

        setGerarAutorizacao(retorno[0].criarautorizacao === "1" ? true : false)
        setVisualizarAutorizacao(retorno[0].visualizarautorizacao === "1" ? true : false)
        setEditarAutorizacao(retorno[0].editarautorizacao === "1" ? true : false)

        if (retorno[0].criarautorizacao === "1" && retorno[0].visualizarautorizacao === "1"
          && retorno[0].editarautorizacao === "1" && retorno[0].excluirautorizacao === "1") {

          setAcessoTotalAutorizacao(true)
        }

        setCriarPendenteDeFaturamento(retorno[0].criarpendentedefaturamento === "1" ? true : false)
        setVisualizarPendenteDeFaturamento(retorno[0].visualizarpendentedefaturamento === "1" ? true : false)
        setEditarPendenteDeFaturamento(retorno[0].editarpendentedefaturamento === "1" ? true : false)
        setExcluirPendenteDeFaturamento(retorno[0].excluirpendentedefaturamento === "1" ? true : false)

        if (retorno[0].criarpendentedefaturamento === "1" && retorno[0].visualizarpendentedefaturamento === "1"
          && retorno[0].editarpendentedefaturamento === "1" && retorno[0].excluirpendentedefaturamento === "1") {

          setAcessoTotalPendenteDeFaturamento(true)
        }


        setCriarFaturamento(retorno[0].criarfaturamento === "1" ? true : false)
        setVisualizarFaturamento(retorno[0].visualizarfaturamento === "1" ? true : false)

        if (retorno[0].criarfaturamento === "1" && retorno[0].visualizarfaturamento === "1") {

          setAcessoTotalFaturamento(true)
        }

        setCriarLancamentoDespesas(retorno[0].criarlancamentodespesas === "1" ? true : false)
        setVisualizarLancamentoDespesas(retorno[0].visualizarlancamentodespesas === "1" ? true : false)
        setEditarLancamentoDespesas(retorno[0].editarlancamentodespesas === "1" ? true : false)

        if (retorno[0].criarlancamentodespesas === "1" && retorno[0].visualizarlancamentodespesas === "1"
          && retorno[0].editarlancamentodespesas === "1") {

          setAcessoTotalLancamentoDespesas(true)
        }

        setVisualizarResumoFinanceiro(retorno[0].visualizarresumofinanceiro === "1" ? true : false)

        setVisualizarProntuario(retorno[0].visualizarprontuario === "1" ? true : false)
        setCriarProntuario(retorno[0].criarprontuario === "1" ? true :false)
        setEditarProntuario(retorno[0].editarprontuario === "1" ? true : false)

        if (retorno[0].visualizarprontuario == true && retorno[0].editarprontuario == true) {

          setAcessoTotalProntuario(true)
        }

        setVisualizarKPi(retorno[0].visualizarkpi === "1" ? true : false)

        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        toast.error(`Erro ao carregar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    if (idperfil !== "novo") {
      carregarDados(idperfil);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idperfil]);
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS DO USUÁRIO
  const atualizarDados = async (
    id_perfil: string,
    nome_perfil: string,
    situacao: number,
    usuario: string,
    permissoes: any
  ) => {
    const dados = {
      nome_perfil: TextoFormatoCaptalize(nome_perfil.toLowerCase()),
      situacao,
      usuario,
      permissoes,
    };

    ApiPut(`/perfil/atualizar/${id_perfil}`, dados, token)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        toast.error(
          `Erro ao atualizar dados. Motivo: ${erro.response.data.message}`
        );
      });
  };
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS DO USUÁRIO
  const gravarDados = (
    nome_perfil: string,
    situacao: number,
    master: number,
    usuario: string,
    permissoes: any
  ) => {
    const dados = {
      nome_perfil: TextoFormatoCaptalize(nome_perfil.toLowerCase()),
      situacao,
      master,
      usuario,
      permissoes,
    };
    ApiPost("/perfil/gravar", dados, token)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };
  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const permisao = {
      Administrativo: {
        EmpresaHolding: [
          {
            admin_01_EmpresaHolding_1: admin_01_EmpresaHolding_1 ? 1 : 0,
            admin_01_EmpresaHolding_2: admin_01_EmpresaHolding_2 ? 1 : 0,
            admin_01_EmpresaHolding_3: admin_01_EmpresaHolding_3 ? 1 : 0,
            admin_01_EmpresaHolding_4: admin_01_EmpresaHolding_4 ? 1 : 0,
            admin_01_EmpresaHolding_5: admin_01_EmpresaHolding_5 ? 1 : 0,
          },
        ],
        PerfilPermissoes: [
          {
            admin_02_PerfilPermissoes_1: admin_02_PerfilPermissoes_1 ? 1 : 0,
            admin_02_PerfilPermissoes_2: admin_02_PerfilPermissoes_2 ? 1 : 0,
            admin_02_PerfilPermissoes_3: admin_02_PerfilPermissoes_3 ? 1 : 0,
            admin_02_PerfilPermissoes_4: admin_02_PerfilPermissoes_4 ? 1 : 0,
            admin_02_PerfilPermissoes_5: admin_02_PerfilPermissoes_5 ? 1 : 0,
          },
        ],
        Usuario: [
          {
            admin_03_Usuario_1: admin_03_Usuario_1 ? 1 : 0,
            admin_03_Usuario_2: admin_03_Usuario_2 ? 1 : 0,
            admin_03_Usuario_3: admin_03_Usuario_3 ? 1 : 0,
            admin_03_Usuario_4: admin_03_Usuario_4 ? 1 : 0,
            admin_03_Usuario_5: admin_03_Usuario_5 ? 1 : 0,
          },
        ],
        CodigoSistema: [
          {
            admin_04_CodigoSistema_1: admin_04_CodigoSistema_1 ? 1 : 0,
            admin_04_CodigoSistema_2: admin_04_CodigoSistema_2 ? 1 : 0,
            admin_04_CodigoSistema_3: admin_04_CodigoSistema_3 ? 1 : 0,
            admin_04_CodigoSistema_4: admin_04_CodigoSistema_4 ? 1 : 0,
            admin_04_CodigoSistema_5: admin_04_CodigoSistema_5 ? 1 : 0,
          },
        ],
      },
      Cadastro: {
        Filiais: [
          {
            cad_01_Clinicas_1: cad_01_Clinicas_1 ? 1 : 0,
            cad_01_Clinicas_2: cad_01_Clinicas_2 ? 1 : 0,
            cad_01_Clinicas_3: cad_01_Clinicas_3 ? 1 : 0,
            cad_01_Clinicas_4: cad_01_Clinicas_4 ? 1 : 0,
            cad_01_Clinicas_5: cad_01_Clinicas_5 ? 1 : 0,
          },
          {
            cad_02_SalasAtendimento_1: cad_02_SalasAtendimento_1 ? 1 : 0,
            cad_02_SalasAtendimento_2: cad_02_SalasAtendimento_2 ? 1 : 0,
            cad_02_SalasAtendimento_3: cad_02_SalasAtendimento_3 ? 1 : 0,
            cad_02_SalasAtendimento_4: cad_02_SalasAtendimento_4 ? 1 : 0,
            cad_02_SalasAtendimento_5: cad_02_SalasAtendimento_5 ? 1 : 0,
          },
          {
            cad_03_AgendaSala_1: cad_03_AgendaSala_1 ? 1 : 0,
            cad_03_AgendaSala_2: cad_03_AgendaSala_2 ? 1 : 0,
            cad_03_AgendaSala_3: cad_03_AgendaSala_3 ? 1 : 0,
            cad_03_AgendaSala_4: cad_03_AgendaSala_4 ? 1 : 0,
            cad_03_AgendaSala_5: cad_03_AgendaSala_5 ? 1 : 0,
          },
        ],
        Profissionais: [
          {
            cad_04_Profissionais_1: cad_04_Profissionais_1 ? 1 : 0,
            cad_04_Profissionais_2: cad_04_Profissionais_2 ? 1 : 0,
            cad_04_Profissionais_3: cad_04_Profissionais_3 ? 1 : 0,
            cad_04_Profissionais_4: cad_04_Profissionais_4 ? 1 : 0,
            cad_04_Profissionais_5: cad_04_Profissionais_5 ? 1 : 0,
          },
        ],
        Procedimentos: [
          {
            cad_05_Procedimentos_1: cad_05_Procedimentos_1 ? 1 : 0,
            cad_05_Procedimentos_2: cad_05_Procedimentos_2 ? 1 : 0,
            cad_05_Procedimentos_3: cad_05_Procedimentos_3 ? 1 : 0,
            cad_05_Procedimentos_4: cad_05_Procedimentos_4 ? 1 : 0,
            cad_05_Procedimentos_5: cad_05_Procedimentos_5 ? 1 : 0,
          },
        ],
        Convenio: [
          {
            cad_06_Convenio_1: cad_06_Convenio_1 ? 1 : 0,
            cad_06_Convenio_2: cad_06_Convenio_2 ? 1 : 0,
            cad_06_Convenio_3: cad_06_Convenio_3 ? 1 : 0,
            cad_06_Convenio_4: cad_06_Convenio_4 ? 1 : 0,
            cad_06_Convenio_5: cad_06_Convenio_5 ? 1 : 0,
          },
          {
            cad_06_ProcedimentoConvenio_1: cad_06_ProcedimentoConvenio_1
              ? 1
              : 0,
            cad_06_ProcedimentoConvenio_2: cad_06_ProcedimentoConvenio_2
              ? 1
              : 0,
            cad_06_ProcedimentoConvenio_3: cad_06_ProcedimentoConvenio_3
              ? 1
              : 0,
            cad_06_ProcedimentoConvenio_4: cad_06_ProcedimentoConvenio_4
              ? 1
              : 0,
            cad_06_ProcedimentoConvenio_5: cad_06_ProcedimentoConvenio_5
              ? 1
              : 0,
          },
        ],
        Paciente: [
          {
            cad_07_PreCadastro_1: cad_07_PreCadastro_1 ? 1 : 0,
            cad_07_PreCadastro_2: cad_07_PreCadastro_2 ? 1 : 0,
            cad_07_PreCadastro_3: cad_07_PreCadastro_3 ? 1 : 0,
            cad_07_PreCadastro_4: cad_07_PreCadastro_4 ? 1 : 0,
            cad_07_PreCadastro_5: cad_07_PreCadastro_5 ? 1 : 0,
          },
          {
            cad_08_Paciente_1: cad_08_Paciente_1 ? 1 : 0,
            cad_08_Paciente_2: cad_08_Paciente_2 ? 1 : 0,
            cad_08_Paciente_3: cad_08_Paciente_3 ? 1 : 0,
            cad_08_Paciente_4: cad_08_Paciente_4 ? 1 : 0,
            cad_08_Paciente_5: cad_08_Paciente_5 ? 1 : 0,
          },
          {
            cad_09_Anamnese_1: cad_09_Anamnese_1 ? 1 : 0,
            cad_09_Anamnese_2: cad_09_Anamnese_2 ? 1 : 0,
            cad_09_Anamnese_3: cad_09_Anamnese_3 ? 1 : 0,
            cad_09_Anamnese_4: cad_09_Anamnese_4 ? 1 : 0,
            cad_09_Anamnese_5: cad_09_Anamnese_5 ? 1 : 0,
          },
        ],
      },
      Agendamento: {
        Consultas: [
          {
            Criar: CriarConsultas ? 1 : 0,
            Visualizar: VisualizarConsultas ? 1 : 0,
            Editar: EditarConsultas ? 1 : 0,
            Excluir: ExcluirConsultas ? 1 : 0
          }
        ],
        Agenda: [
          {
            Criar: CriarAgenda ? 1 : 0,
            Visualizar: VisualizarAgenda ? 1 : 0,
            Editar: EditarAgenda ? 1 : 0,
            Excluir: ExcluirAgenda ? 1 : 0
          }
        ]
      },
      Autorizacao: {
        Criar: GerarAutorizacao ? 1 : 0,
        Visualizar: VisualizarAutorizacao ? 1 : 0,
        Editar: EditarAutorizacao ? 1 : 0
      },
      Financeiro: {
        PendenteDeFaturamento: {
          Criar: CriarPendenteDeFaturamento ? 1 : 0,
          Editar: EditarPendenteDeFaturamento ? 1 : 0,
          Excluir: ExcluirPendenteDeFaturamento ? 1 : 0,
          Visualizar: VisualizarPendenteDeFaturamento ? 1 : 0
        },
        Faturamento: {
          Criar: CriarFaturamento ? 1 : 0,
          Visualizar: VisualizarFaturamento ? 1 : 0
        },
        LancamentoDespesas: {
          Criar: CriarLancamentoDespesas ? 1 : 0,
          Editar: EditarLancamentoDespesas ? 1 : 0,
          Visualizar: VisualizarLancamentoDespesas ? 1 : 0
        },
        ResumoFinanceiro: {
          Visualizar: VisualizarResumoFinanceiro ? 1 : 0
        }
      },
      Prontuario: {
        Editar: EditarProntuario ? 1 : 0,
        Visualizar: VisualizarProntuario ? 1 : 0,
        Criar: CriarProntuario ? 1 : 0
      },
      Kpi: {
        Visualizar: VisualizarKPi ? 1 : 0
      }
    };
    if (idperfil === "novo") {
      gravarDados(
        nome_perfil,
        ativo ? 1 : 0,
        master ? 1 : 0,
        usuario,
        permisao
      );
      toast.info("Inserindo novo registro...");
    } else {
      atualizarDados(id_perfil, nome_perfil, ativo ? 1 : 0, usuario, permisao);
      toast.info("Atualizando dados do registro...");
    }
  };

  useEffect(() => {
    if (
      admin_01_EmpresaHolding_2 ||
      admin_01_EmpresaHolding_3 ||
      admin_01_EmpresaHolding_4
    ) {
      setAdmin_01_EmpresaHolding_1(true);
    }

    if (
      admin_02_PerfilPermissoes_2 ||
      admin_02_PerfilPermissoes_3 ||
      admin_02_PerfilPermissoes_4
    ) {
      setAdmin_02_PerfilPermissoes_1(true);
    }

    if (admin_03_Usuario_2 || admin_03_Usuario_3 || admin_03_Usuario_4) {
      setAdmin_03_Usuario_1(true);
    }

    if (
      admin_04_CodigoSistema_2 ||
      admin_04_CodigoSistema_3 ||
      admin_04_CodigoSistema_4
    ) {
      setAdmin_04_CodigoSistema_1(true);
    }

    if (admin_01_EmpresaHolding_5) {
      setAdmin_01_EmpresaHolding_1(true);
      setAdmin_01_EmpresaHolding_2(true);
      setAdmin_01_EmpresaHolding_3(true);
      setAdmin_01_EmpresaHolding_4(true);
    }

    if (admin_02_PerfilPermissoes_5) {
      setAdmin_02_PerfilPermissoes_1(true);
      setAdmin_02_PerfilPermissoes_2(true);
      setAdmin_02_PerfilPermissoes_3(true);
      setAdmin_02_PerfilPermissoes_4(true);
    }

    if (admin_03_Usuario_5) {
      setAdmin_03_Usuario_1(true);
      setAdmin_03_Usuario_2(true);
      setAdmin_03_Usuario_3(true);
      setAdmin_03_Usuario_4(true);
    }

    if (admin_04_CodigoSistema_5) {
      setAdmin_04_CodigoSistema_1(true);
      setAdmin_04_CodigoSistema_2(true);
      setAdmin_04_CodigoSistema_3(true);
      setAdmin_04_CodigoSistema_4(true);
    }

    if (cad_01_Clinicas_2 || cad_01_Clinicas_3 || cad_01_Clinicas_4) {
      setCad_01_Clinicas_1(true);
    }

    if (
      cad_02_SalasAtendimento_2 ||
      cad_02_SalasAtendimento_3 ||
      cad_02_SalasAtendimento_4
    ) {
      setCad_02_SalasAtendimento_1(true);
    }

    if (cad_03_AgendaSala_2 || cad_03_AgendaSala_3 || cad_03_AgendaSala_4) {
      setCad_03_AgendaSala_1(true);
    }

    if (
      cad_04_Profissionais_2 ||
      cad_04_Profissionais_3 ||
      cad_04_Profissionais_4
    ) {
      setCad_04_Profissionais_1(true);
    }

    if (
      cad_05_Procedimentos_2 ||
      cad_05_Procedimentos_3 ||
      cad_05_Procedimentos_4
    ) {
      setCad_05_Procedimentos_1(true);
    }

    if (cad_06_Convenio_2 || cad_06_Convenio_3 || cad_06_Convenio_4) {
      setCad_06_Convenio_1(true);
    }

    if (
      cad_06_ProcedimentoConvenio_2 ||
      cad_06_ProcedimentoConvenio_3 ||
      cad_06_ProcedimentoConvenio_4
    ) {
      setCad_06_ProcedimentoConvenio_1(true);
    }

    if (cad_07_PreCadastro_2 || cad_07_PreCadastro_3 || cad_07_PreCadastro_4) {
      setCad_07_PreCadastro_1(true);
    }

    if (cad_08_Paciente_2 || cad_08_Paciente_3 || cad_08_Paciente_4) {
      setCad_08_Paciente_1(true);
    }

    if (cad_09_Anamnese_2 || cad_09_Anamnese_3 || cad_09_Anamnese_4) {
      setCad_09_Anamnese_1(true);
    }

    if (cad_01_Clinicas_5) {
      setCad_01_Clinicas_1(true);
      setCad_01_Clinicas_2(true);
      setCad_01_Clinicas_3(true);
      setCad_01_Clinicas_4(true);
    }
    if (cad_02_SalasAtendimento_5) {
      setCad_02_SalasAtendimento_1(true);
      setCad_02_SalasAtendimento_2(true);
      setCad_02_SalasAtendimento_3(true);
      setCad_02_SalasAtendimento_4(true);
    }

    if (cad_03_AgendaSala_5) {
      setCad_03_AgendaSala_1(true);
      setCad_03_AgendaSala_2(true);
      setCad_03_AgendaSala_3(true);
      setCad_03_AgendaSala_4(true);
    }

    if (cad_04_Profissionais_5) {
      setCad_04_Profissionais_1(true);
      setCad_04_Profissionais_2(true);
      setCad_04_Profissionais_3(true);
      setCad_04_Profissionais_4(true);
    }

    if (cad_05_Procedimentos_5) {
      setCad_05_Procedimentos_1(true);
      setCad_05_Procedimentos_2(true);
      setCad_05_Procedimentos_3(true);
      setCad_05_Procedimentos_4(true);
    }

    if (cad_06_Convenio_5) {
      setCad_06_Convenio_1(true);
      setCad_06_Convenio_2(true);
      setCad_06_Convenio_3(true);
      setCad_06_Convenio_4(true);
    }

    if (cad_06_ProcedimentoConvenio_5) {
      setCad_06_ProcedimentoConvenio_1(true);
      setCad_06_ProcedimentoConvenio_2(true);
      setCad_06_ProcedimentoConvenio_3(true);
      setCad_06_ProcedimentoConvenio_4(true);
    }

    if (cad_07_PreCadastro_5) {
      setCad_07_PreCadastro_1(true);
      setCad_07_PreCadastro_2(true);
      setCad_07_PreCadastro_3(true);
      setCad_07_PreCadastro_4(true);
    }

    if (cad_08_Paciente_5) {
      setCad_08_Paciente_1(true);
      setCad_08_Paciente_2(true);
      setCad_08_Paciente_3(true);
      setCad_08_Paciente_4(true);
    }

    if (cad_09_Anamnese_5) {
      setCad_09_Anamnese_1(true);
      setCad_09_Anamnese_2(true);
      setCad_09_Anamnese_3(true);
      setCad_09_Anamnese_4(true);
    }


  });

  //#region Heitor --- inicio
  const [CriarConsultas, setCriarConsultas] = useState(false)
  const [VisualizarConsultas, setVisualizarConsultas] = useState(false)
  const [EditarConsultas, setEditarConsultas] = useState(false)
  const [ExcluirConsultas, setExcluirConsultas] = useState(false)
  const [AcessoTotalConsultas, setAcessoTotalConsultas] = useState(false)

  useEffect(function () {

    setCriarConsultas(AcessoTotalConsultas)
    setVisualizarConsultas(AcessoTotalConsultas)
    setEditarConsultas(AcessoTotalConsultas)
    setExcluirConsultas(AcessoTotalConsultas)
  }, [AcessoTotalConsultas])

  const [CriarAgenda, setCriarAgenda] = useState(false)
  const [VisualizarAgenda, setVisualizarAgenda] = useState(false)
  const [EditarAgenda, setEditarAgenda] = useState(false)
  const [ExcluirAgenda, setExcluirAgenda] = useState(false)
  const [AcessoTotalAgenda, setAcessoTotalAgenda] = useState(false)

  useEffect(function () {

    setCriarAgenda(AcessoTotalAgenda)
    setVisualizarAgenda(AcessoTotalAgenda)
    setEditarAgenda(AcessoTotalAgenda)
    setExcluirAgenda(AcessoTotalAgenda)
  }, [AcessoTotalAgenda])

  const [GerarAutorizacao, setGerarAutorizacao] = useState(false)
  const [VisualizarAutorizacao, setVisualizarAutorizacao] = useState(false)
  const [EditarAutorizacao, setEditarAutorizacao] = useState(false)
  const [AcessoTotalAutorizacao, setAcessoTotalAutorizacao] = useState(false)

  useEffect(function () {

    setGerarAutorizacao(AcessoTotalAutorizacao)
    setVisualizarAutorizacao(AcessoTotalAutorizacao)
    setEditarAutorizacao(AcessoTotalAutorizacao)
  }, [AcessoTotalAutorizacao])


  const [CriarPendenteDeFaturamento, setCriarPendenteDeFaturamento] = useState(false)
  const [VisualizarPendenteDeFaturamento, setVisualizarPendenteDeFaturamento] = useState(false)
  const [EditarPendenteDeFaturamento, setEditarPendenteDeFaturamento] = useState(false)
  const [ExcluirPendenteDeFaturamento, setExcluirPendenteDeFaturamento] = useState(false)
  const [AcessoTotalPendenteDeFaturamento, setAcessoTotalPendenteDeFaturamento] = useState(false)

  useEffect(function () {

    setCriarPendenteDeFaturamento(AcessoTotalPendenteDeFaturamento)
    setVisualizarPendenteDeFaturamento(AcessoTotalPendenteDeFaturamento)
    setEditarPendenteDeFaturamento(AcessoTotalPendenteDeFaturamento)
    setExcluirPendenteDeFaturamento(AcessoTotalPendenteDeFaturamento)
  }, [AcessoTotalPendenteDeFaturamento])

  const [CriarFaturamento, setCriarFaturamento] = useState(false)
  const [VisualizarFaturamento, setVisualizarFaturamento] = useState(false)
  const [AcessoTotalFaturamento, setAcessoTotalFaturamento] = useState(false)

  useEffect(function () {

    setCriarFaturamento(AcessoTotalFaturamento)
    setVisualizarFaturamento(AcessoTotalFaturamento)
  }, [AcessoTotalFaturamento])

  const [CriarLancamentoDespesas, setCriarLancamentoDespesas] = useState(false)
  const [VisualizarLancamentoDespesas, setVisualizarLancamentoDespesas] = useState(false)
  const [EditarLancamentoDespesas, setEditarLancamentoDespesas] = useState(false)
  const [AcessoTotalLancamentoDespesas, setAcessoTotalLancamentoDespesas] = useState(false)

  useEffect(function () {

    setCriarLancamentoDespesas(AcessoTotalLancamentoDespesas)
    setVisualizarLancamentoDespesas(AcessoTotalLancamentoDespesas)
    setEditarLancamentoDespesas(AcessoTotalLancamentoDespesas)
  }, [AcessoTotalLancamentoDespesas])


  const [VisualizarResumoFinanceiro, setVisualizarResumoFinanceiro] = useState(false)

  const [VisualizarProntuario, setVisualizarProntuario] = useState(false)
  const [EditarProntuario, setEditarProntuario] = useState(false)
  const [CriarProntuario, setCriarProntuario] = useState(false)
  const [AcessoTotalProntuario, setAcessoTotalProntuario] = useState(false)

  useEffect(function () {

    setVisualizarProntuario(AcessoTotalProntuario)
    setEditarProntuario(AcessoTotalProntuario)
  }, [AcessoTotalProntuario])

  const [VisualizarKPi, setVisualizarKPi] = useState(false)

  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Box sx={{ width: "100%" }}>
              <TabContext value={guia}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={capturaGuia}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#000",
                        color: "#000",
                      },
                    }}
                  >
                    <Tab label="Geral" value="1" />
                    <Tab label="Permissões" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Row>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Cód. Perfil"
                        name="codperfil"
                        type="text"
                        placeholder="Cód. Perfil"
                        readonly
                        value={id_perfil || "novo"}
                        onChange={setIdPerfil}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      />
                    </Col>
                    <Col sm={5}>
                      <InputSemBorda
                        label="Nome Perfil"
                        name="nomeperfil"
                        type="text"
                        placeholder="Nome Perfil"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={nome_perfil}
                        onChange={setNomePerfil}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Ativo
                      </label>
                      <Switch
                        checked={ativo}
                        onChange={() => setAtivo(!ativo)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Perfil Master
                      </label>
                      <Switch
                        checked={master}
                        onChange={() => setMaster(!master)}
                        disabled={
                          acao === "Visualizar" || isSuper === "0"
                            ? true
                            : false
                        }
                        color="error"
                      />
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel value="2">
                  <TabContext value={guiaPermissao}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={capturaGuiaPermissao}
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: "#000",
                            color: "#000",
                          },
                        }}
                      >
                        <Tab label="Administrativo" value="1" />
                        <Tab label="Cadastro" value="2" />
                        <Tab label="Agendamento" value="3" />
                        <Tab label="Autorizações" value="4" />
                        <Tab label="Financeiro" value="5" />
                        <Tab label="Prontuário" value="6" />
                        <Tab label="KPI's" value="7" />
                      </TabList>
                      <TabPanel value="1">
                        <div className="menu-container">
                          <div className="row">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-2">
                              <h5>Visualizar</h5>
                            </div>
                            <div className="col-sm-2">
                              <h5>Incluir</h5>
                            </div>
                            <div className="col-sm-2">
                              <h5>Editar</h5>
                            </div>
                            <div className="col-sm-2">
                              <h5>Excluir</h5>
                            </div>
                            <div className="col-sm-2">
                              <h5>Acesso Total</h5>
                            </div>
                          </div>
                          {isSuper === "1" && (
                            <>
                              <div className="row">
                                <div className="col-sm-2">
                                  <h5>Empresa (Holding)</h5>
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="01_EmpresaHolding_1"
                                    id="01_EmpresaHolding"
                                    className="m-0"
                                    checked={admin_01_EmpresaHolding_1}
                                    onChange={(e) =>
                                      setAdmin_01_EmpresaHolding_1(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="01_EmpresaHolding_2"
                                    id="01_EmpresaHolding"
                                    className="m-0"
                                    checked={admin_01_EmpresaHolding_2}
                                    onChange={(e) =>
                                      setAdmin_01_EmpresaHolding_2(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="01_EmpresaHolding_3"
                                    id="01_EmpresaHolding"
                                    className="m-0"
                                    checked={admin_01_EmpresaHolding_3}
                                    onChange={(e) =>
                                      setAdmin_01_EmpresaHolding_3(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="01_EmpresaHolding_4"
                                    id="01_EmpresaHolding"
                                    className="m-0"
                                    checked={admin_01_EmpresaHolding_4}
                                    onChange={(e) =>
                                      setAdmin_01_EmpresaHolding_4(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="01_EmpresaHolding_5"
                                    id="01_EmpresaHolding"
                                    className="m-0"
                                    checked={admin_01_EmpresaHolding_5}
                                    onChange={(e) =>
                                      setAdmin_01_EmpresaHolding_5(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <hr />
                            </>
                          )}
                          {isSuper === "1" && (
                            <>
                              <div className="row">
                                <div className="col-sm-2">
                                  <h5>Perfil / Permissões</h5>
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="02_PerfilPermissoes_1"
                                    id="02_PerfilPermissoes"
                                    className="m-0"
                                    checked={admin_02_PerfilPermissoes_1}
                                    onChange={(e) =>
                                      setAdmin_02_PerfilPermissoes_1(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="02_PerfilPermissoes_2"
                                    id="02_PerfilPermissoes"
                                    className="m-0"
                                    checked={admin_02_PerfilPermissoes_2}
                                    onChange={(e) =>
                                      setAdmin_02_PerfilPermissoes_2(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="02_PerfilPermissoes_3"
                                    id="02_PerfilPermissoes"
                                    className="m-0"
                                    checked={admin_02_PerfilPermissoes_3}
                                    onChange={(e) =>
                                      setAdmin_02_PerfilPermissoes_3(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="02_PerfilPermissoes_4"
                                    id="02_PerfilPermissoes"
                                    className="m-0"
                                    checked={admin_02_PerfilPermissoes_4}
                                    onChange={(e) =>
                                      setAdmin_02_PerfilPermissoes_4(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="02_PerfilPermissoes_5"
                                    id="02_PerfilPermissoes"
                                    className="m-0"
                                    checked={admin_02_PerfilPermissoes_5}
                                    onChange={(e) =>
                                      setAdmin_02_PerfilPermissoes_5(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <hr />
                            </>
                          )}
                          <div className="row">
                            <div className="col-sm-2">
                              <h5>Usuário</h5>
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="03_Usuario_1"
                                id="03_Usuario"
                                className="m-0"
                                checked={admin_03_Usuario_1}
                                onChange={(e) =>
                                  setAdmin_03_Usuario_1(e.target.checked)
                                }
                              />
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="03_Usuario_2"
                                id="03_Usuario"
                                className="m-0"
                                checked={admin_03_Usuario_2}
                                onChange={(e) =>
                                  setAdmin_03_Usuario_2(e.target.checked)
                                }
                              />
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="03_Usuario_3"
                                id="03_Usuario"
                                className="m-0"
                                checked={admin_03_Usuario_3}
                                onChange={(e) =>
                                  setAdmin_03_Usuario_3(e.target.checked)
                                }
                              />
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="03_Usuario_4"
                                id="03_Usuario"
                                className="m-0"
                                checked={admin_03_Usuario_4}
                                onChange={(e) =>
                                  setAdmin_03_Usuario_4(e.target.checked)
                                }
                              />
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="03_Usuario_5"
                                id="03_Usuario"
                                className="m-0"
                                checked={admin_03_Usuario_5}
                                onChange={(e) =>
                                  setAdmin_03_Usuario_5(e.target.checked)
                                }
                              />
                            </div>
                          </div>
                          <hr />
                          {isSuper === "1" && (
                            <>
                              <div className="row">
                                <div className="col-sm-2">
                                  <h5>Código do Sistema</h5>
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="04_CodigoSistema_1"
                                    id="04_CodigoSistema"
                                    className="m-0"
                                    checked={admin_04_CodigoSistema_1}
                                    onChange={(e) =>
                                      setAdmin_04_CodigoSistema_1(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="04_CodigoSistema_2"
                                    id="04_CodigoSistema"
                                    className="m-0"
                                    checked={admin_04_CodigoSistema_2}
                                    onChange={(e) =>
                                      setAdmin_04_CodigoSistema_2(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="04_CodigoSistema_3"
                                    id="04_CodigoSistema"
                                    className="m-0"
                                    checked={admin_04_CodigoSistema_3}
                                    onChange={(e) =>
                                      setAdmin_04_CodigoSistema_3(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="04_CodigoSistema_4"
                                    id="04_CodigoSistema"
                                    className="m-0"
                                    checked={admin_04_CodigoSistema_4}
                                    onChange={(e) =>
                                      setAdmin_04_CodigoSistema_4(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="04_CodigoSistema_5"
                                    id="04_CodigoSistema"
                                    className="m-0"
                                    checked={admin_04_CodigoSistema_5}
                                    onChange={(e) =>
                                      setAdmin_04_CodigoSistema_5(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </TabPanel>
                      <TabPanel value="2">
                        <div className="menu-container">
                          <div className="row">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-2">
                              <h5>Visualizar</h5>
                            </div>
                            <div className="col-sm-2">
                              <h5>Incluir</h5>
                            </div>
                            <div className="col-sm-2">
                              <h5>Editar</h5>
                            </div>
                            <div className="col-sm-2">
                              <h5>Excluir</h5>
                            </div>
                            <div className="col-sm-2">
                              <h5>Acesso Total</h5>
                            </div>
                          </div>
                          <Accordion
                            sx={{
                              "& .MuiAccordionSummary-root": {
                                backgroundColor: "#1605EE",
                                borderBottom: "1px solid #fff",
                              },
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon
                                  sx={{
                                    color: "#fff",
                                  }}
                                />
                              }
                              aria-controls="panel1-content"
                              id="panel1-header"
                              sx={{
                                color: "#fff",
                              }}
                            >
                              Filiais
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="row">
                                <div className="col-sm-2">
                                  <h5>Clinicas</h5>
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="01_Clinicas_1"
                                    id="01_Clinicas"
                                    className="m-0"
                                    checked={cad_01_Clinicas_1}
                                    onChange={(e) =>
                                      setCad_01_Clinicas_1(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="01_Clinicas_2"
                                    id="01_Clinicas"
                                    className="m-0"
                                    checked={cad_01_Clinicas_2}
                                    onChange={(e) =>
                                      setCad_01_Clinicas_2(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="01_Clinicas_3"
                                    id="01_Clinicas"
                                    className="m-0"
                                    checked={cad_01_Clinicas_3}
                                    onChange={(e) =>
                                      setCad_01_Clinicas_3(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="01_Clinicas_4"
                                    id="01_Clinicas"
                                    className="m-0"
                                    checked={cad_01_Clinicas_4}
                                    onChange={(e) =>
                                      setCad_01_Clinicas_4(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="01_Clinicas_5"
                                    id="01_Clinicas"
                                    className="m-0"
                                    checked={cad_01_Clinicas_5}
                                    onChange={(e) =>
                                      setCad_01_Clinicas_5(e.target.checked)
                                    }
                                  />
                                </div>
                              </div>
                              <hr />
                              <div className="row">
                                <div className="col-sm-2">
                                  <h5>Salas de Atendimento</h5>
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="02_SalasAtendimento_1"
                                    id="02_SalasAtendimento"
                                    className="m-0"
                                    checked={cad_02_SalasAtendimento_1}
                                    onChange={(e) =>
                                      setCad_02_SalasAtendimento_1(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="02_SalasAtendimento_2"
                                    id="02_SalasAtendimento"
                                    className="m-0"
                                    checked={cad_02_SalasAtendimento_2}
                                    onChange={(e) =>
                                      setCad_02_SalasAtendimento_2(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="02_SalasAtendimento_3"
                                    id="02_SalasAtendimento"
                                    className="m-0"
                                    checked={cad_02_SalasAtendimento_3}
                                    onChange={(e) =>
                                      setCad_02_SalasAtendimento_3(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="02_SalasAtendimento_4"
                                    id="02_SalasAtendimento"
                                    className="m-0"
                                    checked={cad_02_SalasAtendimento_4}
                                    onChange={(e) =>
                                      setCad_02_SalasAtendimento_4(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="02_SalasAtendimento_5"
                                    id="02_SalasAtendimento"
                                    className="m-0"
                                    checked={cad_02_SalasAtendimento_5}
                                    onChange={(e) =>
                                      setCad_02_SalasAtendimento_5(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <hr />
                              <div className="row">
                                <div className="col-sm-2">
                                  <h5>Agenda Sala</h5>
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="03_AgendaSala_1"
                                    id="03_AgendaSala"
                                    className="m-0"
                                    checked={cad_03_AgendaSala_1}
                                    onChange={(e) =>
                                      setCad_03_AgendaSala_1(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="03_AgendaSala_2"
                                    id="03_AgendaSala"
                                    className="m-0"
                                    checked={cad_03_AgendaSala_2}
                                    onChange={(e) =>
                                      setCad_03_AgendaSala_2(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="03_AgendaSala_3"
                                    id="03_AgendaSala"
                                    className="m-0"
                                    checked={cad_03_AgendaSala_3}
                                    onChange={(e) =>
                                      setCad_03_AgendaSala_3(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="03_AgendaSala_4"
                                    id="03_AgendaSala"
                                    className="m-0"
                                    checked={cad_03_AgendaSala_4}
                                    onChange={(e) =>
                                      setCad_03_AgendaSala_4(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="03_AgendaSala_5"
                                    id="03_AgendaSala"
                                    className="m-0"
                                    checked={cad_03_AgendaSala_5}
                                    onChange={(e) =>
                                      setCad_03_AgendaSala_5(e.target.checked)
                                    }
                                  />
                                </div>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            sx={{
                              "& .MuiAccordionSummary-root": {
                                backgroundColor: "#1605EE",
                                borderBottom: "1px solid #fff",
                              },
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon
                                  sx={{
                                    color: "#fff",
                                  }}
                                />
                              }
                              aria-controls="panel1-content"
                              id="panel1-header"
                              sx={{
                                color: "#fff",
                              }}
                            >
                              Profissionais
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="row">
                                <div className="col-sm-2">
                                  <h5>Profissionais</h5>
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="04_Profissionais_1"
                                    id="04_Profissionais"
                                    className="m-0"
                                    checked={cad_04_Profissionais_1}
                                    onChange={(e) =>
                                      setCad_04_Profissionais_1(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="04_Profissionais_2"
                                    id="04_Profissionais"
                                    className="m-0"
                                    checked={cad_04_Profissionais_2}
                                    onChange={(e) =>
                                      setCad_04_Profissionais_2(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="04_Profissionais_3"
                                    id="04_Profissionais"
                                    className="m-0"
                                    checked={cad_04_Profissionais_3}
                                    onChange={(e) =>
                                      setCad_04_Profissionais_3(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="04_Profissionais_4"
                                    id="04_Profissionais"
                                    className="m-0"
                                    checked={cad_04_Profissionais_4}
                                    onChange={(e) =>
                                      setCad_04_Profissionais_4(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="04_Profissionais_5"
                                    id="04_Profissionais"
                                    className="m-0"
                                    checked={cad_04_Profissionais_5}
                                    onChange={(e) =>
                                      setCad_04_Profissionais_5(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            sx={{
                              "& .MuiAccordionSummary-root": {
                                backgroundColor: "#1605EE",
                                borderBottom: "1px solid #fff",
                              },
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon
                                  sx={{
                                    color: "#fff",
                                  }}
                                />
                              }
                              aria-controls="panel1-content"
                              id="panel1-header"
                              sx={{
                                color: "#fff",
                              }}
                            >
                              Procedimentos
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="row">
                                <div className="col-sm-2">
                                  <h5>Procedimentos</h5>
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="05_Procedimentos_1"
                                    id="05_Procedimentos"
                                    className="m-0"
                                    checked={cad_05_Procedimentos_1}
                                    onChange={(e) =>
                                      setCad_05_Procedimentos_1(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="05_Procedimentos_2"
                                    id="05_Procedimentos"
                                    className="m-0"
                                    checked={cad_05_Procedimentos_2}
                                    onChange={(e) =>
                                      setCad_05_Procedimentos_2(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="05_Procedimentos_3"
                                    id="05_Procedimentos"
                                    className="m-0"
                                    checked={cad_05_Procedimentos_3}
                                    onChange={(e) =>
                                      setCad_05_Procedimentos_3(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="05_Procedimentos_4"
                                    id="05_Procedimentos"
                                    className="m-0"
                                    checked={cad_05_Procedimentos_4}
                                    onChange={(e) =>
                                      setCad_05_Procedimentos_4(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="05_Procedimentos_5"
                                    id="05_Procedimentos"
                                    className="m-0"
                                    checked={cad_05_Procedimentos_5}
                                    onChange={(e) =>
                                      setCad_05_Procedimentos_5(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            sx={{
                              "& .MuiAccordionSummary-root": {
                                backgroundColor: "#1605EE",
                                borderBottom: "1px solid #fff",
                              },
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon
                                  sx={{
                                    color: "#fff",
                                  }}
                                />
                              }
                              aria-controls="panel1-content"
                              id="panel1-header"
                              sx={{
                                color: "#fff",
                              }}
                            >
                              Convênio
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="row">
                                <div className="col-sm-2">
                                  <h5>Convênio</h5>
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="06_Convenio_1"
                                    id="06_Convenio"
                                    className="m-0"
                                    checked={cad_06_Convenio_1}
                                    onChange={(e) =>
                                      setCad_06_Convenio_1(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="06_Convenio_2"
                                    id="06_Convenio"
                                    className="m-0"
                                    checked={cad_06_Convenio_2}
                                    onChange={(e) =>
                                      setCad_06_Convenio_2(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="06_Convenio_3"
                                    id="06_Convenio"
                                    className="m-0"
                                    checked={cad_06_Convenio_3}
                                    onChange={(e) =>
                                      setCad_06_Convenio_3(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="06_Convenio_4"
                                    id="06_Convenio"
                                    className="m-0"
                                    checked={cad_06_Convenio_4}
                                    onChange={(e) =>
                                      setCad_06_Convenio_4(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="06_Convenio_5"
                                    id="06_Convenio"
                                    className="m-0"
                                    checked={cad_06_Convenio_5}
                                    onChange={(e) =>
                                      setCad_06_Convenio_5(e.target.checked)
                                    }
                                  />
                                </div>
                              </div>
                              <hr />
                              <div className="row">
                                <div className="col-sm-2">
                                  <h5>Procedimento Convênio</h5>
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="06_ProcedimentoConvenio"
                                    id="06_ProcedimentoConvenio"
                                    className="m-0"
                                    checked={cad_06_ProcedimentoConvenio_1}
                                    onChange={(e) =>
                                      setCad_06_ProcedimentoConvenio_1(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="06_ProcedimentoConvenio_2"
                                    id="06_ProcedimentoConvenio"
                                    className="m-0"
                                    checked={cad_06_ProcedimentoConvenio_2}
                                    onChange={(e) =>
                                      setCad_06_ProcedimentoConvenio_2(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="06_ProcedimentoConvenio_3"
                                    id="06_ProcedimentoConvenio"
                                    className="m-0"
                                    checked={cad_06_ProcedimentoConvenio_3}
                                    onChange={(e) =>
                                      setCad_06_ProcedimentoConvenio_3(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="06_ProcedimentoConvenio_4"
                                    id="06_ProcedimentoConvenio"
                                    className="m-0"
                                    checked={cad_06_ProcedimentoConvenio_4}
                                    onChange={(e) =>
                                      setCad_06_ProcedimentoConvenio_4(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="06_ProcedimentoConvenio_5"
                                    id="06_ProcedimentoConvenio"
                                    className="m-0"
                                    checked={cad_06_ProcedimentoConvenio_5}
                                    onChange={(e) =>
                                      setCad_06_ProcedimentoConvenio_5(
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            sx={{
                              "& .MuiAccordionSummary-root": {
                                backgroundColor: "#1605EE",
                                borderBottom: "1px solid #fff",
                              },
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon
                                  sx={{
                                    color: "#fff",
                                  }}
                                />
                              }
                              aria-controls="panel1-content"
                              id="panel1-header"
                              sx={{
                                color: "#fff",
                              }}
                            >
                              Paciente
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="row">
                                <div className="col-sm-2">
                                  <h5>Pré-Cadastro</h5>
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="07_PreCadastro_1"
                                    id="07_PreCadastro"
                                    className="m-0"
                                    checked={cad_07_PreCadastro_1}
                                    onChange={(e) =>
                                      setCad_07_PreCadastro_1(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="07_PreCadastro_2"
                                    id="07_PreCadastro"
                                    className="m-0"
                                    checked={cad_07_PreCadastro_2}
                                    onChange={(e) =>
                                      setCad_07_PreCadastro_2(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="07_PreCadastro_3"
                                    id="07_PreCadastro"
                                    className="m-0"
                                    checked={cad_07_PreCadastro_3}
                                    onChange={(e) =>
                                      setCad_07_PreCadastro_3(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="07_PreCadastro_4"
                                    id="07_PreCadastro"
                                    className="m-0"
                                    checked={cad_07_PreCadastro_4}
                                    onChange={(e) =>
                                      setCad_07_PreCadastro_4(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="07_PreCadastro_5"
                                    id="07_PreCadastro"
                                    className="m-0"
                                    checked={cad_07_PreCadastro_5}
                                    onChange={(e) =>
                                      setCad_07_PreCadastro_5(e.target.checked)
                                    }
                                  />
                                </div>
                              </div>

                              <hr />
                              <div className="row">
                                <div className="col-sm-2">
                                  <h5>Paciente</h5>
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="08_Paciente_1"
                                    id="08_Paciente"
                                    className="m-0"
                                    checked={cad_08_Paciente_1}
                                    onChange={(e) =>
                                      setCad_08_Paciente_1(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="08_Paciente_2"
                                    id="08_Paciente"
                                    className="m-0"
                                    checked={cad_08_Paciente_2}
                                    onChange={(e) =>
                                      setCad_08_Paciente_2(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="08_Paciente_3"
                                    id="08_Paciente"
                                    className="m-0"
                                    checked={cad_08_Paciente_3}
                                    onChange={(e) =>
                                      setCad_08_Paciente_3(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="08_Paciente_4"
                                    id="08_Paciente"
                                    className="m-0"
                                    checked={cad_08_Paciente_4}
                                    onChange={(e) =>
                                      setCad_08_Paciente_4(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="08_Paciente_5"
                                    id="08_Paciente"
                                    className="m-0"
                                    checked={cad_08_Paciente_5}
                                    onChange={(e) =>
                                      setCad_08_Paciente_5(e.target.checked)
                                    }
                                  />
                                </div>
                              </div>
                              <hr />

                              <div className="row">
                                <div className="col-sm-2">
                                  <h5>Anamnese</h5>
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="09_Anamnese_1"
                                    id="09_Anamnese"
                                    className="m-0"
                                    checked={cad_09_Anamnese_1}
                                    onChange={(e) =>
                                      setCad_09_Anamnese_1(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="09_Anamnese_2"
                                    id="09_Anamnese"
                                    className="m-0"
                                    checked={cad_09_Anamnese_2}
                                    onChange={(e) =>
                                      setCad_09_Anamnese_2(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="09_Anamnese_3"
                                    id="09_Anamnese"
                                    className="m-0"
                                    checked={cad_09_Anamnese_3}
                                    onChange={(e) =>
                                      setCad_09_Anamnese_3(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="09_Anamnese_4"
                                    id="09_Anamnese"
                                    className="m-0"
                                    checked={cad_09_Anamnese_4}
                                    onChange={(e) =>
                                      setCad_09_Anamnese_4(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="09_Anamnese_5"
                                    id="09_Anamnese"
                                    className="m-0"
                                    checked={cad_09_Anamnese_5}
                                    onChange={(e) =>
                                      setCad_09_Anamnese_5(e.target.checked)
                                    }
                                  />
                                </div>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </TabPanel>
                      <TabPanel value="3">
                        <div className="menu-container">
                          <div className="row">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-2">
                              <h5>Visualizar</h5>
                            </div>
                            <div className="col-sm-2">
                              <h5>Incluir</h5>
                            </div>
                            <div className="col-sm-2">
                              <h5>Editar</h5>
                            </div>
                            <div className="col-sm-2">
                              <h5>Excluir</h5>
                            </div>
                            <div className="col-sm-2">
                              <h5>Acesso Total</h5>
                            </div>
                          </div>
                          <hr />
                          {/*AQUI */}
                          <div className="row">
                            <div className="col-sm-2">
                              <h5>Consultas</h5>
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="09_Anamnese_1"
                                id="09_Anamnese"
                                className="m-0"
                                checked={VisualizarConsultas}
                                onChange={(e) =>
                                  setVisualizarConsultas(e.target.checked)
                                }
                              />
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="09_Anamnese_2"
                                id="09_Anamnese"
                                className="m-0"
                                checked={CriarConsultas}
                                onChange={(e) =>
                                  setCriarConsultas(e.target.checked)
                                }
                              />
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="09_Anamnese_3"
                                id="09_Anamnese"
                                className="m-0"
                                checked={EditarConsultas}
                                onChange={(e) =>
                                  setEditarConsultas(e.target.checked)
                                }
                              />
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="09_Anamnese_4"
                                id="09_Anamnese"
                                className="m-0"
                                checked={ExcluirConsultas}
                                onChange={(e) =>
                                  setExcluirConsultas(e.target.checked)
                                }
                              />
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="09_Anamnese_5"
                                id="09_Anamnese"
                                className="m-0"
                                checked={AcessoTotalConsultas}
                                onChange={(e) =>
                                  setAcessoTotalConsultas(e.target.checked)
                                }
                              />
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-2">
                              <h5>Agenda</h5>
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="09_Anamnese_1"
                                id="09_Anamnese"
                                className="m-0"
                                checked={VisualizarAgenda}
                                onChange={(e) =>
                                  setVisualizarAgenda(e.target.checked)
                                }
                              />
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="09_Anamnese_2"
                                id="09_Anamnese"
                                className="m-0"
                                checked={CriarAgenda}
                                onChange={(e) =>
                                  setCriarAgenda(e.target.checked)
                                }
                              />
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="09_Anamnese_3"
                                id="09_Anamnese"
                                className="m-0"
                                checked={EditarAgenda}
                                onChange={(e) =>
                                  setEditarAgenda(e.target.checked)
                                }
                              />
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="09_Anamnese_4"
                                id="09_Anamnese"
                                className="m-0"
                                checked={ExcluirAgenda}
                                onChange={(e) =>
                                  setExcluirAgenda(e.target.checked)
                                }
                              />
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="09_Anamnese_5"
                                id="09_Anamnese"
                                className="m-0"
                                checked={AcessoTotalAgenda}
                                onChange={(e) =>
                                  setAcessoTotalAgenda(e.target.checked)
                                }
                              />
                            </div>
                          </div>

                        </div>
                      </TabPanel>
                      <TabPanel value="4">
                        <div className="menu-container">
                          <div className="row">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-2">
                              <h5>Visualizar</h5>
                            </div>
                            <div className="col-sm-2">
                              <h5>Incluir</h5>
                            </div>
                            <div className="col-sm-2">
                              <h5>Editar</h5>
                            </div>
                            {
                              /*
                              <div className="col-sm-2">
                              <h5>Excluir</h5>
                            </div>
                              */
                            }
                            <div className="col-sm-2">
                              <h5>Acesso Total</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-2">
                              <h5>Autorizações</h5>
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="09_Anamnese_1"
                                id="09_Anamnese"
                                className="m-0"
                                checked={VisualizarAutorizacao}
                                onChange={(e) =>
                                  setVisualizarAutorizacao(e.target.checked)
                                }
                              />
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="09_Anamnese_2"
                                id="09_Anamnese"
                                className="m-0"
                                checked={GerarAutorizacao}
                                onChange={(e) =>
                                  setGerarAutorizacao(e.target.checked)
                                }
                              />
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="09_Anamnese_3"
                                id="09_Anamnese"
                                className="m-0"
                                checked={EditarAutorizacao}
                                onChange={(e) =>
                                  setEditarAutorizacao(e.target.checked)
                                }
                              />
                            </div>
                            {
                              /*
                              
                              <div className="col-sm-2">
                                <Checkbox
                                size="small"
                                name="09_Anamnese_4"
                                id="09_Anamnese"
                                className="m-0"
                                checked={ExcluirConsultas}
                                onChange={(e) =>
                                setExcluirConsultas(e.target.checked)
                                }
                               />
                              </div>
                              */
                            }
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="09_Anamnese_5"
                                id="09_Anamnese"
                                className="m-0"
                                checked={AcessoTotalAutorizacao}
                                onChange={(e) =>
                                  setAcessoTotalAutorizacao(e.target.checked)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel value="5">
                        <Row>
                          <Col sm={12}>
                            <div className="row">
                              <div className="col-sm-2"></div>
                              <div className="col-sm-2">
                                <h5>Visualizar</h5>
                              </div>
                              <div className="col-sm-2">
                                <h5>Incluir</h5>
                              </div>
                              <div className="col-sm-2">
                                <h5>Editar</h5>
                              </div>
                              <div className="col-sm-2">
                                <h5>Excluir</h5>
                              </div>
                              <div className="col-sm-2">
                                <h5>Acesso Total</h5>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-2">
                                <h5>Pendente de Faturamento</h5>
                              </div>
                              <div className="col-sm-2">
                                <Checkbox
                                  size="small"
                                  name="09_Anamnese_1"
                                  id="09_Anamnese"
                                  className="m-0"
                                  checked={VisualizarPendenteDeFaturamento}
                                  onChange={(e) =>
                                    setVisualizarPendenteDeFaturamento(e.target.checked)
                                  }
                                />
                              </div>
                              <div className="col-sm-2">
                                <Checkbox
                                  size="small"
                                  name="09_Anamnese_2"
                                  id="09_Anamnese"
                                  className="m-0"
                                  checked={CriarPendenteDeFaturamento}
                                  onChange={(e) =>
                                    setCriarPendenteDeFaturamento(e.target.checked)
                                  }
                                />
                              </div>
                              <div className="col-sm-2">

                              </div>
                              <div className="col-sm-2">

                              </div>
                              <div className="col-sm-2">
                                <Checkbox
                                  size="small"
                                  name="09_Anamnese_5"
                                  id="09_Anamnese"
                                  className="m-0"
                                  checked={AcessoTotalPendenteDeFaturamento}
                                  onChange={(e) =>
                                    setAcessoTotalPendenteDeFaturamento(e.target.checked)
                                  }
                                />
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-sm-2">
                                <h5>Faturamento</h5>
                              </div>
                              <div className="col-sm-2">
                                <Checkbox
                                  size="small"
                                  name="09_Anamnese_1"
                                  id="09_Anamnese"
                                  className="m-0"
                                  checked={VisualizarFaturamento}
                                  onChange={(e) =>
                                    setVisualizarFaturamento(e.target.checked)
                                  }
                                />
                              </div>
                              <div className="col-sm-2">
                                <Checkbox
                                  size="small"
                                  name="09_Anamnese_2"
                                  id="09_Anamnese"
                                  className="m-0"
                                  checked={CriarFaturamento}
                                  onChange={(e) =>
                                    setCriarFaturamento(e.target.checked)
                                  }
                                />
                              </div>
                              <div className="col-sm-2">

                              </div>
                              <div className="col-sm-2">

                              </div>
                              <div className="col-sm-2">
                                <Checkbox
                                  size="small"
                                  name="09_Anamnese_5"
                                  id="09_Anamnese"
                                  className="m-0"
                                  checked={AcessoTotalFaturamento}
                                  onChange={(e) =>
                                    setAcessoTotalFaturamento(e.target.checked)
                                  }
                                />
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-sm-2">
                                <h5>Lançamento de Despesas/Outras Receitas</h5>
                              </div>
                              <div className="col-sm-2">
                                <Checkbox
                                  size="small"
                                  name="09_Anamnese_1"
                                  id="09_Anamnese"
                                  className="m-0"
                                  checked={VisualizarLancamentoDespesas}
                                  onChange={(e) =>
                                    setVisualizarLancamentoDespesas(e.target.checked)
                                  }
                                />
                              </div>
                              <div className="col-sm-2">
                                <Checkbox
                                  size="small"
                                  name="09_Anamnese_2"
                                  id="09_Anamnese"
                                  className="m-0"
                                  checked={CriarLancamentoDespesas}
                                  onChange={(e) =>
                                    setCriarLancamentoDespesas(e.target.checked)
                                  }
                                />
                              </div>
                              <div className="col-sm-2">
                                <Checkbox
                                  size="small"
                                  name="09_Anamnese_3"
                                  id="09_Anamnese"
                                  className="m-0"
                                  checked={EditarLancamentoDespesas}
                                  onChange={(e) =>
                                    setEditarLancamentoDespesas(e.target.checked)
                                  }
                                />
                              </div>
                              <div className="col-sm-2">

                              </div>
                              <div className="col-sm-2">
                                <Checkbox
                                  size="small"
                                  name="09_Anamnese_5"
                                  id="09_Anamnese"
                                  className="m-0"
                                  checked={AcessoTotalLancamentoDespesas}
                                  onChange={(e) =>
                                    setAcessoTotalLancamentoDespesas(e.target.checked)
                                  }
                                />
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-sm-2">
                                <h5>Resumo Financeiro</h5>
                              </div>
                              <div className="col-sm-2">
                                <Checkbox
                                  size="small"
                                  name="09_Anamnese_1"
                                  id="09_Anamnese"
                                  className="m-0"
                                  checked={VisualizarResumoFinanceiro}
                                  onChange={(e) =>
                                    setVisualizarResumoFinanceiro(e.target.checked)
                                  }
                                />
                              </div>
                              <div className="col-sm-2">

                              </div>
                              <div className="col-sm-2">

                              </div>
                              <div className="col-sm-2">

                              </div>
                              <div className="col-sm-2">

                              </div>
                            </div>
                            <hr />
                          </Col>
                        </Row>
                      </TabPanel>
                      <TabPanel value="6">
                        <div className="menu-container">
                          <div className="row">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-2">
                              <h5>Visualizar</h5>
                            </div>
                            <div className="col-sm-2">
                              <h5>Editar</h5>
                            </div>
                            <div className="col-sm-2">
                              <h5>Criar</h5>
                            </div>
                            <div className="col-sm-2">
                              <h5>Acesso Total</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-2">
                              <h5>Prontuário</h5>
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="09_Anamnese_1"
                                id="09_Anamnese"
                                className="m-0"
                                checked={VisualizarProntuario}
                                onChange={(e) =>
                                  setVisualizarProntuario(e.target.checked)
                                }
                              />
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="09_Anamnese_1"
                                id="09_Anamnese"
                                className="m-0"
                                checked={EditarProntuario}
                                onChange={(e) =>
                                  setEditarProntuario(e.target.checked)
                                }
                              />
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="09_Anamnese_1"
                                id="09_Anamnese"
                                className="m-0"
                                checked={CriarProntuario}
                                onChange={(e) =>
                                  setCriarProntuario(e.target.checked)
                                }
                              />
                            </div>
                            <div className="col-sm-2">
                              <Checkbox
                                size="small"
                                name="09_Anamnese_1"
                                id="09_Anamnese"
                                className="m-0"
                                checked={AcessoTotalProntuario}
                                onChange={(e) =>
                                  setAcessoTotalProntuario(e.target.checked)
                                }
                              />
                            </div>
                            <div className="col-sm-2">

                            </div>
                          </div>
                          <hr />
                        </div>
                      </TabPanel>
                      <TabPanel value="7">
                        <Row>
                          <Col sm={12}>
                            <div className="menu-container">
                              <div className="row">
                                <div className="col-sm-2"></div>
                                <div className="col-sm-2">
                                  <h5>Visualizar</h5>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-2">
                                  <h5>Kpi's</h5>
                                </div>
                                <div className="col-sm-2">
                                  <Checkbox
                                    size="small"
                                    name="09_Anamnese_1"
                                    id="09_Anamnese"
                                    className="m-0"
                                    checked={VisualizarKPi}
                                    onChange={(e) =>
                                      setVisualizarKPi(e.target.checked)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">

                                </div>
                              </div>
                              <hr />
                            </div>
                          </Col>
                        </Row>
                      </TabPanel>
                    </Box>
                  </TabContext>
                </TabPanel>
              </TabContext>
            </Box>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <Button
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <Button
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>
      <ModalLoading show={loading} label="Carregado dados do perfil ..." />
    </>
  );
};
export default FormPerfil;
