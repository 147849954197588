import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../../../components/Formularios/Modal/ModalLoading";
import { useState, useEffect, ChangeEvent } from "react";
import { toast } from "react-toastify";
import { Card, Col, Form, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../components/Formularios/Inputs/InputsSemBorda";

import { Box, Switch, Stack, Paper } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Button as ButtonCustom } from "../../../../../components/Formularios/Buttons/Button";
import { mdiCheckCircle, mdiKeyboardReturn } from "@mdi/js";
import ApiPost from "../../../../../api/endPoints/usePost";
import ApiPut from "../../../../../api/endPoints/usePut";
import ModalUploadImage from "../../../../../components/Formularios/Modal/ModalUpload";
import { styled } from "@mui/material/styles";
import CarregarDados from "../CarregarDados";
// import { InterfaceDadosEmpresa } from "../../../../../types/EmpresaInteface";
import { url_CarregarDados_ID } from "../auxiliar";
import { TextoFormatoCaptalize } from "../../../../../components/Auxiliar/ConvTextoCaptalize";
import useSelectOptions from "../../../../../hooks/useSelectOptions";
import SelectInput from "../../../../../shared/SelectInputs";
import { getSessionData } from "../../../../../utils/storageUtils";
import axios from "axios";
import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F0F0F0",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "#616161",
  borderRadius: "5px",
  fontWeight: "bold",
  fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
}));

interface FormProcedimentoProps {
  idprocedimento?: string;
  acao?: string;
}

const FormProcedimento = ({ idprocedimento, acao }: FormProcedimentoProps) => {
  const navigate = useNavigate();
  const id_clinica = getSessionData("DadosIdClinica") || "";

  //#region Controla as guias
  const [guia, setGuia] = useState("1");

  const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
    setGuia(novaGuia);
  };
  //#endregion

  const [showUpload, setShowUpload] = useState<boolean>(false);

  const [id_procedimento, setId_procedimento] = useState<any>(
    idprocedimento || ""
  );
  const [procedimento, setProcedimento] = useState<string>("");
  const [codigo, setCodigo] = useState<string>("");
  const [status, setStatus] = useState<boolean>(true);
  const [dtcadastro, setDtcadastro] = useState<string>("");
  const [usuario_cadastro, setUsuario_cadastro] = useState<string>("");
  const [valor, setValor] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  //#region ==> Select Options
  // Usando o hook useSelectOptions para obter as opções diretamente
  const tipoProcedimentoOptions = useSelectOptions(
    `/codigosistema/listar/TIPOPROCEDIMENTO`
  );
  const [selectedTipoProcedimento, setSelectedTipoProcedimento] = useState("");

  //#endregion

  const carregar = (url: string, idprocedimento: any, id_clinica: string) => {
    setLoading(true);
    CarregarDados(url, idprocedimento, id_clinica)
      .then((retorno) => {
        setLoading(false);

        setId_procedimento(retorno[0].id_procedimento || "");
        setProcedimento(retorno[0].procedimento || "");
        setSelectedTipoProcedimento(retorno[0].codigo || "");
        setStatus(retorno[0].status === 1 ? true : false);
        setDtcadastro(retorno[0].dtcadastro || "");
        setUsuario_cadastro(retorno[0].usuario_cadastro || "");
        setValor(retorno[0].valor || "0");
        setIdConvenio(retorno[0].id_convenio)
        setIsconvenio(retorno[0].isconvenio)
      })
      .catch((erro) => {
        setLoading(false);
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao carregar dados. Motivo: ${msg}`);
      });
  };
  useEffect(() => {
    if (idprocedimento !== "novo") {
      carregar(url_CarregarDados_ID, idprocedimento, id_clinica);
    }

    carregarConvenio(id_clinica)
  }, []);

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS
  const atualizarDados = async (
    id_procedimento: string,
    id_clinica: string,
    procedimento: string,
    codigo: string,
    status: string,
    dtcadastro: string,
    usuario_cadastro: string,
    valor: number,
    isConvenio: boolean,
    idConvenio: any
  ) => {
    const dados = {
      id_procedimento,
      id_clinica,
      procedimento: TextoFormatoCaptalize(procedimento.toLowerCase()),
      codigo: selectedTipoProcedimento,
      status,
      dtcadastro,
      usuario_cadastro,
      valor,
      isConvenio,
      idConvenio
    };

    ApiPut(`/procedimento/atualizar/`, dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        toast.error(`Erro ao atualizar. Motivo: ${erro.response.data.message}`);
      });
  };
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS
  const gravarDados = (
    id_procedimento: string,
    id_clinica: string,
    procedimento: string,
    codigo: string,
    status: string,
    dtcadastro: string,
    usuario_cadastro: string,
    valor: number,
    isConvenio: boolean,
    idConvenio: any
  ) => {
    const dados = {
      id_procedimento,
      id_clinica,
      procedimento: TextoFormatoCaptalize(procedimento.toLowerCase()),
      codigo: selectedTipoProcedimento,
      status,
      dtcadastro,
      usuario_cadastro,
      valor,
      isConvenio,
      idConvenio
    };
    ApiPost("/procedimento/criar", dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };
  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const _valor = parseFloat(valor ? valor : "0");
    if (_valor <= 0 && procedimento != 'Bloqueio') {
      toast.error("Informe um valor válido para o procedimento.");
      return;
    }
    else if (isConvenio == true && idConvenio == "0") {
      toast.error("Selecione um Convênio.")
      return;
    }
    else {
      if (idprocedimento === "novo") {
        gravarDados(
          id_procedimento,
          id_clinica,
          procedimento,
          codigo,
          status ? "1" : "0",
          dtcadastro,
          usuario_cadastro,
          valor ? parseFloat(valor) : 0,
          isConvenio,
          idConvenio
        );
        toast.info("Inserindo novo registro...");
      } else {
        atualizarDados(
          id_procedimento,
          id_clinica,
          procedimento,
          codigo,
          status ? "1" : "0",
          dtcadastro,
          usuario_cadastro,
          valor ? parseFloat(valor) : 0,
          isConvenio,
          idConvenio
        );
        toast.info("Atualizando dados do registro...");
      }
    }
  };


  const [isConvenio, setIsconvenio] = useState(false)
  const [idConvenio, setIdConvenio] = useState("0")


  //lista os convenios da clinica
  const [ListaConvenio, setListaConvenio] = useState([])
  function carregarConvenio(id_clinica: any) {

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/convenio/listar/${id_clinica}`, {
      headers: {
        Authorization: getSessionData("MultClinicaWebToken")
      }
    }).then(function (resposta) {

      setListaConvenio(resposta.data)
    }).catch(function (erro) {

      toast.error("Erro ao listar convenios.")
    })
  }

  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Box sx={{ width: "100%" }}>
              <TabContext value={guia}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
                <TabPanel value="1">
                  <Row>
                    <Col sm={3}>
                      <InputSemBorda
                        label="ID Procedimento"
                        name="id_procedimento"
                        type="text"
                        placeholder="ID Procedimento"
                        readonly
                        value={id_procedimento || ""}
                        onChange={setId_procedimento}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={6}>
                      <InputSemBorda
                        label="Procedimento"
                        name="procedimento"
                        type="text"
                        placeholder="Procedimento"
                        value={procedimento || ""}
                        onChange={setProcedimento}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                        readonly={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={3}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Ativo
                      </label>
                      <Switch
                        checked={status}
                        onChange={() => setStatus(!status)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <SelectInput
                        value={selectedTipoProcedimento}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setSelectedTipoProcedimento(e.target.value)
                        }
                        options={tipoProcedimentoOptions}
                        placeholder="Tipo Procedimento"
                        disabed={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Valor (R$)"
                        name="valor"
                        type="number"
                        step="0.01"
                        placeholder="Valor (R$)"
                        value={valor || "0"}
                        onChange={setValor}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                        readonly={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={3}>
                      <div className="form-check ms-1 mt-3">
                        <input checked={isConvenio}
                          onChange={function (e) {
                            setIsconvenio(e.target.checked)

                            if (e.target.checked == false) {

                              setIdConvenio("0")
                            }
                          }}
                          className="form-check-input" disabled={acao === "Visualizar" ? true : false} type="checkbox" value="" id="flexCheckDefault" />
                        <label className="form-check-label ms-1">
                          Convênio
                        </label>
                      </div>
                    </Col>
                    {isConvenio == true ?
                      <Col sm={5}>
                        <FormControl variant="standard" sx={{ m: 1.5, width: "100%" }}>
                          <InputLabel>
                            Convênio
                          </InputLabel>
                          <Select
                            disabled={acao === "Visualizar" ? true : false}
                            value={idConvenio}
                            onChange={function (e) {
                              setIdConvenio(e.target.value)
                            }}
                            label={"Convênio"}
                            required={true}
                          >
                            <MenuItem value="">
                              <em>Selecione...</em>
                            </MenuItem>
                            {ListaConvenio.map((option: any) => (
                              <MenuItem value={option.id_convenio}>
                                {option.fantasia}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Col>
                      : <></>}
                  </Row>
                </TabPanel>
              </TabContext>
            </Box>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <ButtonCustom
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
                disabled={acao === "Visualizar" ? true : false}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>
      <ModalUploadImage
        show={showUpload}
        label="Realizando Upload da Imagem ..."
      />
      <ModalLoading
        show={loading}
        label="Carregado dados de procedimento ..."
      />
    </>
  );
};
export default FormProcedimento;
