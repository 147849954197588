import armazem from "../assets/img/armazem.png";
import avatar from "../assets/img/avatar.png";
import { Form, Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ApiPost from "../api/endPoints/usePost";
import { toast } from "react-toastify";
import ApiGet from "../api/endPoints/useGet";
import ModalLoading from "../components/Formularios/Modal/ModalLoading";
import { getSessionData, setSessionData } from "../utils/storageUtils";
import { Col, Modal, Row } from "react-bootstrap";
import InputSemBorda from "../components/Formularios/Inputs/InputsSemBorda";
import { Button } from "../components/Formularios/Buttons/Button";
import { mdiCheckCircle, mdiKeyboardReturn } from "@mdi/js";
import { ModalTrocarSenhaLink } from "./administrativo/usuario/components/ModalTrocarSenhaLink";
import { LogoClinicaAtual } from "../shared/logo";
import AvatarFilial from "../components/Formularios/AvatarFilial";
import ModalSelecionarFilialLink from "./cadastro/filiais/clinicas/components/ModalSelecionaClinicaLink/ModalSelecionaClinicaLink";
import { Badge } from "@mui/material";
const NavBar = () => {
  const token = getSessionData("MultClinicaWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  const isVinculo = getSessionData("isPerfilVinculo") || "0";
  const navigate = useNavigate();
  const usuario = getSessionData("NomeUsuario") || "";
  const perfil = getSessionData("PerfilUsuario") || "";
  const id_usuario = getSessionData("DadosUsuarioID") || "";
  const login_usuario = getSessionData("DadosUsuario") || "";
  const id_clinica = getSessionData("DadosIdClinica") || "";
  const avatarClinica = getSessionData("DadosAvatarClinica") || "";

  const [qtdePaciente, setQtdePaciente] = useState<number>(0);

  const [abrir, setAbrir] = useState<boolean>(false);

  useEffect(() => {
    if (usuario === "") {
      navigate("/login");
    }
  });

  const logoff = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  const [avatarImg, setAvatarImg] = useState<string>(avatarClinica || "");
  const [loading, setLoading] = useState<boolean>(false);
  const carregarAvatar = async (id_usuario: string) => {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/usuario/carregar/:idusuario",
      parametros: {
        idusuario: id_usuario,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((response) => {
        const { avatar } = response[0];
        setLoading(false);
        setAvatarImg(avatar);
      })
      .catch((erro) => {
        setLoading(false);
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Falha ao carregar dados. Motivo: ${msg}`);
      });
  };
  useEffect(() => {
    if (id_usuario !== "") carregarAvatar(id_usuario);
  }, [id_usuario]);

  //#endregion

  //#region Carregar Filiais do usuario
  const [filiais, setFiliais] = useState<any[]>([]);
  const [avatarImgFilial, setAvatarImgFilial] = useState<string>("");
  const [idClinica, setIdClinica] = useState<string>("");
  const [nomeclinica, setNomeClinica] = useState<string>("");

  const carregarFiliais = async (id_usuario: string) => {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/clinica/usuario/listar/:id_usuario/:id_empresa",
      parametros: {
        id_usuario: id_usuario,
        id_empresa: isVinculo,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((response) => {
        const retornoApi = response;
        const qtdClinica = retornoApi.length;
        setLoading(false);
        if (qtdClinica === 1) {
          console.log(retornoApi);
          setFiliais(response);
          setAvatarImgFilial(response[0].avatar);
          setIdClinica(response[0].id_clinica);
          setNomeClinica(response[0].fantasia);
          setSessionData("DadosIdClinica", response[0].id_clinica);
          setSessionData("DadosNomeClinica", response[0].fantasia);
          setSessionData("DadosAvatarClinica", response[0].avatar);
          setSessionData("EmailClinica", response[0].email);
          setSessionData(
            "TelefoneClinica",
            response[0].telfixo || response[0].telcelular
          );
        }
        if (qtdClinica > 1) {
          setFiliais(response);

          setSessionData("DadosIdClinica", response[0].id_clinica);
          setSessionData("DadosAvatarClinica", response[0].avatar);
          setSessionData("DadosNomeClinica", response[0].fantasia);
          setSessionData("EmailClinica", response[0].email);
          setAbrir(true);
        }
      })
      .catch((erro) => {
        setLoading(false);
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Falha ao carregar dados. Motivo: ${msg}`);
      });
  };

  useEffect(() => {
    if (id_usuario !== "") carregarFiliais(id_usuario);
  }, [id_usuario]);

  //#endregion

  const fecharModal = () => {
    sessionStorage.clear();
    navigate("/login");
    // setAbrir(false)
  };

  const receberDados = (dados: any) => {
    setAvatarImgFilial(dados.avatar);
    setIdClinica(dados.id_clinica);
    setNomeClinica(dados.fantasia);
    setSessionData("DadosAvatarClinica", dados.avatar || "");
    setSessionData("DadosIdClinica", dados.id_clinica || "");
    setSessionData("DadosNomeClinica", dados.fantasia || "");
    setSessionData("DadosAvatarClinica", dados.avatar || "");
    setSessionData("EmailClinica", dados.email || "");
    setSessionData("TelefoneClinica", dados.telfixo || dados.telcelular);
    navigate("/kpi/dashboard/dashboard");
    setAbrir(false);
  };

  const verificarQtdePacienteProfissional = async (
    id_clinica: string,
    profissional: string
  ) => {
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/agenda/buscar/qtdepaciente/:id_clinica/:profissional",
      parametros: {
        id_clinica,
        profissional,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((response) => {
        const retornoApi = response[0];
        setQtdePaciente(retornoApi.qtdpaciente);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Falha ao carregar dados. Motivo: ${msg}`);
      });
  };

  const [dadosPaciente, setDadosPaciente] = useState<any[]>([]);
  const verificarPacienteProfissional = async (
    id_clinica: string,
    profissional: string
  ) => {
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/agenda/buscar/paciente/:id_clinica/:profissional",
      parametros: {
        id_clinica,
        profissional,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((response) => {
        const retornoApi = response;
        setDadosPaciente(retornoApi);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Falha ao carregar dados. Motivo: ${msg}`);
      });
  };
  // aqui ira ficar verificando de 10 em 10 segundos a quantidade de pacientes
  useEffect(() => {
    if (id_clinica !== "") {
      verificarQtdePacienteProfissional(id_clinica, login_usuario);
      verificarPacienteProfissional(id_clinica, login_usuario);
    }
    const interval = setInterval(() => {
      if (id_clinica !== "") {
        verificarQtdePacienteProfissional(id_clinica, login_usuario);
        verificarPacienteProfissional(id_clinica, login_usuario);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [id_clinica]);
  return (
    <>
      <div className="navbar-custom">
        <ul className="list-unstyled topbar-menu float-end mb-0">
          {/* colocar um igone de notificacao com o Badge do mui */}
          <li className="dropdown notification-list topbar-dropdown">
            <a
              className="nav-link dropdown-toggle arrow-none"
              data-bs-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <i className="mdi mdi-bell-outline noti-icon" />
              {qtdePaciente > 0 && (
                <Badge badgeContent={qtdePaciente} color="primary">
                  <span className="noti-icon-badge"></span>
                </Badge>
              )}
            </a>
            <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg">
              <div className="dropdown-item noti-title">
                <h5 className="m-0">
                  Aguardando Atendimento
                </h5>
                {qtdePaciente > 0 && (
                  <small
                    className="text-muted"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate("/cadastro/profissionais/filaatendimento")
                    }
                  >
                    Clique aqui para ver os pacientes
                  </small>
                )}
              </div>
              <div
                className="slimscroll noti-scroll"
                style={{ maxHeight: "230px" }}
              >
                {dadosPaciente.length === 0 ? (
                  <a
                    href="javascript:void(0);"
                    className="dropdown-item notify-item"
                  >
                    <div className="notify-icon bg-warning">
                      <i className="mdi mdi-account-alert"></i>
                    </div>
                    <p className="notify-details">
                      <b>Nenhum paciente aguardando...</b>
                    </p>
                  </a>
                ) : (
                  dadosPaciente.map((item) => {
                    return (
                      <a
                        href="javascript:void(0);"
                        className="dropdown-item notify-item"
                      >
                        <div className="notify-icon bg-primary">
                          <i className="mdi mdi-account-circle"></i>
                        </div>
                        <p className="notify-details">
                          <b>{item.nome}</b>
                          <small className="text-muted">
                            Sala: {item.sala}
                          </small>
                          <small className="text-muted">
                            Tempo Em Espera: {item.tempo_espera}
                          </small>
                        </p>
                      </a>
                    );
                  })
                )}
              </div>
            </div>
          </li>
          <li className="dropdown notification-list topbar-dropdown">
            <a
              className="nav-link dropdown-toggle arrow-none"
              data-bs-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <AvatarFilial
                avatarImgFilial={avatarImgFilial}
                alt="filial"
                className="rounded-action-icon imgEmpresa"
              />
              <label className="filial" style={{ color: "black" }}>
                &nbsp;{idClinica} - {nomeclinica}
              </label>
              <i className="mdi mdi-chevron-down d-none d-sm-inline-block align-middle" />
            </a>

            <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
              {filiais.map((item) => {
                return (
                  <a
                    className="dropdown-item notify-item"
                    onClick={() => {
                      setAvatarImgFilial(item.avatar);
                      setIdClinica(item.id_clinica);
                      setNomeClinica(item.fantasia);
                      setSessionData("DadosAvatarClinica", item.avatar);
                      setSessionData("DadosIdClinica", item.id_clinica);
                      setSessionData("DadosNomeClinica", item.fantasia);
                      navigate("/");
                    }}
                  >
                    <img
                      src={
                        item.avatar !== null
                          ? `data:image/png;base64,${item.avatar}`
                          : armazem
                      }
                      alt="filial"
                      className="rounded-action-icon imgEmpresa"
                    />
                    <label className="form-label">
                      &nbsp;{item.id_clinica} - {item.fantasia}
                    </label>
                  </a>
                );
              })}
            </div>
          </li>
          <li className="dropdown notification-list">
            <button
              type="button"
              className="nav-link dropdown-toggle nav-user arrow-none me-0"
              data-bs-toggle="dropdown"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <span className="account-user-avatar">
                <img
                  src={
                    avatarImg ? `data:image/png;base64,${avatarImg}` : avatar
                  }
                  alt="user"
                  className="rounded-circle"
                />
              </span>
              <span>
                <span className="account-user-name">{usuario}</span>
                <span className="account-position">{perfil}</span>
              </span>
            </button>
            <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
              <div className=" dropdown-header noti-title">
                <h6 className="text-overflow m-0">Bem vindo !</h6>
              </div>
              <Link
                to={`/administrativo/usuario/usuario/editar/${id_usuario}`}
                className="dropdown-item notify-item"
              >
                <i className="mdi mdi-account-circle me-1"></i>
                <span>Minha conta</span>
              </Link>
              <Link target="_blank" to={"https://fklservice.com.br/produtos/treinamento-completo-total-clinica/"}
                className="dropdown-item notify-item"
              >
                <i className="mdi mdi-book me-1"></i>
                <span>Manual</span>
              </Link>
              <ModalTrocarSenhaLink />
              <button
                type="button"
                style={{ cursor: "pointer" }}
                className="dropdown-item notify-item"
                onClick={logoff}
              >
                <i className="mdi mdi-logout me-1"></i>
                <span>Logout</span>
              </button>
            </div>
          </li>
        </ul>
        <button className="button-menu-mobile open-left">
          <i className="mdi mdi-menu"></i>
        </button>
      </div>
      <ModalLoading show={loading} label="Carregando dados" />

      <ModalSelecionarFilialLink
        abrir={abrir}
        onClose={fecharModal}
        onclick={receberDados}
      />
    </>
  );
};
export { NavBar };
