import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import  FormUsuario  from "./components/formulario";

export function UsuarioIncluir() {
    const idusuario = useParams();
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Incluir Usuário" />
        <FormUsuario idusuario={idusuario.id} acao="Novo"/>
      </div>
    </>
  );
}
