import axios from "axios";
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Api } from "../../../../../api/api";
import { getSessionData } from "../../../../../utils/storageUtils";
import Tabela from "../../../../../components/Formularios/Table";
import { ITabela } from "../../../../../components/Formularios/Table/TabelaInterface";

interface ModalDuplicarProfissionalProps {
    isOpen: boolean;
    fecharModal: () => void;
    token: string;
    dados: any
    cpfProfissional: string;
}

const ModalDuplicarProfissional: React.FC<ModalDuplicarProfissionalProps> = ({
    isOpen,
    fecharModal,
    token,
    dados,
    cpfProfissional
}) => {
    const [data, setData] = useState([])
    const [dataVinculadas, setDataVinculadas] = useState([])

    const idUsuario = getSessionData("DadosUsuarioID")
    const usuarioLogado = getSessionData("NomeUsuario")

    const colunas: ITabela[] = [
        { titulo: "", acesso: "avatar" },
        { titulo: "Código", acesso: "id_clinica" },
        { titulo: "Nome", acesso: "fantasia" },
        { titulo: "Bairro", acesso: "bairro" },
        { titulo: "UF", acesso: "bairro" },
        { titulo: "Responsável", acesso: "responsavel" }
    ];

    function carregaFiliais() {
        axios.get(`${Api}/carrega/filiais/duplicar/${idUsuario}/${cpfProfissional}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setData(resposta.data.data)
        }).catch(function (erro) {
            console.info(erro.response.data.message)
            setData([])
        })
    }

    function carregaFiliaisVinculadas() {
        axios.get(`${Api}/carrega/filiais/vinculadas/${cpfProfissional}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDataVinculadas(resposta.data.data)
        }).catch(function (erro) {
            console.info(erro.response.data.message)
            setData([])
        })
    }

    const duplicarProfissional = (dadosClinica: any) => {

        axios.post(`${Api}/profissional/duplicar/clinica`, {
            dados,
            idClinica: dadosClinica.id_clinica,
            usuarioLogado
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            carregaFiliais()
            carregaFiliaisVinculadas()
        }).catch(function (erro) {
            toast.error(erro.response.data.message)
        })
    }

    const excluirProfissionalDuplicado = (dadosClinica: any) => {
        axios.delete(`${Api}/excluir/profissional/duplicado/${cpfProfissional}/${dadosClinica.id_clinica}/${idUsuario}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            carregaFiliais()
            carregaFiliaisVinculadas()
        }).catch(function (erro) {
            toast.error(erro.response.data.message)
        })
    }

    return (
        <>
            <Modal
                show={isOpen}
                className='mt-3'
                size='lg'
                onShow={() => {
                    setDataVinculadas([])
                    setData([])
                    carregaFiliais()
                    carregaFiliaisVinculadas()
                }}
            >
                <Modal.Header className="bg-white d-flex justify-content-between align-items-center" style={{ justifyContent: 'center' }}>
                    <Modal.Title style={{ flex: '1', textAlign: 'center' }}>{"Víncular Clínicas"}</Modal.Title>
                    <i
                        className="bi bi-x-circle"
                        style={{ fontSize: '2rem', cursor: "pointer" }}
                        onClick={() => {
                            fecharModal()
                        }}
                    ></i>
                </Modal.Header>
                <Modal.Body className="bg-white">

                    <div className="border container mb-2">
                        <div className="mb-1 mt-2 d-flex justify-content-center align-items-center  m-auto" style={{ backgroundColor: "#1c4b9b", color: "white", paddingTop: '5px' }}>
                            <label className="form-label text-center" style={{ paddingTop: '5px' }}>
                                <strong className="text-center">Clínicas disponíveis para vincular</strong>
                            </label>
                        </div>
                        <Row className="mt-2">
                            <Tabela
                                coluna={colunas}
                                dados={data}
                                itemsPerPage={10}
                                onVisuClick={function () { }}
                                onEditClick={function () { }}
                                onDeleteClick={function () { }}
                                onImprimirClick={function () { }}
                                usaImprimir={false}
                                id="codigo"
                                usaAcoes={true}
                                usaEdit={false}
                                usaDelete={false}
                                usaVisu={false}
                                usaDuplicar={true}
                                onDuplicarClick={duplicarProfissional}
                            />
                        </Row>
                    </div>


                    <div className="border container mt-3 mb-2">
                        <div className="mb-1 mt-2 d-flex justify-content-center align-items-center  m-auto" style={{ backgroundColor: "#1c4b9b", color: "white", paddingTop: '5px' }}>
                            <label className="form-label text-center" style={{ paddingTop: '5px' }}>
                                <strong className="text-center">Clínicas vinculadas</strong>
                            </label>
                        </div>

                        <Row>
                            <Tabela
                                coluna={colunas}
                                dados={dataVinculadas}
                                itemsPerPage={10}
                                onVisuClick={function () { }}
                                onEditClick={function () { }}
                                onDeleteClick={excluirProfissionalDuplicado}
                                onImprimirClick={function () { }}
                                usaImprimir={false}
                                id="codigo"
                                usaAcoes={true}
                                usaEdit={false}
                                usaDelete={true}
                                usaVisu={false}
                            />
                        </Row>
                    </div>

                </Modal.Body>
                <Modal.Footer className="bg-white">

                </Modal.Footer>

            </Modal>
        </>
    )
}

export default ModalDuplicarProfissional