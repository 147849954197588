import Swal, { SweetAlertOptions } from "sweetalert2";

interface ConfirmationResult {
  confirmed: boolean;
  value?: string;
}

export const showConfirmationDialog = (
  titulo: string,
  texto: string,
  icone: "error" | "question" | "warning" | "info" | "success",
  tituloBotaoConfirmar:
    | "Excluir"
    | "Sim"
    | "Confirmar"
    | "Ok"
    | "Salvar"
    | "Incluir"
    | "Enviar"
    | "Enviar E-mail"
    | "Enviar E-mail de Pré Cadastro"
    | "Enviar E-mail de Confirmação"
    | "Enviar E-mail de Recuperação de Senha"
    | "Enviar E-mail de Confirmação de Cadastro"
    | "Enviar E-mail de Confirmação de Pré Cadastro"
    | "Enviar E-mail de Confirmação de Recuperação de Senha"
    | "Enviar E-mail de Confirmação de Alteração de E-mail"
    | "Enviar E-mail de Confirmação de Alteração de Senha"
    | "Enviar E-mail de Confirmação de Alteração de E-mail e Senha"
    | "Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Pré Cadastro"
    | "Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Cadastro"
    | "Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Recuperação de Senha"
    | "Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Pré Cadastro"
    | "Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Cadastro"
    | "Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Recuperação de Senha"
    | "Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Pré Cadastro"
    | "Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Cadastro"
    | "Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Recuperação de Senha"
    | "Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Pré Cadastro"
    | "Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Cadastro"
    | "Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Recuperação de Senha"
    | "Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Pré Cadastro"
    | "Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Cadastro",
  tituloBotaoCancelar:
    | "Cancelar"
    | "Não"
    | "Não Confirmar"
    | "Não Salvar"
    | "Não Incluir"
    | "Não Enviar"
    | "Não Enviar E-mail"
    | "Não Enviar E-mail de Pré Cadastro"
    | "Não Enviar E-mail de Confirmação"
    | "Não Enviar E-mail de Recuperação de Senha"
    | "Não Enviar E-mail de Confirmação de Cadastro"
    | "Não Enviar E-mail de Confirmação de Pré Cadastro"
    | "Não Enviar E-mail de Confirmação de Recuperação de Senha"
    | "Não Enviar E-mail de Confirmação de Alteração de E-mail"
    | "Não Enviar E-mail de Confirmação de Alteração de Senha"
    | "Não Enviar E-mail de Confirmação de Alteração de E-mail e Senha"
    | "Não Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Pré Cadastro"
    | "Não Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Cadastro"
    | "Não Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Recuperação de Senha"
    | "Não Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Pré Cadastro"
    | "Não Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Cadastro"
    | "Não Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Recuperação de Senha"
    | "Não Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Pré Cadastro"
    | "Não Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Cadastro"
    | "Não Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Recuperação de Senha"
    | "Não Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Pré Cadastro"
    | "Não Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Cadastro"
    | "Não Enviar E-mail de Confirmação de Alteração de E-mail e Senha de Recuperação de Senha",
    exibirInput = false,
    inputPlaceholder?: string,
    inputRequired = false,
    textoErroInput?: string,
): Promise<ConfirmationResult> => {
  return new Promise((resolve, reject) => {
    const inputOptions: SweetAlertOptions['input'] = exibirInput ? 'text' : undefined;
		const inputAttributes: SweetAlertOptions['inputAttributes'] = inputRequired ? { required: 'true' } : undefined;
		const customClass: SweetAlertOptions['customClass'] = {
			validationMessage: 'custom-validation-message',
		};
    Swal.fire({
      title: titulo,
      text: texto,
      icon: icone,
      confirmButtonText: tituloBotaoConfirmar,
      cancelButtonText: tituloBotaoCancelar,
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#1605EE",
      reverseButtons: true,
      focusConfirm: true,
      focusCancel: false,
      allowEscapeKey: true,
      allowEnterKey: false,
      showLoaderOnConfirm: false,
      allowOutsideClick: false,
			input: inputOptions,
			inputPlaceholder: inputPlaceholder,
			inputAttributes: inputAttributes,
			validationMessage: inputRequired ? textoErroInput : undefined,
			customClass: customClass,
    }).then((result) => {
      if (result.isConfirmed) {
        resolve({ confirmed: true, value: result.value });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        resolve({ confirmed: false });
      } else {
        reject();
      }
    });
  });
};
