import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function SelectInput({ value, onChange, options, placeholder, disabed = false, required=false }) {
  return (
    <FormControl variant="standard" sx={{ m: 1.5, width: "100%" }}>
      <InputLabel>
        {placeholder}
      </InputLabel>
      <Select
        disabled={disabed}
        value={value}
        onChange={onChange}
        label={placeholder}
        required={required}
      >
        <MenuItem value="">
          <em>Selecione...</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectInput;
