import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../../../../components/Formularios/Modal/ModalLoading";
import { useState, useEffect, ChangeEvent } from "react";
import { toast } from "react-toastify";
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../../components/Formularios/Inputs/InputsSemBorda";

import {
  Box,
  Switch,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tab,
  Avatar,
  Stack,
  Button,
  Paper,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button as ButtonCustom } from "../../../../../../components/Formularios/Buttons/Button";
import {
  mdiCheckCircle,
  mdiKeyboardReturn,
  mdiLockCheckOutline,
} from "@mdi/js";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ApiPost from "../../../../../../api/endPoints/usePost";
import ApiPut from "../../../../../../api/endPoints/usePut";
import ModalUploadImage from "../../../../../../components/Formularios/Modal/ModalUpload";
import { styled } from "@mui/material/styles";
import ConverterBase64 from "../../../../../../components/ConversorBase64";
import CarregarDados from "../CarregarDados";
// import { InterfaceDadosEmpresa } from "../../../../../types/EmpresaInteface";
import { url_CarregarDados_ID } from "../auxiliar";
import { dadosUF } from "../../../../../../components/dadosFixos/UF";
import { dadosPais } from "../../../../../../components/dadosFixos/Pais";
import { TextoFormatoCaptalize } from "../../../../../../components/Auxiliar/ConvTextoCaptalize";
import { TextoFormatoLowerCase } from "../../../../../../components/Auxiliar/ConvTextoLower";
import { TextoFormatoUpperCase } from "../../../../../../components/Auxiliar/ConvTextoUpper";
import useSelectOptions from "../../../../../../hooks/useSelectOptions";
import SelectInput from "../../../../../../shared/SelectInputs";
import axios from "axios";
import { Api } from "../../../../../../api/api";
import DatalistInput from "react-datalist-input";
import "react-datalist-input/dist/styles.css";
import { getSessionData } from "../../../../../../utils/storageUtils";
import { mascaraCEP, mascaraCPF, mascaraTelefoneCelular, mascaraTelefoneFixo } from "../../../../../../hooks/mascaras";
import ApiGet from "../../../../../../api/endPoints/useGet";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F0F0F0",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "#616161",
  borderRadius: "5px",
  fontWeight: "bold",
  fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
}));

interface FormAnamneseProps {
  idanamnese?: string;
  acao?: string;
}

const FormAnamnese = ({ idanamnese, acao }: FormAnamneseProps) => {
  const navigate = useNavigate();
  const id_clinica = getSessionData("DadosIdClinica") || "";

  //#region Controla as guias
  const [guia, setGuia] = useState("1");

  const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
    setGuia(novaGuia);
  };
  //#endregion

  const [showUpload, setShowUpload] = useState<boolean>(false);

  const [id_anamneses, setId_anamneses] = useState<any>(idanamnese || "");

  const [id_paciente, setId_paciente] = useState<string>("");
  const [escolaridade, setEscolaridade] = useState<string>("");
  const [profissao, setProfissao] = useState<string>("");
  const [terapia_anterior, setTerapia_anterior] = useState<boolean>(true);
  const [terapia_anterior_tempo, setTerapia_anterior_tempo] =
    useState<string>("");
  const [terapia_anterior_abordagem, setTerapia_anterior_abordagem] =
    useState<string>("");
  const [motivo_pisicologo, setMotivo_pisicologo] = useState<string>("");
  const [acomp_med, setAcomp_med] = useState<boolean>(true);
  const [acomp_med_descricao, setAcomp_med_descricao] = useState<string>("");
  const [medicamento, setMedicamento] = useState<boolean>(true);
  const [medicamento_desc, setMedicamento_desc] = useState<string>("");
  const [canal, setCanal] = useState<string>("");
  const [cadastro, setCadastro] = useState<string>("");
  const [usuario_cadastro, setUsuario_cadastro] = useState<string>("");
  const [cpf, setCpf] = useState<string>("");
  const [nome, setNome] = useState<string>("");
  const [dtnascimento, setDtnascimento] = useState<string>("");
  const [telfixo, setTelfixo] = useState<string>("");
  const [telcelular, setTelcelular] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [telemerggencia, setTelemerggencia] = useState<string>("");
  const [contemerggencia, setContemerggencia] = useState<string>("");
  const [telresponsavel, setTelresponsavel] = useState<string>("");
  const [responsavel, setResponsavel] = useState<string>("");
  const [logradouro, setLogradouro] = useState<string>("");
  const [numero, setNumero] = useState<string>("");
  const [bairro, setBairro] = useState<string>("");
  const [compl, setCompl] = useState<string>("");
  const [cnum, setCnum] = useState<string>("");
  const [cuf, setCuf] = useState<string>("");
  const [cep, setCep] = useState<string>("");
  const [tipocontratacao, setTipocontratacao] = useState<string>("");
  const [formapag, setFormapag] = useState<string>("");
  const [convenio, setConvenio] = useState<string>("");
  const [codconvenio, setCodconvenio] = useState<string>("");
  const [tipoconselho, setTipoconselho] = useState<string>("");
  const [ufconselho, setUfconselho] = useState<string>("");
  const [codconselho, setCodconselho] = useState<string>("");
  const [ncbo, setNcbo] = useState<string>("");
  const [dtindicacao, setDtindicacao] = useState<string>("");
  const [nomeprofissional, setNomeprofissional] = useState<string>("");
  const [unidprofissional, setUnidprofissional] = useState<string>("");
  const [codcid, setCodcid] = useState<string>("");
  const [descricaocid, setDescricaocid] = useState<string>("");
  const [observacao, setObservacao] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  //#region ==> Select Options
  // Usando o hook useSelectOptions para obter as opções diretamente
  const tipoContratacaoOptions = useSelectOptions(
    `/codigosistema/listar/TIPOCONTRATACAO`
  );
  const tipoConselhoOptions = useSelectOptions(
    `/codigosistema/listar/TIPOCONSELHO`
  );
  const escolaridadeOptions = useSelectOptions(
    `/codigosistema/listar/ESCOLARIDADE`
  );
  const profissaoOptions = useSelectOptions(`/codigosistema/listar/PROFISSAO`);
  const canalOptions = useSelectOptions(`/codigosistema/listar/CANAL`);
  const [selectedTipoContratacao, setSelectedTipoContratacao] = useState("");
  const [selectedTipoConselho, setSelectedTipoConselho] = useState("");
  const [selectedEscolaridade, setSelectedEscolaridade] = useState("");
  const [selectedProfissao, setSelectedProfissao] = useState("");
  const [selectedCanal, setSelectedCanal] = useState("");

  //#endregion

  //#region ==> Datalist Pacientes
  const [datalistPaciente, setDatalistPaciente] = useState([]);
  const carregarDadosPacienteDataList = async (nome: any) => {
    try {
      const response = await axios.get(
        Api +
          `/anamnese/listarpacientedatalist/?nome=${nome}&id_clinica=${id_clinica}`
      );
      if (response.data.length > 0) {
        setDatalistPaciente(response.data);
      }
    } catch (erro) {
      toast.error("Falha ao listar pacientes. Motivo: " + erro);
    }
  };
  const buscarPacientes = (filtro: any) => {
    let pesquisaPaciente = filtro.target.value;
    if (pesquisaPaciente.length >= 3) {
      carregarDadosPacienteDataList(pesquisaPaciente);
    }
  };

  const selecionaPaciente = (dados: any) => {
    setId_paciente(dados.id);
    setNome(dados.nome);
    setCpf(dados.cpf);
    setDtnascimento(dados.dtnascimento);
    setEmail(dados.email);
    setTelfixo(dados.telfixo);
    setTelcelular(dados.telcelular);
    setTelemerggencia(dados.telemerggencia);
    setContemerggencia(dados.contemerggencia);
    setLogradouro(dados.logradouro);
    setNumero(dados.numero);
    setBairro(dados.bairro);
    setCompl(dados.compl);
    setCnum(dados.cnum);
    setCuf(dados.cuf);
    setCep(dados.cep);
    setCanal(dados.canal);
    setResponsavel(dados.responsavel);
    setTelresponsavel(dados.telresponsavel);
    setCodconvenio(dados.codconvenio);
    setConvenio(dados.convenio);
    setCodconselho(dados.codconselho);
    setSelectedTipoConselho(dados.tipoconselho);
    setUfconselho(dados.ufconselho);
    setCodcid(dados.codcid);
    setDescricaocid(dados.descricaocid);
    setSelectedTipoContratacao(dados.tipocontratacao);
    setFormapag(dados.formapag);
    setNcbo(dados.ncbo);
    setNomeprofissional(dados.nomeprofissional);
    setUnidprofissional(dados.unidprofissional);
    setDtindicacao(dados.dtindicacao);
    setObservacao(dados.observacao);
    setDatalistPaciente([]);
  };
  //#endregion

  const carregar = (url: string, idanamnese: any, id_clinica: string) => {
    setLoading(true);
    CarregarDados(url, idanamnese, id_clinica)
      .then((retorno) => {
        setLoading(false);

        setId_anamneses(retorno[0].id_anamneses || "");

        setId_paciente(retorno[0].id_paciente || "");
        setSelectedEscolaridade(retorno[0].escolaridade || "");
        setProfissao(retorno[0].profissao || "");
        setTerapia_anterior(retorno[0].terapia_anterior === 1 ? true : false);
        setTerapia_anterior_tempo(retorno[0].terapia_anterior_tempo || "");
        setTerapia_anterior_abordagem(
          retorno[0].terapia_anterior_abordagem || ""
        );
        setMotivo_pisicologo(retorno[0].motivo_pisicologo || "");
        setAcomp_med(retorno[0].acomp_med === 1 ? true : false);
        setAcomp_med_descricao(retorno[0].acomp_med_descricao || "");
        setMedicamento(retorno[0].medicamento === 1 ? true : false);
        setMedicamento_desc(retorno[0].medicamento_desc || "");
        setSelectedCanal(retorno[0].canal || "");
        setCadastro(retorno[0].cadastro || "");
        setUsuario_cadastro(retorno[0].usuario_cadastro || "");
        setCpf(retorno[0].cpf || "");
        setNome(retorno[0].nome || "");
        setDtnascimento(retorno[0].dtnascimento || "");
        setTelfixo(retorno[0].telfixo || "");
        setTelcelular(retorno[0].telcelular || "");
        setEmail(retorno[0].email || "");
        setTelemerggencia(retorno[0].telemerggencia || "");
        setContemerggencia(retorno[0].contemerggencia || "");
        setTelresponsavel(retorno[0].telresponsavel || "");
        setResponsavel(retorno[0].responsavel || "");
        setLogradouro(retorno[0].logradouro || "");
        setNumero(retorno[0].numero || "");
        setBairro(retorno[0].bairro || "");
        setCompl(retorno[0].compl || "");
        setCnum(retorno[0].cnum || "");
        setCuf(retorno[0].cuf || "");
        setCep(retorno[0].cep || "");
        setSelectedTipoContratacao(retorno[0].tipocontratacao || "");
        setFormapag(retorno[0].formapag || "");
        setConvenio(retorno[0].convenio || "");
        setCodconvenio(retorno[0].codconvenio || "");
        setSelectedTipoConselho(retorno[0].tipoconselho || "");
        setUfconselho(retorno[0].ufconselho || "");
        setCodconselho(retorno[0].codconselho || "");
        setNcbo(retorno[0].ncbo || "");
        setDtindicacao(retorno[0].dtindicacao || "");
        setNomeprofissional(retorno[0].nomeprofissional || "");
        setUnidprofissional(retorno[0].unidprofissional || "");
        setCodcid(retorno[0].codcid || "");
        setDescricaocid(retorno[0].descricaocid || "");
        setObservacao(retorno[0].observacao || "");
        // Campos select options aqui abaixo é um exemplo
        // setSelectedTipoContratacao(retorno[0].tipocontratacao || '');
      })
      .catch((erro) => {
        setLoading(false);
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Falha ao carregar registro. Motivo: ${msg}`);
      });
  };
  useEffect(() => {
    if (idanamnese !== "novo") {
      carregar(url_CarregarDados_ID, idanamnese, id_clinica);
    }
  }, []);

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS
  const atualizarDados = async (
    id_clinica: string,
    id_anamneses: string,
    id_paciente: string,
    escolaridade: string,
    profissao: string,
    terapia_anterior: string,
    terapia_anterior_tempo: string,
    terapia_anterior_abordagem: string,
    motivo_pisicologo: string,
    acomp_med: string,
    acomp_med_descricao: string,
    medicamento: string,
    medicamento_desc: string,
    canal: string,
    cadastro: string,
    usuario_cadastro: string,
    cpf: string,
    nome: string,
    dtnascimento: string,
    telfixo: string,
    telcelular: string,
    email: string,
    telemerggencia: string,
    contemerggencia: string,
    telresponsavel: string,
    responsavel: string,
    logradouro: string,
    numero: string,
    bairro: string,
    compl: string,
    cnum: string,
    cuf: string,
    cep: string,
    tipocontratacao: string,
    formapag: string,
    convenio: string,
    codconvenio: string,
    tipoconselho: string,
    ufconselho: string,
    codconselho: string,
    ncbo: string,
    dtindicacao: string,
    nomeprofissional: string,
    unidprofissional: string,
    codcid: string,
    descricaocid: string,
    observacao: string
  ) => {
    const dados = {
      id_clinica,
      id_anamneses,
      id_paciente,
      escolaridade: selectedEscolaridade,
      profissao:  TextoFormatoCaptalize(profissao.toLowerCase()),
      terapia_anterior,
      terapia_anterior_tempo,
      terapia_anterior_abordagem: TextoFormatoCaptalize(
        terapia_anterior_abordagem.toLowerCase()
      ),
      motivo_pisicologo: TextoFormatoCaptalize(motivo_pisicologo.toLowerCase()),
      acomp_med,
      acomp_med_descricao: TextoFormatoCaptalize(
        acomp_med_descricao.toLowerCase()
      ),
      medicamento,
      medicamento_desc: TextoFormatoCaptalize(medicamento_desc.toLowerCase()),
      canal: selectedCanal,
      cadastro,
      usuario_cadastro,
      cpf,
      nome,
      dtnascimento,
      telfixo,
      telcelular,
      email,
      telemerggencia,
      contemerggencia,
      telresponsavel,
      responsavel,
      logradouro,
      numero,
      bairro,
      compl,
      cnum,
      cuf,
      cep,
      tipocontratacao: selectedTipoContratacao,
      formapag,
      convenio,
      codconvenio,
      tipoconselho: selectedTipoConselho,
      ufconselho,
      codconselho,
      ncbo,
      dtindicacao,
      nomeprofissional,
      unidprofissional,
      codcid,
      descricaocid,
      observacao,
      // tipocontratacao: selectedTipoContratacao
    };

    ApiPut(`/anamnese/atualizar`, dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        toast.error(`Erro ao atualizar. Motivo: ${erro.response.data.message}`);
      });
  };
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS
  const gravarDados = (
    id_clinica: string,
    id_anamneses: string,
    id_paciente: string,
    escolaridade: string,
    profissao: string,
    terapia_anterior: string,
    terapia_anterior_tempo: string,
    terapia_anterior_abordagem: string,
    motivo_pisicologo: string,
    acomp_med: string,
    acomp_med_descricao: string,
    medicamento: string,
    medicamento_desc: string,
    canal: string,
    cadastro: string,
    usuario_cadastro: string,
    cpf: string,
    nome: string,
    dtnascimento: string,
    telfixo: string,
    telcelular: string,
    email: string,
    telemerggencia: string,
    contemerggencia: string,
    telresponsavel: string,
    responsavel: string,
    logradouro: string,
    numero: string,
    bairro: string,
    compl: string,
    cnum: string,
    cuf: string,
    cep: string,
    tipocontratacao: string,
    formapag: string,
    convenio: string,
    codconvenio: string,
    tipoconselho: string,
    ufconselho: string,
    codconselho: string,
    ncbo: string,
    dtindicacao: string,
    nomeprofissional: string,
    unidprofissional: string,
    codcid: string,
    descricaocid: string,
    observacao: string
  ) => {
    const dados = {
      id_clinica,
      id_anamneses,
      id_paciente,
      escolaridade: selectedEscolaridade,
      profissao:  TextoFormatoCaptalize(profissao.toLowerCase()),
      terapia_anterior,
      terapia_anterior_tempo,
      terapia_anterior_abordagem: TextoFormatoCaptalize(
        terapia_anterior_abordagem.toLowerCase()
      ),
      motivo_pisicologo: TextoFormatoCaptalize(motivo_pisicologo.toLowerCase()),
      acomp_med,
      acomp_med_descricao: TextoFormatoCaptalize(
        acomp_med_descricao.toLowerCase()
      ),
      medicamento,
      medicamento_desc: TextoFormatoCaptalize(medicamento_desc.toLowerCase()),
      canal: selectedCanal,
      cadastro,
      usuario_cadastro,
      cpf,
      nome,
      dtnascimento,
      telfixo,
      telcelular,
      email,
      telemerggencia,
      contemerggencia,
      telresponsavel,
      responsavel,
      logradouro,
      numero,
      bairro,
      compl,
      cnum,
      cuf,
      cep,
      tipocontratacao: selectedTipoContratacao,
      formapag,
      convenio,
      codconvenio,
      tipoconselho: selectedTipoConselho,
      ufconselho,
      codconselho,
      ncbo,
      dtindicacao,
      nomeprofissional,
      unidprofissional,
      codcid,
      descricaocid,
      observacao,
      // tipocontratacao: selectedTipoContratacao
    };
    ApiPost("/anamnese/criar", dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`${msg}`);
      });
  };
  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (idanamnese === "novo") {
      gravarDados(
        id_clinica,
        id_anamneses || "0",
        id_paciente || "0",
        escolaridade,
        profissao,
        terapia_anterior ? "1" : "0",
        terapia_anterior_tempo,
        terapia_anterior_abordagem,
        motivo_pisicologo,
        acomp_med ? "1" : "0",
        acomp_med_descricao,
        medicamento ? "1" : "0",
        medicamento_desc,
        canal,
        cadastro,
        usuario_cadastro,
        cpf,
        nome,
        dtnascimento,
        telfixo,
        telcelular,
        email,
        telemerggencia,
        contemerggencia,
        telresponsavel,
        responsavel,
        logradouro,
        numero,
        bairro,
        compl,
        cnum,
        cuf,
        cep,
        tipocontratacao,
        formapag,
        convenio,
        codconvenio,
        tipoconselho,
        ufconselho,
        codconselho,
        ncbo,
        dtindicacao,
        nomeprofissional,
        unidprofissional,
        codcid,
        descricaocid,
        observacao
      );
      toast.info("Inserindo novo registro...");
    } else {
      atualizarDados(
        id_clinica,
        id_anamneses || "0",
        id_paciente || "0",
        escolaridade,
        profissao,
        terapia_anterior ? "1" : "0",
        terapia_anterior_tempo,
        terapia_anterior_abordagem,
        motivo_pisicologo,
        acomp_med ? "1" : "0",
        acomp_med_descricao,
        medicamento ? "1" : "0",
        medicamento_desc,
        canal,
        cadastro,
        usuario_cadastro,
        cpf,
        nome,
        dtnascimento,
        telfixo,
        telcelular,
        email,
        telemerggencia,
        contemerggencia,
        telresponsavel,
        responsavel,
        logradouro,
        numero,
        bairro,
        compl,
        cnum,
        cuf,
        cep,
        tipocontratacao,
        formapag,
        convenio,
        codconvenio,
        tipoconselho,
        ufconselho,
        codconselho,
        ncbo,
        dtindicacao,
        nomeprofissional,
        unidprofissional,
        codcid,
        descricaocid,
        observacao
      );
      toast.info("Atualizando dados do registro...");
    }
  };

  //#region REALIZA REQUISIÇÃO PARA LISTAR DADOS DA TABELA
  const [dadosConvenio, setDadosConvenio] = useState([]);
  const buscarDados = async () => {
    const dados = {
      url: "/convenio/listar/:id_clinica",
      parametros: {
        id_clinica: id_clinica || "",
      },
    };
    ApiGet(dados)
      .then((retorno) => {
        setDadosConvenio(retorno);
        setLoading(false);
      })
      .catch((erro) => {
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    buscarDados();
  }, []);
  //#endregion

  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Box sx={{ width: "100%" }}>
              <TabContext value={guia}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
                <TabPanel value="1">
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="ID Paciente"
                        name="id_paciente"
                        type="text"
                        placeholder="ID Paciente"
                        readonly
                        value={id_paciente || ""}
                        onChange={setId_paciente}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="CPF"
                        name="cpf"
                        type="text"
                        placeholder="CPF"
                        readonly
                        // value={cpf || ""}
                        value={mascaraCPF(cpf) || ""}
                        onChange={setCpf}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <div className="col-sm-4">
                      <div className="mb-1">
                        <label htmlFor="">Nome</label>
                        <DatalistInput
                          placeholder="Digite 3 caracteres para filtrar..."
                          label="Nome"
                          showLabel={false}
                          onChange={(item: any) => buscarPacientes(item)}
                          onSelect={(item: any) => selecionaPaciente(item)}
                          items={datalistPaciente}
                          value={nome || ""}
                          inputProps={{
                            readOnly:
                              acao === "Visualizar" || acao === "Editar"
                                ? true
                                : false,
                          }}
                          className="form-control bordasInferiorInput text-black text-capitalize "
                        />
                      </div>
                    </div>
                    {/* <Col sm={4}>
                      <InputSemBorda
                        label="Nome"
                        name="nome"
                        type="text"
                        placeholder="Nome"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={nome || ""}
                        onChange={setNome}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col> */}
                    <Col sm={2}>
                      <InputSemBorda
                        label="Data Nascimento"
                        name="dtnascimento"
                        type="date"
                        placeholder="Data Nascimento"
                        // required
                        readonly
                        value={dtnascimento}
                        onChange={setDtnascimento}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      />
                    </Col>
                    <Col sm={2}>
                      <SelectInput
                        value={selectedCanal}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setSelectedCanal(e.target.value)
                        }
                        options={canalOptions}
                        placeholder="Canal"
                        disabed={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Email"
                        name="email"
                        type="text"
                        placeholder="Email"
                        required
                        readonly
                        value={email || ""}
                        onChange={setEmail}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Telefone"
                        name="telefone"
                        type="text"
                        placeholder="Telefone Fixo"
                        required
                        readonly
                        // value={telfixo || ""}
                        value={mascaraTelefoneFixo(telfixo) || ""}
                        onChange={setTelfixo}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Celular"
                        name="telcelular"
                        type="text"
                        placeholder="Celular"
                        required
                        readonly
                        // value={telcelular || ""}
                        value={mascaraTelefoneCelular(telcelular) || ""}
                        onChange={setTelcelular}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Tel. Emergência"
                        name="telemerggencia"
                        type="text"
                        placeholder="Tel. Emergência"
                        required
                        readonly
                        // value={telemerggencia || ""}
                        value={mascaraTelefoneCelular(telemerggencia) || ""}
                        onChange={setTelemerggencia}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Contato Emergência"
                        name="contemerggencia"
                        type="text"
                        placeholder="Contato Emergência"
                        required
                        readonly
                        value={contemerggencia || ""}
                        onChange={setContemerggencia}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="CEP"
                        name="cep"
                        type="text"
                        placeholder="CEP"
                        readonly
                        // value={cep || ""}
                        value={mascaraCEP(cep) || ""}
                        onChange={setCep}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Logradouro"
                        name="endereco"
                        type="text"
                        placeholder="Logradouro"
                        readonly
                        value={logradouro || ""}
                        onChange={setLogradouro}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Complemento"
                        name="compl"
                        type="text"
                        placeholder="Complemento"
                        readonly
                        value={compl || ""}
                        onChange={setCompl}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Número"
                        name="numero"
                        type="text"
                        placeholder="Número"
                        readonly
                        value={numero || ""}
                        onChange={setNumero}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Bairro"
                        name="bairro"
                        type="text"
                        placeholder="Bairro"
                        readonly
                        value={bairro || ""}
                        onChange={setBairro}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Cidade"
                        name="cnum"
                        type="text"
                        placeholder="Cidade"
                        required
                        readonly
                        value={cnum || ""}
                        onChange={setCnum}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>

                    <Col sm={2}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1.5, width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          UF
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={cuf}
                          onChange={(e: SelectChangeEvent) =>
                            setCuf(e.target.value)
                          }
                          label="UF"
                          required
                          disabled
                        >
                          <MenuItem value="">
                            <em>Selecione...</em>
                          </MenuItem>
                          {dadosUF.map((item: any) => (
                            <MenuItem value={item.uf}>{item.nome}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Tel. Responsável"
                        name="telresponsavel"
                        type="text"
                        placeholder="Tel. Responsável"
                        required
                        readonly
                        // value={telresponsavel || ""}
                        value={mascaraTelefoneCelular(telresponsavel) || ""}
                        onChange={setTelresponsavel}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Responsável"
                        name="responsavel"
                        type="text"
                        placeholder="Responsável"
                        required
                        readonly
                        value={responsavel || ""}
                        onChange={setResponsavel}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <SelectInput
                        value={selectedTipoContratacao}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setSelectedTipoContratacao(e.target.value)
                        }
                        options={tipoContratacaoOptions}
                        placeholder="Tipo Contratação"
                        disabed
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Forma Pagamento"
                        name="formapag"
                        type="text"
                        placeholder="Forma Pagamento"
                        required
                        readonly
                        value={formapag || ""}
                        onChange={setFormapag}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Cod. Convênio"
                        name="codconvenio"
                        type="text"
                        placeholder="Cod. Convênio"
                        required
                        readonly
                        value={codconvenio || ""}
                        onChange={setCodconvenio}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      {/* <InputSemBorda
                        label="Convênio"
                        name="convenio"
                        type="text"
                        placeholder="Convênio"
                        required
                        readonly
                        value={convenio || ""}
                        onChange={setConvenio}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      /> */}
                      <FormControl
                        variant="standard"
                        sx={{ m: 1.5, width: "100%" }}
                      >
                        <InputLabel>Convênio</InputLabel>
                        <Select
                          value={convenio}
                          onChange={(e: SelectChangeEvent) =>
                            setConvenio(e.target.value)
                          }
                          label="Convênio"
                          
                          disabled={true}
                        >
                          <MenuItem value="">
                            <em>Selecione...</em>
                          </MenuItem>
                          {dadosConvenio.map((item: any) => (
                            <MenuItem value={item.id_convenio}>
                              {item.fantasia}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <SelectInput
                        value={selectedTipoConselho}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setSelectedTipoConselho(e.target.value)
                        }
                        options={tipoConselhoOptions}
                        placeholder="Tipo Conselho"
                        disabed
                      />
                      {/* <p>Opção selecionada: {selectedTipoSala}</p> */}
                    </Col>
                    <Col sm={2}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1.5, width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          UF Conselho
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={ufconselho}
                          onChange={(e: SelectChangeEvent) =>
                            setUfconselho(e.target.value)
                          }
                          label="UF"
                          required
                          disabled
                        >
                          <MenuItem value="">
                            <em>Selecione...</em>
                          </MenuItem>
                          {dadosUF.map((item: any) => (
                            <MenuItem value={item.uf}>{item.nome}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Código Conselho"
                        name="codconselho"
                        type="text"
                        placeholder="Código Conselho"
                        required
                        readonly
                        value={codconselho || ""}
                        onChange={setCodconselho}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Código CBO"
                        name="ncbo"
                        type="text"
                        placeholder="Código CBO"
                        required
                        readonly
                        value={ncbo || ""}
                        onChange={setNcbo}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Data Indicação"
                        name="dtindicacao"
                        type="date"
                        placeholder="Data Indicação"
                        // required
                        readonly
                        // value={converterDataInput(dtindicacao) || ""}
                        value={dtindicacao}
                        onChange={setDtindicacao}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Nome Profissional"
                        name="nomeprofissional"
                        type="text"
                        placeholder="Nome Profissional"
                        required
                        readonly
                        value={nomeprofissional || ""}
                        onChange={setNomeprofissional}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Unidade Profissional"
                        name="unidprofissional"
                        type="text"
                        placeholder="Unidade Profissional"
                        required
                        readonly
                        value={unidprofissional || ""}
                        onChange={setUnidprofissional}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Código CID"
                        name="codcid"
                        type="text"
                        placeholder="Código CID"
                        required
                        readonly
                        value={codcid || ""}
                        onChange={setCodcid}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Descrição CID"
                        name="descricaocid"
                        type="text"
                        placeholder="Descrição CID"
                        required
                        readonly
                        value={descricaocid || ""}
                        onChange={setDescricaocid}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <InputSemBorda
                        label="Observação"
                        name="observacao"
                        type="text"
                        placeholder="Observação"
                        required
                        readonly
                        value={observacao || ""}
                        onChange={setObservacao}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <SelectInput
                        value={selectedEscolaridade}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setSelectedEscolaridade(e.target.value)
                        }
                        options={escolaridadeOptions}
                        placeholder="Escolaridade"
                        disabed={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={2}>
                     
                      <InputSemBorda
                        label="Profissão"
                        name="profissao"
                        type="text"
                        placeholder="Profissão"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={profissao || ""}
                        onChange={setProfissao}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Já fez terapia?
                      </label>
                      <Switch
                        checked={terapia_anterior}
                        onChange={() => setTerapia_anterior(!terapia_anterior)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Quantos Meses?"
                        name="terapia_anterior_tempo"
                        type="number"
                        placeholder="Quantos Meses?"
                        required={terapia_anterior ? true : false}
                        readonly={acao === "Visualizar" ? true : false}
                        value={terapia_anterior_tempo || ""}
                        onChange={setTerapia_anterior_tempo}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Qual abordagem?"
                        name="terapia_anterior_abordagem"
                        type="text"
                        placeholder="Qual abordagem?"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={terapia_anterior_abordagem || ""}
                        onChange={setTerapia_anterior_abordagem}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <InputSemBorda
                        label="Por qual motivo procurou um psicólogo?"
                        name="motivo_pisicologo"
                        type="text"
                        placeholder="Por qual motivo procurou um psicólogo?"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={motivo_pisicologo || ""}
                        onChange={setMotivo_pisicologo}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black  text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Faz acompanhamento médico?
                      </label>
                      <Switch
                        checked={acomp_med}
                        onChange={() => setAcomp_med(!acomp_med)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={10}>
                      <InputSemBorda
                        label="Qual é motivo do acompanhamento médico?"
                        name="acomp_med_descricao"
                        type="text"
                        placeholder="Por qual motivo procurou um psicólogo?"
                        required={acomp_med ? true : false}
                        readonly={acao === "Visualizar" ? true : false}
                        value={acomp_med_descricao || ""}
                        onChange={setAcomp_med_descricao}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black  text-capitalize "
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Faz uso de medicamentos?
                      </label>
                      <Switch
                        checked={medicamento}
                        onChange={() => setMedicamento(!medicamento)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={10}>
                      <InputSemBorda
                        label="Qual é o medicamento?"
                        name="medicamento_desc"
                        type="text"
                        placeholder="Qual é o medicamento?"
                        required={medicamento ? true : false}
                        readonly={acao === "Visualizar" ? true : false}
                        value={medicamento_desc || ""}
                        onChange={setMedicamento_desc}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black  text-capitalize"
                      />
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel value="2">
                  <Row>
                    <Stack spacing={3}>
                      <Item>Configurações Básicas.</Item>
                    </Stack>
                  </Row>
                  <Row>
                    <Col>
                      <table className="table-hover border-bottom hover-bg-blue">
                        <tr>
                          <th>Itens Por Página:</th>
                          <td>
                            <input
                              name="itenspagina"
                              value=""
                              type="number"
                              placeholder="Itens Por Página"
                              onChange={() => {}}
                              className="form-control bordasInferiorInput text-black text-capitalize"
                            />
                          </td>
                          <td>
                            Informe a quantidade de itens que serão exibidos nos
                            grid's. (Por padrão serão exibidos 5 itens por
                            página)
                          </td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                  <Row>
                    <Stack spacing={3}>
                      <Item>Logo - Informe a logomarca da empresa.</Item>
                    </Stack>
                  </Row>
                  <br />
                  {/* <Row className="mb-2">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      {avatar ? (
                        <img
                          alt=""
                          style={{
                            width: "70%",
                            height: "250px",
                            objectFit: "contain",
                            backgroundImage: `url(data:image/png;base64,${avatar})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                          }}
                        />
                      ) : (
                        <Avatar
                          src={""}
                          alt="Avatar"
                          sx={{ width: 240, height: 240 }}
                        />
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        id="avatar-input"
                        onChange={handleAvatarChange}
                        style={{ display: "none" }}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                      <label htmlFor="avatar-input">
                        <IconButton color="primary" component="span">
                          <PhotoCameraIcon />
                        </IconButton>
                      </label>
                      {avatar && acao !== "Visualizar" && (
                        <Button variant="outlined" onClick={handleRemoveAvatar}>
                          Remover Logo
                        </Button>
                      )}
                    </Stack>
                  </Row> */}
                  {/* <Row>
                    <Stack spacing={3}>
                      <Item>Dados de Integração</Item>
                    </Stack>
                  </Row> */}
                  <br />
                  {/* <Row> */}
                  {/* CAMPO PARA CLICAR NO BOTAO GERAR TOKEN E O MESMO SER GERADO */}
                  {/* <Col sm={12}>
                      <InputGroup>
                        <ButtonCustom
                          type="button"
                          className="btn btn-info "
                          icone={mdiLockCheckOutline}
                          descricaoBotao="Gerar Token"
                        />
                        <InputSemBorda
                          label="Token"
                          name="token"
                          type="text"
                          placeholder="token"
                          readonly
                          value={token}
                          onChange={setToken}
                          classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize "
                        />
                      </InputGroup>
                    </Col> */}
                  {/* </Row> */}
                </TabPanel>
              </TabContext>
            </Box>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <ButtonCustom
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
                disabled={acao === "Visualizar" ? true : false}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>
      <ModalUploadImage
        show={showUpload}
        label="Realizando Upload da Imagem ..."
      />
      <ModalLoading show={loading} label="Carregado dados da clinica ..." />
    </>
  );
};
export default FormAnamnese;
