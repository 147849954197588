import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../../../../components/Formularios/Modal/ModalLoading";
import { useState, useEffect, ChangeEvent } from "react";
import { toast } from "react-toastify";
import { Card, Col, Form, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../../components/Formularios/Inputs/InputsSemBorda";

import { Box, Switch } from "@mui/material";
import { Button as ButtonCustom } from "../../../../../../components/Formularios/Buttons/Button";
import { mdiCheckCircle, mdiKeyboardReturn } from "@mdi/js";
import ApiPost from "../../../../../../api/endPoints/usePost";
import ApiPut from "../../../../../../api/endPoints/usePut";
import CarregarDados from "../CarregarDados";
// import { InterfaceDadosEmpresa } from "../../../../../types/EmpresaInteface";
import { url_CarregarDados_ID } from "../auxiliar";
import { TextoFormatoCaptalize } from "../../../../../../components/Auxiliar/ConvTextoCaptalize";
import useSelectOptions from "../../../../../../hooks/useSelectOptions";
import SelectInput from "../../../../../../shared/SelectInputs";
import { getSessionData } from "../../../../../../utils/storageUtils";
import { mascaraCNPJ } from "../../../../../../hooks/mascaras";

interface FormProcedimentoConveniosProps {
  convenioprocedimento?: string;
  acao?: string;
}

const FormProcedimentoConvenios = ({
  convenioprocedimento,
  acao,
}: FormProcedimentoConveniosProps) => {
  const navigate = useNavigate();
  const id_clinica = getSessionData("DadosIdClinica") || "";

  const [convenio_procedimento, setConvenio_procedimento] = useState<any>(
    convenioprocedimento || ""
  );

  const [id_convenio, setId_convenio] = useState<any>("");
  const [cnpj, setCnpj] = useState<string>("");
  const [fantasia, setFantasia] = useState<string>("");
  const [id_procedimento, setId_procedimento] = useState<string>("");
  const procedimento = "";
  const [tabela, setTabela] = useState<string>("");
  const [valor, setValor] = useState<string>("");
  const [status, setStatus] = useState<boolean>(true);
  const [cadastro, setCadastro] = useState<string>("");
  const [usuario_cadastro, setUsuario_cadastro] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  //#region ==> Select Options
  // Usando o hook useSelectOptions para obter as opções diretamente
  const conveniosOptions = useSelectOptions(
    `/convenioprocedimento/listarconvenios/${id_clinica}`
  );
  const procedimentosOptions = useSelectOptions(
    `/convenioprocedimento/listarprocedimentos/${id_clinica}`
  );
  const [selectedConvenios, setSelectedConvenios] = useState("");
  const [selectedProcedimentos, setSelectedProcedimentos] = useState("");

  //#endregion

  const carregar = (
    url: string,
    convenioprocedimento: any,
    id_clinica: string
  ) => {
    setLoading(true);
    CarregarDados(url, convenioprocedimento, id_clinica)
      .then((retorno) => {
        setLoading(false);

        setConvenio_procedimento(retorno[0].convenio_procedimento || "");

        setId_convenio(retorno[0].id_convenio || "");
        setSelectedConvenios(retorno[0].cnpj || "");
        setFantasia(retorno[0].fantasia || "");
        setSelectedProcedimentos(retorno[0].id_procedimento || "");
        setId_procedimento(retorno[0].procedimento || "");
        setTabela(retorno[0].tabela || "");
        setValor(retorno[0].valor || "");
        setStatus(retorno[0].status === 1 ? true : false);
        setCadastro(retorno[0].cadastro || "");
        setUsuario_cadastro(retorno[0].usuario_cadastro || "");
        // Campos select options aqui abaixo é um exemplo
        // setSelectedTipoContratacao(retorno[0].tipocontratacao || '');
      })
      .catch((erro) => {
        setLoading(false);
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao carregar dados. Motivo: ${msg}`);
      });
  };
  useEffect(() => {
    if (convenioprocedimento !== "novo") {
      carregar(url_CarregarDados_ID, convenioprocedimento, id_clinica);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS
  const atualizarDados = async (
    convenio_procedimento: string,
    id_clinica: string,
    id_convenio: string,
    cnpj: string,
    fantasia: string,
    id_procedimento: string,
    procedimento: string,
    tabela: string,
    valor: string,
    status: string,
    cadastro: string,
    usuario_cadastro: string
  ) => {
    const dados = {
      convenio_procedimento,
      id_clinica,
      id_convenio: id_convenio,
      cnpj: selectedConvenios,
      fantasia,
      id_procedimento: selectedProcedimentos,
      procedimento,
      tabela: TextoFormatoCaptalize(tabela.toLowerCase()),
      valor,
      status,
      cadastro,
      usuario_cadastro,
    };

    ApiPut(`/convenioprocedimento/atualizar`, dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        toast.error(`Erro ao atualizar. Motivo: ${erro.response.data.message}`);
      });
  };
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS
  const gravarDados = (
    convenio_procedimento: string,
    id_clinica: string,
    id_convenio: string,
    cnpj: string,
    fantasia: string,
    id_procedimento: string,
    procedimento: string,
    tabela: string,
    valor: string,
    status: string,
    cadastro: string,
    usuario_cadastro: string
  ) => {
    const dados = {
      convenio_procedimento,
      id_clinica,
      id_convenio: id_convenio,
      cnpj: selectedConvenios,
      fantasia,
      id_procedimento: selectedProcedimentos,
      procedimento,
      tabela: TextoFormatoCaptalize(tabela.toLowerCase()),
      valor,
      status,
      cadastro,
      usuario_cadastro,
    };
    ApiPost("/convenioprocedimento/criar", dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };
  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (convenioprocedimento === "novo") {
      gravarDados(
        convenio_procedimento || "0",
        id_clinica || "2",
        id_convenio,
        cnpj,
        fantasia,
        id_procedimento || "0",
        procedimento,
        tabela,
        valor,
        status ? "1" : "0",
        cadastro,
        usuario_cadastro
      );
      toast.info("Inserindo novo registro...");
    } else {
      atualizarDados(
        convenio_procedimento || "0",
        id_clinica || "2",
        id_convenio,
        cnpj,
        fantasia,
        id_procedimento || "0",
        procedimento,
        tabela,
        valor,
        status ? "1" : "0",
        cadastro,
        usuario_cadastro
      );
      toast.info("Atualizando dados do registro...");
    }
  };

  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>

              <Row>
                <Col sm={2}>
                  <InputSemBorda
                    label="ID"
                    name="convenio_procedimento"
                    type="text"
                    placeholder="ID"
                    readonly
                    value={convenio_procedimento || ""}
                    onChange={setConvenio_procedimento}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
                <Col sm={2}>
                  <InputSemBorda
                    label="CNPJ"
                    name="cnpj"
                    type="text"
                    placeholder="CNPJ"
                    readonly
                    // value={cnpj || ""}
                    // value={selectedConvenios || ""}
                    value={mascaraCNPJ(selectedConvenios) || ""}
                    onChange={setCnpj}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
                <Col sm={4}>
                  <SelectInput
                    value={selectedConvenios}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      setSelectedConvenios(e.target.value);
                      setId_convenio(e.target.value);
                    }}
                    options={conveniosOptions}
                    placeholder="Convênio"
                    disabed={acao === "Visualizar" ? true : false}
                  />
                  {/* <p>Opção selecionada: {selectedTipoSala}</p> */}
                </Col>
                <Col sm={4}>
                  <SelectInput
                    value={selectedProcedimentos}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                      setSelectedProcedimentos(e.target.value)
                    }
                    options={procedimentosOptions}
                    placeholder="Procedimento"
                    disabed={acao === "Visualizar" ? true : false}
                  />
                  {/* <p>Opção selecionada: {selectedTipoSala}</p> */}
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <InputSemBorda
                    label="Tabela"
                    name="tabela"
                    type="text"
                    placeholder="Tabela"
                    required
                    readonly={acao === "Visualizar" ? true : false}
                    value={tabela || ""}
                    onChange={setTabela}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
                <Col sm={3}>
                  <InputSemBorda
                    label="Valor"
                    name="valor"
                    type="text"
                    placeholder="Valor"
                    required
                    readonly={acao === "Visualizar" ? true : false}
                    value={valor || ""}
                    onChange={setValor}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
                <Col sm={2}>
                  <br />
                  <label htmlFor="" className="form-label me-2">
                    Ativo
                  </label>
                  <Switch
                    checked={status}
                    onChange={() => setStatus(!status)}
                    disabled={acao === "Visualizar" ? true : false}
                  />
                </Col>
              </Row>
            </Box>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <ButtonCustom
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
                disabled={acao === "Visualizar" ? true : false}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>

      <ModalLoading show={loading} label="Carregado dados da clinica ..." />
    </>
  );
};
export default FormProcedimentoConvenios;
