import React from "react";

type EventContentProps = {
  timeText: string;
  eventTitle: string;
  subtitle: string;
  detail?: string;
};

const EventContent: React.FC<EventContentProps> = (props) => {
  const { timeText, eventTitle, subtitle, detail } = props;

  return (
    <>
      <div className="evento-customizado bg-animate">
        <b>{timeText}</b>
        <br />
        <i>Tipo Sala: {eventTitle}</i>
        <br />
        <i>Paciente: {subtitle}</i>
        <i>{detail}</i>
      </div>
    </>
  );
};

export default EventContent;
