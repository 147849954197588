import React from "react";
import Icon from "@mdi/react";
import { FiUserCheck } from "react-icons/fi";

interface CardProps {
  titulo?: string;
  titulosmall?: string;
  dados?: string;
  rodape?: string;
  icone?: string;
}

const Cards: React.FC<CardProps> = ({
  titulo,
  dados,
  titulosmall,
  rodape,
  icone,
}) => {
  return (
    <div className="card">
      {titulo && (
        <div className="card-header">
          <div className="card-title">{titulo}</div>
        </div>
      )}
      <div className="card body">
        <div className="row">
          <div className="col-5 m-0">
      {dados && (
        <div className="card-body d-flex flex-column align-items-end">
            <FiUserCheck size={"50px"} color="#f58619"/>
          {titulosmall && <h6>{titulosmall}</h6>}
        </div>
      )}

          </div>
          <div className="col-7">
      <h2>{dados}</h2>

          </div>
        </div>
      </div>
      {rodape && (
        <div className="card-footer">
          <h6>
            {icone && <Icon path={icone} size={0.75} />}
            {rodape}
          </h6>
        </div>
      )}
    </div>
  );
};

export default Cards;
