import { ITabela } from "./TabelaInterface";
import React, { useState } from "react";
import { Button } from "../Buttons/Button";
import {
  mdiEye,
  mdiFileEditOutline,
  mdiFilePdfBox,
  mdiPrinter,
  mdiTrashCanOutline,
  mdiContentDuplicate 
} from "@mdi/js";
import Avatar from "@mui/material/Avatar";
import Paginacao from "../Pagination";
import { Badge } from "react-bootstrap";
import { mdiEmailFastOutline, mdiWhatsapp } from "@mdi/js";

interface TabelaProps {
  coluna: ITabela[];
  dados: any[];
  itemsPerPage: number;
  usaVisu?: boolean;
  usaEdit?: boolean;
  usaDelete?: boolean;
  usaImprimir?: boolean;
  usaDuplicar?: boolean;
  onVisuClick: (dados: any) => void;
  onEditClick: (dados: any) => void;
  onDeleteClick: (dados: any) => void;
  onImprimirClick: (dados: any) => void;
  onDuplicarClick?: (dados: any) => void;
  id?: string;
  descEditar?: string;
  descExcluir?: string;
  descVisualizar?: string;
  descImprimir?: string;
  iconeEditar?: string;
  iconeExcluir?: string;
  iconeVisualizar?: string;
  iconeImprimir?: string;
  usaNovoBotao?: boolean; // Adicione uma nova propriedade para o botão opcional
  onNovoBotaoClick?: (dados: any) => void; // Adicione uma nova função de clique para o botão opcional
  descNovoBotao?: string;
  iconeNovoBotao?: string;
  usaAcoes?: boolean;
}
const Tabela: React.FC<TabelaProps> = ({
  coluna,
  dados,
  itemsPerPage,
  usaVisu = true,
  usaEdit = true,
  usaDelete = true,
  usaImprimir = true,
  usaDuplicar = false,
  onDuplicarClick = function () {},
  onVisuClick,
  onDeleteClick,
  onEditClick,
  onImprimirClick,
  id,
  descEditar,
  descExcluir,
  descVisualizar,
  descImprimir,
  iconeEditar,
  iconeExcluir,
  iconeVisualizar,
  iconeImprimir,
  usaNovoBotao,
  onNovoBotaoClick,
  descNovoBotao,
  iconeNovoBotao,
  usaAcoes = true,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(dados.length / itemsPerPage);
  const itens = dados.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // const itens = Array.isArray(dados)
  //   ? dados.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
  //   : [];

  return (
    <div className="table-responsive">
      <table className="table table-centered w-100 dt-responsive nowrap table-striped table-hover">
        <thead className="table-cabecalho">
          <tr>
            {coluna.map((item, index) => (
              <th key={index}>{item.titulo}</th>
            ))}
            {usaAcoes && <th>Ações</th>}
          </tr>
        </thead>
        <tbody>
          {itens.map((item, index) => (
            <tr key={index}>
              {coluna.map((coluna, index) => (
                <td key={index}>
                  {coluna.acesso === "avatar" ? (
                    item[coluna.acesso] !== "" ? (
                      <Avatar
                        src={`data:image/png;base64,${item[coluna.acesso]}`}
                        alt="Avatar"
                      />
                    ) : (
                      <Avatar src={""} alt="Avatar" />
                    )
                  ) : coluna.acesso === 'fatura_gerada' ? (
                    item[coluna.acesso] === null || item[coluna.acesso] === false ? (
                      <Badge bg="danger">N. Faturada</Badge>
                    ) : (
                      <Badge bg="primary">Faturada</Badge>
                    )
                  ) : coluna.acesso === "situacao" ? (
                    item[coluna.acesso] === 1 ? (
                      <Badge bg="primary">Ativo</Badge>
                    ) : (
                      <Badge bg="danger">Inativo</Badge>
                    )
                  ) : coluna.acesso === "status" ? (
                    item[coluna.acesso] === 1 ? (
                      <Badge bg="primary">Ativo</Badge>
                    ) : (
                      <Badge bg="danger">Inativo</Badge>
                    )
                  ) : coluna.acesso === "enviou_email" ? (
                    item[coluna.acesso] === 1 ? (
                      <Badge bg="success">Sim</Badge>
                    ) : (
                      <Badge bg="danger">Não</Badge>
                    )
                  ) : coluna.acesso === "enviou_whatsapp" ? (
                    item[coluna.acesso] === 1 ? (
                      <Badge bg="success">Sim</Badge>
                    ) : (
                      <Badge bg="danger">Não</Badge>
                    )
                  ) : coluna.acesso === "status_pre_cad" ? (
                    item[coluna.acesso] === 0 ? (
                      <Badge bg="primary">Novo</Badge>
                    ) : (
                      <Badge bg="warning">Em Digitação</Badge>
                    )
                  ) : coluna.acesso === "statusagendamento" ? (
                    item[coluna.acesso] === "0" ? (
                      <Badge bg="primary">Novo</Badge>
                    ) : item[coluna.acesso] === "1" ? (
                      <Badge bg="dark">Falta sem Justificatica</Badge>
                    ) : item[coluna.acesso] === "2" ? (
                      <Badge bg="secondary">Falta com Justificatica</Badge>
                    ) : item[coluna.acesso] === "3" ? (
                      <Badge bg="success">Encerrado</Badge>
                    ) : item[coluna.acesso] === "4" ? (
                      <Badge bg="info">Confirmado</Badge>
                    ) : item[coluna.acesso] === "5" ? (
                      <Badge bg="warning">Aguardando Atendimento</Badge>
                    ) : item[coluna.acesso] === "6" ? (
                      <Badge className="bg-black">Aguardando Pagamento</Badge>
                    ) : item[coluna.acesso] === "9" ? (
                      <Badge bg="danger">Cancelada</Badge>
                    ) : (
                      ""
                    )
                  ) : coluna.acesso === "tipoconsulta" ? (
                    item[coluna.acesso] === 0 ? (
                      <Badge bg="primary">Faturado</Badge>
                    ) : item[coluna.acesso] === 1 ? (
                      <Badge bg="warning">Convênio</Badge>
                    ) : item[coluna.acesso] === 2 ? (
                      <Badge bg="secondary">Cortesia</Badge>
                    ) : (
                      <Badge bg="danger">Não Informado</Badge>
                    )
                  ) : coluna.acesso === "id_faturado" ? (
                    item[coluna.acesso] === 0 ? (
                      <Badge bg="primary">Faturado</Badge>
                    ) : item[coluna.acesso] === 1 ? (
                      <Badge bg="warning">Convênio</Badge>
                    ) : item[coluna.acesso] === 2 ? (
                      <Badge bg="secondary">Cortesia</Badge>
                    ) : (
                      <Badge bg="danger">Não Informado</Badge>
                    )
                  ) : coluna.acesso === "status_rec" ? (
                    item[coluna.acesso] === "0" ? (
                      <Badge bg="danger">A Receber</Badge>
                    ) : item[coluna.acesso] === "1" ? (
                      <Badge bg="success">Recebido</Badge>
                    ) : item[coluna.acesso] === "9" ? (
                      <Badge bg="danger">Cancelada</Badge>
                    ) : (
                      ""
                    )
                  ) : coluna.acesso === "valor" ? (
                    parseFloat(
                      item[coluna.acesso] ? item[coluna.acesso] : 0
                    ).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })
                  ) : coluna.acesso === "desconto" ? (
                    parseFloat(
                      item[coluna.acesso] ? item[coluna.acesso] : 0
                    ).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })
                  ) : coluna.acesso === "valor_liquido" ? (
                    parseFloat(
                      item[coluna.acesso] ? item[coluna.acesso] : 0
                    ).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })
                  ) : coluna.acesso === "valor_recebido" ? (
                    parseFloat(
                      item[coluna.acesso] ? item[coluna.acesso] : 0
                    ).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })
                  ) : coluna.acesso === "dia_semana" ? (

                    item[coluna.acesso] === 1 ? (
                      "Domingo"
                    ) : item[coluna.acesso] === 2 ? (
                      "Segunda"
                    ) : item[coluna.acesso] === 3 ? (
                      "Terça"
                    ) : item[coluna.acesso] === 4 ? (
                      "Quarta"
                    ) : item[coluna.acesso] === 5 ? (
                      "Quinta"
                    ) : item[coluna.acesso] === 6 ? (
                      "Sexta"
                    ) : (
                      "Sabado"
                    )

                  ) : coluna.acesso == "status_movimento" ? (
                    item[coluna.acesso] == "Executado" ? (

                      <Badge bg="success">Executado</Badge>
                    ) : item[coluna.acesso] == "Cancelado" ? (

                      <Badge bg="danger">Cancelado</Badge>
                    ) : (

                      <Badge bg="warning">{item[coluna.acesso]}</Badge>
                    )
                  ) : coluna.acesso == 'is_profissional' ? (
                    item[coluna.acesso] == true ? (
                      <Badge bg="success">Sim</Badge>
                    ) : (
                      <Badge bg="danger">Não</Badge>
                    )
                  ) : coluna.acesso == "tipo_mov" ? (
                    item[coluna.acesso] == "S" ? (

                      <Badge bg="danger">Saída</Badge>
                    ) : (

                      <Badge bg="success">Entrada</Badge>
                    )
                  ) : coluna.acesso == "isconvenio" ? (
                    item[coluna.acesso] === true ? (

                      <Badge bg="success">Convênio</Badge>
                    ) : (

                      <Badge bg="secondary">Particular</Badge>
                    )
                  ) : coluna.acesso == "ocorrencia" ? (

                    item[coluna.acesso].length > 60 ? item[coluna.acesso].slice(0, 60) + "(...)" : item[coluna.acesso] 
                  ) : coluna.acesso == "medicamento_prescrito" ? (

                    item[coluna.acesso].length > 60 ? item[coluna.acesso].slice(0,60) + "(...)" : item[coluna.acesso]
                  ) : (

                    item[coluna.acesso]
                  )}
                </td>
              ))}
              {usaAcoes && (
                <td>
                 {usaDuplicar && (
                    <Button
                      onclick={() => onDuplicarClick(item)}
                      icone={mdiContentDuplicate}
                      type="button"
                      className="botaoTransparente"
                      title={"Duplicar profissional"}
                    />
                  )}
                  {usaVisu && (
                    <Button
                      onclick={() => onVisuClick(item)}
                      icone={iconeVisualizar || mdiEye}
                      type="button"
                      className="botaoTransparente"
                      title={descVisualizar || "Visualizar"}
                    />
                  )}
                  {usaEdit && (
                    <Button
                      onclick={() => onEditClick(item)}
                      icone={iconeEditar || mdiFileEditOutline}
                      type="button"
                      className="botaoTransparente"
                      title={descEditar || "Editar"}
                    />
                  )}
                  {usaDelete && (
                    <Button
                      onclick={() => onDeleteClick(item)}
                      icone={iconeExcluir || mdiTrashCanOutline}
                      type="button"
                      className="botaoTransparente"
                      title={descExcluir || "Excluir"}
                    />
                  )}
                  {usaImprimir && (
                    <Button
                      onclick={() => onImprimirClick(item)}
                      icone={iconeImprimir || mdiPrinter}
                      type="button"
                      className="botaoTransparente"
                      title={descImprimir || "Imprimir"}
                    />
                  )}
                  {usaNovoBotao && ( // Verifique se usaNovoBotao é verdadeiro
                    <Button
                      onclick={() => onNovoBotaoClick && onNovoBotaoClick(item)} // Verifique se onNovoBotaoClick está definido antes de chamá-lo
                      icone={iconeNovoBotao || mdiEmailFastOutline} // Ícone personalizado ou padrão
                      type="button"
                      className="botaoTransparente"
                      title={descNovoBotao || "Descrição padrão"}
                    />
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <Paginacao
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        dados={dados}
      />
    </div>
  );
};
export default Tabela;
