import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import {
  getSessionData,
  setSessionData,
} from "../../../../../../../utils/storageUtils";
import ApiGet from "../../../../../../../api/endPoints/useGet";
import { toast } from "react-toastify";
import { ITabela } from "../../../../../../../components/Formularios/Table/TabelaInterface";
import Tabela from "../../../../../../../components/Formularios/Table";
import { mdiCheckAll } from "@mdi/js";
import { useNavigate } from "react-router";

type Props = {
  children?: React.ReactNode;
  titulo: string;
  onclick?: (formData: any) => void;
  onclickFechar?: () => void;
};

export const ModalSelecionaFilial: React.FC<Props> = ({
  children,
  titulo,
  onclick,
  onclickFechar,
}) => {
  const token = getSessionData("MultClinicaWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  const isVinculo = getSessionData("isPerfilVinculo") || "0";
  const [loading, setLoading] = useState<boolean>(false);
  //#region Carregar Filiais do usuario
  const [filiais, setFiliais] = useState<any[]>([]);
  const [avatarImgFilial, setAvatarImgFilial] = useState<string>("");
  const [idClinica, setIdClinica] = useState<string>("");
  const [nomeclinica, setNomeClinica] = useState<string>("");
  const login_usuario = getSessionData("DadosUsuario") || "";
  const id_usuario = getSessionData("DadosUsuarioID") || "";

  const carregarFiliais = async (id_usuario: string) => {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/clinica/usuario/listar/:id_usuario/:id_empresa",
      parametros: {
        id_usuario: id_usuario,
        id_empresa: isVinculo,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((response) => {
        const retornoApi = response;
        setLoading(false);

        setFiliais(response);
      })
      .catch((erro) => {
        setLoading(false);
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Falha ao carregar dados. Motivo: ${msg}`);
      });
  };

  useEffect(() => {
    if (id_usuario !== "") carregarFiliais(id_usuario);
  }, [id_usuario]);

  const colunas: ITabela[] = [
    { titulo: "", acesso: "avatar" },
    { titulo: "ID Clinica", acesso: "id_clinica" },
    { titulo: "Clínica", acesso: "fantasia" },
    { titulo: "Bairro", acesso: "bairro" },
    { titulo: "UF", acesso: "cuf" },
    { titulo: "Empresa Padrao", acesso: "empresa" },
  ];

  //#endregion

  const selecionaFilial = (dados: any) => {
    if (onclick) onclick(dados);
  };
  return (
    <>
      <Modal.Header className="bg-white">
        <Modal.Title>{titulo}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-white">
        <Tabela
          coluna={colunas}
          dados={filiais}
          itemsPerPage={10}
          usaDelete={false}
          usaVisu={false}
          onVisuClick={() => { }}
          onEditClick={selecionaFilial}
          descEditar="Selecionar"
          iconeEditar={mdiCheckAll}
          onDeleteClick={() => { }}
          onImprimirClick={() => { }}
          usaImprimir={false}
          id="codigo"
        />
      </Modal.Body>
      <Modal.Footer className="bg-white">
        <button
          type="button"
          className="btn btn-outline-secondary btn-sm"
          onClick={onclickFechar}
        >
          Cancelar
        </button>
      </Modal.Footer>
    </>
  );
};
