import { useNavigate, useParams } from "react-router-dom";
import ModalLoading from "../../../../../components/Formularios/Modal/ModalLoading";
import { useState, useEffect, ChangeEvent } from "react";
import { toast, useToast } from "react-toastify";
import { Card, Col, Form, FormLabel, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../components/Formularios/Inputs/InputsSemBorda";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  Box,
  Switch,
  Stack,
  Paper,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Button as ButtonCustom } from "../../../../../components/Formularios/Buttons/Button";
import { mdiCheckCircle, mdiKeyboardReturn } from "@mdi/js";
import ApiPost from "../../../../../api/endPoints/usePost";
import ApiPut from "../../../../../api/endPoints/usePut";
import ModalUploadImage from "../../../../../components/Formularios/Modal/ModalUpload";
import { styled } from "@mui/material/styles";
import CarregarDados from "../CarregarDados";
// import { InterfaceDadosEmpresa } from "../../../../../types/EmpresaInteface";
import { url_CarregarDados_ID } from "../auxiliar";
import { TextoFormatoCaptalize } from "../../../../../components/Auxiliar/ConvTextoCaptalize";
import useSelectOptions from "../../../../../hooks/useSelectOptions";
import SelectInput from "../../../../../shared/SelectInputs";
import { getSessionData } from "../../../../../utils/storageUtils";
import { Interface } from "readline";
import axios from "axios";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F0F0F0",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "#616161",
  borderRadius: "5px",
  fontWeight: "bold",
  fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
}));

interface FormProcedimentoProps {
  id_sequencial?: string;
  acao?: string;
}

const FormProcedimentoxProfissional = ({
  id_sequencial,
  acao,
}: FormProcedimentoProps) => {
  const navigate = useNavigate();
  const id_clinica = getSessionData("DadosIdClinica") || "";
  const usuario = getSessionData("DadosUsuario") || "";

  //#region Controla as guias
  const [guia, setGuia] = useState("1");

  const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
    setGuia(novaGuia);
  };
  //#endregion

  const [codigo, setCodigo] = useState<string>("");
  const [id_Procedimento, setId_Procedimento] = useState<any>("");
  const [id_Profissional, setId_Profissional] = useState<string>("");
  const [status, setStatus] = useState<boolean>(true);
  const [valor, setValor] = useState<string>("");
  const [aceita_Desconto, setAceita_Desconto] = useState<boolean>(true);
  const [desconto_Maximo, setDesconto_Maximo] = useState<string>("");
  const [descricao, setDescricao] = useState<string>("");

  const tipo_RepasseP = "P";
  const tipo_RepasseR = "R";
  const [tipo_Repasse, setTipo_Repasse] = useState<string>("R");

  const [valor_Repasse, setValor_Repasse] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  interface IProcedimento {
    value: string;
    label: string;
    valor: string;
  }
  //#region ==> Select Options
  // Usando o hook useSelectOptions para obter as opções diretamente

  const profissionalOptions = useSelectOptions(
    `/procedimentoxprofissional/listar/profissional/${id_clinica}`
  );

  //#endregion

  const selecionaProcedimento = (event: any) => {
    const opcaoSelecionada: any = tipoProcedimentoOptions.find(
      (option: any) => option.value === event.target.value
    );
    if (opcaoSelecionada === undefined) return setValor('0');


    setId_Procedimento(opcaoSelecionada.value);
    setValor(opcaoSelecionada.valor);
  };

  const carregar = (url: string, id_sequencial: any, id_clinica: string) => {
    setLoading(true);
    CarregarDados(url, id_sequencial, id_clinica)
      .then((retorno) => {
        setLoading(false);

        setId_Procedimento(retorno[0].id_procedimento);
        setId_Profissional(retorno[0].id_profissional);
        setStatus(retorno[0].status === 1 ? true : false);
        setValor(retorno[0].valor);
        setAceita_Desconto(retorno[0].aceita_desconto === 1 ? true : false);
        setDesconto_Maximo(retorno[0].desconto_maximo);
        setDescricao(retorno[0].descricao);
        setTipo_Repasse(retorno[0].tipo_repasse);
        setValor_Repasse(retorno[0].valor_repasse);

        console.log(retorno[0].dia_mes_fixo)
        setDiaFechamentoFixo(retorno[0].dia_fechamento_fixo)
        setDiaVencimentoFixo(retorno[0].dia_vencimento_fixo)
        //setdiaFixo(retorno[0].dia_mes_fixo)
        set_diasFixos(retorno[0].dia_fixo)
        console.log(retorno[0].dia_fixo)
        set_DiasCorrido(retorno[0].dias_corridos == null || retorno[0].dias_corridos == undefined ? true : retorno[0].dias_corridos)
        set_qtdDiasCorridos(retorno[0].qtd_dias_corridos)

        setIdConvenio(retorno[0].id_convenio)
        console.log(retorno[0].id_convenio)
        setIsconvenio(retorno[0].isconvenio)
      })
      .catch((erro) => {
        setLoading(false);
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao carregar dados. Motivo: ${msg}`);
      });
  };

  useEffect(() => {
    if (id_sequencial !== "novo") {
      carregar(url_CarregarDados_ID, id_sequencial, id_clinica);
    }
  }, []);

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS
  const atualizarDados = async (
    id_clinica: string,
    id_procedimento: string,
    id_profissional: string,
    status: string,
    aceita_desconto: string,
    desconto_maximo: number,
    descricao: string,
    tipo_repasse: string,
    valor_repasse: number,
    usuario: string,
    diasCorridos: any,
    qtdDiasCorridos: any,
    //diaFixo: any,
    diaFechamentoFixo: any,
    diaVencimentoFixo: any,
    diasFixos: any,
    isConvenio: any,
    IdConvenio: any
  ) => {
    const dados = {
      id_clinica,
      id_procedimento,
      id_profissional,
      status,
      aceita_desconto,
      desconto_maximo,
      descricao: TextoFormatoCaptalize(descricao.toLowerCase()),
      tipo_repasse,
      valor_repasse,
      usuario,
      diasCorridos,
      qtdDiasCorridos,
      //diaFixo,
      diaFechamentoFixo,
      diaVencimentoFixo,
      diasFixos,
      isConvenio,
      IdConvenio
    };

    ApiPut(`/procedimentoxprofissional/atualizar/`, dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        toast.error(`Erro ao atualizar. Motivo: ${erro.response.data.message}`);
      });
  };
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS
  const gravarDados = (
    id_clinica: string,
    id_procedimento: string,
    id_profissional: string,
    status: string,
    aceita_desconto: string,
    desconto_maximo: number,
    descricao: string,
    tipo_repasse: string,
    valor_repasse: number,
    usuario: string,
    diasCorridos: any,
    qtdDiasCorridos: any,
    //diaFixo: any,
    diaFechamentoFixo: any,
    diaVencimentoFixo: any,
    diasFixos: any,
    isConvenio: any,
    IdConvenio: any
  ) => {
    const dados = {
      id_clinica,
      id_procedimento,
      id_profissional,
      status,
      aceita_desconto,
      desconto_maximo,
      descricao: TextoFormatoCaptalize(descricao.toLowerCase()),
      tipo_repasse,
      valor_repasse,
      usuario,
      diasCorridos,
      qtdDiasCorridos,
      //diaFixo,
      diaFechamentoFixo,
      diaVencimentoFixo,
      diasFixos,
      isConvenio,
      IdConvenio
    };
    ApiPost("/procedimentoxprofissional/criar", dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };
  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const _valor = parseFloat(valor ? valor : "0");
    const _repasse = parseFloat(valor_Repasse ? valor_Repasse : "0");

    if (_valor <= 0 && (id_Procedimento !== 27 && id_Procedimento !== 28 && id_Procedimento !== 29)) {
      toast.error("Valor do procedimento não pode ser zero ou negativo.");
      setValor("");

      return;
    } else if (_repasse <= 0 && (id_Procedimento !== 27 && id_Procedimento !== 28 && id_Procedimento !== 29)) {
      toast.error(
        `${tipo_Repasse === "R" ? "Valor" : "Percentual"
        } do repasse não pode ser zero ou negativo.`
      );
      setValor_Repasse("");
      return;
    } else if (tipo_Repasse === "P" && _repasse > 100) {
      toast.error("Percentual do repasse não pode ser maior que 100%.");
      setValor_Repasse("");
      return;
    } else if (tipo_Repasse === "R" && _repasse > _valor) {
      toast.error(
        "Valor do repasse não pode ser maior que o valor do procedimento."
      );
      setValor_Repasse("");
      return;
    } else if (aceita_Desconto && parseFloat(desconto_Maximo) <= 0 && (id_Procedimento !== 27 && id_Procedimento !== 28 && id_Procedimento !== 29)) {
      toast.error("Desconto não pode ser zero ou negativo.");
      setDesconto_Maximo("");
      return;
    } else if (aceita_Desconto && parseFloat(desconto_Maximo) > 100) {
      toast.error("Desconto não pode ser maior que 100%.");
      setDesconto_Maximo("");
      return;
    } else if (parseFloat(diaFechamentoFixo) < 0 || parseFloat(diaVencimentoFixo) > 28) {
      console.log(diaFechamentoFixo)
      console.log(diaVencimentoFixo)
      toast.error("Dia do fechamento ou vencimento inválidos.")
    }
    else if ((qtdDiasCorridos <= 0 || qtdDiasCorridos > 28) && qtdDiasCorridos != "") {


      toast.error("Dias corridos ou dia fixo devem estar Entre dia 01 e 28.")
    }
    else {
      if (id_sequencial === "novo") {
        gravarDados(
          id_clinica,
          id_Procedimento,
          id_Profissional,
          status ? "1" : "0",
          aceita_Desconto ? "1" : "0",
          parseFloat(desconto_Maximo),
          descricao,
          tipo_Repasse,
          parseFloat(valor_Repasse ? valor_Repasse : "0"),
          usuario,
          diasCorridos,
          qtdDiasCorridos,
          //diaFixo,
          diaFechamentoFixo,
          diaVencimentoFixo,
          diasFixos,
          isConvenio,
          IdConvenio
        );
        toast.info("Inserindo novo registro...");
      } else {
        atualizarDados(
          id_clinica,
          id_Procedimento,
          id_Profissional,
          status ? "1" : "0",
          aceita_Desconto ? "1" : "0",
          parseFloat(desconto_Maximo),
          descricao,
          tipo_Repasse,
          parseFloat(valor_Repasse ? valor_Repasse : "0"),
          usuario,
          diasCorridos,
          qtdDiasCorridos,
          //diaFixo,
          diaFechamentoFixo,
          diaVencimentoFixo,
          diasFixos,
          isConvenio,
          IdConvenio
        );
        toast.info("Atualizando dados do registro...");
      }
    }
  };

  const params = useParams()

  //#region config de faturamento
  const [diasCorridos, set_DiasCorrido] = useState<any>(true)
  const [qtdDiasCorridos, set_qtdDiasCorridos] = useState<any>()

  const [diasFixos, set_diasFixos] = useState<any>(false)
  //const [diaFixo, setdiaFixo] = useState("")
  const [diaFechamentoFixo, setDiaFechamentoFixo] = useState("")
  const [diaVencimentoFixo, setDiaVencimentoFixo] = useState("")


  const [isConvenio, setIsconvenio] = useState(false)
  const [IdConvenio, setIdConvenio] = useState("0")

  const [tipoProcedimentoOptions, set_tipoProcedimentoOptions] = useState([])
  useEffect(function () {

    carregarTiposProcediemnto()

    if (isConvenio == false) {

      setIdConvenio("0")
      setId_Procedimento("")
    }
  }, [isConvenio])


  async function carregarTiposProcediemnto() {

    try {

      const procedimentos = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/procedimentoxprofissional/listar/procedimento/${id_clinica}?isconvenio=${isConvenio}&id_convenio=${IdConvenio}`)
      set_tipoProcedimentoOptions(procedimentos.data)
    } catch (error) {

      toast.error("Erro ao carregar procedimentos")
    }
  }


  //lista os convenios da clinica
  const [ListaConvenio, setListaConvenio] = useState([])
  function carregarConvenio(id_clinica: any) {

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/convenio/listar/${id_clinica}`, {
      headers: {
        Authorization: getSessionData("MultClinicaWebToken")
      }
    }).then(function (resposta) {

      setListaConvenio(resposta.data)
    }).catch(function (erro) {

      toast.error("Erro ao listar convenios.")
    })
  }

  useEffect(function () {

    carregarTiposProcediemnto()
  }, [IdConvenio])

  useEffect(function () {

    carregarConvenio(id_clinica)
    carregarTiposProcediemnto()
  }, [])

  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Box sx={{ width: "100%" }}>
              <TabContext value={guia}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
                <TabPanel value="1">
                  <Row>
                    <Col sm={1}>
                      <InputSemBorda
                        label="Código"
                        name="codigo"
                        type="text"
                        placeholder="Código"
                        readonly
                        value={codigo || id_sequencial || ""}
                        onChange={setCodigo}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <div className="form-check mt-3">
                        <input checked={isConvenio}
                          onChange={function (e) {
                            setIsconvenio(e.target.checked)

                            if (e.target.checked == false) {

                              setIdConvenio("0")
                              setId_Procedimento("")
                            }
                          }}
                          className="form-check-input" disabled={acao === "Visualizar" ? true : false} type="checkbox" value="" id="flexCheckDefault" />
                        <label className="form-check-label ms-2">
                          Convênio
                        </label>
                      </div>
                    </Col>
                    {/*
                    
                    <Col sm={3}>
                      <SelectInput
                        value={id_Procedimento}
                        onChange={selecionaProcedimento}
                        options={tipoProcedimentoOptions}
                        placeholder="Procedimento"
                        required
                        disabed={
                          acao === "Visualizar" || acao === "Editar"
                            ? true
                            : false
                        }
                      />
                    </Col>
                    */}
                    {isConvenio == true ?
                      <Col sm={3}>
                        <FormControl variant="standard" sx={{ m: 1.5, width: "100%" }}>
                          <InputLabel>
                            Convênio
                          </InputLabel>
                          <Select
                            disabled={acao === "Visualizar" ? true : false}
                            value={IdConvenio}
                            onChange={function (e) {
                              setIdConvenio(e.target.value)
                            }}
                            label={"Convênio"}
                            required={isConvenio}
                            placeholder="Convênio"
                          >
                            <MenuItem value="0">
                              <em>Selecione...</em>
                            </MenuItem>
                            {ListaConvenio.map((option: any) => (
                              <MenuItem value={option.id_convenio}>
                                {option.fantasia}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Col>
                      : <></>}
                    <Col sm={3}>
                      <FormControl variant="standard" sx={{ m: 1.5, width: "100%" }}>
                        <InputLabel>
                          Procedimento
                        </InputLabel>
                        <Select
                          disabled={acao === "Visualizar" ? true : false}
                          value={id_Procedimento}
                          onChange={function (e) {

                            selecionaProcedimento(e)
                          }}
                          label={"Procedimento"}
                          required={true}
                        >
                          <MenuItem value="">
                            <em>Selecione...</em>
                          </MenuItem>
                          {tipoProcedimentoOptions.map(function (procedimento: any) {
                            return (
                              <MenuItem value={procedimento.value}>
                                <em>{procedimento.label}</em>
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col sm={3}>
                      <SelectInput
                        value={id_Profissional}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setId_Profissional(e.target.value)
                        }
                        options={profissionalOptions}
                        placeholder="Profissional"
                        required
                        disabed={
                          acao === "Visualizar" || acao === "Editar"
                            ? true
                            : false
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Ativo
                      </label>
                      <Switch
                        checked={status}
                        onChange={() => setStatus(!status)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Valor (R$)"
                        name="valor"
                        type="number"
                        step="0.01"
                        placeholder="Valor (R$)"
                        required
                        value={valor || "0"}
                        onChange={setValor}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                        readonly
                      />
                    </Col>
                    <Col
                      sm={6}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormLabel id="demo-radio-buttons-group-label">
                        Tipo Repasse:
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={tipo_RepasseR}
                        name="radio-buttons-group"
                        onChange={(e) => setTipo_Repasse(e.target.value)}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <FormControlLabel
                          value={tipo_RepasseR}
                          disabled={acao === "Visualizar" ? true : false}
                          control={<Radio />}
                          label="Valor (R$)"
                          style={{ marginRight: "10px" }} // Adiciona margem direita para separar os botões
                        />
                        <FormControlLabel
                          value={tipo_RepasseP}
                          disabled={acao === "Visualizar" ? true : false}
                          control={<Radio />}
                          label="Percentual (%)"
                        />
                      </RadioGroup>
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Valor Repasse"
                        name="valor"
                        type="number"
                        step="0.01"
                        placeholder="Valor Repasse"
                        required
                        value={valor_Repasse || "0"}
                        onChange={setValor_Repasse}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                        readonly={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={4}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Aceita Desconto?
                      </label>
                      <Switch
                        checked={aceita_Desconto}
                        onChange={() => setAceita_Desconto(!aceita_Desconto)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    {aceita_Desconto && (
                      <Col sm={4}>
                        <InputSemBorda
                          label="Desconto Máximo (%)"
                          name="descontomaximo"
                          type="number"
                          step="0.01"
                          placeholder="Desconto Máximo (%)"
                          value={desconto_Maximo || ""}
                          onChange={setDesconto_Maximo}
                          required
                          classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                          readonly={acao === "Visualizar" ? true : false}
                        />
                      </Col>
                    )}
                  </Row>
                  <br />
                  <Row sm={12}>
                    <Col sm={12}>
                      <p><b>Configurações de Faturamento</b></p>
                    </Col>
                    <Col sm={2}>
                      <div className="ms-3 form-check">
                        <input required={!diasFixos} disabled={acao === "Visualizar" ? true : false} onChange={function (e) {
                          set_DiasCorrido(e.target.checked)
                          set_diasFixos(!e.target.checked)

                          if (e.target.checked == true && params.id == "novo") {

                            //setdiaFixo("")
                            setDiaVencimentoFixo("")
                            setDiaFechamentoFixo("")
                          }
                        }} className="form-check-input" type="checkbox" checked={diasCorridos} id="flexCheckDefault" />
                        <label className="ms-1 mt-1 form-check-label">
                          Dias Corridos
                        </label>
                      </div>
                    </Col>
                    <Col sm={2}>
                      <div className="ms-3 form-check">
                        <input required={!diasCorridos} disabled={acao === "Visualizar" ? true : false} onChange={function (e) {
                          set_diasFixos(e.target.checked)
                          set_DiasCorrido(!e.target.checked)

                          if (e.target.checked == true && params.id == "novo") {

                            set_qtdDiasCorridos("")
                          }
                        }} className="form-check-input" type="checkbox" checked={diasFixos} id="flexCheckDefault" />
                        <label className="ms-1 mt-1 form-check-label">
                          Dia Fixo
                        </label>
                      </div>
                    </Col>
                    <div className="w-100"></div>

                    {diasFixos == false ? <>
                      <Col sm={2}>
                        <InputSemBorda
                          label="Qtde. Dias Corridos"
                          name="descricao"
                          type="number"
                          required={!diasFixos}
                          placeholder=" "
                          value={qtdDiasCorridos || ""}
                          onChange={set_qtdDiasCorridos}
                          classNameInputsSemBorda="form-control bordasInferiorInput text-black text-center"
                          readonly={acao === "Visualizar" ? true : false}
                        />
                      </Col>
                    </> :
                      <>

                        <Col sm={3}>
                          <InputSemBorda
                            label="Dia do Fechamento - Fixo"
                            name="descricao"
                            type="number"
                            placeholder=" "
                            required={!diasCorridos}
                            value={diaFechamentoFixo || ""}
                            onChange={setDiaFechamentoFixo}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-black text-center"
                            readonly={acao === "Visualizar" ? true : false}
                          />
                        </Col>
                        <Col sm={3}>
                          <InputSemBorda
                            label="Dia do Vencimento - Fixo"
                            name="descricao"
                            type="number"
                            placeholder=" "
                            required={!diasCorridos}
                            value={diaVencimentoFixo || ""}
                            onChange={setDiaVencimentoFixo}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-black text-center"
                            readonly={acao === "Visualizar" ? true : false}
                          />
                        </Col>
                      </>
                    }


                  </Row>
                  <br />
                  <Row>
                    <Col sm={12}>
                      <InputSemBorda
                        label="Descrição"
                        name="descricao"
                        type="textarea"
                        placeholder="Descrição"
                        value={descricao || ""}
                        onChange={setDescricao}
                        height="100px"
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                        readonly={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                  </Row>
                </TabPanel>
              </TabContext>
            </Box>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <ButtonCustom
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
                disabled={acao === "Visualizar" ? true : false}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card >

      <ModalLoading
        show={loading}
        label="Carregado dados de procedimento ..."
      />
    </>
  );
};
export default FormProcedimentoxProfissional;
