import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo";
import  { FormPreCadastro } from "./components/formulario";

export function PreCadastroVisualizar() {
  const idpaciente = useParams();
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Visualizar Pré Cadastro Pacientes" />
        <FormPreCadastro idpaciente={idpaciente.id} acao="Visualizar" />
      </div>
    </>
  );
}
