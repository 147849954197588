import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../../../components/Formularios/Modal/ModalLoading";
import { useState, useEffect, ChangeEvent } from "react";
import { toast } from "react-toastify";
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../components/Formularios/Inputs/InputsSemBorda";

import {
  Box,
  Switch,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tab,
  Avatar,
  Stack,
  Button,
  Paper,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button as ButtonCustom } from "../../../../../components/Formularios/Buttons/Button";
import {
  mdiCheckCircle,
  mdiKeyboardReturn,
  mdiLockCheckOutline,
} from "@mdi/js";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ApiPost from "../../../../../api/endPoints/usePost";
import ApiPut from "../../../../../api/endPoints/usePut";
import ModalUploadImage from "../../../../../components/Formularios/Modal/ModalUpload";
import { styled } from "@mui/material/styles";
import ConverterBase64 from "../../../../../components/ConversorBase64";
import CarregarDados from "../CarregarDados";
// import { InterfaceDadosEmpresa } from "../../../../../types/EmpresaInteface";
import { url_CarregarDados_ID } from "../auxiliar";
import { dadosUF } from "../../../../../components/dadosFixos/UF";
import { dadosPais } from "../../../../../components/dadosFixos/Pais";
import { TextoFormatoCaptalize } from "../../../../../components/Auxiliar/ConvTextoCaptalize";
import { TextoFormatoLowerCase } from "../../../../../components/Auxiliar/ConvTextoLower";
import { TextoFormatoUpperCase } from "../../../../../components/Auxiliar/ConvTextoUpper";
import useSelectOptions from "../../../../../hooks/useSelectOptions";
import SelectInput from "../../../../../shared/SelectInputs";
import { getSessionData } from "../../../../../utils/storageUtils";
import axios from "axios";
import {
  mascaraCEP,
  mascaraCNPJ,
  mascaraTelefoneCelular,
  mascaraTelefoneFixo,
} from "../../../../../hooks/mascaras";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F0F0F0",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "#616161",
  borderRadius: "5px",
  fontWeight: "bold",
  fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
}));

interface FormProfissionalProps {
  idprofissional?: string;
  acao?: string;
}

const FormProfissional = ({ idprofissional, acao }: FormProfissionalProps) => {
  const navigate = useNavigate();
  const id_clinica = getSessionData("DadosIdClinica") || "";

  //#region Controla as guias
  const [guia, setGuia] = useState("1");

  const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
    setGuia(novaGuia);
  };
  //#endregion

  const [showUpload, setShowUpload] = useState<boolean>(false);

  const [id_profissional, setId_profissional] = useState<any>(
    idprofissional || ""
  );
  const [status, setStatus] = useState<boolean>(true);
  const [cpf_cnpj, setCpf_cnpj] = useState<string>("");
  const [nome, setNome] = useState<string>("");
  const [telfixo, setTelfixo] = useState<string>("");
  const [telcelular, setTelcelular] = useState<string>("");
  const [telrecado, setTelrecado] = useState<string>("");
  const [tipocontratacao, setTipocontratacao] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [logradouro, setLogradouro] = useState<string>("");
  const [numero, setNumero] = useState<string>("");
  const [bairro, setBairro] = useState<string>("");
  const [compl, setCompl] = useState<string>("");
  const [cnum, setCnum] = useState<string>("");
  const [cidade, setCidade] = useState<string>("");
  const [cuf, setCuf] = useState<string>("");
  const [cep, setCep] = useState<string>("");
  const [formapag, setFormapag] = useState<string>("");
  const [profissao, setProfissao] = useState<string>("");
  const [especializacao, setEspecializacao] = useState<string>("");
  const [tipoconselho, setTipoconselho] = useState<string>("");
  const [ufconselho, setUfconselho] = useState<string>("");
  const [codconselho, setCodconselho] = useState<string>("");
  const [observacao, setObservacao] = useState<string>("");
  const [cadastro, setCadastro] = useState<string>("");
  const [usuario_cadastro, setUsuario_cadastro] = useState<string>("");
  const [participacao, setParticipacao] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);

  //#region ==> Select Options
  // Usando o hook useSelectOptions para obter as opções diretamente
  const tipoContratacaoOptions = useSelectOptions(
    `/codigosistema/listar/TIPOCONTRATACAO`
  );
  const profissaoOptions = useSelectOptions(`/codigosistema/listar/PROFISSAO`);
  const tipoConselhoOptions = useSelectOptions(
    `/codigosistema/listar/TIPOCONSELHO`
  );
  const formaPagamentoOptions = useSelectOptions(
    `/codigosistema/listar/FORMAPAGAMENTO`
  );
  const [selectedTipoContratacao, setSelectedTipoContratacao] = useState("");
  const [selectedProfissao, setSelectedProfissao] = useState("");
  const [selectedTipoConselho, setSelectedTipoConselho] = useState("");
  const [selectedFormaPagamento, setSelectedFormaPagamento] = useState("");

  //#endregion

  const carregar = (url: string, idprofissional: any, id_clinica: string) => {
    setLoading(true);
    CarregarDados(url, idprofissional, id_clinica)
      .then((retorno) => {
        setLoading(false);

        setId_profissional(retorno[0].id_profissional || "");
        setStatus(retorno[0].status === 1 ? true : false);
        setCpf_cnpj(retorno[0].cpf_cnpj || "");
        setNome(retorno[0].nome || "");
        setTelfixo(retorno[0].telfixo || "");
        setTelcelular(retorno[0].telcelular || "");
        setTelrecado(retorno[0].telrecado || "");
        setSelectedTipoContratacao(retorno[0].tipocontratacao || "");
        setEmail(retorno[0].email || "");
        setLogradouro(retorno[0].logradouro || "");
        setNumero(retorno[0].numero || "");
        setBairro(retorno[0].bairro || "");
        setCompl(retorno[0].compl || "");
        setCnum(retorno[0].cnum || "");
        setCidade(retorno[0].cidade || "");
        setCuf(retorno[0].cuf || "");
        setCep(retorno[0].cep || "");
        setSelectedFormaPagamento(retorno[0].formapag || "");
        // setFormapag(retorno[0].formapag || '');
        setSelectedProfissao(retorno[0].profissao || "");
        // setProfissao(retorno[0].profissao || '');
        setEspecializacao(retorno[0].especializacao || "");
        setSelectedTipoConselho(retorno[0].tipoconselho || "");
        // setTipoconselho(retorno[0].tipoconselho || '');
        setUfconselho(retorno[0].ufconselho || "");
        setCodconselho(retorno[0].codconselho || "");
        setObservacao(retorno[0].observacao || "");
        setCadastro(retorno[0].cadastro || "");
        setUsuario_cadastro(retorno[0].usuario_cadastro || "");

        setParticipacao(retorno[0].participacao ? retorno[0].participacao : '0');
      })
      .catch((erro) => {
        setLoading(false);
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error("Falha ao carregar registro. Motivo? " + msg);
      });
  };
  useEffect(() => {
    if (idprofissional !== "novo") {
      carregar(url_CarregarDados_ID, idprofissional, id_clinica);
    }
  }, []);

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS
  const atualizarDados = async (
    id_profissional: string,
    id_clinica: string,
    status: string,
    cpf_cnpj: string,
    nome: string,
    telfixo: string,
    telcelular: string,
    telrecado: string,
    tipocontratacao: string,
    email: string,
    logradouro: string,
    numero: string,
    bairro: string,
    compl: string,
    cnum: string,
    cidade: string,
    cuf: string,
    cep: string,
    formapag: string,
    profissao: string,
    especializacao: string,
    tipoconselho: string,
    ufconselho: string,
    codconselho: string,
    observacao: string,
    cadastro: string,
    usuario_cadastro: string,
    participacao: number
  ) => {
    const dados = {
      id_profissional,
      id_clinica,
      status,
      cpf_cnpj,
      nome: TextoFormatoCaptalize(nome.toLowerCase()),
      telfixo,
      telcelular,
      telrecado: telrecado.replace(/\D/g, ""),
      tipocontratacao: selectedTipoContratacao,
      email: TextoFormatoLowerCase(email.toLowerCase()),
      logradouro: TextoFormatoCaptalize(logradouro.toLowerCase()),
      numero: TextoFormatoCaptalize(numero.toLowerCase()),
      bairro: TextoFormatoCaptalize(bairro.toLowerCase()),
      compl: TextoFormatoCaptalize(compl.toLowerCase()),
      cnum,
      cidade: TextoFormatoCaptalize(cidade.toLowerCase()),
      cuf,
      cep,
      formapag: selectedFormaPagamento,
      profissao: selectedProfissao,
      especializacao,
      tipoconselho: selectedTipoConselho,
      ufconselho,
      codconselho,
      observacao: TextoFormatoCaptalize(observacao.toLowerCase()),
      cadastro,
      usuario_cadastro: getSessionData("NomeUsuario"),
      participacao
    };

    ApiPut(`/profissional/atualizar`, dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        toast.error(`Erro ao atualizar. Motivo: ${erro.response.data.message}`);
      });
  };
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS
  const gravarDados = (
    id_profissional: string,
    id_clinica: string,
    status: string,
    cpf_cnpj: string,
    nome: string,
    telfixo: string,
    telcelular: string,
    telrecado: string,
    tipocontratacao: string,
    email: string,
    logradouro: string,
    numero: string,
    bairro: string,
    compl: string,
    cnum: string,
    cidade: string,
    cuf: string,
    cep: string,
    formapag: string,
    profissao: string,
    especializacao: string,
    tipoconselho: string,
    ufconselho: string,
    codconselho: string,
    observacao: string,
    cadastro: string,
    usuario_cadastro: string,
    participacao: number
  ) => {
    const dados = {
      id_profissional,
      id_clinica,
      status,
      cpf_cnpj,
      nome: TextoFormatoCaptalize(nome.toLowerCase()),
      telfixo,
      telcelular,
      telrecado: telrecado.replace(/\D/g, ""),
      tipocontratacao: selectedTipoContratacao,
      email: TextoFormatoLowerCase(email.toLowerCase()),
      logradouro: TextoFormatoCaptalize(logradouro.toLowerCase()),
      numero: TextoFormatoCaptalize(numero.toLowerCase()),
      bairro: TextoFormatoCaptalize(bairro.toLowerCase()),
      compl: TextoFormatoCaptalize(compl.toLowerCase()),
      cnum,
      cidade: TextoFormatoCaptalize(cidade.toLowerCase()),
      cuf,
      cep,
      formapag: selectedFormaPagamento,
      profissao: selectedProfissao,
      especializacao,
      tipoconselho: selectedTipoConselho,
      ufconselho,
      codconselho,
      observacao: TextoFormatoCaptalize(observacao.toLowerCase()),
      cadastro,
      usuario_cadastro: getSessionData("NomeUsuario"),
      participacao
    };
    ApiPost("/profissional/criar", dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };
  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (idprofissional === "novo") {
      gravarDados(
        id_profissional,
        id_clinica,
        status ? "1" : "0",
        cpf_cnpj,
        nome,
        telfixo,
        telcelular,
        telrecado,
        tipocontratacao,
        email,
        logradouro,
        numero,
        bairro,
        compl,
        cnum,
        cidade,
        cuf,
        cep,
        formapag,
        profissao,
        especializacao,
        tipoconselho,
        ufconselho,
        codconselho,
        observacao,
        cadastro,
        usuario_cadastro,
        parseFloat(participacao ? participacao : '0')
      );
      toast.info("Inserindo novo registro...");
    } else {
      atualizarDados(
        id_profissional,
        id_clinica || "2",
        status ? "1" : "0",
        cpf_cnpj,
        nome,
        telfixo,
        telcelular,
        telrecado,
        tipocontratacao,
        email,
        logradouro,
        numero,
        bairro,
        compl,
        cnum,
        cidade,
        cuf,
        cep,
        formapag,
        profissao,
        especializacao,
        tipoconselho,
        ufconselho,
        codconselho,
        observacao,
        cadastro,
        usuario_cadastro,
        parseFloat(participacao ? participacao : '0')
      );
      toast.info("Atualizando dados do registro...");
    }
  };

  const buscarCep = async (cep: string) => {
    const cepSemMascara = cep.replace(/\D/g, "");
    if (cepSemMascara.length < 8) {
      toast.error("CEP inválido!");
    } else if (cepSemMascara.length === 8) {
      await axios
        .get(`https://viacep.com.br/ws/${cepSemMascara}/json/`)
        .then((retorno) => {
          if (retorno.data?.erro) {
            toast.error("CEP inválido ou Não Localizado!");
          } else {
            const dadosCep = retorno.data;

            setLogradouro(dadosCep.logradouro);
            setCompl(dadosCep.complemento);
            setBairro(dadosCep.bairro);
            setCidade(dadosCep.localidade);
            setCuf(dadosCep.uf);
            setCnum(dadosCep.ibge);
          }
        })
        .catch((erro) => {
          console.log("erro: ", erro);
          const msg =
            erro.response?.data.message === undefined
              ? erro.message
              : erro.response.data.message;
          toast.error(`Erro ao buscar cep. Motivo: ${msg}`);
        });
    }
  };
  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Box sx={{ width: "100%" }}>
              <TabContext value={guia}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
                <TabPanel value="1">
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="ID Profissional"
                        name="id_profissional"
                        type="text"
                        placeholder="ID Profissional"
                        readonly
                        value={id_profissional || ""}
                        onChange={setId_profissional}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="CPF/CNPJ"
                        name="cpf_cnpj"
                        type="text"
                        placeholder="CPF/CNPJ"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        // value={cpf_cnpj || ""}
                        value={mascaraCNPJ(cpf_cnpj) || ""}
                        onChange={setCpf_cnpj}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Profissional"
                        name="nome"
                        type="text"
                        placeholder="Profissional"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={nome || ""}
                        onChange={setNome}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Email"
                        name="email"
                        type="text"
                        placeholder="Email"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={email || ""}
                        onChange={setEmail}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      />
                    </Col>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Ativo
                      </label>
                      <Switch
                        checked={status}
                        onChange={() => setStatus(!status)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Telefone Fixo"
                        name="telefone"
                        type="text"
                        placeholder="Telefone Fixo"
                        //required
                        readonly={acao === "Visualizar" ? true : false}
                        // value={telfixo || ""}
                        value={mascaraTelefoneFixo(telfixo) || ""}
                        onChange={setTelfixo}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Celular"
                        name="telcelular"
                        type="text"
                        placeholder="Celular"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        // value={telcelular || ""}
                        value={mascaraTelefoneCelular(telcelular) || ""}
                        onChange={setTelcelular}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Telefone Recado"
                        name="telrecado"
                        type="text"
                        placeholder="Celular"
                        //required
                        readonly={acao === "Visualizar" ? true : false}
                        // value={telrecado || ""}
                        value={mascaraTelefoneCelular(telrecado) || ""}
                        onChange={setTelrecado}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <SelectInput
                        value={selectedTipoContratacao}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setSelectedTipoContratacao(e.target.value)
                        }
                        options={tipoContratacaoOptions}
                        placeholder="Tipo Contratação"
                        disabed={acao === "Visualizar" ? true : false}
                      />
                      {/* <p>Opção selecionada: {selectedTipoSala}</p> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="CEP"
                        name="cep"
                        type="text"
                        placeholder="CEP"
                        readonly={acao === "Visualizar" ? true : false}
                        // value={cep || ""}
                        value={mascaraCEP(cep) || ""}
                        onChange={setCep}
                        onBlur={() => buscarCep(cep)}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Logradouro"
                        name="endereco"
                        type="text"
                        placeholder="Logradouro"
                        readonly={acao === "Visualizar" ? true : false}
                        value={logradouro || ""}
                        onChange={setLogradouro}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Complemento"
                        name="compl"
                        type="text"
                        placeholder="Complemento"
                        readonly={acao === "Visualizar" ? true : false}
                        value={compl || ""}
                        onChange={setCompl}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Número"
                        name="numero"
                        type="text"
                        placeholder="Número"
                        readonly={acao === "Visualizar" ? true : false}
                        value={numero || ""}
                        onChange={setNumero}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Bairro"
                        name="bairro"
                        type="text"
                        placeholder="Bairro"
                        readonly={acao === "Visualizar" ? true : false}
                        value={bairro || ""}
                        onChange={setBairro}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Cidade"
                        name="cidade"
                        type="text"
                        placeholder="Cidade"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={cidade || ""}
                        onChange={setCidade}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Cód. Município"
                        name="cnum"
                        type="text"
                        placeholder="Cód. Município"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={cnum || ""}
                        onChange={setCnum}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>

                    <Col sm={2}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1.5, width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          UF
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={cuf}
                          onChange={(e: SelectChangeEvent) =>
                            setCuf(e.target.value)
                          }
                          label="UF"
                          required
                          disabled={acao === "Visualizar" ? true : false}
                        >
                          <MenuItem value="">
                            <em>Selecione...</em>
                          </MenuItem>
                          {dadosUF.map((item: any) => (
                            <MenuItem value={item.uf}>{item.nome}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col sm={2}>
                      <SelectInput
                        value={selectedFormaPagamento}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setSelectedFormaPagamento(e.target.value)
                        }
                        options={formaPagamentoOptions}
                        placeholder="Forma Pagamento"
                        disabed={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <SelectInput
                        value={selectedProfissao}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setSelectedProfissao(e.target.value)
                        }
                        options={profissaoOptions}
                        placeholder="Profissão"
                        disabed={acao === "Visualizar" ? true : false}
                      />
                      {/* <p>Opção selecionada: {selectedTipoSala}</p> */}
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Especialização"
                        name="especializacao"
                        type="text"
                        placeholder="Especialização"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={especializacao || ""}
                        onChange={setEspecializacao}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <SelectInput
                        value={selectedTipoConselho}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setSelectedTipoConselho(e.target.value)
                        }
                        options={tipoConselhoOptions}
                        placeholder="Tipo Conselho"
                        disabed={acao === "Visualizar" ? true : false}
                      />
                      {/* <p>Opção selecionada: {selectedTipoSala}</p> */}
                    </Col>
                    <Col sm={2}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1.5, width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          UF Conselho
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={ufconselho}
                          onChange={(e: SelectChangeEvent) =>
                            setUfconselho(e.target.value)
                          }
                          label="UF"
                          required
                          disabled={acao === "Visualizar" ? true : false}
                        >
                          <MenuItem value="">
                            <em>Selecione...</em>
                          </MenuItem>
                          {dadosUF.map((item: any) => (
                            <MenuItem value={item.uf}>{item.nome}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Código Conselho"
                        name="codconselho"
                        type="text"
                        placeholder="Código Conselho"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={codconselho || ""}
                        onChange={setCodconselho}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={9}>
                      <InputSemBorda
                        label="Observação"
                        name="observacao"
                        type="text"
                        placeholder="Observação"
                        //required
                        readonly={acao === "Visualizar" ? true : false}
                        value={observacao || ""}
                        onChange={setObservacao}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Participação (%)"
                        name="participacao"
                        type="number"
                        step="0.01"
                        placeholder="Participação (%)"
                        readonly={acao === "Visualizar" ? true : false}
                        value={participacao ? participacao : '0'}
                        onChange={setParticipacao}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                </TabPanel>
                
              </TabContext>
            </Box>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <ButtonCustom
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
                disabled={acao === "Visualizar" ? true : false}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>
      <ModalUploadImage
        show={showUpload}
        label="Realizando Upload da Imagem ..."
      />
      <ModalLoading show={loading} label="Carregado dados da clinica ..." />
    </>
  );
};
export default FormProfissional;
