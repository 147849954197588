import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo";
import FormConvenios from "./components/formulario";

export function ConveniosVisualizar() {
  const idconvenio = useParams();
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Visualizar Convênio" />
        <FormConvenios idconvenio={idconvenio.id} acao="Visualizar" />
      </div>
    </>
  );
}
