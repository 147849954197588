import React, { useState } from "react";
import TabelaColunaInterface, { ColunaSimples } from "./TabelaColunas";
import Pagination from "../Paginacao/Paginacao";

interface TableProps {
  coluna: TabelaColunaInterface[];
  dados: any[];
  itemsPerPage: number;
  onclick?: () => void;
}

const Table: React.FC<TableProps> = ({
  coluna,
  dados,
  itemsPerPage,
  onclick,
}) => {
  const formataColunaAcao = coluna.some((coluna) => "acoes" in coluna);
  const formataAbecalhoAcao = coluna.some((coluna) => "acoes" in coluna);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(dados.length / itemsPerPage);
  const itens = dados.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleEditClick = (codigo: number) => {
    // Handle edit action here
    console.log(`Edit clicked for codigo: ${codigo}`);
  };

  const handleDeleteClick = (codigo: number) => {
    // Handle delete action here
    console.log(`Delete clicked for codigo: ${codigo}`);
  };

  return (
    <div className="table-responsive">
      <table className="table table-centered w-100 dt-responsive nowrap table-striped table-hover">
        <thead className="table-cabecalho">
          <tr>
            {coluna.map((column, colIndex) => (
              <th
                key={colIndex}
                className={
                  formataAbecalhoAcao && colIndex === coluna.length - 1
                    ? "acoes"
                    : undefined
                }
              >
                {column.titulo}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {itens.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {coluna.map((column, colIndex) => (
                <td
                  key={colIndex}
                  className={
                    formataColunaAcao && colIndex === coluna.length - 1
                      ? "acoes"
                      : undefined
                  }
                >
                  {isColunaSimples(column)
                    ? row[column.acesso]
                    : "acoes" in column &&
                      column.acoes.map((action, actionIndex) => (
                        <React.Fragment key={actionIndex}>
                          {action === "editar" && (
                            <button
                              onClick={() => handleEditClick(row["codigo"])}
                            >
                              Editar
                            </button>
                          )}
                          {action === "excluir" && (
                            <button
                              onClick={() => handleDeleteClick(row["codigo"])}
                            >
                              Excluir
                            </button>
                          )}
                        </React.Fragment>
                      ))}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default Table;

function isColunaSimples(
  column: TabelaColunaInterface
): column is ColunaSimples {
  return (column as ColunaSimples).acesso !== undefined;
}
