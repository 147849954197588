import { useParams } from "react-router-dom";
import ContainerTitulo from "../../components/Formularios/Container/ContainerTitulo";
import FormProntuario from "./components/formulario";

export function ProntuarioIncluir() {
  const idprontuario = useParams();
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Incluir Prontuario" />
        <FormProntuario idprontuario={idprontuario.id} acao="Novo" />
      </div>
    </>
  );
}
