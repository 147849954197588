import { Col, Form, Modal, Row } from "react-bootstrap";
import { Button } from "../../../../components/Formularios/Buttons/Button";
import { mdiCheckCircle, mdiKeyboardReturn } from "@mdi/js";
import { useEffect, useState } from "react";
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import { Switch } from "@mui/material";

interface DetalheModalProps {
  showModal: boolean;
  acao?: string;
  onClose: () => void;
  dadosCodigo?: string;
  dadosDescricao?: string;
  dadosAtivo?: boolean;
  dadosDefUsuario01?: string;
  dadosDefUsuario02?: string;
  dadosDefUsuario03?: string;
  dadosDefUsuario04?: string;
  dadosDefUsuario05?: string;
  dadosDefUsuario06?: string;
  onSubmit: (formData: any) => void;
}
const ModalDetalhe: React.FC<DetalheModalProps> = ({
  showModal,
  acao,
  onClose,
  dadosCodigo,
  dadosDescricao,
  dadosAtivo,
  dadosDefUsuario01,
  dadosDefUsuario02,
  dadosDefUsuario03,
  dadosDefUsuario04,
  dadosDefUsuario05,
  dadosDefUsuario06,
  onSubmit,
}) => {
  const [codigo, setCodigo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [ativo, setAtivo] = useState<boolean>(true);
  const [defUsuario01, setDefUsuario01] = useState<string>("");
  const [defUsuario02, setDefUsuario02] = useState<string>("");
  const [defUsuario03, setDefUsuario03] = useState<string>("");
  const [defUsuario04, setDefUsuario04] = useState<string>("");
  const [defUsuario05, setDefUsuario05] = useState<string>("");
  const [defUsuario06, setDefUsuario06] = useState<string>("");

  const carregarDados = () => {
    setCodigo(dadosCodigo || "");
    setDescricao(dadosDescricao || "");
    setAtivo(dadosAtivo === true ? true : false);
    setDefUsuario01(dadosDefUsuario01 || "");
    setDefUsuario02(dadosDefUsuario02 || "");
    setDefUsuario03(dadosDefUsuario03 || "");
    setDefUsuario04(dadosDefUsuario04 || "");
    setDefUsuario05(dadosDefUsuario05 || "");
    setDefUsuario06(dadosDefUsuario06 || "");
  };

  useEffect(() => {
    if (showModal) {
      carregarDados();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const dados = {
      codigo,
      descricao,
      ativo,
      defUsuario01,
      defUsuario02,
      defUsuario03,
      defUsuario04,
      defUsuario05,
      defUsuario06,
      acao,
    };
    onSubmit(dados);
  };
  
  return (
    <>
      <Modal
        show={showModal}
        className="modal-full-width "
        size="xl"
        keyboard={false}
        centered
      >
        <Modal.Header className="bg-white">
          <Modal.Title>Incluir Código do Sistema</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body className="bg-white">
            <Row>
              <Col sm={2}>
                <InputSemBorda
                  label="Código"
                  name="codigo"
                  type="text"
                  placeholder="Código"
                  required
                  readonly={acao === "Visualizar" || acao === "EditarItem" ? true : false}
                  value={codigo || ""}
                  onChange={setCodigo}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-uppercase"
                />
              </Col>
              <Col sm={8}>
                <InputSemBorda
                  label="Descrição"
                  name="descricao"
                  type="text"
                  placeholder="Descrição"
                  required
                  readonly={acao === "Visualizar" ? true : false}
                  value={descricao || dadosDescricao || ""}
                  onChange={setDescricao}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                />
              </Col>
              <Col sm={2}>
                <br />
                <label htmlFor="" className="form-label me-2">
                  Ativo
                </label>
                <Switch
                  checked={ativo}
                  onChange={() => setAtivo(!ativo)}
                  disabled={acao === "Visualizar" ? true : false}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <InputSemBorda
                  label="Def. Usuário 01"
                  name="defusuario01"
                  type="text"
                  placeholder="Def. Usuário 01"
                  readonly={acao === "Visualizar" ? true : false}
                  value={defUsuario01}
                  onChange={setDefUsuario01}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                />
              </Col>
              <Col sm={4}>
                <InputSemBorda
                  label="Def. Usuário 02"
                  name="defusuario02"
                  type="text"
                  placeholder="Def. Usuário 02"
                  readonly={acao === "Visualizar" ? true : false}
                  value={defUsuario02}
                  onChange={setDefUsuario02}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                />
              </Col>
              <Col sm={4}>
                <InputSemBorda
                  label="Def. Usuário 03"
                  name="defusuario03"
                  type="text"
                  placeholder="Def. Usuário 03"
                  readonly={acao === "Visualizar" ? true : false}
                  value={defUsuario03}
                  onChange={setDefUsuario03}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <InputSemBorda
                  label="Def. Usuário 04"
                  name="defusuario04"
                  type="text"
                  placeholder="Def. Usuário 04"
                  readonly={acao === "Visualizar" ? true : false}
                  value={defUsuario04}
                  onChange={setDefUsuario04}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                />
              </Col>
              <Col sm={4}>
                <InputSemBorda
                  label="Def. Usuário 05"
                  name="defusuario05"
                  type="text"
                  placeholder="Def. Usuário 05"
                  readonly={acao === "Visualizar" ? true : false}
                  value={defUsuario05}
                  onChange={setDefUsuario05}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                />
              </Col>
              <Col sm={4}>
                <InputSemBorda
                  label="Def. Usuário 06"
                  name="defusuario06"
                  type="text"
                  placeholder="Def. Usuário 06"
                  readonly={acao === "Visualizar" ? true : false}
                  value={defUsuario06}
                  onChange={setDefUsuario06}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="bg-white">
            <div className="d-flex my-2 gap-1 justify-content-between">
              <Button
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={onClose}
              />
              <Button
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
              />
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export default ModalDetalhe;
