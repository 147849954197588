import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import FormProfissional from "./components/formulario";

export function ProfissionaisVisualizar() {
  const idprofissional = useParams();
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Visualizar Profissional" />
        <FormProfissional idprofissional={idprofissional.id} acao="Visualizar" />
      </div>
    </>
  );
}
