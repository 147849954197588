import { Col, Row, Container, Card, Image, Stack } from "react-bootstrap";
import { Button } from "../../components/Formularios/Buttons/Button";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ApiPost from "../../api/endPoints/usePost";
import { toast } from "react-toastify";
import LogoNova from "../../assets/img/logo.jpg";
import InputSemBorda from "../../components/Formularios/Inputs/InputsSemBorda";
import { Button as ButtonCustom } from "../../components/Formularios/Buttons/Button";
import ApiPut from "../../api/endPoints/usePut";
import { Modal } from "react-bootstrap";
import ValidaComplexidadeSenha from "../../components/Formularios/ValidaComplexidadeSenha";
import { mdiCheckCircle, mdiKeyboardReturn } from "@mdi/js";
import ModalLoading from "../../components/Formularios/Modal/ModalLoading";

export function Login() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [textoLoading, setTextoLoading] = useState<string>("");

  const [email, setEmail] = useState<string>("");
  const [senha, setSenha] = useState<string>("");
  const emailRef = useRef<HTMLInputElement>(null);
  const senhaRef = useRef<HTMLInputElement>(null);
  //#region ===> VALIDAR LOGIN
  const login = async (email: string, password: string) => {
    if (!loading) {
      setLoading(true);
      setTextoLoading("Aguarde, Realizando login...");
      const dados = {
        email,
        password,
      };
      ApiPost("/usuario/logar", dados)
        .then((response) => {
          setLoading(false);
          setTextoLoading("");
          const {
            usuario,
            nome,
            email,
            perfil,
            id_usuario,
            token,
            master,
            vinculo,
            cpf,
            id_perfil,
          } = response;
          sessionStorage.setItem("DadosUsuario", usuario);
          sessionStorage.setItem("NomeUsuario", nome);
          sessionStorage.setItem("EmailUsuario", email);
          sessionStorage.setItem("PerfilUsuario", perfil);
          sessionStorage.setItem("DadosUsuarioID", id_usuario);
          sessionStorage.setItem("DadosPerfilID", id_perfil);
          sessionStorage.setItem("MultClinicaWebToken", token);
          sessionStorage.setItem("isPerfilSuper", master);
          sessionStorage.setItem("Cpf", cpf);
          if (vinculo) sessionStorage.setItem("isPerfilVinculo", vinculo);
          navigate("/");
        })
        .catch((erro) => {
          setLoading(false);
          setTextoLoading("");
          const msg =
            erro.response?.data.message !== undefined
              ? erro.response?.data.message
              : erro.message;
          toast.error(`Falha ao realizar login. Motivo: ${msg}`);
        });
    }
  };

  //#endregion
  const enviar = (event: any) => {
    event.preventDefault();
    login(email, senha);
  };

  ///PARTE DE REDEFINIÇÃO DE SENHA///

  //controle modal recSenha
  const [showModalRecSenha, setshowModalRecSenha] = useState(false);

  function abrirModalRecSenha() {
    setbtnEnviarEmail(false);
    setshowModalRecSenha(true);
  }

  const [Email, setEmail_REC] = useState("");
  const [codigo, setCodigo] = useState("");

  const [btnEnviarEmail, setbtnEnviarEmail] = useState(false);

  function EnviaremailRecSenha(e: any) {
    e.preventDefault();
    setbtnEnviarEmail(true);
    const dados = {
      emailUser: Email,
    };

    ApiPost("/rec/senha/user", dados).then(function (resposta) {
      if (resposta.status === 200) {
        setshowModalRecSenha(false);
        //guardo no localstorage o token para verficacao
        localStorage.setItem("token_recSenha", resposta.token);
        setshowModalCodigo(true);
      } else if (resposta.status === 400) {
        setbtnEnviarEmail(false);
        toast.error(resposta.message);
      } else {
        toast.error("Erro desconhecido");
      }
    });
  }

  //controle do Modal de verificar código
  const [showModalCodigo, setshowModalCodigo] = useState(false);

  function setCodigoVal(e: any) {
    if (e.length <= 4) {
      setCodigo(e);
    } else {
      toast.error("O código não tem mais que 4 digitos.");
    }
  }

  function verificarCodParaSetarSenha(e: any) {
    e.preventDefault();
    const dados = {
      codigo: codigo,
      token: localStorage.getItem("token_recSenha"),
    };

    ApiPost("/rec/verificar/tokenSenha", dados)
      .then(function (resposta) {
        if (resposta.status === 200) {
          setshowModalCodigo(false);
          setModalDefinirSenha(true);
        } else {
          toast.error("Código invalido");
        }
      })
      .catch(function (erro) {
        toast.error(erro);
      });
  }

  //modal de definir a senha
  const [senhaRec, setsenhaRec] = useState("");
  const [confirmar_senha, setconfirmar_senha] = useState("");

  const [modalDefinirSenha, setModalDefinirSenha] = useState(false);

  function setNewSenha() {
    if (!loading) {
      if (
        senhaRec === confirmar_senha &&
        senhaRec !== "" &&
        senhaRec !== null &&
        confirmar_senha !== "" &&
        confirmar_senha !== null
      ) {
        setLoading(true);
        setTextoLoading("Aguarde, definindo nova senha...");
        const dados = {
          senha: senhaRec,
          email: Email,
        };
        ApiPut("/redefinir/senha", dados)
          .then(function (resposta) {
            setLoading(false);
            setTextoLoading("");
            toast.success(resposta.message);
            setModalDefinirSenha(false);
            setsenhaRec("");
            setconfirmar_senha("");
            setEmail_REC("");
            setCodigo("");
          })
          .catch(function (erro) {
            setLoading(false);
            setTextoLoading("");
            toast.error(erro);
          });
      } else {
        toast.error("As senhas não são iguais.");
      }
    }
  }

  //fechar qualquer modal recsenha
  function fecharModalsRecSenha() {
    setshowModalRecSenha(false);
    setshowModalCodigo(false);
    setModalDefinirSenha(false);
    setsenhaRec("");
    setconfirmar_senha("");
    setEmail_REC("");
    setCodigo("");
  }

  return (
    <>
      <main className="mt-5">
        <Container>
          <Card>
            <div className="m-4">
              <Row>
                <Col sm={12} md={12} lg={6}>
                  <Card
                    className="p-5 d-flex justify-content-center"
                    style={{ height: "70%" }}
                  >
                    <Image
                      alt="logo"
                      src={LogoNova}
                      // style={{ padding: "0px", margin: "0px", top: "0px" }}
                    />
                  </Card>
                </Col>
                <Col sm={12} md={12} lg={6}>
                  <Card className="p-5 ">
                    <h3 className="text-center text-black">
                      Controle de Acesso
                    </h3>
                    <form onSubmit={enviar}>
                      <Row>
                        <Col sm={12} md={12} lg={12}>
                          <InputSemBorda
                            label="E-mail"
                            name="email"
                            type="email"
                            placeholder="E-mail"
                            value={email || ""}
                            onChange={setEmail}
                            required={true}
                            useRef={emailRef}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                          />
                          {/* <Input
                          type="email"
                          name="email"
                          value={email}
                          classNameInput="form-control"
                          classNameLabel="form-label"
                          onChange={setEmail}
                          label="Email"
                          required={true}
                          useRef={emailRef}
                        /> */}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12} lg={12}>
                          <InputSemBorda
                            label="Senha"
                            name="senha"
                            type="password"
                            placeholder="Senha"
                            value={senha || ""}
                            onChange={setSenha}
                            required={true}
                            useRef={senhaRef}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                          />
                          {/* <Input
                          type="password"
                          name="senha"
                          value={senha}
                          classNameInput="form-control"
                          classNameLabel="form-label"
                          onChange={setSenha}
                          label="Senha"
                          required={true}
                          useRef={senhaRef}
                        /> */}
                        </Col>
                      </Row>

                      <button
                        type="button"
                        className="btn btn-link"
                        style={{ display: "block", margin: "auto" }}
                        onClick={abrirModalRecSenha}
                      >
                        Esqueceu sua senha?
                      </button>

                      <Stack gap={3} className="col-md-5 mx-auto">
                        <Button
                          className="mt-4 btn-info rounded-pill"
                          type="submit"
                          descricaoBotao="Acessar"
                        />
                      </Stack>
                    </form>
                  </Card>
                </Col>
              </Row>
            </div>
            <Card className="rodape-login p-2 mt-3">
              <section className="d-flex justify-content-end">
                <p className="text-light px-3 fs-6">
                  Copyright &copy; {new Date().getFullYear()}. Todos os direitos
                  reservados FKL Tecnologi@.
                </p>
              </section>
            </Card>
          </Card>
        </Container>

        {/*MODAL REC SENHA */}
        <Modal
          show={showModalRecSenha}
          backdrop="static"
          size="lg"
          keyboard={false}
          centered
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            <Modal.Title>Recuperação de Senha</Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ backgroundColor: "white", textAlign: "left" }}>
            <p>
              Informe o e-mail cadastrado no sistema para receber o código de
              validação.
            </p>
            <form onSubmit={EnviaremailRecSenha} style={{ textAlign: "left" }}>
              <div
                className="email-rec"
                style={{ display: "inline-block", width: "50%" }}
              >
                <Row>
                  <Col sm={12} md={12} lg={12}>
                    <InputSemBorda
                      label="E-mail"
                      name="email"
                      type="email"
                      placeholder="E-mail"
                      value={Email}
                      onChange={setEmail_REC}
                      required={true}
                      classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                    />
                  </Col>
                </Row>
              </div>

              <hr />
              <div className="d-flex my-2 gap-1 justify-content-between">
                <ButtonCustom
                  type="button"
                  className="btn btn-light text-danger bg-white border-0"
                  descricaoBotao="Cancelar"
                  icone={mdiKeyboardReturn}
                  onclick={() => {
                    fecharModalsRecSenha();
                  }}
                />

                <div
                  className="deixarinviBtn"
                  hidden={btnEnviarEmail}
                  style={{ display: "inline-block" }}
                >
                  <ButtonCustom
                    type="submit"
                    className="btn btn-light text-info-emphasis bg-white border-0"
                    descricaoBotao="Enviar Email"
                    icone={mdiCheckCircle}
                  />
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal
          show={showModalCodigo}
          backdrop="static"
          size="lg"
          keyboard={false}
          centered
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            <Modal.Title>Recuperação de Senha</Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ backgroundColor: "white" }}>
            <form onSubmit={verificarCodParaSetarSenha}>
              <p>
                Código enviado para: {Email}. Você receberá no email um código
                de validação.
              </p>

              <div
                className="email-rec"
                style={{ display: "inline-block", width: "50%" }}
              >
                <Row>
                  <Col sm={12} md={12} lg={12}>
                    <InputSemBorda
                      label="Cód. de validação"
                      name="Cód. de validação"
                      type="number"
                      placeholder="Cód. de validação"
                      value={codigo}
                      onChange={function (e) {
                        setCodigoVal(e);
                      }}
                      required={true}
                      classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                    />
                  </Col>
                </Row>
              </div>

              <hr />
              <div className="d-flex my-2 gap-1 justify-content-between">
                <ButtonCustom
                  type="button"
                  className="btn btn-light text-danger bg-white border-0"
                  descricaoBotao="Cancelar"
                  icone={mdiKeyboardReturn}
                  onclick={() => {
                    fecharModalsRecSenha();
                  }}
                />
                <ButtonCustom
                  type="submit"
                  className="btn btn-light text-info-emphasis bg-white border-0"
                  descricaoBotao="Validar Código"
                  icone={mdiCheckCircle}
                />
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal
          show={modalDefinirSenha}
          backdrop="static"
          size="lg"
          keyboard={false}
          centered
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            <Modal.Title>Recuperação de Senha</Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ backgroundColor: "white" }}>
            <form style={{ textAlign: "left" }}>
              <p>Digite sua nova senha.</p>

              <div
                className="email-rec"
                style={{ display: "inline-block", width: "50%" }}
              >
                <Row>
                  <Col sm={12} md={12} lg={12}>
                    <InputSemBorda
                      label="Nova senha"
                      name="Nova senha"
                      type="password"
                      placeholder=""
                      value={senhaRec}
                      onChange={setsenhaRec}
                      required={true}
                      classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                    />
                  </Col>
                </Row>
              </div>

              <div className="cad w-50">
                <ValidaComplexidadeSenha senha={senhaRec} />
              </div>

              <div
                className="email-rec"
                style={{ display: "inline-block", width: "50%" }}
              >
                <Row>
                  <Col sm={12} md={12} lg={12}>
                    <InputSemBorda
                      label="Confirmar nova senha"
                      name="Confirmar nova senha"
                      type="password"
                      placeholder=""
                      value={confirmar_senha}
                      onChange={setconfirmar_senha}
                      required={true}
                      classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                    />
                  </Col>
                </Row>
              </div>
              <div className="cad w-50">
                <ValidaComplexidadeSenha senha={confirmar_senha} />
              </div>
              <br />

              <hr />
              <div className="d-flex my-2 gap-1 justify-content-between">
                <ButtonCustom
                  type="button"
                  className="btn btn-light text-danger bg-white border-0"
                  descricaoBotao="Cancelar"
                  icone={mdiKeyboardReturn}
                  onclick={() => {
                    fecharModalsRecSenha();
                  }}
                />
                <ButtonCustom
                  type="button"
                  className="btn btn-light text-info-emphasis bg-white border-0"
                  descricaoBotao="Definir nova senha"
                  icone={mdiCheckCircle}
                  onclick={setNewSenha}
                />
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </main>

      <ModalLoading show={loading} label={textoLoading} />
    </>
  );
}
