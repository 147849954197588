import { Row, Col, Card } from "react-bootstrap";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import Tabela from "../../../components/Formularios/Tabela/Tabela";
import TabelaColunaInterface from "../../../components/Formularios/Tabela/TabelaColunas";
import { Button } from "../../../components/Formularios/Buttons/Button";
import { mdiFileEditOutline, mdiTrashCanOutline } from "@mdi/js";

export function PlanoSaude() {
  const colunas: TabelaColunaInterface[] = [
    { titulo: "Código", acesso: "codigo" },
    { titulo: "Nome", acesso: "nome" },
    { titulo: "Registro ANS", acesso: "registroans" },
    { titulo: "Unidade Associada", acesso: "unidade" },
    { titulo: "Cód. Prestador Operadora", acesso: "codprestador" },
    {
      titulo: "",
      acoes: [
        <Button
          onclick={() => {}}
          icone={mdiFileEditOutline}
          type="button"
          className="botaoTransparente"
          title="Editar"
        />,
        <Button
          onclick={() => {}}
          icone={mdiTrashCanOutline}
          type="button"
          className="botaoTransparente"
          title="Excluir"
        />,
      ],
      className: "acoes",
    },
  ];

  const dados = [
    { 
        codigo: 1, 
        nome: "Bradesco Saúde", 
        registroans: '005177',
        unidade: 'Pinheiros',
        codprestador: '676002',
     },
    
    
    
    
    // Adicione mais dados conforme necessário
  ];
  return (
    <>
      <div className="container-fluid">
        <ContainerTitulo titulo="Listagem de Planos de Saúde" />
      </div>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Body>
              <Row>
                <div className="d-flex my-2 gap-1 ">
                  <Col sm={8}>
                    <InputSemBorda
                      name="pesquisa"
                      type="text"
                      value=""
                      onChange={() => {}}
                      classNameInputsSemBorda=" bordasInferiorInput"
                      placeholder="Código de Prestadora"
                    />
                  </Col>
                  <Col sm={4}>
                    <InputSemBorda
                      name="pesquisa"
                      type="text"
                      value=""
                      onChange={() => {}}
                      classNameInputsSemBorda=" bordasInferiorInput"
                      placeholder="Convênio"
                    />
                  </Col>
                  
                </div>
              </Row>
              <Row>
                <Tabela coluna={colunas} dados={dados} itemsPerPage={5}/>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
