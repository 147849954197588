import { Row, Col, Card } from "react-bootstrap";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";

import AddIcon from "@mui/icons-material/Add";
// import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
// import SaveIcon from "@mui/icons-material/Save";
// import PrintIcon from "@mui/icons-material/Print";
import Tabela from "../../../components/Formularios/Table";

import { useEffect, useState } from "react";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import { InterfaceUsuario } from "../../../types/UsuarioInterface";
import { toast } from "react-toastify";
import ApiGet from "../../../api/endPoints/useGet";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import ApiDelete from "../../../api/endPoints/useDelete";
import { getSessionData } from "../../../utils/storageUtils";
import ApiPost from "../../../api/endPoints/usePost";

export function Permissao() {
  const token = getSessionData("MultClinicaWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  const navigate = useNavigate();

  interface Action {
    icon: JSX.Element;
    name: string;
  }
  const actions: Action[] = [
    {
      icon: (
        <AddIcon
          onClick={() => navigate("/administrativo/permisao/permissao/novo")}
        />
      ),
      name: "Incluir",
    },
    // { icon: <FileCopyIcon />, name: "Duplicar" },
    // { icon: <SaveIcon />, name: "Salvar" },
    // { icon: <PrintIcon />, name: "Imprimir" },
  ];

  const visualizar = (dados: any) => {
    const id_perfil = dados.id_perfil;
    navigate("/administrativo/permisao/permissao/visu/" + id_perfil);
  };

  const editar = (dados: any) => {
    const id_perfil = dados.id_perfil;
    navigate("/administrativo/permisao/permissao/editar/" + id_perfil);
  };

  const excluir = async (dados: any) => {
    const id_perfil = dados.id_perfil;
    await showConfirmationDialog(
      "Excluir Perfil/Permissão",
      `Tem certeza que deseja excluir o perfil \n ${dados.nome_perfil}?`,
      "question",
      "Excluir",
      "Cancelar"
    )
      .then((result) => {
        if (result.confirmed) {
          const headers = {
            Authorization: token,
          };
          const dados = {
            url: "/perfil/excluir/:id_perfil",
            parametros: { id_perfil: id_perfil },
            headers: headers,
          };
          ApiDelete(dados)
            .then((retorno) => {
              toast.success(retorno.message);
              buscarDados();
            })
            .catch((erro) => {
              toast.error(
                `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
              );
            });
        }
      })
      .catch((erro) => {
        toast.error(
          `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
        );
      });
  };

  const colunas: ITabela[] = [
    { titulo: "Código", acesso: "id_perfil" },
    { titulo: "Perfil", acesso: "nome_perfil" },
    { titulo: "Situação", acesso: "situacao" },
  ];

  //#region REALIZA REQUISIÇÃO PARA LISTAR DADOS DA TABELA
  const [dados, setDados] = useState<InterfaceUsuario[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [textoLoading, setTextoLoading] = useState<string>("");
  const buscarDados = async () => {
    setLoading(true);
    setTextoLoading("Carregando Listagem de Perfil/Permissão");
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: `/perfil/listar/${isSuper}`,
      parametros: {},
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        setDados(retorno);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar perfil. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    buscarDados();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion
  const filtrarDados = async (filtro: string) => {
    setLoading(true);
    setTextoLoading("Filtrando Listagem de Perfil/Permissão...");

    const dados = {
      filtro,
    };
    ApiPost(`/perfil/filtro`, dados, token)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        setDados(retorno);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        setLoading(false);
        toast.error(`Erro ao filtrar dados. Motivo: ${erro.message}`);
      });
  };
  const [pesquisa, setPesquisa] = useState<string>("");
  const pesquisar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (pesquisa === "") return buscarDados();
    else {
      const _filtro = pesquisa.toLowerCase();
      filtrarDados(_filtro);
    }
  };
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Listagem de Perfil / Permissão" />

        <Row>
          <Col sm={12}>
            <form onSubmit={pesquisar}>
              <Card>
                <Card.Body>
                  <Row>
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      direction="left"
                      sx={{
                        right: 0,
                        position: "absolute",
                      }}
                      icon={<SpeedDialIcon />}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction
                          key={action.name}
                          icon={action.icon}
                          tooltipTitle={action.name}
                        />
                      ))}
                    </SpeedDial>
                  </Row>
                  <Row>
                    <div className="d-flex my-2 gap-1 ">
                      <Col sm={11}>
                        <InputSemBorda
                          name="permissao"
                          type="text"
                          value={pesquisa}
                          onChange={setPesquisa}
                          classNameInputsSemBorda="form-control bordasInferiorInput text-capitalize"
                          placeholder=" "
                          label="Pesquisar"
                        />
                      </Col>
                    </div>
                  </Row>

                  <Row>
                    <Tabela
                      coluna={colunas}
                      dados={dados}
                      itemsPerPage={10}
                      onVisuClick={visualizar}
                      onEditClick={editar}
                      onDeleteClick={excluir}
                      onImprimirClick={() => {}}
                      usaImprimir={false}
                      id="codigo"
                    />
                  </Row>
                </Card.Body>
              </Card>
            </form>
          </Col>
        </Row>
      </div>
      <ModalLoading show={loading} label={textoLoading} />
    </>
  );
}
