import { useNavigate, useParams } from "react-router-dom";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";
import { useState, useEffect, ChangeEvent, useCallback } from "react";
import { toast } from "react-toastify";
import { Card, Col, Form, FormLabel, Row } from "react-bootstrap";
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import { useDropzone } from 'react-dropzone';
import 'react-circular-progressbar/dist/styles.css';
import { Box, Tab, Paper, Icon } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button as ButtonCustom } from "../../../../components/Formularios/Buttons/Button";
import { mdiCheckCircle, mdiCloudUpload, mdiEye, mdiKeyboardReturn, mdiPlus } from "@mdi/js";
import ApiPost from "../../../../api/endPoints/usePost";
import ApiPut from "../../../../api/endPoints/usePut";
import ModalUploadImage from "../../../../components/Formularios/Modal/ModalUpload";
import { styled } from "@mui/material/styles";
import CarregarDados from "../CarregarDados";
import { url_CarregarDados_ID } from "../auxiliar";
import { TextoFormatoCaptalize } from "../../../../components/Auxiliar/ConvTextoCaptalize";
import { TextoFormatoLowerCase } from "../../../../components/Auxiliar/ConvTextoLower";
import useSelectOptions from "../../../../hooks/useSelectOptions";
import SelectInput from "../../../../shared/SelectInputs";
import { getSessionData } from "../../../../utils/storageUtils";
import axios from "axios";
import { mascaraCEP, mascaraTelefoneCelular, mascaraTelefoneFixo } from "../../../../hooks/mascaras";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir";
import ApiDelete from "../../../../api/endPoints/useDelete";
import Tabela from "../../../../components/Formularios/Table";
import ModalProntuario from "../modalProntuario";
import DatalistInput from "react-datalist-input";
import { Api } from "../../../../api/api";
import ModalDocumentos from "../modalDocumentos";
import { el } from "@fullcalendar/core/internal-common";
import FileSaver from "file-saver";
import { mdiDownload } from "@mdi/js";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F0F0F0",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "#616161",
  borderRadius: "5px",
  fontWeight: "bold",
  fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
}));

interface FormProntuarioProps {
  idprontuario?: string;
  acao?: string;
}

const FormProntuario = ({ idprontuario, acao }: FormProntuarioProps) => {
  const navigate = useNavigate();
  const id_clinica = getSessionData("DadosIdClinica") || "";
  const CPF = sessionStorage.getItem('Cpf') || '';

  const { idProntuario } = useParams();

  //#region Controla as guias
  const [guia, setGuia] = useState("1");

  const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
    setGuia(novaGuia);
  };
  //#endregion

  const [showUpload, setShowUpload] = useState<boolean>(false);


  const [id_prontuario, setId_prontuario] = useState<any>(idprontuario || '');
  const [id_paciente, setId_paciente] = useState<string>("");
  const [id_profissional, setId_profissional] = useState<string>("");
  const [encerramento, setEncerramento] = useState<string>("");
  const [data_encerramento, setData_encerramento] = useState<string>("");
  const [avaliacao, setAvaliacao] = useState<string>("");
  const [motivopsicoterapia, setMotivopsicoterapia] = useState<string>("");
  const [tratamentomedico, setTratamentomedico] = useState<string>("");
  const [usamedicacao, setUsamedicacao] = useState<string>("");
  const [possuidiagnostico, setPossuidiagnostico] = useState<string>("");
  const [percepcoes, setPercepcoes] = useState<string>("");
  const [cpf, setCpf] = useState<string>('');
  const [nome, setNome] = useState<string>("");
  const [dtnascimento, setDtnascimento] = useState<string>("");
  const [telfixo, setTelfixo] = useState<string>("");
  const [telcelular, setTelcelular] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [telemerggencia, setTelemerggencia] = useState<string>("");
  const [contemerggencia, setContemerggencia] = useState<string>("");
  const [telresponsavel, setTelresponsavel] = useState<string>("");
  const [responsavel, setResponsavel] = useState<string>("");
  const [logradouro, setLogradouro] = useState<string>("");
  const [numero, setNumero] = useState<string>("");
  const [bairro, setBairro] = useState<string>("");
  const [compl, setCompl] = useState<string>("");
  const [cnum, setCnum] = useState<string>("");
  const [cuf, setCuf] = useState<string>("");
  const [cep, setCep] = useState<string>("");
  const [codconselho, setCodconselho] = useState<string>("");
  const [telcelularprof, setTelcelularprof] = useState<string>("");
  const [detalhes, setDetalhes] = useState<any[]>([]);
  const [docs, setDocs] = useState<any[]>([]);
  // const [ocorrencia, setOcorrencia] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  //#region ==> Select Options
  // Supondo que o valor inicial seja uma string, ajuste conforme necessário
  type ProfissionalOption = {
    value: string;
    label: string;
    telcelularprof: string;
    codconselho: string;
  };

  //#region ==> Select Options
  const profissionalOptions: ProfissionalOption[] = useSelectOptions(`/prontuario/listarprofissionais/${id_clinica}`);
  const [selectedProfissional, setSelectedProfissional] = useState<string | undefined>('');

  // Função de manipulação de mudanças
  const handleChangeProfissional = async (e: ChangeEvent<HTMLSelectElement>) => {
    const profissionalSelecionado = e.target.value;
    setSelectedProfissional(profissionalSelecionado);

    // Forçar a tipagem da array de opções como any[]
    const selectedProfissionalObj = (profissionalOptions as any[]).find((profissional: any) => profissional.value === parseInt(profissionalSelecionado));

    // Atualizar o(s) estado(s) com o valor apropriado do profissional selecionado
    if (selectedProfissionalObj) {
      setCodconselho(selectedProfissionalObj.codconselho);
      setTelcelularprof(selectedProfissionalObj.telcelularprof);
      // Demais estados a serem atualizados
      // Fazendo a requisição GET
      const response = await axios.get(Api + `/prontuario/listarcomplementares/${id_clinica}/${idprontuario}/${profissionalSelecionado}`);
      const data = response.data

      if (data.length > 0) {
        // Atualizando os estados com o retorno da requisição
        setEncerramento(data[0].encerramento);
        setData_encerramento(data[0].data_encerramento);
        setAvaliacao(data[0].avaliacao);
      } else {
        setEncerramento("");
        setData_encerramento("");
        setAvaliacao("");
      }

    } else {
      // Se nenhum profissional for selecionado, limpar os estados
      setCodconselho("");
      setTelcelularprof("");
      setEncerramento("");
      setData_encerramento("");
      setAvaliacao("");
    }
  };

  //#region ==> Datalist Pacientes
  const [datalistPaciente, setDatalistPaciente] = useState([]);
  const carregarDadosPacienteDataList = async (nome: any) => {
    try {
      const response = await axios.get(
        Api +
        `/anamnese/listarpacientedatalist/?nome=${nome}&id_clinica=${id_clinica}`
      );
      if (response.data.length > 0) {
        setDatalistPaciente(response.data);
      }
    } catch (erro) {
      toast.error("Falha ao listar pacientes. Motivo: " + erro);
    }
  };
  const buscarPacientes = (filtro: any) => {
    let pesquisaPaciente = filtro.target.value;
    if (pesquisaPaciente.length >= 3) {
      carregarDadosPacienteDataList(pesquisaPaciente);
    }
  };

  const selecionaPaciente = (dados: any) => {

    setId_paciente(dados.id);
    setNome(dados.nome);
    setCpf(dados.cpf);
    setDtnascimento(dados.dtnascimento);
    setEmail(dados.email);
    setTelfixo(dados.telfixo);
    setTelcelular(dados.telcelular);
    setTelemerggencia(dados.telemerggencia);
    setContemerggencia(dados.contemerggencia);
    setLogradouro(dados.logradouro);
    setNumero(dados.numero);
    setBairro(dados.bairro);
    setCompl(dados.compl);
    setCnum(dados.cnum);
    setCuf(dados.cuf);
    setCep(dados.cep);
    setResponsavel(dados.responsavel);
    setTelresponsavel(dados.telresponsavel);
    setDatalistPaciente([]);
  };
  //#endregion

  const [dados, setDados] = useState<any[]>([]);
  const carregar = (url: string, idprontuario: string, id_clinica: string) => {
    setLoading(true);
    CarregarDados(url, idprontuario, id_clinica)
      .then((retorno) => {
        setLoading(false);
        setDados(retorno);

        setId_prontuario(retorno[0].cab.id_prontuario || '');
        setId_paciente(retorno[0].cab.id_paciente || '');
        setSelectedProfissional(retorno[0].cab.id_profissional || '');
        setEncerramento(retorno[0].cab.encerramento || '');
        setData_encerramento(retorno[0].comp.dtencerramento_formatada || '');
        setAvaliacao(retorno[0].comp.avaliacao || '');
        setMotivopsicoterapia(retorno[0].cab.motivopsicoterapia || '');
        setTratamentomedico(retorno[0].cab.tratamentomedico || '');
        setUsamedicacao(retorno[0].cab.usamedicacao || '');
        setPossuidiagnostico(retorno[0].cab.possuidiagnostico || '');
        setPercepcoes(retorno[0].cab.percepcoes || '');
        setCpf(retorno[0].cab.cpf || '');
        setNome(retorno[0].cab.nome || '');
        setDtnascimento(retorno[0].cab.dtnascimento || '');
        setTelfixo(retorno[0].cab.telfixo || '');
        setTelcelular(retorno[0].cab.telcelular || '');
        setEmail(retorno[0].cab.email || '');
        setTelemerggencia(retorno[0].cab.telemerggencia || '');
        setContemerggencia(retorno[0].cab.contemerggencia || '');
        setTelresponsavel(retorno[0].cab.telresponsavel || '');
        setResponsavel(retorno[0].cab.responsavel || '');
        setLogradouro(retorno[0].cab.logradouro || '');
        setNumero(retorno[0].cab.numero || '');
        setBairro(retorno[0].cab.bairro || '');
        setCompl(retorno[0].cab.compl || '');
        setCnum(retorno[0].cab.cnum || '');
        setCuf(retorno[0].cab.cuf || '');
        setCep(retorno[0].cab.cep || '');
        setCodconselho(retorno[0].cab.codconselho || '');
        setTelcelularprof(retorno[0].cab.telcelularprof || '');
        setDetalhes(retorno[0].det || [])
        setListaDocumentos(retorno[0].doc || [])
      })
      .catch((erro) => {
        setLoading(false);
        const msg = erro.response?.data.message === undefined ? erro.message : erro.response.data.message;
        toast.error(erro.response.data || erro.statusText || erro.message);
      });
  };
  useEffect(() => {
    if (idprontuario !== "novo") {
      carregar(url_CarregarDados_ID, idprontuario || '', id_clinica);
    }
  }, []);

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS
  const atualizarDados = async () => {
    const dados = {
      id_clinica: id_clinica,
      id_prontuario,
      id_paciente,
      id_profissional: selectedProfissional || '',
      encerramento,
      data_encerramento,
      avaliacao,
      motivopsicoterapia: TextoFormatoCaptalize(motivopsicoterapia.toLowerCase()),
      tratamentomedico: TextoFormatoCaptalize(tratamentomedico.toLowerCase()),
      usamedicacao: TextoFormatoCaptalize(usamedicacao.toLowerCase()),
      possuidiagnostico: TextoFormatoCaptalize(possuidiagnostico.toLowerCase()),
      percepcoes, //: TextoFormatoCaptalize(percepcoes.toLowerCase()),
      cpf,
      // nome: TextoFormatoCaptalize(nome.toLowerCase()),
      nome: TextoFormatoCaptalize(nome ? nome.toLowerCase() : ''),
      dtnascimento,
      telfixo,
      telcelular,
      email: TextoFormatoLowerCase(email.toLowerCase()),
      telemerggencia,
      contemerggencia,
      telresponsavel,
      responsavel: TextoFormatoCaptalize(responsavel.toLowerCase()),
      logradouro: TextoFormatoCaptalize(logradouro.toLowerCase()),
      numero: TextoFormatoCaptalize(numero.toLowerCase()),
      bairro: TextoFormatoCaptalize(bairro.toLowerCase()),
      compl: TextoFormatoCaptalize(compl.toLowerCase()),
      cnum,
      cuf,
      cep,
      // detalhes: ocorrencia,
      detalhes: detalhes,
    }

    ApiPut(`/prontuario/atualizar`, dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        toast.error(`Erro ao atualizar. Motivo: ${erro.response.data.message}`);
      });
  };
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS
  const incluirDados = () => {
    const dados = {
      id_clinica: id_clinica,
      id_prontuario,
      id_paciente,
      id_profissional: selectedProfissional || '',
      encerramento,
      data_encerramento,
      avaliacao,
      motivopsicoterapia: TextoFormatoCaptalize(motivopsicoterapia.toLowerCase()),
      tratamentomedico: TextoFormatoCaptalize(tratamentomedico.toLowerCase()),
      usamedicacao: TextoFormatoCaptalize(usamedicacao.toLowerCase()),
      possuidiagnostico: TextoFormatoCaptalize(possuidiagnostico.toLowerCase()),
      percepcoes, //: TextoFormatoCaptalize(percepcoes.toLowerCase()),
      cpf,
      // nome: TextoFormatoCaptalize(nome.toLowerCase()),
      nome: TextoFormatoCaptalize(nome ? nome.toLowerCase() : ''),
      dtnascimento,
      telfixo,
      telcelular,
      email: TextoFormatoLowerCase(email.toLowerCase()),
      telemerggencia,
      contemerggencia,
      telresponsavel,
      responsavel: TextoFormatoCaptalize(responsavel.toLowerCase()),
      logradouro: TextoFormatoCaptalize(logradouro.toLowerCase()),
      numero: TextoFormatoCaptalize(numero.toLowerCase()),
      bairro: TextoFormatoCaptalize(bairro.toLowerCase()),
      compl: TextoFormatoCaptalize(compl.toLowerCase()),
      cnum,
      cuf,
      cep,
      // detalhes: ocorrencia,
      detalhes: detalhes,
      docs: ListaDocumentos,
      usuario_cadastro: getSessionData("NomeUsuario")
    };
    ApiPost("/prontuario/criar", dados)
      .then((retorno) => {
        toast.success(retorno);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {

        const msg = erro.response?.data.message === undefined ? erro.message : erro.response.data.message;
        toast.error(erro.response.data || erro.statusText || erro.message);
      });
  };
  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (idprontuario === "novo") {
      incluirDados();
      toast.info("Inserindo novo registro...");
    } else {
      atualizarDados();
      toast.info("Atualizando dados do registro...");
    }
  };

  //Detalhes
  const [dadosItens, setDadosItens] = useState({
    id_clinica: "",
    id_prontuario: "",
    id_prontuario_det: "",
    data_ocorrencia: "",
    ocorrencia: "",
    acao: "",
    medicamento_prescrito: ""
  });

  const colunasDetalhes: ITabela[] = [
    { titulo: "Data Evolução", acesso: "data_ocorrencia_formatada" },
    { titulo: "Profissional", acesso: "nome" },
  ];

  const colunasDocumentos: ITabela[] = [
    { titulo: "Data Adição", acesso: "cadastro" },
    { titulo: "Documento", acesso: "documento" },
  ];


  const [imagemModal, setImagemModal] = useState<string>('');
  const visualizarImagem = (dados: any) => {
    const imagem = dados.imagem;
    setImagemModal(imagem); // Use um estado para armazenar a imagem no componente pai
    setShowModalDocumentos(true);
  }

  const visualizar = (dadosVisualizar: any) => {
    setDadosItens((state) => {
      return {
        ...state,
        id_clinica: dadosVisualizar.id_clinica,
        id_prontuario: dadosVisualizar.id_prontuario,
        id_prontuario_det: dadosVisualizar.id_prontuario_det,
        data_ocorrencia: dadosVisualizar.data_ocorrencia_formatada,
        ocorrencia: dadosVisualizar.ocorrencia,
        medicamento_prescrito: dadosVisualizar.medicamento_prescrito,
        acao: "Visualizar",
      };
    });
    setShowModalDetalhe(true);
  };

  const editar = (dadosEditar: any) => {
    setDadosItens((state) => {
      return {
        ...state,
        id_clinica: dadosEditar.id_clinica,
        id_prontuario: dadosEditar.id_prontuario,
        id_prontuario_det: dadosEditar.id_prontuario_det,
        data_ocorrencia: dadosEditar.data_ocorrencia_formatada,
        ocorrencia: dadosEditar.ocorrencia,
        medicamento_prescrito: dadosEditar.medicamento_prescrito,
        acao: "EditarItem",
      };
    });
    setShowModalDetalhe(true);
  };

  const excluir = async (dados: any) => {
    const idprontuario = dados.id_prontuario;
    const id_clinica = dados.id_clinica;
    const id_documento = dados.id_documento;
    await showConfirmationDialog(
      "Excluir Documento",
      `Tem certeza que deseja excluir o documento \n ${dados.documento}?`,
      "question",
      "Excluir",
      "Cancelar"
    )
      .then((result) => {
        if (result.confirmed) {
          const dados = {
            url: "/prontuario/excluirdocumento/:idprontuario/:id_clinica/:id_documento",
            parametros: {
              idprontuario: idprontuario,
              id_clinica: id_clinica,
              id_documento: id_documento,
            },
          };
          ApiDelete(dados)
            .then((retorno) => {
              toast.success(retorno.message);
              carregar(url_CarregarDados_ID, idprontuario || '', id_clinica);
            })
            .catch((erro) => {
              toast.error(
                `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
              );
            });
        }
      })
      .catch((erro) => {
        toast.error(
          `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
        );
      });
  };

  //#region Controla o estado do modal para adicionar detalhes a tabela
  const [showModalDetalhe, setShowModalDetalhe] = useState<boolean>(false);
  const [showModalDocumentos, setShowModalDocumentos] = useState<boolean>(false);
  //#endregion

  const handleOpenModal = () => {
    //Limpar Campos do Modal
    setDadosItens({
      id_clinica: "",
      id_prontuario: "",
      id_prontuario_det: "",
      data_ocorrencia: "",
      ocorrencia: "",
      acao: "Novo",
      medicamento_prescrito: ""
    });

    setShowModalDetalhe(true);
  };

  const handleCloseModal = () => {
    // Simulação: Buscar dados de um GET ou preparar valores iniciais
    setShowModalDetalhe(false);
  };

  const handleCloseModalDocumentos = () => {
    setShowModalDocumentos(false);
  };
  /*
    const colunasDetalhes: ITabela[] = [
    { titulo: "Data Evolução", acesso: "data_ocorrencia_formatada" },
    { titulo: "Profissional", acesso: "nome" },
  ];
  */
  const handleDetalhe = (novoDetalhe: any) => {

    if (params.id == "novo" && selectedProfissional != "") {
      // Se não existir, adiciona um novo detalhe
      const profissionalDetalhes: any = profissionalOptions.find(function (profissional: any) {

        return profissional.value == selectedProfissional
      })
      setDetalhes([...detalhes, {
        id_controle: detalhes.length + 1,
        id_clinica: id_clinica,
        id_prontuario: id_prontuario,
        nome: profissionalDetalhes.label,
        id_prontuario_det: "Novo",
        data_ocorrencia_formatada: novoDetalhe.data_ocorrencia,
        medicamento_prescrito: novoDetalhe.medicamento_prescrito,
        ocorrencia: TextoFormatoCaptalize(novoDetalhe.ocorrencia.toLowerCase())
      }])
    }
    else if (params.id != "novo" && selectedProfissional != "" && novoDetalhe.id_prontuario_det == "") {
      //criar novo detalhe para prontuario ja existente
      const profissionalDetalhes: any = profissionalOptions.find(function (profissional: any) {

        return profissional.value == selectedProfissional
      })

      const dados = {
        id_controle: detalhes.length + 1,
        id_clinica: id_clinica,
        id_prontuario: id_prontuario,
        nome: profissionalDetalhes.label,
        id_prontuario_det: "Novo",
        data_ocorrencia_formatada: novoDetalhe.data_ocorrencia,
        ocorrencia: TextoFormatoCaptalize(novoDetalhe.ocorrencia.toLowerCase()),
        selectedProfissional,
        medicamento_prescrito: novoDetalhe.medicamento_prescrito,
        user_cadastro: getSessionData("NomeUsuario")
      }

      axios.post(`${process.env.REACT_APP_API_BASE_URL}/add/new/det`, dados)
        .then(function (resposta) {

          toast.success(resposta.data)
          carregar(url_CarregarDados_ID, id_prontuario || '', id_clinica);
        }).catch(function (erro) {

          toast.error(erro.response.data || erro.statusText || erro.message)
        })
    }
    else if (params.id != "novo" && selectedProfissional != "" && novoDetalhe.id_prontuario_det != "Novo") {

      const dados = {
        id_controle: detalhes.length + 1,
        id_clinica: id_clinica,
        id_prontuario: id_prontuario,
        id_prontuario_det: novoDetalhe.id_prontuario_det,
        data_ocorrencia_formatada: novoDetalhe.data_ocorrencia,
        ocorrencia: TextoFormatoCaptalize(novoDetalhe.ocorrencia.toLowerCase()),
        medicamento_prescrito: novoDetalhe.medicamento_prescrito,
        selectedProfissional,
        user_cadastro: getSessionData("NomeUsuario")
      }


      axios.put(`${process.env.REACT_APP_API_BASE_URL}/att/new/det`, dados)
        .then(function (resposta) {

          toast.success(resposta.data)
          carregar(url_CarregarDados_ID, id_prontuario || '', id_clinica);
        }).catch(function (erro) {

          toast.error(erro.response.data || erro.statusText || erro.message)
        })
    }
    else {

      toast.error("Selecione um profissional.")
    }
    /*const idDetalheEditar = novoDetalhe.id_prontuario_det;

    // Verifica se já existe um detalhe com o mesmo id_prontuario_det
    const detalheExistente = detalhes.find(det => det.id_prontuario_det === idDetalheEditar);

    if (detalheExistente) {
      // Se o detalhe existir, atualiza suas propriedades
      const detalhesAtualizados = detalhes.map(det =>
        det.id_prontuario_det === idDetalheEditar ? { ...det, ...novoDetalhe } : det
      );

      setDetalhes(detalhesAtualizados);
    } else {;

      setDetalhes([...detalhes, novoDetalheFormatado]);
    }*/
  };

  //#region ==> React Dropzone
  interface FileData {
    file: File;
    id: string;
  }

  interface Base64Data {
    id: string;
    base64: string;
  }

  const [files, setFiles] = useState<FileData[]>([]);
  const [base64Data, setBase64Data] = useState<Base64Data[]>([]);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    try {
      const updatedFiles = acceptedFiles.map((file) => ({
        file,
        id: Math.random().toString(),
      }));

      setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);

      const promises = acceptedFiles.map((file) => {
        return new Promise<Base64Data>((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve({
              id: Math.random().toString(),
              base64: reader.result?.toString().split(',')[1] || '',
            });
          };
          reader.readAsDataURL(file);
        });
      });

      const base64Results = await Promise.all(promises);

      setBase64Data((prevBase64Data) => [...prevBase64Data, ...base64Results]);

      const postData = base64Results.map((data) => ({
        id_clinica: id_clinica,
        id_prontuario: id_prontuario,
        cadastro: new Date().toISOString(),
        documento: data.id + '.jpg',  // Pode ajustar a lógica para nomear os documentos
        imagem: data.base64,
      }));

      if (id_prontuario !== 'novo') {
        const response = await axios.post(Api + '/prontuario/criardocumento', postData)
        toast.success('Registro(s) inserido com sucesso!');
        setDocs((prevDocs) => [...prevDocs, ...postData]); // Atualiza a tabela de documentos mantendo os documentos anteriores e atualiza o estado com os novos documentos))
        carregar(url_CarregarDados_ID, id_prontuario || '', id_clinica);
      } else {
        setDocs(postData); // Atualiza a tabela de documentos mantendo os documentos anteriores e atualiza o estado com os novos documentos)
      }

      // Limpa os estados após o envio bem-sucedido, se necessário.
      setFiles([]);
      setBase64Data([]);

    } catch (error) {
      // Lida com erros durante a solicitação POST.
      console.error('Erro ao enviar os dados:', error);

      toast.error(`Erro ao gravar dados. Motivo:`);
    }
  }, [id_clinica, id_prontuario]);

  const handleDelete = (id: string) => {
    const updatedFiles = files.filter((file) => file.id !== id);
    setFiles(updatedFiles);

    const updatedBase64Data = base64Data.filter((data) => data.id !== id);
    setBase64Data(updatedBase64Data);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const fileItemStyles: React.CSSProperties = {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px',
    border: '1px solid #ddd',
    borderRadius: '4px',
  };
  //#endregion 

  const [ListaDocumentos, setListaDocumentos] = useState<any>([])
  const dropzoneStyles: React.CSSProperties = {
    border: '2px dashed #ffa500 ',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: '20px',
  };

  const colunas4: ITabela[] = [
    { titulo: "ID", acesso: "id_documento" },
    { titulo: "Tipo", acesso: "tipo_doc" },
    { titulo: "Nome", acesso: "nome_documento" }
  ]

  const params = useParams()
  async function visulizarArquivo(dado: any) {

    const byteCharacters = atob(dado.arquivo_base64.split(",")[1])
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: dado.arquivo_base64.split(";")[0].split(":")[1] })

    FileSaver.saveAs(blob, dado.nome_documento)
  }

  async function excluirArquivo(dado: any) {

    try {

      await showConfirmationDialog("Excluir Arquivo ?",
        "Confirmar?", "question", "Confirmar", "Cancelar").then(async function (resposta) {

          if (resposta.confirmed) {
            setLoading(true)

            if (params.id == "novo") {

              const novaLista = ListaDocumentos.filter(function (doc: any) {

                return doc.id_controle != dado.id_controle
              })

              setListaDocumentos(novaLista)
              setLoading(false)
            }
            else {
              const resposta = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/prontuario/excluirdocumento/${dado.id_prontuario}/${dado.id_clinica}/${dado.id_documento}`, {
                headers: {
                  Authorization: getSessionData("")
                }
              })
              setLoading(false)
              toast.success(resposta.data)
              carregar(url_CarregarDados_ID, idprontuario || '', id_clinica);
            }
          }
        })
    } catch (error: any) {

      toast.error(error.response || error.statusText || error)
    }
  }

  const [tipo_doc, set_tipo_doc] = useState("")
  const [nome_documento, set_nome_documento] = useState("")
  const [arquivoBase64, set_arquivoBase64] = useState<any>("")

  function ImportarArquivo(file: any) {

    set_nome_documento(file.name.split('.')[0])
    set_tipo_doc(file.name.split('.')[1])

    if (file) {
      const reader = new FileReader()
      reader.onloadend = function () {

        set_arquivoBase64(reader.result)
      }

      reader.readAsDataURL(file)
    }
  }

  async function InserirArquivoNoBancoOuArrayTemporario() {

    if (params.id == "novo" && nome_documento != "") {

      setListaDocumentos([...ListaDocumentos, {
        id_controle: ListaDocumentos.length + 1,
        id_documento: "Novo",
        nome_documento: nome_documento,
        tipo_doc: tipo_doc,
        arquivo_base64: arquivoBase64
      }])

      set_tipo_doc("")
      set_nome_documento("")
    }
    else if (params.id != "novo" && nome_documento != "") {

      const dados = {
        nome_documento: nome_documento,
        tipo_doc: tipo_doc,
        arquivo_base64: arquivoBase64,
        id_clinica,
        id_prontuario,
        usuario_cadastro: getSessionData("NomeUsuario")
      }

      axios.post(`${process.env.REACT_APP_API_BASE_URL}/prontuario/criardocumento`, dados)
        .then(function (resposta) {

          toast.success(resposta.data)
          carregar(url_CarregarDados_ID, idprontuario || '', id_clinica);
        }).catch(function (erro) {

          toast.error(erro.response.data || erro.statusText || erro.message)
        })
    }
    else {

      toast.error("Importe um arquivo.")
    }
  }

  async function CarregarDocs(id_cliente: any, id_filial: any) {

    try {
      setLoading(true)
      const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/docs/${id_cliente}/${id_filial}`, {
        headers: {
          Authorization: getSessionData("MultfilialWebToken")
        }
      })
      setLoading(false)
      setListaDocumentos(resposta.data)
    } catch (error: any) {
      setLoading(false)
      toast.error(error.response || error.statusText || error)
    }
  }


  const [AllEvolucoes, setAllEvolucoes] = useState(false)
  const [ListaEvolucoes2, setListaEvolucoes2] = useState([])

  const colunasDetalhes2: ITabela[] = [
    { titulo: "Data de Criação", acesso: "data_cadastro_formatada" },
    { titulo: "Data Ocorrencia", acesso: "data_ocorrencia_formatada" },
    { titulo: "Profissional", acesso: "nome" },
    { titulo: "Ocorrência", acesso: "ocorrencia" },
    { titulo: "Medicamento", acesso: "medicamento_prescrito" }
  ];

  function CarregarTodasEvolucoes() {

    const dados = {
      id_clinica,
      id_prontuario
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/carregar/evolucoes/pront`, dados)
      .then(function (resposta) {

        setListaEvolucoes2(resposta.data)
        setAllEvolucoes(true)
      }).catch(function (erro) {

        toast.error(erro.response.data || erro.statusText || erro.message)
      })
  }


  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Box sx={{ width: "100%" }}>
              <TabContext value={guia}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={capturaGuia}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#000",
                        color: "#000",
                      },
                    }}
                  >
                    <Tab label="Dados" value="1" />
                    <Tab label="Dados Complementares" value="2" />
                    <Tab label="Documentos" value="3" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="ID Prontuário"
                        name="id_prontuario"
                        type="text"
                        placeholder="ID Prontuário"
                        readonly
                        value={id_prontuario || ""}
                        onChange={setId_prontuario}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <SelectInput
                        value={selectedProfissional || ''}
                        // onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedProfissional(e.target.value)}
                        onChange={handleChangeProfissional}
                        options={profissionalOptions}
                        placeholder="Profissional"
                      // disabed

                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Codigo Conselho"
                        name="codconselho"
                        type="text"
                        placeholder="Codigo Conselho"
                        required
                        readonly
                        value={codconselho || ""}
                        onChange={setCodconselho}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Tel. Celular Profissional"
                        name="telcelularprof"
                        type="text"
                        placeholder="Tel. Celular Profissional"
                        required
                        readonly
                        value={mascaraTelefoneCelular(telcelularprof) || ""}
                        onChange={setTelcelularprof}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="CPF Paciente"
                        name="cpf"
                        type="text"
                        placeholder="CPF"
                        required
                        readonly
                        value={cpf || ""}
                        onChange={setCpf}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <DatalistInput
                        placeholder="Digite 3 caracteres para filtrar..."
                        label="Paciente"
                        showLabel={true}
                        onChange={(item: any) => buscarPacientes(item)}
                        onSelect={(item: any) => selecionaPaciente(item)}
                        items={datalistPaciente}
                        value={nome || ""}
                        inputProps={{
                          readOnly:
                            acao === "Visualizar" || acao === "Editar"
                              ? true
                              : false,
                        }}
                        className="form-control bordasInferiorInput text-black text-capitalize "
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Email"
                        name="email"
                        type="text"
                        placeholder="Email"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={email || ""}
                        onChange={setEmail}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Telefone"
                        name="telefone"
                        type="text"
                        placeholder="Telefone Fixo"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={mascaraTelefoneFixo(telfixo) || ""}
                        onChange={setTelfixo}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Celular"
                        name="telcelular"
                        type="text"
                        placeholder="Celular"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={mascaraTelefoneCelular(telcelular) || ""}
                        onChange={setTelcelular}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="CEP"
                        name="cep"
                        type="text"
                        placeholder="CEP"
                        readonly={acao === "Visualizar" ? true : false}
                        value={mascaraCEP(cep) || ""}
                        onChange={setCep}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Logradouro"
                        name="endereco"
                        type="text"
                        placeholder="Logradouro"
                        readonly={acao === "Visualizar" ? true : false}
                        value={logradouro || ""}
                        onChange={setLogradouro}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Complemento"
                        name="compl"
                        type="text"
                        placeholder="Complemento"
                        readonly={acao === "Visualizar" ? true : false}
                        value={compl || ""}
                        onChange={setCompl}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Número"
                        name="numero"
                        type="text"
                        placeholder="Número"
                        readonly={acao === "Visualizar" ? true : false}
                        value={numero || ""}
                        onChange={setNumero}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Bairro"
                        name="bairro"
                        type="text"
                        placeholder="Bairro"
                        readonly={acao === "Visualizar" ? true : false}
                        value={bairro || ""}
                        onChange={setBairro}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>

                  <hr />
                  <Row>
                    <Col sm={2}>
                      <ButtonCustom
                        type="button"
                        className="btn btn-light text-bg-dark bg-white border-0"
                        descricaoBotao="Incluir Evolução"
                        icone={mdiPlus}
                        onclick={handleOpenModal}
                      />
                    </Col>
                    <Col sm={4}>
                      <ButtonCustom
                        type="button"
                        className="btn btn-light text-bg-dark bg-white border-0"
                        descricaoBotao="Visualizar Todas Evoluções"
                        disabled={id_prontuario == "novo" ? true : false}
                        icone={mdiEye}
                        onclick={CarregarTodasEvolucoes}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Tabela
                      coluna={colunasDetalhes}
                      itemsPerPage={5}
                      dados={detalhes}
                      usaDelete={false}
                      onDeleteClick={() => { }}
                      onVisuClick={visualizar}
                      onEditClick={editar}
                      onImprimirClick={() => { }}
                      usaImprimir={false}
                    />
                  </Row>
                </TabPanel>
                <TabPanel value="2">
                  <Row>
                    <Col sm={12}>
                      <FormLabel className="text-black">O que motivou buscar atendimento? Já faz algum tratamento médico? Faz uso de medicação? Possui algum diagnostico ?</FormLabel> {""}
                      {/* <FormLabel className="text-black">Faz tratamento médico?</FormLabel> */}
                    </Col>

                    {/* <Col sm={6}>
                      <InputSemBorda
                        label="O que o motivou a buscar a psicoterapia?"
                        name="motivopsicoterapia"
                        type="textarea"
                        placeholder="Motivo Psicoterapia"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={motivopsicoterapia || ""}
                        onChange={setMotivopsicoterapia}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize custom-textarea"
                        rows={20}
                      />
                    </Col> */}
                    {/* <Col sm={6}>
                      <InputSemBorda
                        label="Faz tratamento médico?"
                        name="tratamentomedico"
                        type="textarea"
                        placeholder="Faz tratamento médico?"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={tratamentomedico || ""}
                        onChange={setTratamentomedico}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize custom-textarea"
                        rows={20}
                      />
                    </Col> */}
                  </Row>
                  {/* <Row>
                    <Col sm={6}>
                      <InputSemBorda
                        label="Faz uso de medicação?"
                        name="usamedicacao"
                        type="textarea"
                        placeholder="Faz uso de medicação?"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={usamedicacao || ""}
                        onChange={setUsamedicacao}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize custom-textarea"
                        rows={20}
                      />
                    </Col>
                    <Col sm={6}>
                      <InputSemBorda
                        label="Possui um diagnóstico?"
                        name="possuidiagnostico"
                        type="textarea"
                        placeholder="Possui um diagnóstico?"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={possuidiagnostico || ""}
                        onChange={setPossuidiagnostico}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize custom-textarea"
                        rows={20}
                      />
                    </Col>
                  </Row> */}
                  <Row>
                    <Col sm={12}>
                      <InputSemBorda
                        label="Avaliação"
                        name="avaliacao"
                        type="textarea"
                        placeholder="Avaliação"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={avaliacao || ""}
                        onChange={setAvaliacao}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black custom-textarea"
                        rows={20}
                        height="300px" // Defina a altura desejada aqui
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={10}>
                      <InputSemBorda
                        label="Encerramento / Encaminhamento"
                        name="encerramento"
                        type="textarea"
                        placeholder="Encerramento / Encaminhamento"
                        readonly={acao === "Visualizar" ? true : false}
                        value={encerramento || ""}
                        onChange={setEncerramento}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black custom-textarea"
                        required={data_encerramento ? true : false}
                        rows={20}
                        height="200px" // Defina a altura desejada aqui
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Data Encerramento"
                        name="dtencerramento"
                        type="date"
                        placeholder="Data Encerramento"
                        readonly={acao === "Visualizar" ? true : false}
                        value={data_encerramento || ""}
                        onChange={setData_encerramento}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                        required={encerramento ? true : false}
                      />
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel value="3">
                  <Box sx={{ width: "100%" }}>
                    <div className="conatiner">

                      <input type="file" name="" id="FilesFiles" style={dropzoneStyles} onChange={function (e: any) {

                        ImportarArquivo(e.target.files[0])
                      }} />
                      <div className="row">
                        <div className="col-sm col-md col-lg text-center">
                          <button type="button" className="btn btn-secondary pt-1 pb-1" onClick={function () {

                            //insert do documento no banco
                            InserirArquivoNoBancoOuArrayTemporario()

                          }}><i className="bi bi-download fs-4 me-1"></i>Salvar</button>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="container">
                      <div className="row">
                        <Row>
                          <Tabela
                            coluna={colunas4}
                            dados={ListaDocumentos}
                            itemsPerPage={10}
                            iconeVisualizar={mdiDownload}
                            onVisuClick={visulizarArquivo}
                            onEditClick={function () {

                            }}
                            onDeleteClick={excluirArquivo}
                            id="codigo"
                            usaEdit={false}
                            usaDelete={true}
                            usaVisu={true}
                            usaImprimir={false}
                            onImprimirClick={function () {
                            }}
                          />
                        </Row>
                      </div>
                    </div>
                  </Box>
                </TabPanel>
              </TabContext>
            </Box>

          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <ButtonCustom
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
                disabled={acao === "Visualizar" ? true : false}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>
      <ModalUploadImage
        show={showUpload}
        label="Realizando Upload da Imagem ..."
      />
      <ModalProntuario showModal={showModalDetalhe}
        onClose={handleCloseModal}
        onSubmit={handleDetalhe}
        acao={dadosItens.acao}
        dadosId_Prontuario={dadosItens.id_prontuario}
        dadosId_Prontuario_Det={dadosItens.id_prontuario_det}
        dadosData_Ocorrencia={dadosItens.data_ocorrencia}
        dadosOcorrencia={dadosItens.ocorrencia}
        medicamento_prescrito={dadosItens.medicamento_prescrito}
      />
      <ModalDocumentos
        showModal={showModalDocumentos}
        onClose={handleCloseModalDocumentos}
        dadosImagem={imagemModal}
      />
      <ModalLoading show={loading} label="Carregado dados do prontuario ..." />



      <Modal centered={true} size="lg" show={AllEvolucoes} onHide={function () {
        setAllEvolucoes(false)
      }}>
        <Modal.Header closeButton className="bg-white">
          <Modal.Title>Listagem de Todas as Evoluções</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          <Row>
            <Tabela
              coluna={colunasDetalhes2}
              itemsPerPage={30}
              dados={ListaEvolucoes2}
              usaDelete={false}
              onDeleteClick={() => { }}
              onVisuClick={visualizar}
              onEditClick={editar}
              onImprimirClick={() => { }}
              usaImprimir={false}
            />
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default FormProntuario;
