import ApiGet from "../../../../../api/endPoints/useGet";

const CarregarDados = (url: string, id_sequencial: string, id_clinica: string): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    try {
      const dados = {
        url: url,
        parametros: {
          id_clinica: id_clinica,
          id_sequencial: id_sequencial,
        },
      };
      ApiGet(dados)
        .then((retorno) => {
          resolve(retorno);
        })
        .catch((erro) => {
          reject(erro);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export default CarregarDados;
