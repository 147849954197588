import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import FormProcedimento from "./components/formulario";

export function ProcedimentosVisualizar() {
  const idprocedimento = useParams();
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Visualizar Procedimento" />
        <FormProcedimento idprocedimento={idprocedimento.id} acao="Visualizar" />
      </div>
    </>
  );
}
