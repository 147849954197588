import "./index.css";
import Rotas from "./router/routes";

function App() {
  return (
    <div className="App">
      <div className="wrapper">
        <Rotas />;
      </div>
    </div>
  );
}

export default App;
