import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo";
import FormAnamnese from "./components/formulario";

export function AnamneseIncluir() {
  const idanamnese = useParams();
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Incluir Anamnese" />
        <FormAnamnese idanamnese={idanamnese.id} acao="Novo" />
      </div>
    </>
  );
}
