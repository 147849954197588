import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../../../../components/Formularios/Modal/ModalLoading";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Card, Col, Form, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../../components/Formularios/Inputs/InputsSemBorda";

import {
  Box,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { Button as ButtonCustom } from "../../../../../../components/Formularios/Buttons/Button";
import { mdiCheckCircle, mdiKeyboardReturn } from "@mdi/js";
import ApiPost from "../../../../../../api/endPoints/usePost";
import ApiPut from "../../../../../../api/endPoints/usePut";
import CarregarDados from "../CarregarDados";
// import { InterfaceDadosEmpresa } from "../../../../../types/EmpresaInteface";
import { url_CarregarDados_ID } from "../auxiliar";
import { dadosUF } from "../../../../../../components/dadosFixos/UF";
import { TextoFormatoCaptalize } from "../../../../../../components/Auxiliar/ConvTextoCaptalize";
import { TextoFormatoLowerCase } from "../../../../../../components/Auxiliar/ConvTextoLower";
// import useSelectOptions from "../../../../../../hooks/useSelectOptions";
import { getSessionData } from "../../../../../../utils/storageUtils";
import axios from "axios";
import {
  mascaraCEP,
  mascaraCNPJ,
  mascaraTelefoneCelular,
  mascaraTelefoneFixo,
} from "../../../../../../hooks/mascaras";



interface FormConveniosProps {
  idconvenio?: string;
  acao?: string;
}

const FormConvenios = ({ idconvenio, acao }: FormConveniosProps) => {
  const navigate = useNavigate();
  const id_clinica = getSessionData("DadosIdClinica") || "";

  const [id_convenio, setId_convenio] = useState<any>(idconvenio || "");
  const [status, setStatus] = useState<boolean>(true);
  const [cnpj, setCnpj] = useState<string>("");
  const [fantasia, setFantasia] = useState<string>("");
  const [razao_social, setRazao_social] = useState<string>("");
  const [telfixo, setTelfixo] = useState<string>("");
  const [telcelular, setTelcelular] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [logradouro, setLogradouro] = useState<string>("");
  const [numero, setNumero] = useState<string>("");
  const [bairro, setBairro] = useState<string>("");
  const [compl, setCompl] = useState<string>("");
  const [cnum, setCnum] = useState<string>("");
  const [cidade, setCidade] = useState<string>("");
  const [cuf, setCuf] = useState<string>("");
  const [cep, setCep] = useState<string>("");
  const [ans, setAns] = useState<string>("");
  const [prazo_pag, setPrazo_pag] = useState<string>("");
  const [integra_autoriza, setIntegra_autoriza] = useState<boolean>(true);
  const [integra_fatura, setIntegra_fatura] = useState<boolean>(true);
  const [lote_fatura, setLote_fatura] = useState<string>("");
  const [observacao, setObservacao] = useState<string>("");
  const [secret_cod, setSecret_cod] = useState<string>("");
  const [secret_key, setSecret_key] = useState<string>("");
  const [cod_cadastro, setCod_cadastro] = useState<string>("");
  const [versao_tiss, setVersao_tiss] = useState<string>("");
  const [cadastro, setCadastro] = useState<string>("");
  const [usuario_cadastro, setUsuario_cadastro] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  //#region ==> Select Options
  // Usando o hook useSelectOptions para obter as opções diretamente
  // const tipoContratacaoOptions = useSelectOptions(
  //   `/clinica/listartipocontratacao`
  // );
  // const [selectedTipoContratacao, setSelectedTipoContratacao] = useState("");

  //#endregion

  const carregar = (url: string, idconvenio: any, id_clinica: string) => {
    setLoading(true);
    CarregarDados(url, idconvenio, id_clinica)
      .then((retorno) => {
        setLoading(false);

        setId_convenio(retorno[0].id_convenio || "");
        setStatus(retorno[0].status === 1 ? true : false);
        setCnpj(retorno[0].cnpj || "");
        setFantasia(retorno[0].fantasia || "");
        setRazao_social(retorno[0].razao_social || "");
        setTelfixo(retorno[0].telfixo || "");
        setTelcelular(retorno[0].telcelular || "");
        setEmail(retorno[0].email || "");
        setLogradouro(retorno[0].logradouro || "");
        setNumero(retorno[0].numero || "");
        setBairro(retorno[0].bairro || "");
        setCompl(retorno[0].compl || "");
        setCnum(retorno[0].cnum || "");
        setCidade(retorno[0].cidade || "");
        setCuf(retorno[0].cuf || "");
        setCep(retorno[0].cep || "");
        setAns(retorno[0].ans || "");
        setPrazo_pag(retorno[0].prazo_pag || "");
        setIntegra_autoriza(retorno[0].integra_autoriza === 1 ? true : false);
        setIntegra_fatura(retorno[0].integra_fatura === 1 ? true : false);
        setLote_fatura(retorno[0].lote_fatura || "");
        setObservacao(retorno[0].observacao || "");
        setSecret_cod(retorno[0].secret_cod || "");
        setSecret_key(retorno[0].secret_key || "");
        setCod_cadastro(retorno[0].cod_cadastro || "");
        setVersao_tiss(retorno[0].versao_tiss || "");
        setCadastro(retorno[0].cadastro || "");
        setUsuario_cadastro(retorno[0].usuario_cadastro || "");
        // Campos select options aqui abaixo é um exemplo
        // setSelectedTipoContratacao(retorno[0].tipocontratacao || '');
      })
      .catch((erro) => {
        setLoading(false);
        console.log("erro: ", erro);
      });
  };
  useEffect(() => {
    if (idconvenio !== "novo") {
      carregar(url_CarregarDados_ID, idconvenio, id_clinica);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS
  const atualizarDados = async (
    id_convenio: string,
    id_clinica: string,
    status: string,
    cnpj: string,
    fantasia: string,
    razao_social: string,
    telfixo: string,
    telcelular: string,
    email: string,
    logradouro: string,
    numero: string,
    bairro: string,
    compl: string,
    cnum: string,
    cidade: string,
    cuf: string,
    cep: string,
    ans: string,
    prazo_pag: string,
    integra_autoriza: string,
    integra_fatura: string,
    lote_fatura: string,
    observacao: string,
    secret_cod: string,
    secret_key: string,
    cod_cadastro: string,
    versao_tiss: string,
    cadastro: string,
    usuario_cadastro: string
  ) => {
    const dados = {
      id_convenio,
      id_clinica,
      status,
      cnpj,
      fantasia: TextoFormatoCaptalize(fantasia.toLowerCase()),
      razao_social: TextoFormatoCaptalize(razao_social.toLowerCase()),
      telfixo,
      telcelular,
      email: TextoFormatoLowerCase(email.toLowerCase()),
      logradouro: TextoFormatoCaptalize(logradouro.toLowerCase()),
      numero: TextoFormatoCaptalize(numero.toLowerCase()),
      bairro: TextoFormatoCaptalize(bairro.toLowerCase()),
      compl: TextoFormatoCaptalize(compl.toLowerCase()),
      cnum,
      cidade: TextoFormatoCaptalize(cidade.toLowerCase()),
      cuf,
      cep,
      ans,
      prazo_pag,
      integra_autoriza,
      integra_fatura,
      lote_fatura,
      observacao: TextoFormatoCaptalize(observacao.toLowerCase()),
      secret_cod,
      secret_key,
      cod_cadastro,
      versao_tiss,
      cadastro,
      usuario_cadastro,
    };

    ApiPut(`/convenio/atualizar`, dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        toast.error(`Erro ao atualizar. Motivo: ${erro.response.data.message}`);
      });
  };
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS
  const gravarDados = (
    id_convenio: string,
    id_clinica: string,
    status: string,
    cnpj: string,
    fantasia: string,
    razao_social: string,
    telfixo: string,
    telcelular: string,
    email: string,
    logradouro: string,
    numero: string,
    bairro: string,
    compl: string,
    cnum: string,
    cidade: string,
    cuf: string,
    cep: string,
    ans: string,
    prazo_pag: string,
    integra_autoriza: string,
    integra_fatura: string,
    lote_fatura: string,
    observacao: string,
    secret_cod: string,
    secret_key: string,
    cod_cadastro: string,
    versao_tiss: string,
    cadastro: string,
    usuario_cadastro: string
  ) => {
    const dados = {
      id_convenio,
      id_clinica,
      status,
      cnpj,
      fantasia: TextoFormatoCaptalize(fantasia.toLowerCase()),
      razao_social: TextoFormatoCaptalize(razao_social.toLowerCase()),
      telfixo,
      telcelular,
      email: TextoFormatoLowerCase(email.toLowerCase()),
      logradouro: TextoFormatoCaptalize(logradouro.toLowerCase()),
      numero: TextoFormatoCaptalize(numero.toLowerCase()),
      bairro: TextoFormatoCaptalize(bairro.toLowerCase()),
      compl: TextoFormatoCaptalize(compl.toLowerCase()),
      cnum,
      cidade: TextoFormatoCaptalize(cidade.toLowerCase()),
      cuf,
      cep,
      ans,
      prazo_pag,
      integra_autoriza,
      integra_fatura,
      lote_fatura,
      observacao: TextoFormatoCaptalize(observacao.toLowerCase()),
      secret_cod,
      secret_key,
      cod_cadastro,
      versao_tiss,
      cadastro,
      usuario_cadastro,
      //tipocontratacao: selectedTipoContratacao,
    };
    ApiPost("/convenio/criar", dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };
  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (idconvenio === "novo") {
      gravarDados(
        id_convenio || "0",
        id_clinica,
        status ? "1" : "0",
        cnpj,
        fantasia,
        razao_social,
        telfixo,
        telcelular,
        email,
        logradouro,
        numero,
        bairro,
        compl,
        cnum,
        cidade,
        cuf,
        cep,
        ans,
        prazo_pag,
        integra_autoriza ? "1" : "0",
        integra_fatura ? "1" : "0",
        lote_fatura,
        observacao,
        secret_cod,
        secret_key,
        cod_cadastro,
        versao_tiss,
        cadastro,
        usuario_cadastro
      );
      toast.info("Inserindo novo registro...");
    } else {
      atualizarDados(
        id_convenio || "0",
        id_clinica,
        status ? "1" : "0",
        cnpj,
        fantasia,
        razao_social,
        telfixo,
        telcelular,
        email,
        logradouro,
        numero,
        bairro,
        compl,
        cnum,
        cidade,
        cuf,
        cep,
        ans,
        prazo_pag,
        integra_autoriza ? "1" : "0",
        integra_fatura ? "1" : "0",
        lote_fatura,
        observacao,
        secret_cod,
        secret_key,
        cod_cadastro,
        versao_tiss,
        cadastro,
        usuario_cadastro
      );
      toast.info("Atualizando dados do registro...");
    }
  };

  const buscarCep = async (cep: string) => {
    const cepSemMascara = cep.replace(/\D/g, "");
    if (cepSemMascara.length < 8) {
      toast.error("CEP inválido!");
    } else if (cepSemMascara.length === 8) {
      await axios
        .get(`https://viacep.com.br/ws/${cepSemMascara}/json/`)
        .then((retorno) => {
          if (retorno.data?.erro) {
            toast.error("CEP inválido ou Não Localizado!");
          } else {
            const dadosCep = retorno.data;

            setLogradouro(dadosCep.logradouro);
            setCompl(dadosCep.complemento);
            setBairro(dadosCep.bairro);
            setCidade(dadosCep.localidade);
            setCuf(dadosCep.uf);
            setCnum(dadosCep.ibge);
          }
        })
        .catch((erro) => {
          console.log("erro: ", erro);
          const msg =
            erro.response?.data.message === undefined
              ? erro.message
              : erro.response.data.message;
          toast.error(`Erro ao buscar cep. Motivo: ${msg}`);
        });
    }
  };

  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Box sx={{ width: "100%" }}>
              {/* <TabContext value={guia}> */}
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
              {/* <TabPanel value="1"> */}
              <Row>
                <Col sm={2}>
                  <InputSemBorda
                    label="ID Convênio"
                    name="id_convenio"
                    type="text"
                    placeholder="ID Convênio"
                    readonly
                    value={id_convenio || ""}
                    onChange={setId_convenio}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
                <Col sm={2}>
                  <InputSemBorda
                    label="CNPJ"
                    name="cnjp"
                    type="text"
                    placeholder="CNPJ"
                    readonly={
                      acao === "Visualizar" || acao === "Editar" ? true : false
                    }
                    // value={cnpj || ""}
                    value={mascaraCNPJ(cnpj) || ""}
                    onChange={setCnpj}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
                <Col sm={4}>
                  <InputSemBorda
                    label="Fantasia"
                    name="fantasia"
                    type="text"
                    placeholder="Fantasia"
                    required
                    readonly={acao === "Visualizar" ? true : false}
                    value={fantasia || ""}
                    onChange={setFantasia}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
                <Col sm={4}>
                  <InputSemBorda
                    label="Razão Social"
                    name="razao_social"
                    type="text"
                    placeholder="Razaão Social"
                    required
                    readonly={acao === "Visualizar" ? true : false}
                    value={razao_social || ""}
                    onChange={setRazao_social}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <InputSemBorda
                    label="Email"
                    name="email"
                    type="text"
                    placeholder="Email"
                    required
                    readonly={acao === "Visualizar" ? true : false}
                    value={email || ""}
                    onChange={setEmail}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                  />
                </Col>
                <Col sm={3}>
                  <InputSemBorda
                    label="Telefone"
                    name="telefone"
                    type="text"
                    placeholder="Telefone Fixo"
                    required
                    readonly={acao === "Visualizar" ? true : false}
                    // value={telfixo || ""}
                    value={mascaraTelefoneFixo(telfixo) || ""}
                    onChange={setTelfixo}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
                <Col sm={3}>
                  <InputSemBorda
                    label="Celular"
                    name="telcelular"
                    type="text"
                    placeholder="Celular"
                    required
                    readonly={acao === "Visualizar" ? true : false}
                    // value={telcelular || ""}
                    value={mascaraTelefoneCelular(telcelular) || ""}
                    onChange={setTelcelular}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
                <Col sm={2}>
                  <br />
                  <label htmlFor="" className="form-label me-2">
                    Ativo
                  </label>
                  <Switch
                    checked={status}
                    onChange={() => setStatus(!status)}
                    disabled={acao === "Visualizar" ? true : false}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <InputSemBorda
                    label="CEP"
                    name="cep"
                    type="text"
                    placeholder="CEP"
                    readonly={acao === "Visualizar" ? true : false}
                    // value={cep || ""}
                    value={mascaraCEP(cep) || ""}
                    onChange={setCep}
                    onBlur={(e: any) => buscarCep(e.target.value)}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
                <Col sm={4}>
                  <InputSemBorda
                    label="Logradouro"
                    name="endereco"
                    type="text"
                    placeholder="Logradouro"
                    readonly={acao === "Visualizar" ? true : false}
                    value={logradouro || ""}
                    onChange={setLogradouro}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
                <Col sm={4}>
                  <InputSemBorda
                    label="Complemento"
                    name="compl"
                    type="text"
                    placeholder="Complemento"
                    readonly={acao === "Visualizar" ? true : false}
                    value={compl || ""}
                    onChange={setCompl}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
                <Col sm={2}>
                  <InputSemBorda
                    label="Número"
                    name="numero"
                    type="text"
                    placeholder="Número"
                    readonly={acao === "Visualizar" ? true : false}
                    value={numero || ""}
                    onChange={setNumero}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <InputSemBorda
                    label="Bairro"
                    name="bairro"
                    type="text"
                    placeholder="Bairro"
                    readonly={acao === "Visualizar" ? true : false}
                    value={bairro || ""}
                    onChange={setBairro}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
                <Col sm={3}>
                  <InputSemBorda
                    label="Cidade"
                    name="cidade"
                    type="text"
                    placeholder="Cidade"
                    required
                    readonly={acao === "Visualizar" ? true : false}
                    value={cidade || ""}
                    onChange={setCidade}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
                <Col sm={2}>
                  <InputSemBorda
                    label="Cód. Município"
                    name="cnum"
                    type="text"
                    placeholder="Cód. Município"
                    required
                    readonly={acao === "Visualizar" ? true : false}
                    value={cnum || ""}
                    onChange={setCnum}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>

                <Col sm={2}>
                  <FormControl
                    variant="standard"
                    sx={{ m: 1.5, width: "100%" }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      UF
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={cuf}
                      onChange={(e: SelectChangeEvent) =>
                        setCuf(e.target.value)
                      }
                      label="UF"
                      required
                      disabled={acao === "Visualizar" ? true : false}
                    >
                      <MenuItem value="">
                        <em>Selecione...</em>
                      </MenuItem>
                      {dadosUF.map((item: any) => (
                        <MenuItem value={item.uf}>{item.nome}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col sm={1}>
                  <InputSemBorda
                    label="ANS"
                    name="ans"
                    type="text"
                    placeholder="Cidade"
                    required
                    readonly={acao === "Visualizar" ? true : false}
                    value={ans || ""}
                    onChange={setAns}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
                <Col sm={2}>
                  <InputSemBorda
                    label="Prazo Pagamento"
                    name="prazo_pag"
                    type="number"
                    placeholder="Cidade"
                    required
                    readonly={acao === "Visualizar" ? true : false}
                    value={prazo_pag || ""}
                    onChange={setPrazo_pag}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <br />
                  <label htmlFor="" className="form-label me-2">
                    Integra Guia / Solicitação
                  </label>
                  <Switch
                    checked={integra_autoriza}
                    onChange={() => setIntegra_autoriza(!integra_autoriza)}
                    disabled={acao === "Visualizar" ? true : false}
                  />
                </Col>
                <Col sm={2}>
                  <br />
                  <label htmlFor="" className="form-label me-2">
                    Integra Faturamentos
                  </label>
                  <Switch
                    checked={integra_fatura}
                    onChange={() => setIntegra_fatura(!integra_fatura)}
                    disabled={acao === "Visualizar" ? true : false}
                  />
                </Col>
                <Col sm={2}>
                  <InputSemBorda
                    label="Lote Fatura"
                    name="lote_fatura"
                    type="number"
                    placeholder="Lote Fatura"
                    required
                    readonly={acao === "Visualizar" ? true : false}
                    value={lote_fatura || ""}
                    onChange={setLote_fatura}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
                <Col sm={3}>
                  <InputSemBorda
                    label="Código Cadastro"
                    name="cod_cadastro"
                    type="text"
                    placeholder="Código Cadastro"
                    required
                    readonly={acao === "Visualizar" ? true : false}
                    value={cod_cadastro || ""}
                    onChange={setCod_cadastro}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
                <Col sm={3}>
                  <InputSemBorda
                    label="Versão TISS"
                    name="versao_tiss"
                    type="text"
                    placeholder="Versão TISS"
                    required
                    readonly={acao === "Visualizar" ? true : false}
                    value={versao_tiss || ""}
                    onChange={setVersao_tiss}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <InputSemBorda
                    label="Secret Código"
                    name="secret_cod"
                    type="text"
                    placeholder="Secret Código"
                    required
                    readonly={acao === "Visualizar" ? true : false}
                    value={secret_cod || ""}
                    onChange={setSecret_cod}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
                <Col sm={6}>
                  <InputSemBorda
                    label="Secret Key"
                    name="secret_key"
                    type="text"
                    placeholder="Secret Key"
                    required
                    readonly={acao === "Visualizar" ? true : false}
                    value={secret_key || ""}
                    onChange={setSecret_key}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <InputSemBorda
                    label="Observação"
                    name="observacao"
                    type="text"
                    placeholder="Observação"
                    required
                    readonly={acao === "Visualizar" ? true : false}
                    value={observacao || ""}
                    onChange={setObservacao}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  />
                </Col>
              </Row>
            </Box>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <ButtonCustom
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
                disabled={acao === "Visualizar" ? true : false}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>
      
      <ModalLoading show={loading} label="Carregado dados da clinica ..." />
    </>
  );
};
export default FormConvenios;
