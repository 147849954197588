import { Col, Form, Modal, Row } from "react-bootstrap";
import { Button } from "../../../../components/Formularios/Buttons/Button";
import { mdiCheckCircle, mdiKeyboardReturn } from "@mdi/js";
import { useEffect, useState } from "react";
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import { Switch } from "@mui/material";

interface DocsModalProps {
    showModal: boolean;
    acao?: string;
    onClose: () => void;
    dadosImagem?: string;
}
const ModalDocumentos: React.FC<DocsModalProps> = ({
    showModal,
    acao,
    onClose,
    dadosImagem,
}) => {
    const [imagem, setImagem] = useState("");

    const carregarDados = () => {
        setImagem(dadosImagem || "");
    };

    useEffect(() => {
        if (showModal) {
            carregarDados();
        }
    }, [showModal]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const dados = {
            imagem: dadosImagem || "",
        };
        setImagem("");
    };

    return (
        <>
            <Modal
                show={showModal}
                className="modal-full-width "
                size="xl"
                keyboard={false}
                centered
            >
                <Modal.Header className="bg-white">
                    <Modal.Title>
                        <div className="d-flex my-2 gap-1 between">
                            <Button
                                type="button"
                                className="btn btn-light text-danger bg-white border-0"
                                descricaoBotao="Voltar"
                                icone={mdiKeyboardReturn}
                                onclick={onClose}
                            />
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Form
                // onSubmit={handleSubmit}
                >
                    <Modal.Body className="bg-white">
                        <Row>
                            <Col sm={12}>
                                {/* Renderizar a imagem aqui */}
                                <img src={`data:image/jpeg;base64,${imagem}`} alt="Imagem" style={{ maxWidth: '100%' }} />
                            </Col>
                        </Row>
                    </Modal.Body>
                    {/* <Modal.Footer className="bg-white">
                        <div className="d-flex my-2 gap-1 justify-content-between">
                            <Button
                                type="button"
                                className="btn btn-light text-danger bg-white border-0"
                                descricaoBotao="Voltar"
                                icone={mdiKeyboardReturn}
                                onclick={onClose}
                            />
                            <Button
                                type="submit"
                                className="btn btn-light text-info-emphasis bg-white border-0"
                                descricaoBotao="Incluir"
                                icone={mdiCheckCircle}
                                onclick={onClose}
                            />
                        </div>
                    </Modal.Footer> */}
                </Form>
            </Modal>
        </>
    );
};
export default ModalDocumentos;
