import { useParams } from "react-router-dom";
import ContainerTitulo from "../../components/Formularios/Container/ContainerTitulo";
import FormProntuario from "./components/formulario";

export function ProntuarioVisualizar() {
  const idprontuario = useParams();
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Visualizar Prontuario" />
        <FormProntuario idprontuario={idprontuario.id} acao="Visualizar" />
      </div>
    </>
  );
}
