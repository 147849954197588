const Api = process.env.REACT_APP_API_BASE_URL?.replace('"', "").replace(
    '";',
    ""
  );

const linkPreCadastro = process.env.REACT_APP_LINK_PRECADASTRO?.replace('"', "").replace(
    '";',
    ""
  );
  
  export { Api, linkPreCadastro };
  