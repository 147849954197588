import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../../../../components/Formularios/Modal/ModalLoading";
import { useState, useEffect, ChangeEvent } from "react";
import { toast } from "react-toastify";
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../../components/Formularios/Inputs/InputsSemBorda";
// import applyCepMask  from "../../../../../../components/Formularios/Inputs/InputComMascara";
import { mascaraCEP, mascaraTelefoneCelular, mascaraTelefoneFixo } from "../../../../../../hooks/mascaras";

import {
  Box,
  Switch,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tab,
  Avatar,
  Stack,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button as ButtonCustom } from "../../../../../../components/Formularios/Buttons/Button";
import {
  mdiCheckCircle,
  mdiKeyboardReturn,
  mdiLockCheckOutline,
} from "@mdi/js";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ApiPost from "../../../../../../api/endPoints/usePost";
import ApiPut from "../../../../../../api/endPoints/usePut";
import ModalUploadImage from "../../../../../../components/Formularios/Modal/ModalUpload";
import { styled } from "@mui/material/styles";
import ConverterBase64 from "../../../../../../components/ConversorBase64";
import CarregarDados from "../CarregarDados";
// import { InterfaceDadosEmpresa } from "../../../../../types/EmpresaInteface";
import { url_CarregarDados_Empresa, url_CarregarDados_ID } from "../auxiliar";
import { dadosUF } from "../../../../../../components/dadosFixos/UF";
import { dadosPais } from "../../../../../../components/dadosFixos/Pais";
import { TextoFormatoCaptalize } from "../../../../../../components/Auxiliar/ConvTextoCaptalize";
import { TextoFormatoLowerCase } from "../../../../../../components/Auxiliar/ConvTextoLower";
import { TextoFormatoUpperCase } from "../../../../../../components/Auxiliar/ConvTextoUpper";
import useSelectOptions from "../../../../../../hooks/useSelectOptions";
import SelectInput from "../../../../../../shared/SelectInputs";
import CarregarDadosEmpresa from "../CarregarEmpresa";
import axios from "axios";
import { getSessionData } from "../../../../../../utils/storageUtils";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F0F0F0",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "#616161",
  borderRadius: "5px",
  fontWeight: "bold",
  fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
}));

interface FormClinicaProps {
  idclinica?: string;
  acao?: string;
}

const FormClinica = ({ idclinica, acao }: FormClinicaProps) => {
  const navigate = useNavigate();
  const isVinculo = getSessionData("isPerfilVinculo") || "0";

  //#region Controla as guias
  const [guia, setGuia] = useState("1");

  const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
    setGuia(novaGuia);
  };
  //#endregion

  const [showUpload, setShowUpload] = useState<boolean>(false);

  const [id_clinica, setId_clinica] = useState<any>(idclinica || "");
  const [status, setStatus] = useState<boolean>(true);
  const [dtcadastro, setDtcadastro] = useState<string>("");
  const [id_filial, setId_filial] = useState<string>("");
  const [tipocontratacao, setTipocontratacao] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [logradouro, setLogradouro] = useState<string>("");
  const [numero, setNumero] = useState<string>("");
  const [bairro, setBairro] = useState<string>("");
  const [compl, setCompl] = useState<string>("");
  const [cnum, setCnum] = useState<string>("");
  const [cidade, setCidade] = useState<string>("");
  const [cuf, setCuf] = useState<string>("");
  const [cep, setCep] = useState<string>("");
  const [responsavel, setResponsavel] = useState<string>("");
  const [tipoconselho, setTipoconselho] = useState<string>("");
  const [ufconselho, setUfconselho] = useState<string>("");
  const [codconselho, setCodconselho] = useState<string>("");
  const [unidprofissional, setUnidprofissional] = useState<string>("");
  const [usuario_cadastro, setUsuario_cadastro] = useState<string>("");
  const [fantasia, setFantasia] = useState<string>("");
  const [telfixo, setTelfixo] = useState<string>("");
  const [telcelular, setTelcelular] = useState<string>("");
  const [empresaVinculada, setEmpresaVinculada] = useState<string>("");
  const [avatar, setAvatar] = useState<string>("");
  const [smtp_Servidor, setSmtp_Servidor] = useState<string>("");
  const [smtp_Porta, setSmtp_Porta] = useState<string>("");
  const [smtp_Email, setSmtp_Email] = useState<string>("");
  const [smtp_Usuario, setSmtp_Usuario] = useState<string>("");
  const [smtp_Senha, setSmtp_Senha] = useState<string>("");
  const [smtp_UsarSSL, setSmtp_UsarSSL] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  // const handleCepChange = (value: string) => {
  //   const formattedCep = applyCepMask(value);
  //   setCep(formattedCep);
  // };

  //#region ==> Select Options
  // Usando o hook useSelectOptions para obter as opções diretamente
  const tipoContratacaoOptions = useSelectOptions(
    `/clinica/listartipocontratacao`
  );
  const [selectedTipoContratacao, setSelectedTipoContratacao] = useState("");


  //#endregion

  const carregar = (url: string, idclinica: any) => {
    setLoading(true);
    CarregarDados(url, idclinica)
      .then((retorno) => {
        setLoading(false);

        setId_clinica(retorno[0].id_clinica || "");
        setStatus(retorno[0].status === 1 ? true : false);
        setDtcadastro(retorno[0].dtcadastro || "");
        setId_filial(retorno[0].id_filial || "");
        // setTipocontratacao(retorno[0].tipocontratacao || '');
        setSelectedTipoContratacao(retorno[0].tipocontratacao || "");
        setEmail(retorno[0].email || "");
        setLogradouro(retorno[0].logradouro || "");
        setNumero(retorno[0].numero || "");
        setBairro(retorno[0].bairro || "");
        setCompl(retorno[0].compl || "");
        setCnum(retorno[0].cnum || "");
        setCidade(retorno[0].cidade|| "")
        setCuf(retorno[0].cuf || "");
        setCep(retorno[0].cep || "");
        setResponsavel(retorno[0].responsavel || "");
        setTipoconselho(retorno[0].tipoconselho || "");
        setUfconselho(retorno[0].ufconselho || "");
        setCodconselho(retorno[0].codconselho || "");
        setUnidprofissional(retorno[0].unidprofissional || "");
        setUsuario_cadastro(retorno[0].usuario_cadastro || "");
        setFantasia(retorno[0].fantasia || "");
        setTelfixo(retorno[0].telfixo || "");
        setTelcelular(retorno[0].telcelular || "");
        setEmpresaVinculada(retorno[0].id_empresa || "");
        setAvatar(retorno[0].avatar || "");
        setSmtp_Servidor(retorno[0].smtp_servidor || "");
        setSmtp_Porta(retorno[0].smtp_porta || "");
        setSmtp_Email(retorno[0].smtp_email || "");
        setSmtp_Usuario(retorno[0].smtp_usuario || "");
        setSmtp_Senha(retorno[0].smtp_senha || "");
        setSmtp_UsarSSL(retorno[0].smtp_usassl === 1 ? true : false);


      })
      .catch((erro) => {
        setLoading(false);
        toast.error("Falha ao carregar dados da filial. Motivo: ", erro);
      });
  };
  useEffect(() => {
    if (idclinica !== "novo") {
      carregar(url_CarregarDados_ID, idclinica);

    }
  }, []);

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS
  const atualizarDados = async (
    id_clinica: string,
    status: string,
    dtcadastro: string,
    id_filial: string,
    tipocontratacao: string,
    email: string,
    logradouro: string,
    numero: string,
    bairro: string,
    compl: string,
    cnum: string,
    cidade: string,
    cuf: string,
    cep: string,
    responsavel: string,
    tipoconselho: string,
    ufconselho: string,
    codconselho: string,
    unidprofissional: string,
    usuario_cadastro: string,
    fantasia: string,
    telfixo: string,
    telcelular: string,
    id_empresa: string,
    smtp_servidor: string,
    smtp_porta: string,
    smtp_email: string,
    smtp_usuario: string,
    smtp_senha: string,
    avatar: string,
    smtp_usassl: string
  ) => {
    const dados = {
      id_clinica,
      status,
      dtcadastro,
      id_filial,
      tipocontratacao: selectedTipoContratacao,
      email: TextoFormatoLowerCase(email.toLowerCase()),
      logradouro: TextoFormatoCaptalize(logradouro.toLowerCase()),
      numero: TextoFormatoCaptalize(numero.toLowerCase()),
      bairro: TextoFormatoCaptalize(bairro.toLowerCase()),
      compl: TextoFormatoCaptalize(compl.toLowerCase()),
      cnum,
      cidade: TextoFormatoCaptalize(cidade.toLowerCase()),
      cuf,
      cep,
      responsavel: TextoFormatoCaptalize(responsavel.toLowerCase()),
      tipoconselho,
      ufconselho,
      codconselho,
      unidprofissional,
      usuario_cadastro,
      fantasia: TextoFormatoCaptalize(fantasia.toLowerCase()),
      telfixo,
      telcelular,
      id_empresa,
      smtp_servidor: TextoFormatoLowerCase(smtp_Servidor.toLowerCase()),
      smtp_porta,
      smtp_email: TextoFormatoLowerCase(smtp_email.toLowerCase()),
      smtp_usuario: TextoFormatoLowerCase(smtp_usuario.toLowerCase()),
      smtp_senha,
      avatar,
      smtp_usassl,
    };

    ApiPut(`/clinica/atualizar/${idclinica}`, dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        toast.error(`Erro ao atualizar. Motivo: ${erro.response.data.message}`);
      });
  };
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS
  const gravarDados = (
    id_clinica: string,
    status: string,
    dtcadastro: string,
    id_filial: string,
    tipocontratacao: string,
    email: string,
    logradouro: string,
    numero: string,
    bairro: string,
    compl: string,
    cnum: string,
    cidade: string,
    cuf: string,
    cep: string,
    responsavel: string,
    tipoconselho: string,
    ufconselho: string,
    codconselho: string,
    unidprofissional: string,
    usuario_cadastro: string,
    fantasia: string,
    telfixo: string,
    telcelular: string,
    id_empresa: string,
    smtp_servidor: string,
    smtp_porta: string,
    smtp_email: string,
    smtp_usuario: string,
    smtp_senha: string,
    avatar: string,
    smtp_usassl: string
  ) => {
    const dados = {
      id_clinica,
      status,
      dtcadastro,
      id_filial,
      tipocontratacao: selectedTipoContratacao,
      email: TextoFormatoLowerCase(email.toLowerCase()),
      logradouro: TextoFormatoCaptalize(logradouro.toLowerCase()),
      numero: TextoFormatoCaptalize(numero.toLowerCase()),
      bairro: TextoFormatoCaptalize(bairro.toLowerCase()),
      compl: TextoFormatoCaptalize(compl.toLowerCase()),
      cnum,
      cidade: TextoFormatoCaptalize(cidade.toLowerCase()),
      cuf,
      cep,
      responsavel: TextoFormatoCaptalize(responsavel.toLowerCase()),
      tipoconselho,
      ufconselho,
      codconselho,
      unidprofissional,
      usuario_cadastro,
      fantasia: TextoFormatoCaptalize(fantasia.toLowerCase()),
      telfixo,
      telcelular,
      id_empresa,
      smtp_servidor: TextoFormatoLowerCase(smtp_Servidor.toLowerCase()),
      smtp_porta,
      smtp_email: TextoFormatoLowerCase(smtp_email.toLowerCase()),
      smtp_usuario: TextoFormatoLowerCase(smtp_usuario.toLowerCase()),
      smtp_senha,
      avatar,
      smtp_usassl,
    };
    ApiPost("/clinica/criar", dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };
  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (idclinica === "novo") {
      gravarDados(
        id_clinica,
        status ? "1" : "0",
        dtcadastro,
        id_filial || "2",
        tipocontratacao,
        email,
        logradouro,
        numero,
        bairro,
        compl,
        cnum,
        cidade,
        cuf,
        cep,
        responsavel,
        tipoconselho,
        ufconselho,
        codconselho,
        unidprofissional,
        usuario_cadastro,
        fantasia,
        telfixo,
        telcelular,
        empresaVinculada,
        smtp_Servidor,
        smtp_Porta,
        smtp_Email,
        smtp_Usuario,
        smtp_Senha,
        avatar,
        smtp_UsarSSL ? "1" : "0"
      );
      toast.info("Inserindo novo registro...");
    } else {
      atualizarDados(
        id_clinica,
        status ? "1" : "0",
        dtcadastro,
        id_filial || "2",
        tipocontratacao,
        email,
        logradouro,
        numero,
        bairro,
        compl,
        cnum,
        cidade,
        cuf,
        cep,
        responsavel,
        tipoconselho,
        ufconselho,
        codconselho,
        unidprofissional,
        usuario_cadastro,
        fantasia,
        telfixo,
        telcelular,
        empresaVinculada,
        smtp_Servidor,
        smtp_Porta,
        smtp_Email,
        smtp_Usuario,
        smtp_Senha,
        avatar,
        smtp_UsarSSL ? "1" : "0"
      );
      toast.info("Atualizando dados do registro...");
    }
  };

  //#region Listar Empresa Contratante
  const [empresa, setEmpresa] = useState<any[]>([]);
  const listarEmpresa = async () => {
    CarregarDadosEmpresa(url_CarregarDados_Empresa)
      .then((retorno) => {
        setEmpresa(retorno);
      })
      .catch((erro) => {
        const msg = erro.response?.data.message;
        toast.error(`Erro ao listar dados empresa. Motivo: ${msg}`);
      });
  };
  useEffect(() => {
    listarEmpresa();
  }, []);

  //#endregion

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      setShowUpload(true);
      ConverterBase64(file)
        .then((retorno) => {
          setShowUpload(false);
          setAvatar(retorno);
        })
        .catch((erro) => {
          setShowUpload(false);
          toast.error(erro);
        });
    }
  };

  const handleRemoveAvatar = () => {
    setAvatar("");
  };

  const buscarCep = async (cep: string) => {
    const cepSemMascara = cep.replace(/\D/g, "");
    if (cepSemMascara.length < 8) {
      toast.error("CEP inválido!");
    } else if (cepSemMascara.length === 8) {
      await axios
        .get(`https://viacep.com.br/ws/${cepSemMascara}/json/`)
        .then((retorno) => {
          if (retorno.data?.erro) {
            toast.error("CEP inválido ou Não Localizado!");
          } else {
            const dadosCep = retorno.data;
            
            setLogradouro(dadosCep.logradouro);
            setCompl(dadosCep.complemento);
            setBairro(dadosCep.bairro);
            setCidade(dadosCep.localidade);
            setCuf(dadosCep.uf);
            setCnum(dadosCep.ibge);
          }
        })
        .catch((erro) => {
          const msg =
            erro.response?.data.message === undefined
              ? erro.message
              : erro.response.data.message;
          toast.error(`Erro ao buscar cep. Motivo: ${msg}`);
        });
    }
  };
  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Box sx={{ width: "100%" }}>
              <TabContext value={guia}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={capturaGuia}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#000",
                        color: "#000",
                      },
                    }}
                  >
                    <Tab label="Dados Básicos" value="1" />
                    <Tab label="Adicionais" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="ID Clínica"
                        name="id_clinica"
                        type="text"
                        placeholder="ID Clinica"
                        readonly
                        value={id_clinica}
                        onChange={setId_clinica}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Fantasia"
                        name="fantasia"
                        type="text"
                        placeholder="Nome Fantasia"
                        required
                        readonly={
                          acao === "Visualizar" || acao === "Editar"
                            ? true
                            : false
                        }
                        value={fantasia || ""}
                        onChange={setFantasia}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Email"
                        name="email"
                        type="text"
                        placeholder="Email"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={email || ""}
                        onChange={setEmail}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                      />
                    </Col>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Ativo
                      </label>
                      <Switch
                        checked={status}
                        onChange={() => setStatus(!status)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Telefone"
                        name="telefone"
                        type="text"
                        placeholder="Telefone Fixo"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                         // value={telfixo || ""}
                         value={mascaraTelefoneFixo(telfixo) || ""}
                         onChange={setTelfixo}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Celular"
                        name="telcelular"
                        type="text"
                        placeholder="Celular"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                         // value={telcelular || ""}
                         value={mascaraTelefoneCelular(telcelular) || ""}
                         onChange={setTelcelular}		
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Responsável Técnico"
                        name="responsavel Técnico"
                        type="text"
                        placeholder="Responsável"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={responsavel || ""}
                        onChange={setResponsavel}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <SelectInput
                        value={selectedTipoContratacao}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setSelectedTipoContratacao(e.target.value)
                        }
                        options={tipoContratacaoOptions}
                        placeholder="Tipo Contratação"
                      />
                      {/* <p>Opção selecionada: {selectedTipoSala}</p> */}
                    </Col>
                  </Row>
                  <Row>
                   
                    <Col sm={2}>
                      <InputSemBorda
                        label="CEP"
                        name="cep"
                        type="text"
                        placeholder="CEP"
                        readonly={acao === "Visualizar" ? true : false}
                        // value={cep || ""}
                        value={mascaraCEP(cep) || ""}
                        onChange={setCep}
                        onBlur={() => buscarCep(cep)}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Logradouro"
                        name="endereco"
                        type="text"
                        placeholder="Logradouro"
                        readonly={acao === "Visualizar" ? true : false}
                        value={logradouro || ""}
                        onChange={setLogradouro}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Complemento"
                        name="compl"
                        type="text"
                        placeholder="Complemento"
                        readonly={acao === "Visualizar" ? true : false}
                        value={compl || ""}
                        onChange={setCompl}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Número"
                        name="numero"
                        type="text"
                        placeholder="Número"
                        readonly={acao === "Visualizar" ? true : false}
                        value={numero || ""}
                        onChange={setNumero}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Bairro"
                        name="bairro"
                        type="text"
                        placeholder="Bairro"
                        readonly={acao === "Visualizar" ? true : false}
                        value={bairro || ""}
                        onChange={setBairro}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Cidade"
                        name="cidade"
                        type="text"
                        placeholder="Cidade"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={cidade || ""}
                        onChange={setCidade}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Cód. Municipio"
                        name="cnum"
                        type="text"
                        placeholder="Cod. Municipio"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={cnum || ""}
                        onChange={setCnum}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>

                    <Col sm={3}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1.5, width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          UF
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={cuf}
                          onChange={(e: SelectChangeEvent) =>
                            setCuf(e.target.value)
                          }
                          label="UF"
                          required
                          disabled={acao === "Visualizar" ? true : false}
                        >
                          <MenuItem value="">
                            <em>Selecione...</em>
                          </MenuItem>
                          {dadosUF.map((item: any) => (
                            <MenuItem value={item.uf}>{item.nome}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                    
                  </Row>
                  <Row>
                  <Col sm={12}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1.5, width: "100%" }}
                      >
                        <InputLabel>Empresa</InputLabel>
                        <Select
                          value={empresaVinculada}
                          onChange={(e: SelectChangeEvent) =>
                            setEmpresaVinculada(e.target.value)
                          }
                          label="UF"
                          required
                          disabled={
                            acao === "Visualizar" || acao === "Editar"
                              ? true
                              : false
                          }
                        >
                          <MenuItem value="">
                            <em>Selecione...</em>
                          </MenuItem>
                          {empresa.map((item: any) => (
                            <MenuItem value={item.id}>{item.fantasia}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel value="2">
                  <Typography borderBottom={1}>Dados Servidor de Email</Typography>
                  <Row>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Servidor SMTP"
                        name="smtp"
                        type="text"
                        placeholder="Servidor SMTP"
                        readonly={acao === "Visualizar" ? true : false}
                        value={smtp_Servidor}
                        onChange={setSmtp_Servidor}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Porta"
                        name="porta"
                        type="text"
                        placeholder="Porta"
                        readonly={acao === "Visualizar" ? true : false}
                        value={smtp_Porta}
                        onChange={setSmtp_Porta}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                      />
                    </Col>
                    <Col sm={6}>
                      <InputSemBorda
                        label="E-mail"
                        name="email"
                        type="email"
                        placeholder="Email"
                        readonly={acao === "Visualizar" ? true : false}
                        value={smtp_Email}
                        onChange={setSmtp_Email}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={5}>
                      <InputSemBorda
                        label="Usuário/E-mail (Autenticação)"
                        name="usuarioemail"
                        type="text"
                        placeholder="Usuário/E-mail (Autenticação)"
                        readonly={acao === "Visualizar" ? true : false}
                        value={smtp_Usuario}
                        onChange={setSmtp_Usuario}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Senha (Autenticação)"
                        name="senha"
                        type="password"
                        placeholder="Senha (Autenticação)"
                        readonly={acao === "Visualizar" ? true : false}
                        value={smtp_Senha}
                        onChange={setSmtp_Senha}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                      />
                    </Col>
                    <Col sm={3}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Requer Autenticação SSL
                      </label>
                      <Switch
                        checked={smtp_UsarSSL}
                        onChange={() => setSmtp_UsarSSL(!smtp_UsarSSL)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                  </Row>
                  <Typography borderBottom={1} marginBottom={2}>Logo</Typography>
                  <Row className="mb-2">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      {avatar ? (
                        <img
                          alt=""
                          style={{
                            width: "70%",
                            height: "250px",
                            objectFit: "contain",
                            backgroundImage: `url(data:image/png;base64,${avatar})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                          }}
                        />
                      ) : (
                        <Avatar
                          src={""}
                          alt="Avatar"
                          sx={{ width: 240, height: 240 }}
                        />
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        id="avatar-input"
                        onChange={handleAvatarChange}
                        style={{ display: "none" }}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                      <label htmlFor="avatar-input">
                        <IconButton color="primary" component="span">
                          <PhotoCameraIcon />
                        </IconButton>
                      </label>
                      {avatar && acao !== "Visualizar" && (
                        <Button variant="outlined" onClick={handleRemoveAvatar}>
                          Remover Logo
                        </Button>
                      )}
                    </Stack>
                  </Row>
                </TabPanel>
              </TabContext>
            </Box>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <ButtonCustom
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
                disabled={acao === "Visualizar" ? true : false}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>
      <ModalUploadImage
        show={showUpload}
        label="Realizando Upload da Imagem ..."
      />
      <ModalLoading show={loading} label="Carregado dados da clinica ..." />
    </>
  );
};
export default FormClinica;
