import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo";
import FormProcedimentoConvenios from "./components/formulario";

export function ProcedimentoConveniosEditar() {
  const convenioprocedimento = useParams();
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Editar Procedimento Convênio" />
        <FormProcedimentoConvenios convenioprocedimento={convenioprocedimento.id} acao="Editar" />
      </div>
    </>
  );
}
