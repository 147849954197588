import { Row, Col, Card } from "react-bootstrap";
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";

import AddIcon from "@mui/icons-material/Add";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import Tabela from "../../../../components/Formularios/Table";

import { useEffect, useState } from "react";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import { InterfaceUsuario } from "../../../../types/UsuarioInterface";

import { toast } from "react-toastify";
import ApiGet from "../../../../api/endPoints/useGet";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir";
import ApiDelete from "../../../../api/endPoints/useDelete";
import { getSessionData } from "../../../../utils/storageUtils";
import ApiPost from "../../../../api/endPoints/usePost";

export function Salas() {
  const id_clinica = getSessionData("DadosIdClinica") || "";
  const navigate = useNavigate();
  const token = getSessionData("MultClinicaWebToken") || "";
  const perfil = getSessionData("DadosPerfilID") || "0";
  const isSuper = getSessionData("isPerfilSuper") || "";

  interface Action {
    icon: JSX.Element;
    name: string;
  }
  const actions: Action[] = [
    {
      icon: (
        <AddIcon onClick={() => navigate("/cadastro/filiais/salas/novo")} />
      ),
      name: "Incluir",
    },
    // { icon: <FileCopyIcon />, name: "Duplicar" },
    // { icon: <SaveIcon />, name: "Salvar" },
    // { icon: <PrintIcon />, name: "Imprimir" },
  ];

  const visualizar = (dados: any) => {
    const idsala = dados.id_sala;
    navigate("/cadastro/filiais/salas/visu/" + idsala);
  };

  const editar = async (dados: any) => {
    const idsala = dados.id_sala;
    navigate("/cadastro/filiais/salas/editar/" + idsala);
  };

  const excluir = async (dados: any) => {
    const idsala = dados.id_sala;
    await showConfirmationDialog(
      "Excluir Sala",
      `Tem certeza que deseja excluir a sala \n ${dados.sala}?`,
      "question",
      "Excluir",
      "Cancelar"
    )
      .then((result) => {
        if (result.confirmed) {
          const dados = {
            url: "/sala/excluir/:idsala/:id_clinica",
            parametros: { idsala: idsala, id_clinica: id_clinica },
          };
          ApiDelete(dados)
            .then((retorno) => {
              toast.success(retorno.message);
              buscarDados();
            })
            .catch((erro) => {
              toast.error(
                `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
              );
            });
        }
      })
      .catch((erro) => {
        toast.error(
          `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
        );
      });
  };

  const colunas: ITabela[] = [
    { titulo: "ID Sala", acesso: "id_sala" },
    { titulo: "Sala", acesso: "sala" },
    { titulo: "Clínica", acesso: "fantasia" },
    { titulo: "Tipo Sala", acesso: "desctiposala" },
    { titulo: "Situação", acesso: "status" },
  ];

  //#region REALIZA REQUISIÇÃO PARA LISTAR DADOS DA TABELA
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [textoLoading, setTextoLoading] = useState<string>("");

  const buscarDados = async () => {
    setLoading(true);
    setTextoLoading("Buscando Listagem de Salas...");
    const dados = {
      url: "/sala/listar/:id_clinica",
      parametros: {
        id_clinica: id_clinica,
      },
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");

        setDados(retorno);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };

  const [permIncluir, setPermIncluir] = useState<boolean>(false);
  const [permAlterar, setPermAlterar] = useState<boolean>(false);
  const [permExcluir, setPermExcluir] = useState<boolean>(false);
  const [permVisualizar, setPermVisualizar] = useState<boolean>(false);
  const [permUsaAcoes, setPermUsaAcoes] = useState<boolean>(false);

  const buscarDadosPermissao = async (perfil: string) => {
    setLoading(true);
    setTextoLoading("Aguarde, Verificando Permissões...");
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/perfil/carregar/:id_perfil",
      parametros: {
        id_perfil: perfil,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        const retornoApi = retorno[0];
        setPermIncluir(
          retornoApi.cad_filiais_sala_atend_incluir === "1" || isSuper === "1"
            ? true
            : false
        );
        setPermAlterar(
          retornoApi.cad_filiais_sala_atend_editar === "1" || isSuper === "1"
            ? true
            : false
        );
        setPermExcluir(
          retornoApi.cad_filiais_sala_atend_excluir === "1" || isSuper === "1"
            ? true
            : false
        );
        setPermVisualizar(
          retornoApi.cad_filiais_sala_atend_visu === "1" || isSuper === "1"
            ? true
            : false
        );

        if (
          retornoApi.cad_filiais_sala_atend_incluir === "1" ||
          retornoApi.cad_filiais_sala_atend_editar === "1" ||
          retornoApi.cad_filiais_sala_atend_excluir === "1" ||
          retornoApi.cad_filiais_sala_atend_visu === "1" ||
          isSuper === "1"
        ) {
          setPermUsaAcoes(true);
        } else {
          setPermUsaAcoes(false);
        }

        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    buscarDadosPermissao(perfil);
    buscarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion

  const filtrarDados = async (filtro: string, vinculo: string) => {
    setLoading(true);
    setTextoLoading("Filtrando Listagem de Salas...");

    const dados = {
      filtro,
    };
    ApiPost(`/sala/filtro/${vinculo}`, dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        setDados(retorno);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        setLoading(false);
        toast.error(`Erro ao filtrar dados. Motivo: ${erro.message}`);
      });
  };
  const [pesquisa, setPesquisa] = useState<string>("");
  const pesquisar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (pesquisa === "") return buscarDados();
    else {
      const _filtro = pesquisa.toLowerCase();
      filtrarDados(_filtro, id_clinica);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <ContainerTitulo titulo="Listagem de Salas" />
      </div>
      <Row>
        <Col sm={12}>
          <form onSubmit={pesquisar}>
            <Card>
              <Card.Body>
                {permIncluir && (
                  <Row>
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      direction="left"
                      sx={{
                        right: 0,
                        position: "absolute",
                      }}
                      icon={<SpeedDialIcon />}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction
                          key={action.name}
                          icon={action.icon}
                          tooltipTitle={action.name}
                        />
                      ))}
                    </SpeedDial>
                  </Row>
                )}
                <Row>
                  <div className="d-flex my-2 gap-1 ">
                    <Col sm={permIncluir ? 11 : 12}>
                      <InputSemBorda
                        name="pesquisa"
                        type="text"
                        value={pesquisa}
                        onChange={setPesquisa}
                        classNameInputsSemBorda="form-control bordasInferiorInput"
                        placeholder=" "
                        label="Pesquisar"
                      />
                    </Col>
                  </div>
                </Row>
                <Row>
                  <Tabela
                    coluna={colunas}
                    dados={dados}
                    itemsPerPage={10}
                    onVisuClick={visualizar}
                    onEditClick={editar}
                    onDeleteClick={excluir}
                    onImprimirClick={() => {}}
                    usaImprimir={false}
                    id="codigo"
                    usaAcoes={permUsaAcoes}
                    usaEdit={permAlterar}
                    usaDelete={permExcluir}
                    usaVisu={permVisualizar}
                  />
                </Row>
              </Card.Body>
            </Card>
          </form>
        </Col>
      </Row>
      <ModalLoading show={loading} label={textoLoading} />
    </>
  );
}
