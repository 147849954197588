import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";

interface BarChartProps {
  categoriesData: string[];
  seriesData: ApexOptions["series"];
  colors?: string[]; // Adicionando a propriedade de cores
  height?: number;
  distributed?: boolean;
  labels?: boolean;
}

const BarChart: React.FC<BarChartProps> = ({categoriesData, seriesData, colors, height, distributed, labels}) => {
  const options = {
    chart: {
      type: 'bar' as const,
      width: '100%',
    },
    
    xaxis: {
      categories: categoriesData,
      labels: {
        show: labels ?? true, //usando operador de coalescência nula (??) para definir um valor padrão para a propriedade labels... o valor padrão é true
      },
    },
    series: seriesData,
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: distributed,
      }
    },
    responsive: [{
      breakpoint: 600,
      options: {
        chart: {
          width: '100%'
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  return <Chart options={options} series={options.series} type="bar" height={height}/>;
};

export default BarChart;
