import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import { Row, Col, Card } from "react-bootstrap";
import { getSessionData } from "../../../utils/storageUtils";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import { InputLabel } from "@mui/material";
import { useState, useEffect } from "react";
import { FaMoneyBill } from "react-icons/fa";
import { BsArrowDownCircleFill, BsArrowUpCircleFill } from "react-icons/bs";
import CardResumo from "../../../components/Formularios/CardResumo";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import axios from "axios";
import { toast } from "react-toastify";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import Tabela from "../../../components/Formularios/Table";
import { mascaraCNPJ, mascaraCPF } from "../../../hooks/mascaras";

export function ResumoFinanceiro() {


  const id_clinica = getSessionData("DadosIdClinica") || "";
  const token = getSessionData("MultClinicaWebToken") || "";
  const usuario = getSessionData("DadosUsuario") || "";

  const dataAtual = new Date();
  const mesAtual = (dataAtual.getMonth() + 1).toString().padStart(2, "0");
  const anoAtual = dataAtual.getFullYear();
  const _dataInicial = anoAtual + "-" + mesAtual + "-01";

  const [datainicio, setDataInicio] = useState<string>(_dataInicial);

  const [datafim, setDataFim] = useState<string>(
    dataAtual.toISOString().split("T")[0]
  );

  const [carregando, set_carregando] = useState(false)
  const [totalSaldoPrevisto, set_totalSaldoPrevisto] = useState<any>("")
  const [totalReceitas, set_totalReceitas] = useState<any>("")
  const [totalDespesas, set_totalDespesas] = useState<any>("")
  const [ListaMovimentos, setListaMovimentos] = useState([])

  function BuscarResumoFinanceiro(datainicio: any, datafim: any) {

    set_carregando(true)
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/all/resumo/financeiro/${id_clinica}/${datainicio}/${datafim}`, {
      headers: {
        Authorization: getSessionData("MultClinicaWebToken")
      }
    }).then(function (resposta) {

      set_carregando(false)//formatValor(resposta.data[0].soma_total)
      set_totalSaldoPrevisto(resposta.data[0].soma_total)
      set_totalReceitas(formatValor(resposta.data[0].soma_entrada))
      set_totalDespesas((formatValor(resposta.data[0].soma_saida)))
    }).catch(function (erro) {

      set_carregando(false)
      toast.error(erro.response.data || erro.statusText || erro.message)
    })
  }

  function ListarResumoFinanceiro(datainicio: any, datafim: any) {

    set_carregando(true)
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/all/resumo/detalhado/financeiro/${id_clinica}/${datainicio}/${datafim}`, {
      headers: {
        Authorization: getSessionData("MultClinicaWebToken")
      }
    }).then(function (resposta) {

      console.log(resposta.data)
      set_carregando(false)
      setListaMovimentos(resposta.data)
    }).catch(function (erro) {

      set_carregando(false)
      toast.error(erro.response.data || erro.statusText || erro.message)
    })
  }

  function formatValor(valorAformatar: string) {

    let valor = valorAformatar.replace(/\D/g, '')

    // Adiciona a vírgula para separar os centavos
    valor = valor.replace(/(\d)(\d{2})$/, '$1,$2')

    // Adiciona o ponto para separar os milhares
    valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '.')

    return valor
  }

  useEffect(function () {

    const newDataInicial = new Date(new Date(new Date(new Date().setDate(1))).setMonth(new Date(new Date(new Date().setDate(0))).getMonth() + 1)).toISOString().split("T")[0]
    setDataInicio(newDataInicial)

    const newDataFim = new Date(new Date(new Date(new Date().setDate(0))).setMonth(new Date(new Date(new Date().setDate(0))).getMonth() + 1)).toISOString().split("T")[0]
    setDataFim(newDataFim)

    BuscarResumoFinanceiro(newDataInicial, newDataFim)
    ListarResumoFinanceiro(newDataInicial, newDataFim)

    ConsultarCODsistema("STATUSMOVIMENTO")
    ConsultarCODsistema("TIPODOC")
    ConsultarCODsistema("FORMAPAGAMENTO")
    EncontrarEntidade()
  }, [])


  const colunas: ITabela[] = [
    { titulo: "ID Mov.", acesso: "id" },
    { titulo: "N° Doc", acesso: "nundoc" },
    { titulo: "Profissional/Entidade", acesso: "entidade" },
    { titulo: "Tipo", acesso: "tipo_mov" },
    // { titulo: "Tipo Agendamento", acesso: "desctiporeceita" },
    // { titulo: "Procedimento", acesso: "procedimento" },
    { titulo: "Emissão", acesso: "dt_emissao_formatada" },
    { titulo: "Vencimento", acesso: "dt_vencimento_formatada" },
    { titulo: "Pagamento", acesso: "dt_pagamento_formatada" },
    { titulo: "Valor", acesso: "valor" },
    { titulo: "Forma Pagamento", acesso: "forma_pagamento" },
    { titulo: "status", acesso: "status_movimento" }
  ];



  const [cpf_cnpj, set_cpf_cnpj] = useState("")
  const [entidade, set_entidade] = useState<any>("")
  const [tipo, set_tipo] = useState("S") //tipo do movimento
  const [titulo, set_titulo] = useState("")
  const [descMovimento, set_descMovimento] = useState("")
  const [tipoDoc, set_tipoDoc] = useState("")
  const [num_doc, set_num_doc] = useState("")
  const [valor, set_valor] = useState<any>("")
  const [emissao, set_emissao] = useState("")
  const [vencimento, set_vencimento] = useState("")
  const [pagamento, set_pagamento] = useState("")
  const [formaPagamento, set_formaPagamento] = useState("")
  const [status, set_status] = useState("")
  const [id_edicao, set_id_edicao] = useState("")
  function abrirEdicaoDeLançamento(dado: any) {

    set_id_edicao(dado.id)
    set_cpf_cnpj(dado.cnpj_cpf)
    set_entidade(dado.entidade)
    set_tipo(dado.tipo_mov)
    set_titulo(dado.titulo)
    set_descMovimento(dado.descricao)
    set_tipoDoc(dado.tipodoc)
    set_num_doc(dado.nundoc)
    set_valor(dado.valor.replace(".", ","))
    set_emissao(dado.dt_emissao_formatada != null ? dado.dt_emissao_formatada.split("/")[2] + "-" + dado.dt_emissao_formatada.split("/")[1] + "-" + dado.dt_emissao_formatada.split("/")[0] : "")
    set_vencimento(dado.dt_vencimento_formatada != null ? dado.dt_vencimento_formatada.split("/")[2] + "-" + dado.dt_vencimento_formatada.split("/")[1] + "-" + dado.dt_vencimento_formatada.split("/")[0] : "")
    set_pagamento(dado.dt_pagamento_formatada != null ? dado.dt_pagamento_formatada.split("/")[2] + "-" + dado.dt_pagamento_formatada.split("/")[1] + "-" + dado.dt_pagamento_formatada.split("/")[0] : "")
    set_formaPagamento(dado.forma_pagamento)
    set_status(dado.status)
    AcaoModal(1)
  }



  //abrir ou fechar modal
  function AcaoModal(editar: any = 0) {

    const btn = document.querySelector("#btnModal")! as HTMLElement
    if (editar == 0) {

      set_entidade("")
      set_cpf_cnpj("")
      set_titulo("")
      set_descMovimento("")
      set_tipoDoc("")
      set_num_doc("")
      set_valor("")
      set_emissao("")
      set_vencimento("")
      set_pagamento("")
      set_formaPagamento("")
      set_status("")
      set_id_edicao("")
    }
    btn.click()
  }


  //cod sistemas
  const [listaCODtipoDoc, set_listaCODtipoDoc] = useState([])
  const [listaCODtipoPagamento, set_listaCODtipoPagamento] = useState([])
  const [listaCODstatusMov, set_listaCODstatusMov] = useState([])


  function ConsultarCODsistema(tabela: string) {

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/codigosistema/listar/${tabela}`, {
      headers: {
        Authorization: token
      }
    }).then(function (resposta) {

      if (tabela == "STATUSMOVIMENTO") {

        set_listaCODstatusMov(resposta.data)
      }
      else if (tabela == "TIPODOC") {

        set_listaCODtipoDoc(resposta.data)
      }
      else if (tabela == "FORMAPAGAMENTO") {

        set_listaCODtipoPagamento(resposta.data)
      }
    })
  }



  const [ListaOptionEntidade, setListaOptionEntidade] = useState([])
  function EncontrarEntidade() {


    axios.get(`${process.env.REACT_APP_API_BASE_URL}/preencher/entidade/${id_clinica}`, {
      headers: {
        Authorization: token
      }
    }).then(function (resposta) {

      //set_entidade(resposta.data[0].entidade)
      setListaOptionEntidade(resposta.data)
    }).catch(function (erro) {

      if (erro.response.status != 406) {

        toast.error(erro.response.data || erro.message || erro.statusText)
      }
      else {

        toast.error(erro.response.data)
      }
    })
  }


  function formatCpfCnpj(info: string) {

    if (info.replace(/[.-]/g, "").length == 11) {

      set_cpf_cnpj(mascaraCPF(info))
    }
    else if (info.replace(/[.-]/g, "").length == 14) {

      set_cpf_cnpj(mascaraCNPJ(info))
    }
    else {

      set_cpf_cnpj(info)
    }
  }


  function setEntidadeNoem(cpf_cnpj: string) {

    const entidade_selecionada: any = ListaOptionEntidade.find(function (entidade: any) {

      return entidade.cnpj_cpf == cpf_cnpj.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "")
    })


    set_entidade(entidade_selecionada != undefined ? entidade_selecionada!.entidade : "")
  }


  //verifica permissões
  const token_ = getSessionData("MultClinicaWebToken")
  const isSuper = getSessionData("isPerfilSuper") || "";
  const [visualizar, set_visualizar] = useState(false)
  const [criar, set_criar] = useState(false)
  const [editar, set_editar] = useState(false)
  function verificaPermissoes(id_perfil: any) {

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/perfil/carregar/${id_perfil}`, {
      headers: {
        Authorization: token_
      }
    }).then(function (resposta) {

      set_visualizar(resposta.data[0].visualizarresumofinanceiro === "1" || isSuper === "1" ? true : false)
    }).catch(function (erro) {

      toast.error("Erro ao carregar permissão.")
    })
  }

  useEffect(function () {

    verificaPermissoes(getSessionData("DadosPerfilID"))
  }, [])

  return (
    <>
      <div className="page-content">
        <ContainerTitulo titulo="Resumo Financeiro" />
      </div>

      <Row>
        <Col sm={12}>
          <Card>
            <Card.Body>
              <Row>
                <Col sm={2}>
                  <InputSemBorda
                    name="datainicio"
                    type="date"
                    value={datainicio}
                    onChange={setDataInicio}
                    onBlur={function () {
                      BuscarResumoFinanceiro(datainicio, datafim)
                      ListarResumoFinanceiro(datainicio, datafim)
                    }}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                    placeholder="Data Início"
                    label="Data Início"
                  />
                </Col>
                <Col sm={2}>
                  <InputSemBorda
                    name="datafim"
                    type="date"
                    value={datafim}
                    onChange={setDataFim}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                    placeholder="Data Fim"
                    onBlur={function () {
                      BuscarResumoFinanceiro(datainicio, datafim)
                      ListarResumoFinanceiro(datainicio, datafim)
                    }}
                    label="Data Fim"
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <CardResumo
                  icone={<FaMoneyBill size={60} />}
                  titulo="Saldo Previsto"
                  valor={`R$ ${totalSaldoPrevisto}`}
                  textoTooltip="Saldo Previsto = Receitas - Despesas"
                />

                <CardResumo
                  icone={<BsArrowDownCircleFill size={60} color="#0ACF97" />}
                  titulo="Receitas"
                  valor={`R$ ${totalReceitas}`}
                  corBorda="#0ACF97"
                  textoTooltip="Receitas = Consultas + Procedimentos + Outros"
                />

                <CardResumo
                  icone={<BsArrowUpCircleFill size={60} color="#f44336" />}
                  titulo="Despesas"
                  valor={`R$ ${totalDespesas}`}
                  corBorda="#f44336"
                  textoTooltip="Despesas = Salários + Contas + Outros"
                />
              </Row>

              <br />

              <Row>
                <Tabela
                  coluna={colunas}
                  dados={ListaMovimentos}
                  itemsPerPage={10}
                  onVisuClick={abrirEdicaoDeLançamento}
                  usaDelete={false}
                  usaEdit={false}
                  usaVisu={visualizar}
                  onEditClick={function () { }}
                  onDeleteClick={() => { }}
                  onImprimirClick={function () {

                  }}
                  usaImprimir={false}
                  id="codigo"
                />
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>



      {/*MODAL PARA CRIAR DESPESA OU RECEITA */}
      <button hidden type="button" id="btnModal" className="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">Large modal</button>


      <div className="modal fade bd-example-modal-lg" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content bg-white">
            <div className="modal-header">
              <h4 className="modal-title" id="AbrirModalLabel">Preenchimento da despesa/receita</h4>
              <button onClick={function () {

                AcaoModal()
              }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="container">
                  <div className="row">
                    <div className="col-sm col-md-6 col-lg-5">
                      <div className="form-floating">
                        <input disabled value={cpf_cnpj} onChange={function (e) {
                          formatCpfCnpj(e.target.value)
                        }}
                          type="text" onBlur={function () {

                            setEntidadeNoem(cpf_cnpj)
                          }} list="complit" placeholder="" className="form-control bordasInferiorInput text-black" />
                        <label>Entidade - CPF/CNPJ</label>
                      </div>

                      {
                        cpf_cnpj.length > 2 ?

                          <datalist id="complit" className="bg-white">
                            {ListaOptionEntidade.map(function (entidade: any) {

                              return (
                                <>
                                  <option value={entidade.cnpj_cpf}>{entidade.entidade}</option>
                                </>
                              )
                            })}
                          </datalist>
                          : ""
                      }
                    </div>
                    <div className="col-sm col-md-6 col-lg-7">
                      <InputSemBorda
                        name="entidade"
                        type="text"
                        readonly
                        value={entidade}
                        onChange={set_entidade}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                        placeholder=" "
                        label="Entidade"
                        required
                      />
                    </div>
                    <div className="col-sm col-md col-lg">
                      <InputSemBorda
                        name="Titulo"
                        type="text"
                        value={titulo}
                        readonly
                        onChange={set_titulo}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                        placeholder=" "
                        label="Título do movimento"
                        required
                      />
                    </div>
                    <div className="p-1"></div>
                    <div className="col-sm col-md col-lg">
                      <div className="form-floating">
                        <select disabled value={tipo} onChange={function (e) {

                          set_tipo(e.target.value)
                        }} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                          <option value="S" selected>Saída</option>
                          <option value="E">Entrada</option>
                        </select>
                        <label>Tipo de movimento</label>
                      </div>
                    </div>
                    <div className="form-floating">
                      <textarea disabled style={{ height: "125px" }} value={descMovimento} onChange={function (e) {
                        set_descMovimento(e.target.value)
                      }} className="form-control bordasInferiorInput text-black" placeholder=""></textarea>
                      <label>Descrição do movimento</label>
                    </div>
                    <div className="p-1"></div>
                    <div className="col-sm col-md-6 col-lg-5">
                      <div className="form-floating">
                        <select disabled required value={tipoDoc} onChange={function (e) {
                          set_tipoDoc(e.target.value)
                        }} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                          <option value="" selected>Selecione...</option>
                          {listaCODtipoDoc.map(function (tipo: any) {

                            return (
                              <option value={tipo.label}>{tipo.label}</option>
                            )
                          })}
                        </select>
                        <label>Tipo de Documento</label>
                      </div>
                    </div>
                    <div className="col-sm col-md-3 col-lg-3">
                      <InputSemBorda
                        name="num_documento"
                        type="text"
                        readonly
                        value={num_doc}
                        onChange={set_num_doc}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                        placeholder=" "
                        label="N° Doc"
                      />
                    </div>
                    <div className="col-sm col-md-3 col-lg-4">
                      <InputSemBorda
                        name="Valor"
                        type="text"
                        value={valor}
                        onChange={set_valor}
                        onBlur={function () {

                          if (valor != "") {

                            formatValor(valor.replace(".", "").replace(",", "").replace(",00", ""))
                          }
                        }}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                        placeholder=" "
                        readonly
                        label="Valor R$"
                        required
                      />
                    </div>
                    <div className="p-1"></div>
                    <div className="col-sm col-md-4 col-lg-3">
                      <InputSemBorda
                        name="data_emissao"
                        type="date"
                        value={emissao}
                        readonly
                        onChange={set_emissao}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                        placeholder=" "
                        label="Data de Emissão"
                        required
                      />
                    </div>
                    <div className="col-sm col-md-4 col-lg-3">
                      <InputSemBorda
                        name="data_vencimento"
                        type="date"
                        readonly
                        value={vencimento}
                        onChange={set_vencimento}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                        placeholder=" "
                        label="Data de Vencimento"
                        required
                      />
                    </div>
                    <div className="col-sm col-md-4 col-lg-3">
                      <InputSemBorda
                        name="pagamento"
                        type="date"
                        readonly
                        value={pagamento}
                        onChange={set_pagamento}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                        placeholder=" "
                        label="Data de Pagamento"
                        required={formaPagamento != "" || status == "Executado" ? true : false}
                      />
                    </div>
                    <div className="col-sm col-md-6 col-lg-3">
                      <div className="form-floating">
                        <select disabled required={pagamento != "" || status == "Executado" ? true : false} value={formaPagamento} onChange={function (e) {
                          set_formaPagamento(e.target.value)
                        }} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                          <option value="" selected>Selecione...</option>

                          {listaCODtipoPagamento.map(function (tipo: any) {

                            return (
                              <option value={tipo.label}>{tipo.label}</option>
                            )
                          })}
                        </select>
                        <label>Pagamento</label>
                      </div>
                    </div>
                    <div className="col-sm col-md col-lg">
                      <div className="form-floating">
                        <select disabled value={status} onChange={function (e) {
                          set_status(e.target.value)
                        }} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                          <option value="" selected>Selecione...</option>
                          {listaCODstatusMov.map(function (tipo: any) {

                            return (
                              <option hidden={id_edicao == "" && tipo.label == "Cancelado" ? true : false} value={tipo.label}>{tipo.label}</option>
                            )
                          })}
                        </select>
                        <label>Status</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ModalLoading show={carregando} />
    </>
  );
}
