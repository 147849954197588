import React from "react";
import { Route, Routes } from "react-router-dom";
import { MainPage } from "../pages/mainPage";
import { Salas } from "../pages/cadastro/filiais/salas/salas";
import { AgendaSalas } from "../pages/cadastro/filiais/agendaSalas/agendaSalas";
import { PlanoSaude } from "../pages/cadastro/planoSaude/planoSaude";
import { Usuario } from "../pages/administrativo/usuario/usuario";
import { Dashboard } from "../pages/kpi/dashboard/dashboard";
import { Login } from "../pages/login/login";
import { ConsultasProcedimentos } from "../pages/agendamento/consultasProcedimentos";
import { UsuarioIncluir } from "../pages/administrativo/usuario/usuarioIncluir";
import { UsuarioEditar } from "../pages/administrativo/usuario/usuarioEditar";
import { UsuarioVisualizar } from "../pages/administrativo/usuario/usuarioVisualizar";
import { Permissao } from "../pages/administrativo/permissao/permissao";
import { CodigiodoSistema } from "../pages/administrativo/codigodoSistema/codigodosistema";
import { Empresa } from "../pages/administrativo/empresa/empresa";
import { PermissaoIncluir } from "../pages/administrativo/permissao/permissaoIncluir";
import { PermissaoVisualizar } from "../pages/administrativo/permissao/permissaoVisualizar";
import { PermissaoEditar } from "../pages/administrativo/permissao/permissaoEditar";
import { EmpresaIncluir } from "../pages/administrativo/empresa/empresaIncluir";
import { EmpresaEditar } from "../pages/administrativo/empresa/empresaEditar";
import { EmpresaVisualizar } from "../pages/administrativo/empresa/empresaVisualizar";
import { CodigiodoSistemaIncluir } from "../pages/administrativo/codigodoSistema/codigodosistemaIncluir";
import { CodigiodoSistemaVisualizar } from "../pages/administrativo/codigodoSistema/codigodosistemaVisualizar";
import { CodigiodoSistemaEditar } from "../pages/administrativo/codigodoSistema/codigodosistemaEditar";
import { Clinicas } from "../pages/cadastro/filiais/clinicas/clinicas";
import { ClinicaIncluir } from "../pages/cadastro/filiais/clinicas/clinicasIncluir";
import { ClinicaVisualizar } from "../pages/cadastro/filiais/clinicas/clinicasVisualizar";
import { ClinicaEditar } from "../pages/cadastro/filiais/clinicas/clinicasEditar";
import { SalasIncluir } from "../pages/cadastro/filiais/salas/SalasIncluir";
import { SalasVisualizar } from "../pages/cadastro/filiais/salas/salasVisualizar";
import { SalasEditar } from "../pages/cadastro/filiais/salas/salasEditar";
import { AgendaSalaIncluir } from "../pages/cadastro/filiais/agendaSalas/agendaSalasIncluir";
import { AgendaSalaVisualizar } from "../pages/cadastro/filiais/agendaSalas/agendaSalasVisualizar";
import { AgendaSalaEditar } from "../pages/cadastro/filiais/agendaSalas/agendaSalasEditar";
import { Profissionais } from "../pages/cadastro/profissionais/profissionais";
import { ProfissionaisIncluir } from "../pages/cadastro/profissionais/profissionaisIncluir";
import { ProfissionaisVisualizar } from "../pages/cadastro/profissionais/profissionaisVisualizar";
import { ProfissionaisEditar } from "../pages/cadastro/profissionais/profissionaisEditar";
import { Procedimentos } from "../pages/cadastro/procedimento/procedimentos";
import { ProcedimentosIncluir } from "../pages/cadastro/procedimento/procedimentosIncluir";
import { ProcedimentosVisualizar } from "../pages/cadastro/procedimento/procedimentosVisualizar";
import { ProcedimentosEditar } from "../pages/cadastro/procedimento/procedimentosEditar";
import { Convenios } from "../pages/cadastro/convenios/convenios/convenios";
import { ConveniosIncluir } from "../pages/cadastro/convenios/convenios/conveniosIncluir";
import { ConveniosVisualizar } from "../pages/cadastro/convenios/convenios/conveniosVisualizar";
import { ConveniosEditar } from "../pages/cadastro/convenios/convenios/conveniosEditar";
import { Pacientes } from "../pages/cadastro/pacientes/paciente/pacientes";
import { PacientesIncluir } from "../pages/cadastro/pacientes/paciente/pacientesIncluir";
import { PacientesVisualizar } from "../pages/cadastro/pacientes/paciente/pacientesVisualizar";
import { PacientesEditar } from "../pages/cadastro/pacientes/paciente/pacientesEditar";
import { Anamnese } from "../pages/cadastro/pacientes/anamnese/anamnese";
import { AnamneseIncluir } from "../pages/cadastro/pacientes/anamnese/anamneseIncluir";
import { AnamneseVisualizar } from "../pages/cadastro/pacientes/anamnese/anamneseVisualizar";
import { AnamneseEditar } from "../pages/cadastro/pacientes/anamnese/anamneseEditar";
import { ProcedimentoConvenios } from "../pages/cadastro/convenios/procedimentoconvenio/procedimentoconvenios";
import { ProcedimentoConveniosIncluir } from "../pages/cadastro/convenios/procedimentoconvenio/procedimentoconveniosIncluir";
import { ProcedimentoConveniosVisualizar } from "../pages/cadastro/convenios/procedimentoconvenio/procedimentoconveniosVisualizar";
import { ProcedimentoConveniosEditar } from "../pages/cadastro/convenios/procedimentoconvenio/procedimentoconveniosEditar";
import { PreCadastro } from "../pages/cadastro/pacientes/precadastropaciente/precadastro";
import { PreCadastroIncluir } from "../pages/cadastro/pacientes/precadastropaciente/precadastroIncluir";
import { PreCadastroVisualizar } from "../pages/cadastro/pacientes/precadastropaciente/precadastroVisualizar";
import { PreCadastroEditar } from "../pages/cadastro/pacientes/precadastropaciente/precadastroEditar";
import { FormPreCadastroExterno } from "../pages/cadastro/pacientes/precadastropaciente/formExternoPreCadastro/formprecadastroexterno";
import { CalendarioConsultas } from "../pages/agendamento/consultas/consultas";
import { Prontuario } from "../pages/prontuario/prontuario";
import { ProntuarioIncluir } from "../pages/prontuario/prontuarioIncluir";
import { ProntuarioVisualizar } from "../pages/prontuario/prontuarioVisualizar";
import { ProntuarioEditar } from "../pages/prontuario/prontuarioEditar";
import { AgendaSalaAtendimento } from "../pages/agendamento/consultas/salasAtendimentoAgenda";
import { AgendamentoAgenda } from "../pages/agendamento/agenda";
import { Autorizacao } from "../pages/autorizacoes/autorizacao";
import { ProcedimentoxProfissional } from "../pages/cadastro/procedimentoxprofissional/procedimentosxprofissional";
import { ProcedimentosxProfissionalIncluir } from "../pages/cadastro/procedimentoxprofissional/procedimentosxprofissionalIncluir";
import { ProcedimentosxProfissionalVisualizar } from "../pages/cadastro/procedimentoxprofissional/procedimentosxprofissionalVisualizar";
import { ProcedimentosxProfissionalEditar } from "../pages/cadastro/procedimentoxprofissional/procedimentosxprofissionalEditar";
import { ResumoFinanceiro } from "../pages/faturamento/resumo/resumoFinanceiro";
import { FaturamentoReceitas } from "../pages/faturamento/receitas/receitas";
import { FaturamentoDespesas } from "../pages/faturamento/despesas/despesas";
import { ProfissionalFilaAtendimento } from "../pages/cadastro/profissionais/filaAtendimento/filaAtendimento";
import PendentesFaturamento from "../pages/faturamento/PendenteFaturamento/PendenteFaturamento"


export default function Rotas(): JSX.Element {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="cliqueaqui/pacientes/continuar/3KbFPOGuSgNir9qngMaCYA/:id"
        element={<FormPreCadastroExterno />}
      />
      <Route path="/" element={<MainPage />}>
        {/* Administrativo */}
        {/* Administrativo -> Usuário */}
        <Route path="administrativo/usuario" element={<Usuario />} />
        <Route
          path="administrativo/usuario/usuario/:id"
          element={<UsuarioIncluir />}
        />
        <Route
          path="administrativo/usuario/usuario/visu/:id"
          element={<UsuarioVisualizar />}
        />
        <Route
          path="administrativo/usuario/usuario/editar/:id"
          element={<UsuarioEditar />}
        />

        {/* Administrativo -> Permissão */}
        <Route path="administrativo/permisao" element={<Permissao />} />
        <Route
          path="administrativo/permisao/permissao/:id"
          element={<PermissaoIncluir />}
        />
        <Route
          path="administrativo/permisao/permissao/visu/:id"
          element={<PermissaoVisualizar />}
        />
        <Route
          path="administrativo/permisao/permissao/editar/:id"
          element={<PermissaoEditar />}
        />

        {/* Administrativo -> Empresa */}
        <Route path="administrativo/empresa" element={<Empresa />} />
        <Route
          path="administrativo/empresa/empresa/:id"
          element={<EmpresaIncluir />}
        />
        <Route
          path="administrativo/empresa/empresa/visu/:id"
          element={<EmpresaVisualizar />}
        />
        <Route
          path="administrativo/empresa/empresa/editar/:id"
          element={<EmpresaEditar />}
        />

        {/* Administrativo -> Códigos do Sistema */}
        <Route path="administrativo/codistema" element={<CodigiodoSistema />} />
        <Route
          path="administrativo/codistema/codsis/:id"
          element={<CodigiodoSistemaIncluir />}
        />
        <Route
          path="administrativo/codistema/codsis/visu/:id"
          element={<CodigiodoSistemaVisualizar />}
        />
        <Route
          path="administrativo/codistema/codsis/editar/:id"
          element={<CodigiodoSistemaEditar />}
        />

        {/* Cadastro -> Filiais -> Clínicas */}
        <Route path="cadastro/filiais/clinicas" element={<Clinicas />} />
        <Route
          path="cadastro/filiais/clinicas/:id"
          element={<ClinicaIncluir />}
        />
        <Route
          path="cadastro/filiais/clinicas/visu/:id"
          element={<ClinicaVisualizar />}
        />
        <Route
          path="cadastro/filiais/clinicas/editar/:id"
          element={<ClinicaEditar />}
        />

        {/* Cadastro -> Filiais -> Salas */}
        <Route path="cadastro/filiais/salas" element={<Salas />} />
        <Route path="cadastro/filiais/salas/:id" element={<SalasIncluir />} />
        <Route
          path="cadastro/filiais/salas/visu/:id"
          element={<SalasVisualizar />}
        />
        <Route
          path="cadastro/filiais/salas/editar/:id"
          element={<SalasEditar />}
        />

        {/* Cadastro -> Filiais -> Agenda Salas */}
        <Route path="cadastro/filiais/agendasalas" element={<AgendaSalas />} />
        <Route
          path="cadastro/filiais/agendasalas/:id"
          element={<AgendaSalaIncluir />}
        />
        <Route
          path="cadastro/filiais/agendasalas/visu/:id"
          element={<AgendaSalaVisualizar />}
        />
        <Route
          path="cadastro/filiais/agendasalas/editar/:id"
          element={<AgendaSalaEditar />}
        />

        {/* Cadastro -> Profissionais */}
        <Route
          path="cadastro/profissionais/profissionais"
          element={<Profissionais />}
        />
        <Route
          path="cadastro/profissionais/profissionais/:id"
          element={<ProfissionaisIncluir />}
        />
        <Route
          path="cadastro/profissionais/profissionais/visu/:id"
          element={<ProfissionaisVisualizar />}
        />
        <Route
          path="cadastro/profissionais/profissionais/editar/:id"
          element={<ProfissionaisEditar />}
        />
        {/* Cadastro -> Profissionais -> Fila Atendimento*/}
        <Route
          path="cadastro/profissionais/filaatendimento"
          element={<ProfissionalFilaAtendimento />}
        />

        {/* Cadastro -> Procedimentos */}
        <Route
          path="cadastro/procedimentos/procedimentos"
          element={<Procedimentos />}
        />
        <Route
          path="cadastro/procedimentos/procedimentos/:id"
          element={<ProcedimentosIncluir />}
        />
        <Route
          path="cadastro/procedimentos/procedimentos/visu/:id"
          element={<ProcedimentosVisualizar />}
        />
        <Route
          path="cadastro/procedimentos/procedimentos/editar/:id"
          element={<ProcedimentosEditar />}
        />

        {/* Cadastro -> Procedimentos */}
        <Route
          path="cadastro/procedimentos/procedimentoxprofissional"
          element={<ProcedimentoxProfissional />}
        />
        <Route
          path="cadastro/procedimentos/procedimentoxprofissional/:id"
          element={<ProcedimentosxProfissionalIncluir />}
        />
        <Route
          path="cadastro/procedimentos/procedimentoxprofissional/visu/:id"
          element={<ProcedimentosxProfissionalVisualizar />}
        />
        <Route
          path="cadastro/procedimentos/procedimentoxprofissional/editar/:id"
          element={<ProcedimentosxProfissionalEditar />}
        />

        {/* Cadastro -> Convênios -> Convenios */}
        <Route path="cadastro/convenios/convenios" element={<Convenios />} />
        <Route
          path="cadastro/convenios/convenios/:id"
          element={<ConveniosIncluir />}
        />
        <Route
          path="cadastro/convenios/convenios/visu/:id"
          element={<ConveniosVisualizar />}
        />
        <Route
          path="cadastro/convenios/convenios/editar/:id"
          element={<ConveniosEditar />}
        />

        {/* Cadastro -> Convênios -> Procedimentos Convenios */}
        <Route
          path="cadastro/convenios/procedimentoconvenios"
          element={<ProcedimentoConvenios />}
        />
        <Route
          path="cadastro/convenios/procedimentoconvenios/:id"
          element={<ProcedimentoConveniosIncluir />}
        />
        <Route
          path="cadastro/convenios/procedimentoconvenios/visu/:id"
          element={<ProcedimentoConveniosVisualizar />}
        />
        <Route
          path="cadastro/convenios/procedimentoconvenios/editar/:id"
          element={<ProcedimentoConveniosEditar />}
        />

        {/* Cadastro -> Pacientes -> Pré Cadastro Paciente */}
        <Route
          path="cadastro/pacientes/precadastro"
          element={<PreCadastro />}
        />
        <Route
          path="cadastro/pacientes/precadastro/:id"
          element={<PreCadastroIncluir />}
        />
        <Route
          path="cadastro/pacientes/precadastro/visu/:id"
          element={<PreCadastroVisualizar />}
        />
        <Route
          path="cadastro/pacientes/precadastro/editar/:id"
          element={<PreCadastroEditar />}
        />

        {/* Cadastro -> Pacientes -> Pacientes */}
        <Route path="cadastro/pacientes/pacientes" element={<Pacientes />} />
        <Route
          path="cadastro/pacientes/pacientes/:id"
          element={<PacientesIncluir />}
        />
        <Route
          path="cadastro/pacientes/pacientes/visu/:id"
          element={<PacientesVisualizar />}
        />
        <Route
          path="cadastro/pacientes/pacientes/editar/:id"
          element={<PacientesEditar />}
        />

        {/* Cadastro -> Pacientes -> Anamnese */}
        <Route path="cadastro/pacientes/anamneses" element={<Anamnese />} />
        <Route
          path="cadastro/pacientes/anamneses/:id"
          element={<AnamneseIncluir />}
        />
        <Route
          path="cadastro/pacientes/anamneses/visu/:id"
          element={<AnamneseVisualizar />}
        />
        <Route
          path="cadastro/pacientes/anamneses/editar/:id"
          element={<AnamneseEditar />}
        />

        <Route path="cadastro/planosaude" element={<PlanoSaude />} />

        {/* Agendamento -> Salas - Agenda - Salas de Atendimento */}

        <Route
          path="agendamento/salasatendimento"
          element={<AgendaSalaAtendimento />}
        />
        <Route
          path="agendamento/agenda"
          element={<AgendamentoAgenda />}
        />
        {/* Agendamento -> Salas - Agenda - Salas de Atendimento -> Consultas */}

        <Route
          path="agendamento/salasatendimento/consultas"
          element={<CalendarioConsultas />}
        />

        <Route
          path="agendamento/agendamentoprocedimento"
          element={<ConsultasProcedimentos />}
        />

        {/* Autorizações -> Autorização */}

        <Route
          path="autorizacoes/autorizacao"
          element={<Autorizacao />}
        />
        {/* Financeiro -> Resumo Financeiro */}

        <Route
          path="faturamento/resumo"
          element={<ResumoFinanceiro />}
        />
        {/* Financeiro -> Receitas */}

        <Route
          path="faturamento/receitas"
          element={<FaturamentoReceitas />}
        />
        {/* Financeiro -> Despesas */}

        <Route
          path="faturamento/despesas"
          element={<FaturamentoDespesas />}
        />

        {/* Financeiro -> Pendentes de faturamento */}
        <Route
          path="faturamento/pendentes"
          element={<PendentesFaturamento />}
        />

        {/* KPI -> Dashboard */}
        <Route path="kpi/dashboard/dashboard" element={<Dashboard />} />

        {/* Prontuário -> Paciente */}
        <Route path="prontuario/paciente" element={<Prontuario />} />
        <Route path="prontuario/paciente/:id" element={<ProntuarioIncluir />} />
        <Route path="prontuario/paciente/visu/:id" element={<ProntuarioVisualizar />} />
        <Route path="prontuario/paciente/editar/:id" element={<ProntuarioEditar />} />
      </Route>

    </Routes>
  );
}
