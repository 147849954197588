import { Row, Col, Card } from "react-bootstrap";
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";

import AddIcon from "@mui/icons-material/Add";
import Tabela from "../../../../components/Formularios/Table";

import { useEffect, useState } from "react";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import { InterfaceUsuario } from "../../../../types/UsuarioInterface";

import { toast } from "react-toastify";
import ApiGet from "../../../../api/endPoints/useGet";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir";
import ApiDelete from "../../../../api/endPoints/useDelete";
import { getSessionData } from "../../../../utils/storageUtils";
import ApiPost from "../../../../api/endPoints/usePost";
import { PermissaoIncluir } from "../../../administrativo/permissao/permissaoIncluir";
import { mdiClipboardList, mdiFormatListChecks } from "@mdi/js";
import ApiPut from "../../../../api/endPoints/usePut";

export function ProfissionalFilaAtendimento() {
  const navigate = useNavigate();
  const id_clinica = getSessionData("DadosIdClinica") || "";
  const usuario = getSessionData("DadosUsuario") || "";
  const token = getSessionData("MultClinicaWebToken") || "";
  const perfil = getSessionData("DadosPerfilID") || "0";
  const isSuper = getSessionData("isPerfilSuper") || "";

  interface Action {
    icon: JSX.Element;
    name: string;
  }
  const actions: Action[] = [
    {
      icon: (
        <AddIcon
          onClick={() => navigate("/cadastro/profissionais/profissionais/novo")}
        />
      ),
      name: "Incluir",
    },
    // { icon: <FileCopyIcon />, name: "Duplicar" },
    // { icon: <SaveIcon />, name: "Salvar" },
    // { icon: <PrintIcon />, name: "Imprimir" },
  ];

  const visualizar = (dados: any) => {
    const id_prontuario = dados.id_prontuario;
    if (!id_prontuario) {
      toast.error("Paciente não possui prontuário cadastrado!");
      return;
    } else navigate("/prontuario/paciente/visu/" + id_prontuario);
  };

  const editar = async (dados: any) => {
    const id_prontuario = dados.id_prontuario;
    if (!id_prontuario) {
      toast.error("Paciente não possui prontuário cadastrado!");
      return;
    } else navigate("/prontuario/paciente/editar/" + id_prontuario);
  };

  const novoProntuario = async (dados: any) => {
    const id_prontuario = dados.id_prontuario;
    if (!id_prontuario) {
      await showConfirmationDialog(
        "Incluir Prontuário",
        `Tem certeza que deseja INCLUIR novo Prontuário?`,
        "question",
        "Sim",
        "Não"
      )
        .then((result) => {
          if (result.confirmed) {
            navigate("/prontuario/paciente/novo");
          }
        })
        .catch((erro) => {
          toast.error(`Erro ao gerar novo prontuário. Motivo: ${erro}`);
        });
    } else {
      toast.warning("Paciente já possui prontuário cadastrado!");
      return;
    }
  };

  const colunas: ITabela[] = [
    { titulo: "ID Agendamento", acesso: "id_agendamento" },
    { titulo: "CPF", acesso: "cpf" },
    { titulo: "Paciente", acesso: "nome" },
    { titulo: "Data Nascimento", acesso: "datanascimento" },
    { titulo: "Sala", acesso: "sala" },
    { titulo: "Tipo Sala", acesso: "tiposala" },
    { titulo: "Tipo Atendimento", acesso: "tipoconsulta" },
    { titulo: "Procedimento", acesso: "procedimento" },
    { titulo: "Núm. Prontuário", acesso: "id_prontuario" },

    { titulo: "Tempo Espera", acesso: "tempo_espera" },
  ];

  //#region REALIZA REQUISIÇÃO PARA LISTAR DADOS DA TABELA
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [textoLoading, setTextoLoading] = useState<string>("");

  const buscarDados = async (id_clinica: string, profissional: string) => {
    setLoading(true);
    setTextoLoading("Carregando Listagem de Profissionais...");
    const dados = {
      url: "/profissional/listarpacientes/:id_clinica/:profissional",
      parametros: { id_clinica: id_clinica, profissional: profissional },
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        setDados(retorno);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };

  useEffect(() => {
    buscarDados(id_clinica, usuario);
  }, [id_clinica, usuario]);
  //#endregion

  const filtrarDados = async (filtro: string, vinculo: string) => {
    setLoading(true);
    setTextoLoading("Filtrando Listagem de Profissionais...");

    const dados = {
      filtro,
    };
    ApiPost(`/profissional/filtro/${vinculo}`, dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        setDados(retorno);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        setLoading(false);
        toast.error(`Erro ao filtrar dados. Motivo: ${erro.message}`);
      });
  };
  const [pesquisa, setPesquisa] = useState<string>("");
  const pesquisar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (pesquisa === "") return buscarDados(id_clinica, usuario);
    else {
      const _filtro = pesquisa.toLowerCase();
      filtrarDados(_filtro, id_clinica);
    }
  };

  const finalizar = async (
    id_clinica: string,
    id_agendamento: string,
    id_sala: string,
    observacao: string,
    usuario: string
  ) => {
    setLoading(true);
    setTextoLoading("Finalizando Atendimento...");
    const dados = {
      observacao,
      usuario,
    };

    ApiPut(
      `/agenda/finalizar/${id_agendamento}/${id_clinica}/${id_sala}`,
      dados,
      token
    )
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        const msg = retorno.message;
        toast.success(msg);
        buscarDados(id_clinica, usuario);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao finalizar atendimento. Motivo: ${erro.message}`);
      });
  };
  const finalizarAtendimento = async (dados: any) => {    
    await showConfirmationDialog(
      "Finalizar Atendimento",
      `Finalizar o atendimento do paciente \n ${dados.nome}?`,
      "question",
      "Sim",
      "Não",
      true,
      "Informe a Observação",
      false,
      "Observação"
    )
      .then((result) => {
        if (result.confirmed) {
          const observacao = result.value;
          finalizar(
            id_clinica,
            dados.id_agendamento,
            dados.id_sala,
            observacao || "",
            usuario
          );
        }
      })
      .catch((erro) => {
        toast.error(
          `Erro ao finalizar registro. Motivo: ${erro.response.data.message}`
        );
      });
  };
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Fila de Atendimento" />

        <Row>
          <Col sm={12}>
            <form onSubmit={pesquisar}>
              <Card>
                <Card.Body>
                  <Row>
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      direction="left"
                      sx={{
                        right: 0,
                        position: "absolute",
                      }}
                      icon={<SpeedDialIcon />}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction
                          key={action.name}
                          icon={action.icon}
                          tooltipTitle={action.name}
                        />
                      ))}
                    </SpeedDial>
                  </Row>

                  <Row>
                    <div className="d-flex my-2 gap-1 ">
                      <Col sm={11}>
                        <InputSemBorda
                          name="nome"
                          type="text"
                          value={pesquisa}
                          onChange={setPesquisa}
                          classNameInputsSemBorda="form-control bordasInferiorInput"
                          placeholder=" "
                          label="Pesquisar"
                        />
                      </Col>
                    </div>
                  </Row>

                  <Row>
                    <Tabela
                      coluna={colunas}
                      dados={dados}
                      itemsPerPage={10}
                      onVisuClick={visualizar}
                      descVisualizar="Ver Prontuário"
                      descEditar="Editar Prontuário"
                      onEditClick={editar}
                      onDeleteClick={novoProntuario}
                      descExcluir="Incluir Prontuário"
                      iconeExcluir={mdiClipboardList}
                      descNovoBotao="Finalizar Atendimento"
                      iconeNovoBotao={mdiFormatListChecks}
                      usaNovoBotao={true}
                      onNovoBotaoClick={finalizarAtendimento}
                      onImprimirClick={() => {}}
                      usaImprimir={false}
                      id="codigo"
                    />
                  </Row>
                </Card.Body>
              </Card>
            </form>
          </Col>
        </Row>
      </div>
      <ModalLoading show={loading} label={textoLoading} />
    </>
  );
}
