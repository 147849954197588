import { Row, Col, Card } from "react-bootstrap";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";

import AddIcon from "@mui/icons-material/Add";
// import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
// import SaveIcon from "@mui/icons-material/Save";
// import PrintIcon from "@mui/icons-material/Print";
import Tabela from "../../../components/Formularios/Table";

import { useEffect, useState } from "react";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import { InterfaceUsuario } from "../../../types/UsuarioInterface";
import { toast } from "react-toastify";
import ApiGet from "../../../api/endPoints/useGet";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
// import Swal from "sweetalert2";
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import ApiDelete from "../../../api/endPoints/useDelete";
import { getSessionData } from "../../../utils/storageUtils";
import ApiPost from "../../../api/endPoints/usePost";

export function Usuario() {
  const token = getSessionData("MultClinicaWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  const isVinculo = getSessionData("isPerfilVinculo") || "";
  const perfil = getSessionData("DadosPerfilID") || "0";
  const navigate = useNavigate();

  interface Action {
    icon: JSX.Element;
    name: string;
  }
  const actions: Action[] = [
    {
      icon: (
        <AddIcon
          onClick={() => navigate("/administrativo/usuario/usuario/novo")}
        />
      ),
      name: "Incluir",
    },
    // { icon: <FileCopyIcon />, name: "Duplicar" },
    // { icon: <SaveIcon />, name: "Salvar" },
    // { icon: <PrintIcon />, name: "Imprimir" },
  ];

  const visualiarUsuario = (dados: any) => {
    const id_usuario = dados.id_usuario;
    navigate("/administrativo/usuario/usuario/visu/" + id_usuario);
  };

  const editarUsuario = (dados: any) => {
    const id_usuario = dados.id_usuario;
    navigate("/administrativo/usuario/usuario/editar/" + id_usuario);
  };

  const excluirUsuario = async (dados: any) => {
    const id_usuario = dados.id_usuario;
    await showConfirmationDialog(
      "Excluir Usuário",
      `Tem certeza que deseja excluir o usuário \n ${dados.nome}?`,
      "question",
      "Excluir",
      "Cancelar"
    )
      .then((result) => {
        if (result.confirmed) {
          const headers = {
            Authorization: token,
          };
          const dados = {
            url: `/usuario/excluir/?id_usuario=${id_usuario}&vinculo=${isVinculo}`,
            parametros: {},
            headers,
          };
          console.log("dados: ", dados);
          ApiDelete(dados)
            .then((retorno) => {
              toast.success(retorno.message);
              buscarDados(isSuper);
            })
            .catch((erro) => {
              toast.error(
                `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
              );
            });
        }
      })
      .catch((erro) => {
        toast.error(
          `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
        );
      });
  };

  const colunas: ITabela[] = [
    { titulo: "", acesso: "avatar" },
    { titulo: "Código", acesso: "id_usuario" },
    { titulo: "Nome", acesso: "nome" },
    { titulo: "Usuário", acesso: "nome_usuario" },
    { titulo: "Email", acesso: "email" },
    { titulo: "Telefone", acesso: "telefone" },
    { titulo: "Perfil", acesso: "nome_perfil" },
    { titulo: "Situação", acesso: "situacao" },
  ];

  //#region REALIZA REQUISIÇÃO PARA LISTAR DADOS DA TABELA
  const [dados, setDados] = useState<InterfaceUsuario[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [textoLoading, setTextoLoading] = useState<string>("");
  const buscarDados = async (master: string) => {
    setLoading(true);
    setTextoLoading("Carregando Listagem de Usuários");
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: `/usuario/listar/?master=${master}&vinculo=${isVinculo || "0"}`,
      parametros: {},
      headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        setDados(retorno);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar usuários. Motivo: ${erro.message}`);
      });
  };

  const [permIncluir, setPermIncluir] = useState<boolean>(false);
  const [permAlterar, setPermAlterar] = useState<boolean>(false);
  const [permExcluir, setPermExcluir] = useState<boolean>(false);
  const [permVisualizar, setPermVisualizar] = useState<boolean>(false);
  const [permUsaAcoes, setPermUsaAcoes] = useState<boolean>(false);

  const buscarDadosPermissao = async (perfil: string) => {
    setLoading(true);
    setTextoLoading("Aguarde, Verificando Permissões...");
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/perfil/carregar/:id_perfil",
      parametros: {
        id_perfil: perfil,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        const retornoApi = retorno[0];
        setPermIncluir(
          retornoApi.admin_user_incluir === "1" || isSuper === "1"
            ? true
            : false
        );
        setPermAlterar(
          retornoApi.admin_user_editar === "1" || isSuper === "1" ? true : false
        );
        setPermExcluir(
          retornoApi.admin_user_excluir === "1" || isSuper === "1"
            ? true
            : false
        );
        setPermVisualizar(
          retornoApi.admin_user_visu === "1" || isSuper === "1" ? true : false
        );

        if (
          retornoApi.admin_user_incluir === "1" ||
          retornoApi.admin_user_alterar === "1" ||
          retornoApi.admin_user_excluir === "1" ||
          retornoApi.admin_user_visu === "1" ||
          isSuper === "1"
        ) {
          setPermUsaAcoes(true);
        } else {
          setPermUsaAcoes(false);
        }

        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    buscarDadosPermissao(perfil);
    buscarDados(isSuper);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion

  const filtrarDados = async (
    filtro: string,
    master: string,
    vinculo: string
  ) => {
    setLoading(true);
    setTextoLoading("Filtrando Listagem de Perfil/Permissão...");

    const dados = {
      filtro,
      master,
      vinculo,
    };
    ApiPost(`/usuario/filtro`, dados, token)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        setDados(retorno);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        setLoading(false);
        toast.error(`Erro ao filtrar dados. Motivo: ${erro.message}`);
      });
  };
  const [pesquisa, setPesquisa] = useState<string>("");
  const pesquisar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (pesquisa === "") return buscarDados(isSuper);
    else {
      const _filtro = pesquisa.toLowerCase();
      filtrarDados(_filtro, isSuper, isVinculo || "0");
    }
  };

  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Listagem de Usuários" />

        <Row>
          <Col sm={12}>
            <form onSubmit={pesquisar}>
              <Card>
                <Card.Body>
                  {permIncluir && (
                    <Row>
                      <SpeedDial
                        ariaLabel="SpeedDial basic example"
                        direction="left"
                        sx={{
                          right: 0,
                          position: "absolute",
                        }}
                        icon={<SpeedDialIcon />}
                      >
                        {actions.map((action) => (
                          <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                          />
                        ))}
                      </SpeedDial>
                    </Row>
                  )}
                  <Row>
                    <div className="d-flex my-2 gap-1 ">
                      <Col sm={permIncluir ? 11 : 12}>
                        <InputSemBorda
                          name="pesquisa"
                          type="text"
                          value={pesquisa}
                          onChange={setPesquisa}
                          classNameInputsSemBorda="form-control bordasInferiorInput"
                          placeholder=" "
                          label="Pesquisar"
                        />
                      </Col>
                    </div>
                  </Row>

                  <Row>
                    <Tabela
                      coluna={colunas}
                      dados={dados}
                      itemsPerPage={10}
                      onVisuClick={visualiarUsuario}
                      onEditClick={editarUsuario}
                      onDeleteClick={excluirUsuario}
                      onImprimirClick={() => {}}
                      usaAcoes={permUsaAcoes}
                      usaEdit={permAlterar}
                      usaDelete={permExcluir}
                      usaVisu={permVisualizar}
                      usaImprimir={false}
                      id="codigo"
                    />
                  </Row>
                </Card.Body>
              </Card>
            </form>
          </Col>
        </Row>
      </div>
      <ModalLoading show={loading} label={textoLoading} />
    </>
  );
}
