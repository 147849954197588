import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../../../../components/Formularios/Modal/ModalLoading";
import { useState, useEffect, ChangeEvent } from "react";
import { toast } from "react-toastify";
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../../components/Formularios/Inputs/InputsSemBorda";

import {
  Box,
  Switch,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tab,
  Avatar,
  Stack,
  Button,
  Paper,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button as ButtonCustom } from "../../../../../../components/Formularios/Buttons/Button";
import {
  mdiCheckCircle,
  mdiKeyboardReturn,
  mdiLockCheckOutline,
} from "@mdi/js";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ApiPost from "../../../../../../api/endPoints/usePost";
import ApiPut from "../../../../../../api/endPoints/usePut";
import ModalUploadImage from "../../../../../../components/Formularios/Modal/ModalUpload";
import { styled } from "@mui/material/styles";
import ConverterBase64 from "../../../../../../components/ConversorBase64";
import CarregarDados from "../CarregarDados";
// import { InterfaceDadosEmpresa } from "../../../../../types/EmpresaInteface";
import { url_CarregarDados_ID } from "../auxiliar";
import { dadosUF } from "../../../../../../components/dadosFixos/UF";
import { dadosPais } from "../../../../../../components/dadosFixos/Pais";
import { TextoFormatoCaptalize } from "../../../../../../components/Auxiliar/ConvTextoCaptalize";
import { TextoFormatoLowerCase } from "../../../../../../components/Auxiliar/ConvTextoLower";
import { TextoFormatoUpperCase } from "../../../../../../components/Auxiliar/ConvTextoUpper";
import useSelectOptions from "../../../../../../hooks/useSelectOptions";
import SelectInput from "../../../../../../shared/SelectInputs";
import { getSessionData } from "../../../../../../utils/storageUtils";
import axios from "axios";
import { mascaraCEP, mascaraCPF, mascaraTelefoneCelular, mascaraTelefoneFixo } from "../../../../../../hooks/mascaras";
import ApiGet from "../../../../../../api/endPoints/useGet";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F0F0F0",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "#616161",
  borderRadius: "5px",
  fontWeight: "bold",
  fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
}));

interface FormPacientesProps {
  idpaciente?: string;
  acao?: string;
}

const FormPacientes = ({ idpaciente, acao }: FormPacientesProps) => {
  const navigate = useNavigate();
  const id_clinica = getSessionData("DadosIdClinica") || "";

  //#region Controla as guias
  const [guia, setGuia] = useState("1");

  const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
    setGuia(novaGuia);
  };
  //#endregion

  const [showUpload, setShowUpload] = useState<boolean>(false);

  const [id_paciente, setId_paciente] = useState<any>(idpaciente || "");

  const [status, setStatus] = useState<boolean>(true);
  const [cpf, setCpf] = useState<string>("");
  const [nome, setNome] = useState<string>("");
  const [dtnascimento, setDtnascimento] = useState<string>("");
  const [telfixo, setTelfixo] = useState<string>("");
  const [telcelular, setTelcelular] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [telemerggencia, setTelemerggencia] = useState<string>("");
  const [contemerggencia, setContemerggencia] = useState<string>("");
  const [telresponsavel, setTelresponsavel] = useState<string>("");
  const [responsavel, setResponsavel] = useState<string>("");
  const [logradouro, setLogradouro] = useState<string>("");
  const [numero, setNumero] = useState<string>("");
  const [bairro, setBairro] = useState<string>("");
  const [compl, setCompl] = useState<string>("");
  const [cnum, setCnum] = useState<string>("");
  const [cidade, setCidade] = useState<string>("");
  const [cuf, setCuf] = useState<string>("");
  const [cep, setCep] = useState<string>("");
  const [tipocontratacao, setTipocontratacao] = useState<string>("");
  const [formapag, setFormapag] = useState<string>("");
  const [convenio, setConvenio] = useState<string>("");
  const [codconvenio, setCodconvenio] = useState<string>("");
  const [tipoconselho, setTipoconselho] = useState<string>("");
  const [ufconselho, setUfconselho] = useState<string>("");
  const [codconselho, setCodconselho] = useState<string>("");
  const [ncbo, setNcbo] = useState<string>("");
  const [dtindicacao, setDtindicacao] = useState<string>("");
  const [nomeprofissional, setNomeprofissional] = useState<string>("");
  const [unidprofissional, setUnidprofissional] = useState<string>("");
  const [codcid, setCodcid] = useState<string>("");
  const [descricaocid, setDescricaocid] = useState<string>("");
  const [observacao, setObservacao] = useState<string>("");
  const [usercadastro, setUsercadastro] = useState<string>("");
  const [cadastro, setCadastro] = useState<string>("");
  const [usuario_cadastro, setUsuario_cadastro] = useState<string>("");
  const [enviaEmailPreCadastro, setEnviaEmailPreCadastro] = useState<boolean>(
    false
  );

  //#region REALIZA REQUISIÇÃO PARA LISTAR DADOS DA TABELA
  const [dadosConvenio, setDadosConvenio] = useState([]);
  const buscarDados = async () => {
    const dados = {
      url: "/convenio/listar/:id_clinica",
      parametros: {
        id_clinica: id_clinica || "",
      },
    };
    ApiGet(dados)
      .then((retorno) => {
        setDadosConvenio(retorno);
        setLoading(false);
      })
      .catch((erro) => {
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    buscarDados();
  }, []);
  //#endregion

  const [loading, setLoading] = useState<boolean>(false);

  //#region ==> Select Options
  // Usando o hook useSelectOptions para obter as opções diretamente
  const tipoContratacaoOptions = useSelectOptions(
    `/clinica/listartipocontratacao`
  );
  const tipoConselhoOptions = useSelectOptions(
    `/codigosistema/listar/TIPOCONSELHO`
  );
  const [selectedTipoContratacao, setSelectedTipoContratacao] = useState("");
  const [selectedTipoConselho, setSelectedTipoConselho] = useState("");

  //#endregion

  const carregar = (url: string, idpaciente: any, id_clinica: string) => {
    setLoading(true);
    CarregarDados(url, idpaciente, id_clinica)
      .then((retorno) => {
        setLoading(false);

        setId_paciente(retorno[0].id_paciente || "");
        setStatus(retorno[0].status === 1 ? true : false);
        setCpf(retorno[0].cpf || "");
        setNome(retorno[0].nome || "");
        setDtnascimento(retorno[0].dtnascimento || "");
        setTelfixo(retorno[0].telfixo || "");
        setTelcelular(retorno[0].telcelular || "");
        setEmail(retorno[0].email || "");
        setTelemerggencia(retorno[0].telemerggencia || "");
        setContemerggencia(retorno[0].contemerggencia || "");
        setTelresponsavel(retorno[0].telresponsavel || "");
        setResponsavel(retorno[0].responsavel || "");
        setLogradouro(retorno[0].logradouro || "");
        setNumero(retorno[0].numero || "");
        setBairro(retorno[0].bairro || "");
        setCompl(retorno[0].compl || "");
        setCnum(retorno[0].cnum || "");
        setCidade(retorno[0].cidade || "");
        setCuf(retorno[0].cuf || "");
        setCep(retorno[0].cep || "");
        // setTipocontratacao(retorno[0].tipocontratacao || '');
        setSelectedTipoContratacao(retorno[0].tipocontratacao || "");
        setFormapag(retorno[0].formapag || "");
        setConvenio(retorno[0].convenio || "");
        setCodconvenio(retorno[0].codconvenio || "");
        // setTipoconselho(retorno[0].tipoconselho || '');
        setSelectedTipoConselho(retorno[0].tipoconselho || "");
        setUfconselho(retorno[0].ufconselho || "");
        setCodconselho(retorno[0].codconselho || "");
        setNcbo(retorno[0].ncbo || "");
        setDtindicacao(retorno[0].dtindicacao || "");
        setNomeprofissional(retorno[0].nomeprofissional || "");
        setUnidprofissional(retorno[0].unidprofissional || "");
        setCodcid(retorno[0].codcid || "");
        setDescricaocid(retorno[0].descricaocid || "");
        setObservacao(retorno[0].observacao || "");
        setUsercadastro(retorno[0].usercadastro || "");
        setCadastro(retorno[0].cadastro || "");
        setUsuario_cadastro(retorno[0].usuario_cadastro || "");
        // Campos select options aqui abaixo é um exemplo
        // setSelectedTipoContratacao(retorno[0].tipocontratacao || '');
      })
      .catch((erro) => {
        setLoading(false);
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao carregar dados. Motivo: ${msg}`);
      });
  };
  useEffect(() => {
    if (idpaciente !== "novo") {
      carregar(url_CarregarDados_ID, idpaciente, id_clinica);
    }
  }, []);

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS
  const atualizarDados = async (
    id_clinica: string,
    id_paciente: string,
    status: string,
    cpf: string,
    nome: string,
    dtnascimento: string,
    telfixo: string,
    telcelular: string,
    email: string,
    telemerggencia: string,
    contemerggencia: string,
    telresponsavel: string,
    responsavel: string,
    logradouro: string,
    numero: string,
    bairro: string,
    compl: string,
    cnum: string,
    cidade: string,
    cuf: string,
    cep: string,
    tipocontratacao: string,
    formapag: string,
    convenio: string,
    codconvenio: string,
    tipoconselho: string,
    ufconselho: string,
    codconselho: string,
    ncbo: string,
    dtindicacao: string,
    nomeprofissional: string,
    unidprofissional: string,
    codcid: string,
    descricaocid: string,
    observacao: string,
    usercadastro: string,
    cadastro: string,
    usuario_cadastro: string
  ) => {
    const dados = {
      id_clinica,
      id_paciente,
      status,
      cpf,
      nome: TextoFormatoCaptalize(nome.toLowerCase()),
      dtnascimento,
      telfixo,
      telcelular,
      email: TextoFormatoLowerCase(email.toLowerCase()),
      telemerggencia,
      contemerggencia,
      telresponsavel,
      responsavel: TextoFormatoCaptalize(responsavel.toLowerCase()),
      logradouro: TextoFormatoCaptalize(logradouro.toLowerCase()),
      numero: TextoFormatoCaptalize(numero.toLowerCase()),
      bairro: TextoFormatoCaptalize(bairro.toLowerCase()),
      compl: TextoFormatoCaptalize(compl.toLowerCase()),
      cnum,
      cidade: TextoFormatoCaptalize(cidade.toLowerCase()),
      cuf,
      cep,
      tipocontratacao: selectedTipoContratacao,
      formapag,
      convenio,
      codconvenio,
      tipoconselho: selectedTipoConselho,
      ufconselho,
      codconselho,
      ncbo,
      dtindicacao,
      nomeprofissional: TextoFormatoCaptalize(nomeprofissional.toLowerCase()),
      unidprofissional: TextoFormatoCaptalize(unidprofissional.toLowerCase()),
      codcid,
      descricaocid: TextoFormatoCaptalize(descricaocid.toLowerCase()),
      observacao: TextoFormatoCaptalize(observacao.toLowerCase()),
      usercadastro,
      cadastro,
      usuario_cadastro
    };

    ApiPut(`/paciente/atualizar`, dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        toast.error(`Erro ao atualizar. Motivo: ${erro.response.data.message}`);
      });
  };
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS
  const gravarDados = (
    id_clinica: string,
    id_paciente: string,
    status: string,
    cpf: string,
    nome: string,
    dtnascimento: string,
    telfixo: string,
    telcelular: string,
    email: string,
    telemerggencia: string,
    contemerggencia: string,
    telresponsavel: string,
    responsavel: string,
    logradouro: string,
    numero: string,
    bairro: string,
    compl: string,
    cnum: string,
    cidade: string,
    cuf: string,
    cep: string,
    tipocontratacao: string,
    formapag: string,
    convenio: string,
    codconvenio: string,
    tipoconselho: string,
    ufconselho: string,
    codconselho: string,
    ncbo: string,
    dtindicacao: string,
    nomeprofissional: string,
    unidprofissional: string,
    codcid: string,
    descricaocid: string,
    observacao: string,
    usercadastro: string,
    cadastro: string,
    usuario_cadastro: string
  ) => {
    const dados = {
      id_clinica,
      id_paciente,
      status,
      cpf,
      nome: TextoFormatoCaptalize(nome.toLowerCase()),
      dtnascimento,
      telfixo,
      telcelular,
      email: TextoFormatoLowerCase(email.toLowerCase()),
      telemerggencia,
      contemerggencia,
      telresponsavel,
      responsavel: TextoFormatoCaptalize(responsavel.toLowerCase()),
      logradouro: TextoFormatoCaptalize(logradouro.toLowerCase()),
      numero: TextoFormatoCaptalize(numero.toLowerCase()),
      bairro: TextoFormatoCaptalize(bairro.toLowerCase()),
      compl: TextoFormatoCaptalize(compl.toLowerCase()),
      cnum,
      cidade: TextoFormatoCaptalize(cidade.toLowerCase()),
      cuf,
      cep,
      tipocontratacao: selectedTipoContratacao,
      formapag,
      convenio,
      codconvenio,
      tipoconselho: selectedTipoConselho,
      ufconselho,
      codconselho,
      ncbo,
      dtindicacao,
      nomeprofissional: TextoFormatoCaptalize(nomeprofissional.toLowerCase()),
      unidprofissional: TextoFormatoCaptalize(unidprofissional.toLowerCase()),
      codcid,
      descricaocid: TextoFormatoCaptalize(descricaocid.toLowerCase()),
      observacao: TextoFormatoCaptalize(observacao.toLowerCase()),
      usercadastro,
      cadastro,
      usuario_cadastro
      //tipocontratacao: selectedTipoContratacao,
    };
    ApiPost("/paciente/criar", dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };
  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if(enviaEmailPreCadastro && email){
      toast.error("Enviando e-mail de pré-cadastro para o paciente!");
    }
    else{
    if (idpaciente === "novo") {
      gravarDados(
        id_clinica,
        id_paciente || "0",
        status ? "1" : "0",
        cpf,
        nome,
        dtnascimento,
        telfixo,
        telcelular,
        email,
        telemerggencia,
        contemerggencia,
        telresponsavel,
        responsavel,
        logradouro,
        numero,
        bairro,
        compl,
        cnum,
        cidade,
        cuf,
        cep,
        tipocontratacao,
        formapag,
        convenio,
        codconvenio,
        tipoconselho,
        ufconselho,
        codconselho,
        ncbo,
        dtindicacao,
        nomeprofissional,
        unidprofissional,
        codcid,
        descricaocid,
        observacao,
        usercadastro,
        cadastro,
        usuario_cadastro
      );
      toast.info("Inserindo novo registro...");
    } else {
      atualizarDados(
        id_clinica,
        id_paciente || "0",
        status ? "1" : "0",
        cpf,
        nome,
        dtnascimento,
        telfixo,
        telcelular,
        email,
        telemerggencia,
        contemerggencia,
        telresponsavel,
        responsavel,
        logradouro,
        numero,
        bairro,
        compl,
        cnum,
        cidade,
        cuf,
        cep,
        tipocontratacao,
        formapag,
        convenio,
        codconvenio,
        tipoconselho,
        ufconselho,
        codconselho,
        ncbo,
        dtindicacao,
        nomeprofissional,
        unidprofissional,
        codcid,
        descricaocid,
        observacao,
        usercadastro,
        cadastro,
        usuario_cadastro
      );
      toast.info("Atualizando dados do registro...");
    }}
  };

  
  const buscarCep = async (cep: string) => {
    const cepSemMascara = cep.replace(/\D/g, "");
    if (cepSemMascara.length < 8) {
      toast.error("CEP inválido!");
    } else if (cepSemMascara.length === 8) {
      await axios
        .get(`https://viacep.com.br/ws/${cepSemMascara}/json/`)
        .then((retorno) => {
          if (retorno.data?.erro) {
            toast.error("CEP inválido ou Não Localizado!");
          } else {
            const dadosCep = retorno.data;
            
            setLogradouro(dadosCep.logradouro);
            setCompl(dadosCep.complemento);
            setBairro(dadosCep.bairro);
            setCidade(dadosCep.localidade);
            setCuf(dadosCep.uf);
            setCnum(dadosCep.ibge);
          }
        })
        .catch((erro) => {
          console.log("erro: ", erro);
          const msg =
            erro.response?.data.message === undefined
              ? erro.message
              : erro.response.data.message;
          toast.error(`Erro ao buscar cep. Motivo: ${msg}`);
        });
    }
  };

  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Box sx={{ width: "100%" }}>
              <TabContext value={guia}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
                <TabPanel value="1">
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="ID Paciente"
                        name="id_paciente"
                        type="text"
                        placeholder="ID Paciente"
                        readonly
                        value={id_paciente || ""}
                        onChange={setId_paciente}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="CPF"
                        name="cpf"
                        type="text"
                        placeholder="CPF"
                        readonly={
                          acao === "Visualizar" || acao === "Editar"
                            ? true
                            : false
                        }
                        // value={cpf || ""}
                        value={mascaraCPF(cpf) || ""}
                        onChange={setCpf}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                        required
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Nome"
                        name="nome"
                        type="text"
                        placeholder="Nome"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={nome || ""}
                        onChange={setNome}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Data Nascimento"
                        name="dtnascimento"
                        type="date"
                        placeholder="Data Nascimento"
                        // required
                        readonly={acao === "Visualizar" ? true : false}
                        value={dtnascimento}
                        onChange={setDtnascimento}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      />
                    </Col>
                    <Col sm={1}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Ativo
                      </label>
                      <Switch
                        checked={status}
                        onChange={() => setStatus(!status)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Email"
                        name="email"
                        type="text"
                        placeholder="Email"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={email || ""}
                        onChange={setEmail}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Telefone"
                        name="telefone"
                        type="text"
                        placeholder="Telefone Fixo"                        
                        readonly={acao === "Visualizar" ? true : false}
                        // value={telfixo || ""}
                        value={mascaraTelefoneFixo(telfixo) || ""}
                        onChange={setTelfixo}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Celular"
                        name="telcelular"
                        type="text"
                        placeholder="Celular"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        // value={telcelular || ""}
                        value={mascaraTelefoneCelular(telcelular) || ""}
                        onChange={setTelcelular}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Tel. Emergência"
                        name="telemerggencia"
                        type="text"
                        placeholder="Tel. Emergência"                        
                        readonly={acao === "Visualizar" ? true : false}
                        // value={telemerggencia || ""}
                        value={mascaraTelefoneCelular(telemerggencia) || ""}
                        onChange={setTelemerggencia}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Contato Emergência"
                        name="contemerggencia"
                        type="text"
                        placeholder="Contato Emergência"                        
                        readonly={acao === "Visualizar" ? true : false}
                        value={contemerggencia || ""}
                        onChange={setContemerggencia}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="CEP"
                        name="cep"
                        type="text"
                        placeholder="CEP"
                        readonly={acao === "Visualizar" ? true : false}
                        // value={cep || ""}
                        value={mascaraCEP(cep) || ""}
                        onChange={setCep}
                        onBlur={() => buscarCep(cep)}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Logradouro"
                        name="endereco"
                        type="text"
                        placeholder="Logradouro"
                        readonly={acao === "Visualizar" ? true : false}
                        value={logradouro || ""}
                        onChange={setLogradouro}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Complemento"
                        name="compl"
                        type="text"
                        placeholder="Complemento"
                        readonly={acao === "Visualizar" ? true : false}
                        value={compl || ""}
                        onChange={setCompl}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Número"
                        name="numero"
                        type="text"
                        placeholder="Número"
                        readonly={acao === "Visualizar" ? true : false}
                        value={numero || ""}
                        onChange={setNumero}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Bairro"
                        name="bairro"
                        type="text"
                        placeholder="Bairro"
                        readonly={acao === "Visualizar" ? true : false}
                        value={bairro || ""}
                        onChange={setBairro}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Cidade"
                        name="cidade"
                        type="text"
                        placeholder="Cidade"                        
                        readonly={acao === "Visualizar" ? true : false}
                        value={cidade || ""}
                        onChange={setCidade}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Cód. Município"
                        name="cnum"
                        type="text"
                        placeholder="Cód. Município"                        
                        readonly={acao === "Visualizar" ? true : false}
                        value={cnum || ""}
                        onChange={setCnum}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>

                    <Col sm={2}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1.5, width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          UF
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={cuf}
                          onChange={(e: SelectChangeEvent) =>
                            setCuf(e.target.value)
                          }
                          label="UF"
                          disabled={acao === "Visualizar" ? true : false}
                        >
                          <MenuItem value="">
                            <em>Selecione...</em>
                          </MenuItem>
                          {dadosUF.map((item: any) => (
                            <MenuItem value={item.uf}>{item.nome}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Tel. Responsável"
                        name="telresponsavel"
                        type="text"
                        placeholder="Tel. Responsável"
                        readonly={acao === "Visualizar" ? true : false}
                        // value={telresponsavel || ""}
                        value={mascaraTelefoneCelular(telresponsavel) || ""}
                        onChange={setTelresponsavel}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Responsável"
                        name="responsavel"
                        type="text"
                        placeholder="Responsável"
                        readonly={acao === "Visualizar" ? true : false}
                        value={responsavel || ""}
                        onChange={setResponsavel}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <SelectInput
                        value={selectedTipoContratacao}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setSelectedTipoContratacao(e.target.value)
                        }
                        options={tipoContratacaoOptions}
                        placeholder="Tipo Contratação"
                        disabed={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Forma Pagamento"
                        name="formapag"
                        type="text"
                        placeholder="Forma Pagamento"
                        readonly={acao === "Visualizar" ? true : false}
                        value={formapag || ""}
                        onChange={setFormapag}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Cod. Convênio"
                        name="codconvenio"
                        type="text"
                        placeholder="Cod. Convênio"
                        readonly={acao === "Visualizar" ? true : false}
                        value={codconvenio || ""}
                        onChange={setCodconvenio}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      {/* <InputSemBorda
                        label="Convênio"
                        name="convenio"
                        type="text"
                        placeholder="Convênio"
                        readonly={acao === "Visualizar" ? true : false}
                        value={convenio || ""}
                        onChange={setConvenio}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      /> */}
                      <FormControl
                        variant="standard"
                        sx={{ m: 1.5, width: "100%" }}
                      >
                        <InputLabel>Convênio</InputLabel>
                        <Select
                          value={convenio}
                          onChange={(e: SelectChangeEvent) =>
                            setConvenio(e.target.value)
                          }
                          label="Convênio"
                          required={
                            selectedTipoContratacao === "1" ||
                            selectedTipoContratacao === "3"
                              ? true
                              : false
                          }
                          disabled={
                            selectedTipoContratacao === "1" ||
                            selectedTipoContratacao === "3"
                              ? false
                              : true
                          }
                        >
                          <MenuItem value="">
                            <em>Selecione...</em>
                          </MenuItem>
                          {dadosConvenio.map((item: any) => (
                            <MenuItem value={item.id_convenio}>
                              {item.fantasia}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <SelectInput
                        value={selectedTipoConselho}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setSelectedTipoConselho(e.target.value)
                        }
                        options={tipoConselhoOptions}
                        placeholder="Tipo Conselho"
                        disabed={acao === "Visualizar" ? true : false}
                      />
                      {/* <p>Opção selecionada: {selectedTipoSala}</p> */}
                    </Col>
                    <Col sm={2}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1.5, width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          UF Conselho
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={ufconselho}
                          onChange={(e: SelectChangeEvent) =>
                            setUfconselho(e.target.value)
                          }
                          label="UF"
                          disabled={acao === "Visualizar" ? true : false}
                        >
                          <MenuItem value="">
                            <em>Selecione...</em>
                          </MenuItem>
                          {dadosUF.map((item: any) => (
                            <MenuItem value={item.uf}>{item.nome}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Código Conselho"
                        name="codconselho"
                        type="text"
                        placeholder="Código Conselho"
                        readonly={acao === "Visualizar" ? true : false}
                        value={codconselho || ""}
                        onChange={setCodconselho}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Código CBO"
                        name="ncbo"
                        type="text"
                        placeholder="Código CBO"
                        readonly={acao === "Visualizar" ? true : false}
                        value={ncbo || ""}
                        onChange={setNcbo}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Data Indicação"
                        name="dtindicacao"
                        type="date"
                        placeholder="Data Indicação"
                        readonly={acao === "Visualizar" ? true : false}
                        // value={converterDataInput(dtindicacao) || ""}
                        value={dtindicacao}
                        onChange={setDtindicacao}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Nome Profissional"
                        name="nomeprofissional"
                        type="text"
                        placeholder="Nome Profissional"
                        readonly={acao === "Visualizar" ? true : false}
                        value={nomeprofissional || ""}
                        onChange={setNomeprofissional}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Unidade Profissional"
                        name="unidprofissional"
                        type="text"
                        placeholder="Unidade Profissional"
                        readonly={acao === "Visualizar" ? true : false}
                        value={unidprofissional || ""}
                        onChange={setUnidprofissional}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Código CID"
                        name="codcid"
                        type="text"
                        placeholder="Código CID"
                        readonly={acao === "Visualizar" ? true : false}
                        value={codcid || ""}
                        onChange={setCodcid}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Descrição CID"
                        name="descricaocid"
                        type="text"
                        placeholder="Descrição CID"
                        readonly={acao === "Visualizar" ? true : false}
                        value={descricaocid || ""}
                        onChange={setDescricaocid}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <InputSemBorda
                        label="Observação"
                        name="observacao"
                        type="text"
                        placeholder="Observação"
                        readonly={acao === "Visualizar" ? true : false}
                        value={observacao || ""}
                        onChange={setObservacao}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                </TabPanel>
                
              </TabContext>
            </Box>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              
              <ButtonCustom
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
                disabled={acao === "Visualizar" ? true : false}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>
      <ModalUploadImage
        show={showUpload}
        label="Realizando Upload da Imagem ..."
      />
      <ModalLoading show={loading} label="Carregado dados da clinica ..." />
    </>
  );
};
export default FormPacientes;
