import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo";
import FormPacientes from "./components/formulario";

export function PacientesVisualizar() {
  const idpaciente = useParams();
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Visualizar Paciente" />
        <FormPacientes idpaciente={idpaciente.id} acao="Visualizar" />
      </div>
    </>
  );
}
