import { Card, Col, Row } from "react-bootstrap";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import { getSessionData, setSessionData } from "../../../utils/storageUtils";
import { useEffect, useState } from "react";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import ApiGet from "../../../api/endPoints/useGet";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import axios from "axios";

export function AgendaSalaAtendimento() {
  const navigate = useNavigate();
  const id_clinica = getSessionData("DadosIdClinica") || "";
  const token = getSessionData("MultClinicaWebToken") || "";

  const [loading, setLoading] = useState<boolean>(false);
  const [textoLoading, setTextoLoading] = useState<string>("");
  const [dadosSalas, setDadosSalas] = useState<any[]>([]);
  const buscarSalasAtenimento = (id_clinica: string) => {
    setLoading(true);
    setTextoLoading("Carregando Salas de Atendimento...");
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/consulta/listar/:id_clinica",
      parametros: {
        id_clinica: id_clinica,
      },
      headers,
    };
    ApiGet(dados)
      .then((resposta) => {
        setLoading(false);
        setTextoLoading("");
        setDadosSalas(resposta);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(
          `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
        );
      });
  };
  useEffect(() => {
    buscarSalasAtenimento(id_clinica);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_clinica]);
  const selecionarSala = (dados: any) => {
    setSessionData("DadosSalaAtendimento", dados);
    setSessionData("DiaSemanaSala", dados.dia_semana)
    navigate("/agendamento/salasatendimento/consultas");
  };



  const [busca, set_busca] = useState("")
  function buscar(texto: string) {

    if (texto.length == 0 || texto == "") {
      buscarSalasAtenimento(id_clinica)

    }
    else {


      const listaFiltrada = dadosSalas.filter(function (sala: any) {


        return sala.nome.toLowerCase().includes(texto.toLowerCase())
      })

      setDadosSalas(listaFiltrada)
    }
  }

  useEffect(function () {

    buscar(busca)
  }, [busca])


  useEffect(function () {

    
  }, [dadosSalas])


  //verifica permissões

  const token_ = getSessionData("MultClinicaWebToken")
  const isSuper = getSessionData("isPerfilSuper") || "";
  const [visualizar, set_visualizar] = useState(false)
  const [criar, set_criar] = useState(false)
  const [editar, set_editar] = useState(false)
  const [excluir, set_excluir] = useState(false)
  function verificaPermissoes(id_perfil: any) {

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/perfil/carregar/${id_perfil}`, {
      headers: {
        Authorization: token_
      }
    }).then(function (resposta) {

      set_visualizar(resposta.data[0].visualizarconsultas === "1" || isSuper === "1" ? true : false)

    }).catch(function (erro) {

      toast.error("Erro ao carregar permissão.")
    })
  }

  useEffect(function () {

    verificaPermissoes(getSessionData("DadosPerfilID"))
  }, [])

  return (
    <>
      <div className="page-content">
        <ContainerTitulo titulo="Agenda - Salas de Atendimento" />
        <Card>
          <Col>
            <div className="px-3">
              <InputSemBorda
                name="pesquisa"
                type="text"
                value={busca}
                onChange={set_busca}
                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize w-75"
                placeholder=" "
                label="Procurar por profissional"
              />
            </div>
          </Col>
          <Row>

            {visualizar == true ? dadosSalas.map(function (item: any) {

              return (
                <>
                  <Col sm={12} md={6} lg={3}>
                    <Card className="cardSalasAtendimento" onClick={() => { selecionarSala(item) }}>
                      <label className="text-center text-black">{item.sala}</label>
                      <hr className="margeLinha" />
                      <label className="text-black m-1">
                        Tipo Sala: <label htmlFor="">{item.desc_tiposala}</label>
                      </label>
                      <label className="text-black m-1">
                        Profisional: {item.nome}
                      </label>
                      <label className="text-black m-1">
                        Especialização: {item.especializacao}
                      </label>
                      <label className="text-black m-1">
                        Horário: {item.inicio} às {item.fim}
                      </label>
                      <label className="text-black m-1">
                        Dia: <b>{item.dia_semana == 1 ? "Domingo"
                          : item.dia_semana == 2 ? "Segunda"
                            : item.dia_semana == 3 ? "Terça"
                              : item.dia_semana == 4 ? "Quarta"
                                : item.dia_semana == 5 ? "Quinta"
                                  : item.dia_semana == 6 ? "Sexta"
                                    : item.dia_semana == 7 ? "Sábado"
                                      : "--"}</b>
                      </label>
                      <label className="text-black m-1">
                        Duração: {item.duracao_janela}
                      </label>
                    </Card>
                  </Col>
                </>
              )
            }) : <></>}
          </Row>
        </Card>
      </div>
      <ModalLoading show={loading} label={textoLoading} />
    </>
  );
}
