import React, { ChangeEvent } from "react";

export interface InputSemBordaProps {
  value: string;
  type:
    | "text"
    | "number"
    | "password"
    | "email"
    | "tel"
    | "date"
    | "time"
    | "datetime-local"
    | "month"
    | "week"
    | "url"
    | "search"
    | "color"
    | "file"
    | "range"
    | "hidden"
    | "image"
    | "button"
    | "reset"
    | "submit"
    | "checkbox"
    | "radio"
    | "select"
    | "textarea"
    | undefined;
  name: string;
  required?: boolean;
  placeholder?: string;
  classNameInputsSemBorda?: string;
  onChange: (value: string) => void;
  readonly?: boolean;
  label?: string;
  classNameLabel?: string;
  accept?: string;
  tittle?: string;
  useRef?: any;
  datacomponent?: string;
  datamonitorid?: string;
  id?: string;
  onBlur?: any;
  rows?: number;
  height?: string;
  step?: string;
}

const InputSemBorda: React.FC<InputSemBordaProps> = ({
  type,
  value,
  name,
  required,
  classNameInputsSemBorda,
  onChange,
  readonly,
  placeholder,
  label,
  classNameLabel,
  accept,
  tittle,
  useRef,
  datacomponent,
  datamonitorid,
  id,
  onBlur,
  rows,
  height,
  step,
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className="form-floating w-100">
      {type === "textarea" ? (
        <textarea
          className={classNameInputsSemBorda}
          value={value}
          name={name}
          required={required}
          onChange={handleChange}
          readOnly={readonly}
          placeholder={placeholder}
          title={tittle}
          ref={useRef}
          data-component={datacomponent}
          data-monitor-id={datamonitorid}
          id={id}
          onBlur={onBlur}
          rows={rows}
          style={{ height }} // Aplica a altura aqui
        />
      ) : (
        <input
          className={classNameInputsSemBorda}
          type={type}
          value={value}
          name={name}
          required={required}
          onChange={handleChange}
          readOnly={readonly}
          placeholder={placeholder}
          accept={accept}
          title={tittle}
          ref={useRef}
          data-component={datacomponent}
          data-monitor-id={datamonitorid}
          id={id}
          onBlur={onBlur}
          step={step}
        />
      )}
      {label ? (
        <label htmlFor={name} className={classNameLabel}>
          {label}
        </label>
      ) : null}
    </div>
  );
};

export default InputSemBorda;
