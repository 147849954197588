import { Row, Col, Card, Modal, Form } from "react-bootstrap";
import ContainerTitulo from "../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../components/Formularios/Inputs/InputsSemBorda";
import Tabela from "../../components/Formularios/Table";
import { ITabela } from "../../components/Formularios/Table/TabelaInterface";
import { getSessionData } from "../../utils/storageUtils";
import React, { ChangeEvent, useEffect, useState } from "react";
import ApiGet from "../../api/endPoints/useGet";
import { toast } from "react-toastify";
import ModalLoading from "../../components/Formularios/Modal/ModalLoading";
import {
  mdiCheckCircle,
  mdiReload,
  mdiFileReplace,
  mdiKeyboardReturn,
  mdiMagnify,
} from "@mdi/js";
import { Button } from "../../components/Formularios/Buttons/Button";
import SelectInput from "../../shared/SelectInputs";
import ApiPut from "../../api/endPoints/usePut";
import { TextoFormatoCaptalize } from "../../components/Auxiliar/ConvTextoCaptalize";
import ApiPost from "../../api/endPoints/usePost";
import axios from "axios";

export function AgendamentoAgenda() {
  const token = getSessionData("MultClinicaWebToken") || "";
  const id_clinica = getSessionData("DadosIdClinica") || "";
  const usuario = getSessionData("DadosUsuario") || "";

  const dadosTipoConsulta = [
    { value: "0", label: "Faturado" },
    { value: "1", label: "Convênio" },
    { value: "2", label: "Cortesia" },
  ];
  const colunas: ITabela[] = [
    { titulo: "Código", acesso: "id_agendamento" },
    { titulo: "Tipo", acesso: "tipoconsulta" },

    { titulo: "Sala", acesso: "sala" },
    { titulo: "Profissional", acesso: "profissional" },
    { titulo: "Paciente", acesso: "paciente" },
    { titulo: "Convênio", acesso: "desc_convenio" },
    { titulo: "Data Início", acesso: "data_inicio" },
    { titulo: "Hora Início", acesso: "hora_inicio" },
    { titulo: "Data Fim", acesso: "data_fim" },
    { titulo: "Hora Fim", acesso: "hora_fim" },
    { titulo: "Status", acesso: "statusagendamento" },
  ];

  const [dados, setDados] = useState([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [textoLoading, setTextoLoading] = useState<string>("");
  const buscarDados = async () => {
    setLoading(true);
    setTextoLoading("Aguarde, Buscando Agendamentos...");
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/agenda/listar/:id_clinica",
      parametros: {
        id_clinica: id_clinica,
      },
      headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");

        setDados(retorno);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    consultarTipoProcedimento();
    // consultarTipoContratacao();
    consultarTodosStatusAgenda();
    // buscarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [abrirModalAtualizarStatus, setAbrirModalAtualizarStatus] =
    useState<boolean>(false);

  // DADOS PARA O MODAL DE ATUALIZAR STATUS
  const [dadosModalAtualizarStatus, setDadosModalAtualizarStatus] =
    useState<any>([]);
  const atualizarAgenda = (dados: any) => {
    // console.log("dados: ", dados);
    /*if (dados.faturado === 1) {
      toast.warning(
        "Agendamento com convênio, não é possível atualizar o status."
      );
    } else {
      setDadosModalAtualizarStatus(dados);
      consultarStatusAgenda();
      setStatusAgenda("");
      setMotivo("");
      setAbrirModalAtualizarStatus(true);
    }*/

    setDadosModalAtualizarStatus(dados);
    consultarStatusAgenda();
    setStatusAgenda("");
    setMotivo("");
    setAbrirModalAtualizarStatus(true);
  };

  const [dadosStatusAgenda, setDadosStatusAgenda] = useState<any[]>([]);
  const [statusAgenda, setStatusAgenda] = useState<string>("");
  const [motivo, setMotivo] = useState<string>("");
  const consultarStatusAgenda = () => {
    setLoading(true);
    setTextoLoading("Carregando dados Status da Agenda...");
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/agenda/listar/statusagenda/STATUSAGENDA",
      parametros: {},
      headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        setDadosStatusAgenda(retorno);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };
  const [filtroStatusAgenda, setFiltroStatusAgenda] = useState<any[]>([]);
  const consultarTodosStatusAgenda = () => {
    setLoading(true);
    setTextoLoading("Carregando dados Status da Agenda...");
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/agenda/listar/todosstatusagenda/STATUSAGENDA",
      parametros: {},
      headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        setFiltroStatusAgenda(retorno);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };

  // const [dadosTipoContratacao, setDadosTipoContratacao] = useState<any[]>([]);
  // const consultarTipoContratacao = () => {
  //   setLoading(true);
  //   setTextoLoading("Carregando dados Tipo de Contratação...");
  //   const dados = {
  //     url: "/codigosistema/listar/TIPOCONTRATACAO",
  //     parametros: {},
  //   };
  //   ApiGet(dados)
  //     .then((retorno) => {
  //       setLoading(false);
  //       setTextoLoading("");
  //       setDadosTipoContratacao(retorno);
  //     })
  //     .catch((erro) => {
  //       setLoading(false);
  //       setTextoLoading("");
  //       toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
  //     });
  // };

  const [dadosTipoProcedimento, setDadosTipoProcedimento] = useState<any[]>([]);
  const consultarTipoProcedimento = () => {
    setLoading(true);
    setTextoLoading("Carregando dados Tipo de Procedimento...");
    const dados = {
      url: "/codigosistema/listar/TIPOPROCEDIMENTO",
      parametros: {},
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        setDadosTipoProcedimento(retorno);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };

  const gravar = async (
    id_agendamento: string,
    id_clinica: string,
    id_sala: string,
    usuario: string,
    statusagenda: string,
    motivo: string,
    id_paciente: string,
    id_tipo_atendimento: string,
    valor: string,
    valor_final: string,
    faturado: string
  ) => {
    if (!loading) {
      setLoading(true);
      setTextoLoading("Aguarde, Gravando Agendamento...");
      const dados = {
        usuario,
        statusagenda,
        motivo: TextoFormatoCaptalize(motivo.toLowerCase()),
        id_paciente,
        id_tipo_atendimento,
        valor,
        valor_final,
        faturado,
      };

      ApiPut(
        `/agenda/atualizar/${id_agendamento}/${id_clinica}/${id_sala}`,
        dados,
        token
      )
        .then((retorno) => {
          setLoading(false);
          setTextoLoading("");
          toast.success("Agendamento atualizado com sucesso!");

          setStatusAgenda("");
          setMotivo("");
          setAbrirModalAtualizarStatus(false);
          buscarDados();
        })
        .catch((erro) => {
          setLoading(false);
          setTextoLoading("");
          const msg =
            erro.response?.data.message !== undefined
              ? erro.response?.data.message
              : "Erro ao atualizar dados. Tente novamente.";
          toast.error(`Erro ao atualizar dados. Motivo: ${msg}`);
        });
    }
  };

  const gravarAtualizacaoAgenda = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    gravar(
      dadosModalAtualizarStatus.id_agendamento,
      dadosModalAtualizarStatus.id_clinica,
      dadosModalAtualizarStatus.id_sala,
      usuario,
      statusAgenda,
      motivo,
      dadosModalAtualizarStatus.id_paciente,
      dadosModalAtualizarStatus.id_tipo_atendimento,
      dadosModalAtualizarStatus.valor,
      dadosModalAtualizarStatus.valor_final,
      dadosModalAtualizarStatus.faturado
    );
  };

  const [abrirModalVisualizar, setAbrirModalVisualizar] =
    useState<boolean>(false);
  const [dadosModalVisualizar, setDadosModalVisualizar] = useState<any>([]);
  const visualizar = (dados: any) => {
    setDadosModalVisualizar(dados);
    setAbrirModalVisualizar(true);
  };

  //DADOS PARA PESQUISA
  const [sala, setSala] = useState<string>("");
  const [profissional, setProfissional] = useState<string>("");
  const [paciente, setPaciente] = useState<string>("");
  const [tipocontratacao, setTipoContratacao] = useState<string>("");
  const [convenio, setConvenio] = useState<string>("");
  const [tipoProcendimento, setTipoProcedimento] = useState<string>("");
  const [datainicio, setDataInicio] = useState<string>("");
  const [horainicio, setHoraInicio] = useState<string>("");
  const [datafim, setDataFim] = useState<string>("");
  const [horafim, setHoraFim] = useState<string>("");
  const [statusAgendaPesquisa, setStatusAgendaPesquisa] = useState<string>("");

  const pesquisar = async (
    id_clinica: string,
    sala: string,
    profissional: string,
    paciente: string,
    tipocontratacao: string,
    convenio: string,
    tipoprocedimento: string,
    datainicio: string,
    horainicio: string,
    datafim: string,
    horafim: string,
    status: string
  ) => {
    setLoading(true);
    setTextoLoading("Aguarde, Pesquisando Agendamentos...");

    const dados = {
      sala,
      profissional,
      paciente,
      tipocontratacao,
      convenio,
      tipoprocedimento,
      datainicio,
      horainicio,
      datafim,
      horafim,
      status,
    };
    ApiPost(`/agenda/pesquisar/${id_clinica}`, dados, token)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");

        setDados(retorno);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Erro ao listar dados. Motivo: ${msg}`);
      });
  };
  const pesquiarAgendamento = (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();

    if (visualizar_agenda == true) {
      pesquisar(
        id_clinica,
        sala,
        profissional,
        paciente,
        tipocontratacao,
        convenio,
        tipoProcendimento,
        datainicio,
        horainicio,
        datafim,
        horafim,
        statusAgendaPesquisa
      );
    }
    else {

      toast.error("Perfil sem permissão para visualizar Agenda.")
    }
  };

  const limparFiltro = () => {
    setSala("");
    setProfissional("");
    setPaciente("");
    setTipoContratacao("");
    setConvenio("");
    setTipoProcedimento("");
    setDataInicio("");
    setHoraInicio("");
    setDataFim("");
    setHoraFim("");
    setStatusAgendaPesquisa("");

    consultarTipoProcedimento();
    // consultarTipoContratacao();
    consultarTodosStatusAgenda();
    buscarDados();
  };



  const token_ = getSessionData("MultClinicaWebToken")
  const isSuper = getSessionData("isPerfilSuper") || "";
  const [visualizar_agenda, set_visualizar] = useState(false)
  const [criar, set_criar] = useState(false)
  const [editar, set_editar] = useState(false)
  const [excluir, set_excluir] = useState(false)
  function verificaPermissoes(id_perfil: any) {

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/perfil/carregar/${id_perfil}`, {
      headers: {
        Authorization: token_
      }
    }).then(function (resposta) {

      set_visualizar(resposta.data[0].visualizaragenda === "1" || isSuper === "1" ? true : false)
      set_criar(resposta.data[0].criaragenda === "1" || isSuper === "1" ? true : false)
      set_editar(resposta.data[0].editaragenda === "1" || isSuper === "1" ? true : false)
      set_excluir(resposta.data[0].excluiragenda === "1" || isSuper === "1" ? true : false)
    }).catch(function (erro) {

      toast.error("Erro ao carregar permissão.")
    })
  }

  useEffect(function () {

    verificaPermissoes(getSessionData("DadosPerfilID"))
  }, [])

  return (
    <>
      <div className="container-fluid">
        <ContainerTitulo titulo="Listagem de Agendamentos" />
      </div>
      <Row>
        <Col sm={12}>
          <Card>
            <form onSubmit={pesquiarAgendamento}>
              <Card.Body>
                <Row>
                  {/* <div className="d-flex my-2 gap-1 "> */}
                  <Col sm={2}>
                    <InputSemBorda
                      name="sala"
                      type="text"
                      value={sala}
                      onChange={setSala}
                      classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      placeholder="Sala"
                      label="Sala"
                    />
                  </Col>
                  <Col sm={2}>
                    <InputSemBorda
                      name="profissional"
                      type="text"
                      value={profissional}
                      onChange={setProfissional}
                      classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      placeholder="Profissional"
                      label="Profissional"
                    />
                  </Col>
                  <Col sm={2}>
                    <InputSemBorda
                      name="paciente"
                      type="text"
                      value={paciente}
                      onChange={setPaciente}
                      classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      placeholder="Paciente"
                      label="Paciente"
                    />
                  </Col>
                  <Col sm={2}>
                    <SelectInput
                      value={tipocontratacao}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                        setTipoContratacao(e.target.value)
                      }
                      options={dadosTipoConsulta}
                      placeholder="Tipo Consulta"
                    />
                  </Col>
                  <Col sm={2}>
                    <InputSemBorda
                      name="convenio"
                      type="text"
                      value={convenio}
                      onChange={setConvenio}
                      classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      placeholder="Convênio"
                      label="Convênio"
                    />
                  </Col>
                  <Col sm={2}>
                    <SelectInput
                      value={tipoProcendimento}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                        setTipoProcedimento(e.target.value)
                      }
                      options={dadosTipoProcedimento}
                      placeholder="Tipo Procedimento"
                    />
                  </Col>
                  {/* </div> */}
                </Row>
                <Row>
                  <Col sm={2}>
                    <InputSemBorda
                      name="datainicio"
                      type="date"
                      value={datainicio}
                      onChange={setDataInicio}
                      classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      placeholder="Data Início"
                      label="Data Início"
                    />
                  </Col>
                  <Col sm={2}>
                    <InputSemBorda
                      name="horainicio"
                      type="time"
                      value={horainicio}
                      onChange={setHoraInicio}
                      classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      placeholder="Hora Início"
                      label="Hora Início"
                    />
                  </Col>
                  <Col sm={2}>
                    <InputSemBorda
                      name="datafim"
                      type="date"
                      value={datafim}
                      onChange={setDataFim}
                      classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      placeholder="Data Fim"
                      label="Data Fim"
                    />
                  </Col>
                  <Col sm={2}>
                    <InputSemBorda
                      name="horafim"
                      type="time"
                      value={horafim}
                      onChange={setHoraFim}
                      classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      placeholder="Hora Fim"
                      label="Hora Fim"
                    />
                  </Col>
                  <Col sm={2}>
                    <SelectInput
                      value={statusAgendaPesquisa}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                        setStatusAgendaPesquisa(e.target.value)
                      }
                      options={filtroStatusAgenda}
                      placeholder="Status Agendamento"
                    />
                  </Col>
                  {/* botao pesquisar alinhado com o input */}
                  <Col sm={2}>
                    <label className="form-label">&nbsp;</label>
                    <div className="input-group input-group-merge">
                      <Button
                        type="submit"
                        className="btn btn-light text-info-emphasis bg-white border-0"
                        descricaoBotao=""
                        title="Pesquisar"
                        icone={mdiMagnify}
                      />
                      <Button
                        type="button"
                        className="btn btn-light bg-white border-0"
                        descricaoBotao=""
                        icone={mdiReload}
                        title="Limpar Filtro"
                        onclick={limparFiltro}
                      />
                    </div>
                  </Col>
                </Row>

                <hr />
                <Row>
                  <Tabela
                    coluna={colunas}
                    dados={dados}
                    itemsPerPage={5}
                    onVisuClick={visualizar}
                    onEditClick={() => { }}
                    usaEdit={false}
                    usaDelete={excluir}
                    onDeleteClick={atualizarAgenda}
                    iconeExcluir={mdiFileReplace}
                    descExcluir="Atualizar Status"
                    onImprimirClick={() => { }}
                    usaImprimir={false}
                  />
                  {/*
                                      usaDelete={dados.some(
                      (item: any) =>
                        item.statusagendamento !== "3" &&
                        item.statusagendamento !== "5" &&
                        item.statusagendamento !== "6" &&
                        item.statusagendamento !== "9"
                    )}
                  */}
                </Row>
              </Card.Body>
            </form>
          </Card>
        </Col>
      </Row>

      <ModalLoading show={loading} label={textoLoading} />

      <Modal show={abrirModalAtualizarStatus} size="lg">
        <Modal.Header className="bg-white">
          <Modal.Title>Atualizar Agendamento</Modal.Title>
        </Modal.Header>
        <Form onSubmit={gravarAtualizacaoAgenda}>
          <Modal.Body className="bg-white">
            <Row>
              <Col sm={3}>
                <InputSemBorda
                  name="pesquisa"
                  type="text"
                  value={dadosModalAtualizarStatus.id_agendamento}
                  onChange={() => { }}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  placeholder="Código"
                  label="Código"
                />
              </Col>
              <Col sm={3}>
                <InputSemBorda
                  name="pesquisa"
                  type="text"
                  value={
                    dadosModalAtualizarStatus.statusagendamento === "0"
                      ? "Novo"
                      : dadosModalAtualizarStatus.statusagendamento === "1"
                        ? "Falta sem Justificatica"
                        : dadosModalAtualizarStatus.statusagendamento === "2"
                          ? "Falta com Justificatica"
                          : dadosModalAtualizarStatus.statusagendamento === "3"
                            ? "Executada"
                            : dadosModalAtualizarStatus.statusagendamento === "4"
                              ? "Confirmado"
                              : dadosModalAtualizarStatus.statusagendamento === "5"
                                ? "Aguardando Atendimento"
                                : dadosModalAtualizarStatus.statusagendamento === "9"
                                  ? "Cancelada"
                                  : ""
                  }
                  onChange={() => { }}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  placeholder="Status"
                  label="Status"
                />
              </Col>
              <Col sm={3}>
                <InputSemBorda
                  name="pesquisa"
                  type="text"
                  value={
                    dadosModalVisualizar.faturado === 0
                      ? "Faturado"
                      : dadosModalVisualizar.faturado === 1
                        ? "Convênio"
                        : dadosModalVisualizar.faturado === 2
                          ? "Cortesia"
                          : "Não Informado"
                  }
                  onChange={() => { }}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  placeholder="Tipo Consulta"
                  label="Tipo Consulta"
                />
              </Col>
              <Col sm={3}>
                <InputSemBorda
                  name="pesquisa"
                  type="text"
                  value={dadosModalVisualizar.desc_convenio}
                  onChange={() => { }}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  placeholder="Convênio"
                  label="Convênio"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <InputSemBorda
                  name="pesquisa"
                  type="text"
                  value={dadosModalAtualizarStatus.sala}
                  onChange={() => { }}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  placeholder="Sala"
                  label="Sala"
                />
              </Col>
              <Col sm={4}>
                <InputSemBorda
                  name="pesquisa"
                  type="text"
                  value={dadosModalAtualizarStatus.tiposala}
                  onChange={() => { }}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  placeholder="Tipo de Sala"
                  label="Tipo de Sala"
                />
              </Col>
              <Col sm={4}>
                <InputSemBorda
                  name="pesquisa"
                  type="text"
                  value={dadosModalAtualizarStatus.profissional}
                  onChange={() => { }}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  placeholder="Profissional"
                  label="Profissional"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={7}>
                <InputSemBorda
                  name="pesquisa"
                  type="text"
                  value={dadosModalAtualizarStatus.paciente}
                  onChange={() => { }}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  placeholder="Paciente"
                  label="Paciente"
                />
              </Col>
              <Col sm={5}>
                <InputSemBorda
                  name="pesquisa"
                  type="text"
                  value={dadosModalAtualizarStatus.telefone}
                  onChange={() => { }}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  placeholder="Telefone"
                  label="Telefone"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <InputSemBorda
                  name="pesquisa"
                  type="text"
                  value={dadosModalAtualizarStatus.data_inicio}
                  onChange={() => { }}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  placeholder="Data Início"
                  label="Data Início"
                />
              </Col>
              <Col sm={3}>
                <InputSemBorda
                  name="pesquisa"
                  type="text"
                  value={dadosModalAtualizarStatus.hora_inicio}
                  onChange={() => { }}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  placeholder="Hora Início"
                  label="Hora Início"
                />
              </Col>
              <Col sm={3}>
                <InputSemBorda
                  name="pesquisa"
                  type="text"
                  value={dadosModalAtualizarStatus.data_fim}
                  onChange={() => { }}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  placeholder="Data Fim"
                  label="Data Fim"
                />
              </Col>
              <Col sm={3}>
                <InputSemBorda
                  name="pesquisa"
                  type="text"
                  value={dadosModalAtualizarStatus.hora_fim}
                  onChange={() => { }}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  placeholder="Hora Fim"
                  label="Hora Fim"
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <InputSemBorda
                  name="pesquisa"
                  type="text"
                  value={dadosModalAtualizarStatus.procedimento}
                  onChange={() => { }}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  placeholder="Procedimento"
                  label="Procedimento"
                />
              </Col>
            </Row>
            {/* alterar status do agendameneto */}
            <Row>
              <Col sm={12}>
                <SelectInput
                  value={statusAgenda}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    setStatusAgenda(e.target.value)
                  }
                  options={dadosStatusAgenda}
                  placeholder="Atualizar Status Agendamento"
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <InputSemBorda
                  name="pesquisa"
                  type="text"
                  value={motivo}
                  onChange={setMotivo}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                  placeholder="Motivo"
                  label="Motivo"
                  required={
                    statusAgenda === "1" || statusAgenda === "2" ? true : false
                  }
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="bg-white">
            <div className="d-flex my-2 gap-1 justify-content-between">
              <Button
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => setAbrirModalAtualizarStatus(false)}
              />
              <Button
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
              />
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* ABRIR MODAL VISUALIZAR AGENDAMENTO */}
      <Modal show={abrirModalVisualizar} size="lg">
        <Modal.Header className="bg-white">
          <Modal.Title>Visualizar Agendamento</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          <Row>
            <Col sm={3}>
              <InputSemBorda
                name="pesquisa"
                type="text"
                value={dadosModalVisualizar.id_agendamento}
                onChange={() => { }}
                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                placeholder="Código"
                label="Código"
              />
            </Col>
            <Col sm={3}>
              <InputSemBorda
                name="pesquisa"
                type="text"
                value={
                  dadosModalVisualizar.statusagendamento === "0"
                    ? "Novo"
                    : dadosModalVisualizar.statusagendamento === "1"
                      ? "Falta sem Justificatica"
                      : dadosModalVisualizar.statusagendamento === "2"
                        ? "Falta com Justificatica"
                        : dadosModalVisualizar.statusagendamento === "3"
                          ? "Executada"
                          : dadosModalVisualizar.statusagendamento === "4"
                            ? "Confirmado"
                            : dadosModalVisualizar.statusagendamento === "5"
                              ? "Aguardando Atendimento"
                              : dadosModalVisualizar.statusagendamento === "6"
                                ? "Aguardando Pagamento"
                                : dadosModalVisualizar.statusagendamento === "9"
                                  ? "Cancelada"
                                  : ""
                }
                onChange={() => { }}
                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                placeholder="Status"
                label="Status"
              />
            </Col>
            <Col sm={3}>
              <InputSemBorda
                name="pesquisa"
                type="text"
                value={
                  dadosModalVisualizar.faturado === 0
                    ? "Faturado"
                    : dadosModalVisualizar.faturado === 1
                      ? "Convênio"
                      : dadosModalVisualizar.faturado === 2
                        ? "Cortesia"
                        : "Não Informado"
                }
                onChange={() => { }}
                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                placeholder="Tipo Consulta"
                label="Tipo Consulta"
              />
            </Col>
            <Col sm={3}>
              <InputSemBorda
                name="pesquisa"
                type="text"
                value={dadosModalVisualizar.desc_convenio}
                onChange={() => { }}
                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                placeholder="Convênio"
                label="Convênio"
              />
            </Col>
          </Row>

          <Row>
            <Col sm={3}>
              <InputSemBorda
                name="pesquisa"
                type="text"
                value={dadosModalVisualizar.sala}
                onChange={() => { }}
                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                placeholder="Sala"
                label="Sala"
              />
            </Col>
            <Col sm={3}>
              <InputSemBorda
                name="pesquisa"
                type="text"
                value={dadosModalVisualizar.tiposala}
                onChange={() => { }}
                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                placeholder="Tipo de Sala"
                label="Tipo de Sala"
              />
            </Col>
            <Col sm={6}>
              <InputSemBorda
                name="pesquisa"
                type="text"
                value={dadosModalVisualizar.profissional}
                onChange={() => { }}
                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                placeholder="Profissional"
                label="Profissional"
              />
            </Col>
          </Row>

          <Row>
            <Col sm={7}>
              <InputSemBorda
                name="pesquisa"
                type="text"
                value={dadosModalVisualizar.paciente}
                onChange={() => { }}
                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                placeholder="Paciente"
                label="Paciente"
              />
            </Col>
            <Col sm={5}>
              <InputSemBorda
                name="pesquisa"
                type="text"
                value={dadosModalVisualizar.telefone}
                onChange={() => { }}
                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                placeholder="Telefone"
                label="Telefone"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <InputSemBorda
                name="pesquisa"
                type="text"
                value={dadosModalVisualizar.data_inicio}
                onChange={() => { }}
                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                placeholder="Data Início"
                label="Data Início"
              />
            </Col>
            <Col sm={3}>
              <InputSemBorda
                name="pesquisa"
                type="text"
                value={dadosModalVisualizar.hora_inicio}
                onChange={() => { }}
                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                placeholder="Hora Início"
                label="Hora Início"
              />
            </Col>
            <Col sm={3}>
              <InputSemBorda
                name="pesquisa"
                type="text"
                value={dadosModalVisualizar.data_fim}
                onChange={() => { }}
                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                placeholder="Data Fim"
                label="Data Fim"
              />
            </Col>
            <Col sm={3}>
              <InputSemBorda
                name="pesquisa"
                type="text"
                value={dadosModalVisualizar.hora_fim}
                onChange={() => { }}
                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                placeholder="Hora Fim"
                label="Hora Fim"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <InputSemBorda
                name="pesquisa"
                type="text"
                value={dadosModalVisualizar.procedimento}
                onChange={() => { }}
                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                placeholder="Procedimento"
                label="Procedimento"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <InputSemBorda
                name="pesquisa"
                type="text"
                value={dadosModalVisualizar.motivo}
                onChange={() => { }}
                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                placeholder="Motivo"
                label="Motivo"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="bg-white">
          <div className="d-flex my-2 gap-1 justify-content-end">
            <Button
              type="button"
              className="btn btn-light text-danger bg-white border-0"
              descricaoBotao="Voltar"
              icone={mdiKeyboardReturn}
              onclick={() => setAbrirModalVisualizar(false)}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
