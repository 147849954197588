import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../../../../components/Formularios/Modal/ModalLoading";
import { useState, useEffect, ChangeEvent } from "react";
import { toast } from "react-toastify";
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../../components/Formularios/Inputs/InputsSemBorda";

import {
  Box,
  Switch,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tab,
  Avatar,
  Stack,
  Button,
  Paper,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button as ButtonCustom } from "../../../../../../components/Formularios/Buttons/Button";
import {
  mdiCheckCircle,
  mdiKeyboardReturn,
  mdiLockCheckOutline,
} from "@mdi/js";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ApiPost from "../../../../../../api/endPoints/usePost";
import ApiPut from "../../../../../../api/endPoints/usePut";
import ModalUploadImage from "../../../../../../components/Formularios/Modal/ModalUpload";
import { styled } from "@mui/material/styles";
import ConverterBase64 from "../../../../../../components/ConversorBase64";
import CarregarDados from "../CarregarDados";
// import { InterfaceDadosEmpresa } from "../../../../../types/EmpresaInteface";
import { url_CarregarDados_ID } from "../auxiliar";
import { dadosUF } from "../../../../../../components/dadosFixos/UF";
import { dadosPais } from "../../../../../../components/dadosFixos/Pais";
import { TextoFormatoCaptalize } from "../../../../../../components/Auxiliar/ConvTextoCaptalize";
import { TextoFormatoLowerCase } from "../../../../../../components/Auxiliar/ConvTextoLower";
import { TextoFormatoUpperCase } from "../../../../../../components/Auxiliar/ConvTextoUpper";
import useSelectOptions from "../../../../../../hooks/useSelectOptions";
import SelectInput from "../../../../../../shared/SelectInputs";
import { getSessionData } from "../../../../../../utils/storageUtils";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F0F0F0",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "#616161",
  borderRadius: "5px",
  fontWeight: "bold",
  fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
}));

interface FormAgendaSalaProps {
  idagendasala?: string;
  acao?: string;
}

const FormAgendaSala = ({ idagendasala, acao }: FormAgendaSalaProps) => {
  const idclinica = getSessionData("DadosIdClinica") || "";
  const navigate = useNavigate();

  //#region Controla as guias
  const [guia, setGuia] = useState("1");

  const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
    setGuia(novaGuia);
  };
  //#endregion

  const [showUpload, setShowUpload] = useState<boolean>(false);


  const [id_agenda_sala, setId_agenda_sala] = useState<any>(idagendasala || '');
  const [fantasia, setFantasia] = useState<string>('');
  const [id_sala, setId_sala] = useState<string>('');
  const [sala, setSala] = useState<string>('');
  const [id_janela, setId_janela] = useState<string>('');
  const [status, setStatus] = useState<boolean>(true);
  const [tiposala, setTiposala] = useState<string>('');
  const [desctiposala, setDesctiposala] = useState<string>('');
  const [bloco, setBloco] = useState<string>('');
  const [id_profissional, setId_profissional] = useState<string>('');
  const [inicio, setInicio] = useState<string>('');
  const [fim, setFim] = useState<string>('');
  const [duracao_janela, setDuracao_janela] = useState<string>('');
  const [dia_semana, setDia_semana] = useState<string>('');
  const [feriado, setFeriado] = useState<boolean>(true);
  const [cadastro, setCadastro] = useState<string>('');
  const [usuario_cadastro, setUsuario_cadastro] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);

  //#region ==> Select Options
  // Usando o hook useSelectOptions para obter as opções diretamente  
  const salaOptions: any = useSelectOptions(`/sala/listarsala/${idclinica}`);
  const tipoSalaOptions = useSelectOptions(`/sala/listartiposala`);
  const blocoOptions = useSelectOptions(`/codigosistema/listar/BLOCOAGENDA`);
  const diaSemanaOptions = useSelectOptions(`/codigosistema/listar/DIASDASEMANA`);
  const profissionalOptions = useSelectOptions(`/agendasala/listarprofissionais/${idclinica}`);
  const [selectedSala, setSelectedSala] = useState('');
  const [selectedTipoSala, setSelectedTipoSala] = useState('');
  const [selectedBloco, setSelectedBloco] = useState('');
  const [selectedDiaSemana, setSelectedDiaSemana] = useState('');
  const [selectedProfissional, setSelectedProfissional] = useState('');


  //#endregion

  const handleSalaChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = e.target.value as string;

    // Encontrar o tipo de sala correspondente a sala selecionada
    const selectedSala = salaOptions.find((item: any) => item.value === selectedValue);

    if (selectedSala) {
   
      setSelectedSala(selectedSala.value);
      setDesctiposala(selectedSala.descricao);
      setTiposala(selectedSala.tiposala)
    } else {

      setSelectedSala('');
      setDesctiposala('');
    }
  };

  const carregar = (url: string, idagendasala: any, idclinica: string) => {
    setLoading(true);
    CarregarDados(url, idagendasala, idclinica)
      .then((retorno) => {
        setLoading(false);

        setId_agenda_sala(retorno[0].id_agenda_sala || '');
        // setId_clinica(retorno[0].id_clinica || '');
        setFantasia(retorno[0].fantasia || '');
        // setId_sala(retorno[0].id_sala || '');
        setSelectedSala(retorno[0].id_sala || '');
        setSala(retorno[0].sala || '');
        setId_janela(retorno[0].id_janela || '');
        setStatus(retorno[0].status === 1 ? true : false);
        setTiposala(retorno[0].tiposala || '');
        setSelectedTipoSala(retorno[0].tiposala || '');
        setDesctiposala(retorno[0].desctiposala || '');
        // setBloco(retorno[0].bloco || '');
        setSelectedBloco(retorno[0].bloco || '');
        // setProfissional(retorno[0].profissional || '');
        setSelectedProfissional(retorno[0].id_profissional || '');
        setInicio(retorno[0].inicio || '');
        setFim(retorno[0].fim || '');
        setDuracao_janela(retorno[0].duracao_janela || '');
        // setDia_semana(retorno[0].dia_semana || '');
        console.log(retorno[0].dia_semana)
        setSelectedDiaSemana(retorno[0].dia_semana || '');
        setFeriado(retorno[0].feriado === 1 ? true : false);
        setCadastro(retorno[0].cadastro || '');
        setUsuario_cadastro(retorno[0].usuario_cadastro || '');
      })
      .catch((erro) => {
        setLoading(false);
        const msg = erro.response?.data.message === undefined ? erro.message : erro.response.data.message;
        toast.error("Falha ao carregar registro. Motivo: ", msg);
      });
  };
  useEffect(() => {
    if (idagendasala !== "novo") {
      carregar(url_CarregarDados_ID, idagendasala, idclinica);
    }
  }, []);

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS
  const atualizarDados = async (
    id_agenda_sala: string,
    id_clinica: string,
    fantasia: string,
    id_sala: string,
    sala: string,
    id_janela: string,
    status: string,
    tiposala: string,
    desctiposala: string,
    bloco: string,
    id_profissional: string,
    inicio: string,
    fim: string,
    duracao_janela: string,
    dia_semana: string,
    feriado: string,
    cadastro: string,
    usuario_cadastro: string,

  ) => {
    const dados = {
      id_agenda_sala,
      id_clinica,
      fantasia,
      id_sala: selectedSala,
      sala,
      id_janela,
      status,
      tiposala,
      desctiposala,
      bloco: selectedBloco,
      id_profissional: selectedProfissional,
      inicio,
      fim,
      duracao_janela,
      dia_semana: selectedDiaSemana,
      feriado,
      cadastro,
      usuario_cadastro,
    };

    ApiPut(`/agendasala/atualizar/${idagendasala}`, dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        toast.error(`Erro ao atualizar. Motivo: ${erro.response.data.message}`);
      });
  };
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS
  const gravarDados = (
    id_agenda_sala: string,
    id_clinica: string,
    fantasia: string,
    id_sala: string,
    sala: string,
    id_janela: string,
    status: string,
    tiposala: string,
    desctiposala: string,
    bloco: string,
    id_profissional: string,
    inicio: string,
    fim: string,
    duracao_janela: string,
    dia_semana: string,
    feriado: string,
    cadastro: string,
    usuario_cadastro: string,
  ) => {
    const dados = {
      id_agenda_sala,
      id_clinica,
      fantasia,
      id_sala: selectedSala,
      sala,
      id_janela,
      status,
      tiposala,
      desctiposala,
      bloco: selectedBloco,
      id_profissional: selectedProfissional,
      inicio,
      fim,
      duracao_janela,
      dia_semana: selectedDiaSemana,
      feriado,
      cadastro,
      usuario_cadastro,
    };
    ApiPost("/agendasala/criar", dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        const msg = erro.response?.data.message === undefined ? erro.message : erro.response.data.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };
  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (idagendasala === "novo") {
      gravarDados(
        id_agenda_sala,
        idclinica,
        fantasia,
        id_sala,
        sala,
        id_janela,
        status ? '1' : '0',
        tiposala,
        desctiposala,
        bloco,
        id_profissional,
        inicio,
        fim,
        duracao_janela,
        dia_semana,
        feriado ? '1' : '0',
        cadastro,
        usuario_cadastro,
      );
      // toast.info("Inserindo novo registro...");
    } else {
      atualizarDados(
        id_agenda_sala,
        idclinica,
        fantasia,
        id_sala,
        sala,
        id_janela,
        status ? '1' : '0',
        tiposala,
        desctiposala,
        bloco,
        id_profissional,
        inicio,
        fim,
        duracao_janela,
        dia_semana,
        feriado ? '1' : '0',
        cadastro,
        usuario_cadastro,
      );
      // toast.info("Atualizando dados do registro...");
    }
  };

  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Box sx={{ width: "100%" }}>
              <TabContext value={guia}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                </Box>
                <TabPanel value="1">
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="ID Agenda Sala"
                        name="id_agenda_sala"
                        type="text"
                        placeholder="ID Agenda Sala"
                        readonly
                        value={id_agenda_sala}
                        onChange={setId_agenda_sala}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>

                    <Col sm={2}>
                      <InputSemBorda
                        label="ID Sala"
                        name="id_sala"
                        type="text"
                        placeholder="ID Sala"
                        readonly
                        value={selectedSala}
                        onChange={setId_sala}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"

                      />
                    </Col>
                    <Col sm={4}>
                      <SelectInput
                        value={selectedSala || ''}
                        onChange={
                          handleSalaChange as any
                        }
                        options={salaOptions}
                        placeholder="Sala"
                        disabed={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Tipo Sala"
                        name="desctiposala"
                        type="text"
                        placeholder="Tipo Sala"
                        readonly
                        value={desctiposala || ''}
                        onChange={function(){

                        }}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                      {/* <SelectInput
                        value={selectedTipoSala || ''}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedTipoSala(e.target.value)}
                        options={tipoSalaOptions}
                        placeholder="Tipo Sala"
                      /> */}
                    </Col>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Ativo
                      </label>
                      <Switch
                        checked={status}
                        onChange={() => setStatus(!status)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                  </Row>
                  <Row>



                  </Row>
                  <Row>
                    <Col sm={3}>
                      <SelectInput
                        value={selectedProfissional || ''}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedProfissional(e.target.value)}
                        options={profissionalOptions}
                        placeholder="Profissional"
                      />
                      {/* <InputSemBorda
                        label="Profissional"
                        name="profissional"
                        type="text"
                        placeholder="Profissional"
                        readonly={acao === "Visualizar" ? true : false}
                        value={profissional || ""}
                        onChange={setProfissional}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      /> */}
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Início"
                        name="inicio"
                        type="time"
                        placeholder="Início"
                        readonly={acao === "Visualizar" ? true : false}
                        value={inicio || ""}
                        onChange={setInicio}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Fim"
                        name="fim"
                        type="time"
                        placeholder="Fim"
                        readonly={acao === "Visualizar" ? true : false}
                        value={fim || ""}
                        onChange={setFim}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Duração Janela"
                        name="duracao_janela"
                        type="time"
                        placeholder="Duração Janela"
                        readonly={acao === "Visualizar" ? true : false}
                        value={duracao_janela || ""}
                        onChange={setDuracao_janela}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={5}>
                      <SelectInput
                        value={selectedBloco || ''}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedBloco(e.target.value)}
                        options={blocoOptions}
                        placeholder="Bloco"
                        disabed={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={5}>
                      <SelectInput
                        value={selectedDiaSemana || ''}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedDiaSemana(e.target.value)}
                        options={diaSemanaOptions}
                        placeholder="Dia da Semana"
                        disabed={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Feriado
                      </label>
                      <Switch
                        checked={feriado}
                        onChange={() => setFeriado(!feriado)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel value="2">
                  <Row>
                    <Stack spacing={3}>
                      <Item>Configurações Básicas.</Item>
                    </Stack>
                  </Row>
                  <Row>
                    <Col>
                      <table className="table-hover border-bottom hover-bg-blue" >
                        <tr >
                          <th >Itens Por Página:</th>
                          <td>
                            <input
                              name="itenspagina"
                              value=""
                              type="number"
                              placeholder="Itens Por Página"
                              onChange={() => { }}
                              className="form-control bordasInferiorInput text-black text-capitalize"
                            />
                          </td>
                          <td>
                            Informe a quantidade de itens que serão exibidos nos
                            grid's. (Por padrão serão exibidos 5 itens por
                            página)
                          </td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                  <Row>
                    <Stack spacing={3}>
                      <Item>Logo - Informe a logomarca da empresa.</Item>
                    </Stack>
                  </Row>
                  <br />
                  {/* <Row className="mb-2">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      {avatar ? (
                        <img
                          alt=""
                          style={{
                            width: "70%",
                            height: "250px",
                            objectFit: "contain",
                            backgroundImage: `url(data:image/png;base64,${avatar})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                          }}
                        />
                      ) : (
                        <Avatar
                          src={""}
                          alt="Avatar"
                          sx={{ width: 240, height: 240 }}
                        />
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        id="avatar-input"
                        onChange={handleAvatarChange}
                        style={{ display: "none" }}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                      <label htmlFor="avatar-input">
                        <IconButton color="primary" component="span">
                          <PhotoCameraIcon />
                        </IconButton>
                      </label>
                      {avatar && acao !== "Visualizar" && (
                        <Button variant="outlined" onClick={handleRemoveAvatar}>
                          Remover Logo
                        </Button>
                      )}
                    </Stack>
                  </Row> */}
                  {/* <Row>
                    <Stack spacing={3}>
                      <Item>Dados de Integração</Item>
                    </Stack>
                  </Row> */}
                  <br />
                  {/* <Row> */}
                  {/* CAMPO PARA CLICAR NO BOTAO GERAR TOKEN E O MESMO SER GERADO */}
                  {/* <Col sm={12}>
                      <InputGroup>
                        <ButtonCustom
                          type="button"
                          className="btn btn-info "
                          icone={mdiLockCheckOutline}
                          descricaoBotao="Gerar Token"
                        />
                        <InputSemBorda
                          label="Token"
                          name="token"
                          type="text"
                          placeholder="token"
                          readonly
                          value={token}
                          onChange={setToken}
                          classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize "
                        />
                      </InputGroup>
                    </Col> */}
                  {/* </Row> */}
                </TabPanel>
              </TabContext>
            </Box>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <ButtonCustom
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
                disabled={acao === "Visualizar" ? true : false}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>
      <ModalUploadImage
        show={showUpload}
        label="Realizando Upload da Imagem ..."
      />
      <ModalLoading show={loading} label="Carregado dados da clinica ..." />
    </>
  );
};
export default FormAgendaSala;
