import { Row, Col, Card } from "react-bootstrap";
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";

import AddIcon from "@mui/icons-material/Add";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import Tabela from "../../../../components/Formularios/Table";

import { useEffect, useState } from "react";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import { InterfaceUsuario } from "../../../../types/UsuarioInterface";

import { toast } from "react-toastify";
import ApiGet from "../../../../api/endPoints/useGet";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir";
import ApiDelete from "../../../../api/endPoints/useDelete";
import { getSessionData } from "../../../../utils/storageUtils";
import ApiPost from "../../../../api/endPoints/usePost";

export function Anamnese() {
  const navigate = useNavigate();
  const id_clinica = getSessionData("DadosIdClinica") || "";
  const token = getSessionData("MultClinicaWebToken") || "";
  const perfil = getSessionData("DadosPerfilID") || "0";
  const isSuper = getSessionData("isPerfilSuper") || "";

  interface Action {
    icon: JSX.Element;
    name: string;
  }
  const actions: Action[] = [
    {
      icon: (
        <AddIcon
          onClick={() => navigate("/cadastro/pacientes/anamneses/novo")}
        />
      ),
      name: "Incluir",
    },
    // { icon: <FileCopyIcon />, name: "Duplicar" },
    // { icon: <SaveIcon />, name: "Salvar" },
    // { icon: <PrintIcon />, name: "Imprimir" },
  ];

  const visualizar = (dados: any) => {
    const idanamnese = dados.id_anamneses;
    navigate("/cadastro/pacientes/anamneses/visu/" + idanamnese);
  };

  const editar = async (dados: any) => {
    const idanamnese = dados.id_anamneses;
    navigate("/cadastro/pacientes/anamneses/editar/" + idanamnese);
  };

  const excluir = async (dados: any) => {
    const idanamnese = dados.id_anamneses;
    await showConfirmationDialog(
      "Excluir Anamnese",
      `Tem certeza que deseja excluir a anamnese do cliente \n ${dados.nome}?`,
      "question",
      "Excluir",
      "Cancelar"
    )
      .then((result) => {
        if (result.confirmed) {
          const dados = {
            url: "/anamnese/excluir/:idanamnese/:id_clinica",
            parametros: { idanamnese: idanamnese, id_clinica: id_clinica },
          };
          ApiDelete(dados)
            .then((retorno) => {
              toast.success(retorno.message);
              buscarDados();
            })
            .catch((erro) => {
              toast.error(
                `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
              );
            });
        }
      })
      .catch((erro) => {
        toast.error(
          `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
        );
      });
  };

  const colunas: ITabela[] = [
    { titulo: "ID Paciente", acesso: "id_paciente" },
    { titulo: "CPF", acesso: "cpf" },
    { titulo: "Nome", acesso: "nome" },
    { titulo: "Endereço", acesso: "logradouro" },
    { titulo: "Número", acesso: "numero" },
    { titulo: "Bairro", acesso: "bairro" },
    { titulo: "Cidade", acesso: "cidade" },
    { titulo: "UF", acesso: "cuf" },
    { titulo: "Cep", acesso: "cep" },
    { titulo: "Telefone", acesso: "telcelular" },
    // { titulo: "Situação", acesso: "status" },
  ];

  //#region REALIZA REQUISIÇÃO PARA LISTAR DADOS DA TABELA
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [textoLoading, setTextoLoading] = useState<string>("");
  const buscarDados = async () => {
    setLoading(true);
    setTextoLoading("Buscando Listagem de Anamneses...");
    const dados = {
      url: "/anamnese/listar/:id_clinica",
      parametros: {
        id_clinica: id_clinica,
      },
    };
    ApiGet(dados)
      .then((retorno) => {
        setDados(retorno);
        setLoading(false);
        setTextoLoading("");
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };

  const [permIncluir, setPermIncluir] = useState<boolean>(false);
  const [permAlterar, setPermAlterar] = useState<boolean>(false);
  const [permExcluir, setPermExcluir] = useState<boolean>(false);
  const [permVisualizar, setPermVisualizar] = useState<boolean>(false);
  const [permUsaAcoes, setPermUsaAcoes] = useState<boolean>(false);

  const buscarDadosPermissao = async (perfil: string) => {
    setLoading(true);
    setTextoLoading("Aguarde, Verificando Permissões...");
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/perfil/carregar/:id_perfil",
      parametros: {
        id_perfil: perfil,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        const retornoApi = retorno[0];
        setPermIncluir(
          retornoApi.cad_paciente_anamnese_incluir === "1" || isSuper === "1"
            ? true
            : false
        );
        setPermAlterar(
          retornoApi.cad_paciente_anamnese_editar === "1" || isSuper === "1"
            ? true
            : false
        );
        setPermExcluir(
          retornoApi.cad_paciente_anamnese_excluir === "1" || isSuper === "1"
            ? true
            : false
        );
        setPermVisualizar(
          retornoApi.cad_paciente_anamnese_visu === "1" || isSuper === "1"
            ? true
            : false
        );

        if (
          retornoApi.cad_paciente_anamnese_incluir === "1" ||
          retornoApi.cad_paciente_anamnese_editar === "1" ||
          retornoApi.cad_paciente_anamnese_excluir === "1" ||
          retornoApi.cad_paciente_anamnese_visu === "1" ||
          isSuper === "1"
        ) {
          setPermUsaAcoes(true);
        } else {
          setPermUsaAcoes(false);
        }

        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    buscarDadosPermissao(perfil);
    buscarDados();
  }, []);
  //#endregion

  const filtrarDados = async (filtro: string, vinculo: string) => {
    setLoading(true);
    setTextoLoading("Filtrando Listagem de Anamneses...");

    const dados = {
      filtro,
    };
    ApiPost(`/anamnese/filtro/${vinculo}`, dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        setDados(retorno);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        setLoading(false);
        toast.error(`Erro ao filtrar dados. Motivo: ${erro.message}`);
      });
  };
  const [pesquisa, setPesquisa] = useState<string>("");
  const pesquisar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (pesquisa === "") return buscarDados();
    else {
      const _filtro = pesquisa.toLowerCase();
      filtrarDados(_filtro, id_clinica);
    }
  };
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Listagem de Anamneses" />

        <Row>
          <Col sm={12}>
            <form onSubmit={pesquisar}>
              <Card>
                <Card.Body>
                  {permIncluir && (
                    <Row>
                      <SpeedDial
                        ariaLabel="SpeedDial basic example"
                        direction="left"
                        sx={{
                          right: 0,
                          position: "absolute",
                        }}
                        icon={<SpeedDialIcon />}
                      >
                        {actions.map((action) => (
                          <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                          />
                        ))}
                      </SpeedDial>
                    </Row>
                  )}
                  <Row>
                    <div className="d-flex my-2 gap-1 ">
                      <Col sm={permIncluir ? 11 : 12}>
                        <InputSemBorda
                          name="fantasia"
                          type="text"
                          value={pesquisa}
                          onChange={setPesquisa}
                          classNameInputsSemBorda="form-control bordasInferiorInput"
                          placeholder=" "
                          label="Pesquisar"
                        />
                      </Col>
                    </div>
                  </Row>

                  <Row>
                    <Tabela
                      coluna={colunas}
                      dados={dados}
                      itemsPerPage={10}
                      onVisuClick={visualizar}
                      onEditClick={editar}
                      onDeleteClick={excluir}
                      onImprimirClick={() => {}}
                      usaAcoes={permUsaAcoes}
                      usaEdit={permAlterar}
                      usaDelete={permExcluir}
                      usaVisu={permVisualizar}
                      usaImprimir={false}
                      id="codigo"
                    />
                  </Row>
                </Card.Body>
              </Card>
            </form>
          </Col>
        </Row>
      </div>
      <ModalLoading show={loading} label={textoLoading} />
    </>
  );
}
