import { Row, Col, Card, Modal, Form } from "react-bootstrap";
import ContainerTitulo from "../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../components/Formularios/Inputs/InputsSemBorda";
import Tabela from "../../components/Formularios/Table";
import { ITabela } from "../../components/Formularios/Table/TabelaInterface";
import { getSessionData } from "../../utils/storageUtils";
import React, { ChangeEvent, useEffect, useState } from "react";
import ApiGet from "../../api/endPoints/useGet";
import { toast } from "react-toastify";
import ModalLoading from "../../components/Formularios/Modal/ModalLoading";
import {
  mdiCheckCircle,
  mdiCheckboxMultipleMarkedOutline,
  mdiFileReplace,
  mdiKeyboardReturn,
} from "@mdi/js";
import SelectInput from "../../shared/SelectInputs";
import { Button } from "../../components/Formularios/Buttons/Button";
import ApiPost from "../../api/endPoints/usePost";
import { showConfirmationDialog } from "../../components/Formularios/Modal/ModalExcluir";
import axios from "axios";

export function Autorizacao() {
  const token = getSessionData("MultClinicaWebToken") || "";
  const id_clinica = getSessionData("DadosIdClinica") || "";
  const usuario = getSessionData("DadosUsuario") || "";

  const colunas: ITabela[] = [
    { titulo: "Código", acesso: "id_agendamento" },
    { titulo: "Data", acesso: "data" },
    { titulo: "Paciente", acesso: "paciente" },
    { titulo: "Data Nasc.", acesso: "data_nascimento" },
    { titulo: "Idade", acesso: "idade" },

    { titulo: "Convênio", acesso: "nomeconvenio" },
    { titulo: "Senha", acesso: "senha" },
    { titulo: "Status Senha", acesso: "descstatussenha" },
    { titulo: "Guia", acesso: "guia" },
    { titulo: "Status Guia", acesso: "descstatusguia" },
  ];

  // const [dados, setDados] = useState([]);
  const [dados, setDados] = useState([]);

  const [abrirModal, setAbrirModal] = useState<boolean>(false);

  const [senha, setSenha] = useState<string>("");
  const [statusSenha, setStatusSenha] = useState<string>("");
  const [guia, setGuia] = useState<string>("");
  const [statusGuia, setStatusGuia] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [textoLoading, setTextoLoading] = useState<string>("");
  const buscarDados = async () => {
    setLoading(true);
    setTextoLoading("Aguarde, Buscando Autorizações...");
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/autorizacao/listar/:id_clinica",
      parametros: {
        id_clinica: id_clinica,
      },
      headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");

        setDados(retorno);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    buscarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dadosAtualizarSenha, setDadosAtualizarSenha] = useState<any>({});
  const atualizarStatus = async (dados: any) => {

    if (editar == true) {

      await buscarStatusGuia("STATUSGUIA");
      await buscarStatusSenha("STATUSSENHA");
      setDadosAtualizarSenha(dados);
      setSenha(dados.senha);
      setStatusSenha(dados.statussenha);
      setGuia(dados.guia);
      setStatusGuia(dados.statusguia);
      setAbrirModal(true);
    }
    else {

      toast.error("Perfil sem permissão para editar Autorização")
    }
  };

  const [dadosSenha, setDadosSenha] = useState<any>([]);
  const buscarStatusSenha = async (tabela: string) => {
    setLoading(true);
    setTextoLoading("Aguarde, Buscando Status Senha...");
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/autorizacao/listar/status/:tabela",
      parametros: {
        tabela: tabela,
      },
      headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        setDadosSenha(retorno);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };

  const [dadosGuia, setDadosGuia] = useState<any>([]);
  const buscarStatusGuia = async (tabela: string) => {
    setLoading(true);
    setTextoLoading("Aguarde, Buscando Status Guia...");
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/autorizacao/listar/status/:tabela",
      parametros: {
        tabela: tabela,
      },
      headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        setDadosGuia(retorno);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };

  const atualizarAutorizacao = async (
    id_agendamento: string,
    id_clinica: string,
    senha: string,
    statussenha: string,
    guia: string,
    statusguia: string,
    usuario: string,
    id_paciente: string,
    id_tipo_atendimento: string,
    valor: string,
    valor_final: string
  ) => {
    setLoading(true);
    setTextoLoading("Aguarde, Atualizando Autorização...");

    const dados = {
      senha,
      statussenha,
      guia,
      statusguia,
      usuario,
      id_paciente,
      id_tipo_atendimento,
      valor,
      valor_final,
    };
    ApiPost(
      `/autorizacao/atualizar/${id_agendamento}/${id_clinica}`,
      dados,
      token
    )
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        toast.success("Autorização Atualizada com Sucesso.");
        setAbrirModal(false);
        buscarDados();
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response.data.message
            : erro.mesage;
        toast.error(`Erro ao Atualizar Autorização. Motivo: ${msg}`);
      });
  };
  const atualizarStatusAutorizacao = (
    event: React.FormEvent<HTMLFormElement>
  ) => {

    if (editar == true) {
      event.preventDefault();
      if (statusGuia === "1" && statusSenha !== "2") {
        toast.warning(
          "Somente é possível autorizar a guia se a senha estiver autorizada."
        );
      } else {
        atualizarAutorizacao(
          dadosAtualizarSenha.id_agendamento,
          id_clinica,
          senha,
          statusSenha,
          guia,
          statusGuia,
          usuario,
          dadosAtualizarSenha.id_paciente,
          dadosAtualizarSenha.id_tipo_atendimento,
          dadosAtualizarSenha.valor,
          dadosAtualizarSenha.valor_final
        );
      }
    }
    else {

      toast.error("Perfil sem permissão para editar Autorização.")
    }

  };

  const fecharModal = () => {
    setSenha("");
    setStatusSenha("");
    setGuia("");
    setStatusGuia("");
    setDadosAtualizarSenha({});
    setAbrirModal(false);
  };

  const atualizarStatusSenha = (e: ChangeEvent<HTMLSelectElement>) => {
    const valor = e.target.value;
    // console.log("valor: ", valor);

    setStatusSenha(valor);

    if (valor === "9") {
      setStatusGuia("2");
    }
  };
  const atualizarStatusGuia = (e: ChangeEvent<HTMLSelectElement>) => {
    const valor = e.target.value;
    // console.log("valor: ", valor);

    setStatusGuia(valor);

    if (valor === "1" && statusSenha !== "2") {
      setStatusGuia("1");
    }
  };

  const gerarFilaAtendimento = async (
    id_agendamento: string,
    id_clinica: string,
    usuario: string
  ) => {
    setLoading(true);
    setTextoLoading("Aguarde, Gerando Atendimento...");

    const dados = {
      usuario,
    };
    ApiPost(
      `/autorizacao/atualizar/agenda/${id_agendamento}/${id_clinica}`,
      dados,
      token
    )
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        toast.success("Atendimento Gerado com Sucesso.");
        setAbrirModal(false);
        buscarDados();
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response.data.message
            : erro.mesage;
        toast.error(`Erro ao Gerar Atendimento. Motivo: ${msg}`);
      });
  };

  const gerarAtendimento = async (dados: any) => {

    if (criar == true) {

      const paciente = dados.paciente;
      await showConfirmationDialog(
        "Gerar Atendimento",
        `Deseja gerar o atendimento para o paciente ${paciente}?`,
        "question",
        "Sim",
        "Não"
      )
        .then((result) => {
          if (result.confirmed) {
            gerarFilaAtendimento(dados.id_agendamento, id_clinica, usuario);
          }
        })
        .catch((erro) => {
          toast.error(
            `Falha ao gerar atendimento. Motivo: ${erro.response.data.message}`
          );
        });
    }
    else{

      toast.error("Perfil sem permissão para criar Autorização")
    }
  };

  const token_ = getSessionData("MultClinicaWebToken")
  const isSuper = getSessionData("isPerfilSuper") || "";
  const [visualizar, set_visualizar] = useState(false)
  const [criar, set_criar] = useState(false)
  const [editar, set_editar] = useState(false)
  const [excluir, set_excluir] = useState(false)
  function verificaPermissoes(id_perfil: any) {

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/perfil/carregar/${id_perfil}`, {
      headers: {
        Authorization: token_
      }
    }).then(function (resposta) {

      set_visualizar(resposta.data[0].visualizarautorizacao === "1" || isSuper === "1" ? true : false)
      set_criar(resposta.data[0].criarautorizacao === "1" || isSuper === "1" ? true : false)
      set_editar(resposta.data[0].editarautorizacao === "1" || isSuper === "1" ? true : false)
    }).catch(function (erro) {

      toast.error("Erro ao carregar permissão.")
    })
  }

  useEffect(function () {

    verificaPermissoes(getSessionData("DadosPerfilID"))
  }, [])

  return (
    <>
      <div className="container-fluid">
        <ContainerTitulo titulo="Listagem de Autorização" />
      </div>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Body>
              <Row>
                <div className="d-flex my-2 gap-1 ">
                  <Col sm={10}>
                    <InputSemBorda
                      name="pesquisa"
                      type="text"
                      value=""
                      onChange={() => { }}
                      classNameInputsSemBorda=" bordasInferiorInput"
                      placeholder="Procedimento"
                    />
                  </Col>
                  <Col sm={2}>
                    <InputSemBorda
                      name="pesquisa"
                      type="text"
                      value=""
                      onChange={() => { }}
                      classNameInputsSemBorda=" bordasInferiorInput"
                      placeholder="Convênio"
                    />
                  </Col>
                </div>
              </Row>
              <Row>
                <Tabela
                  coluna={colunas}
                  dados={dados}
                  itemsPerPage={5}
                  onVisuClick={() => { }}
                  usaVisu={false}
                  onEditClick={() => { }}
                  usaEdit={false}
                  onDeleteClick={atualizarStatus}
                  iconeExcluir={mdiFileReplace}
                  usaDelete={dados.some(
                    (item: any) =>
                      item.statussenha !== "2" && item.statusguia !== "1"
                    // item.statussenha !== "9" && item.statusguia !== "2"
                  )}
                  descExcluir="Atualizar Status"
                  onImprimirClick={gerarAtendimento}
                  iconeImprimir={mdiCheckboxMultipleMarkedOutline}
                  usaImprimir={dados.some(
                    (item: any) =>
                      item.statussenha === "2" && item.statusguia === "1"
                    // item.statussenha !== "9" && item.statusguia !== "2"
                  )}
                  descImprimir="Gerar Atendimento"
                />
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* ABRIR MODAL COM OS DADOS DE AGENDAMENTO PARA PREENCHER A SENHA A GUIA E INFORMAR O STATUS DE CADA UM */}
      <Modal
        show={abrirModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={false}
        centered
      >
        <Modal.Header className="bg-white">
          <Modal.Title id="contained-modal-title-vcenter">
            Atualizar Status de Autorização
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={atualizarStatusAutorizacao}>
          <Modal.Body className="bg-white">
            <Row>
              <Col sm={6}>
                <InputSemBorda
                  name="paciente"
                  type="text"
                  value={dadosAtualizarSenha.paciente}
                  onChange={() => { }}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                  placeholder="Paciente"
                  label="Paciente"
                />
              </Col>
              <Col sm={6}>
                <InputSemBorda
                  name="datanascimento"
                  type="text"
                  value={
                    dadosAtualizarSenha.data_nascimento +
                    " - " +
                    dadosAtualizarSenha.idade
                  }
                  onChange={() => { }}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                  placeholder="Data Nascimento"
                  label="Data Nascimento"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <InputSemBorda
                  name="dataagendamento"
                  type="text"
                  value={dadosAtualizarSenha.data_inicio}
                  onChange={() => { }}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                  placeholder="Data Agendamento"
                  label="Data Agendamento"
                />
              </Col>
              <Col sm={4}>
                <InputSemBorda
                  name="horaagendamento"
                  type="text"
                  value={
                    dadosAtualizarSenha.hora_inicio +
                    " a " +
                    dadosAtualizarSenha.hora_fim
                  }
                  onChange={() => { }}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                  placeholder="Hora Agendamento"
                  label="Hora Agendamento"
                />
              </Col>
              <Col sm={5}>
                <InputSemBorda
                  name="convenio"
                  type="text"
                  value={dadosAtualizarSenha.nomeconvenio}
                  onChange={() => { }}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                  placeholder="Convênio"
                  label="Convênio"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <InputSemBorda
                  name="senha"
                  type="text"
                  value={senha}
                  onChange={setSenha}
                  required={statusSenha === "2" ? true : false}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                  placeholder="Senha"
                  label="Senha"
                />
              </Col>
              <Col sm={6}>
                <SelectInput
                  value={statusSenha}
                  onChange={atualizarStatusSenha}
                  options={dadosSenha}
                  placeholder="Status Senha"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <InputSemBorda
                  name="guia"
                  type="text"
                  value={guia}
                  onChange={setGuia}
                  required={statusGuia === "1" ? true : false}
                  readonly={
                    statusSenha === "9" || statusSenha !== "2" ? true : false
                  }
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                  placeholder="Guia"
                  label="Guia"
                />
              </Col>
              <Col sm={6}>
                <SelectInput
                  value={statusGuia}
                  onChange={atualizarStatusGuia}
                  options={dadosGuia}
                  disabed={
                    statusSenha === "9" || statusSenha !== "2" ? true : false
                  }
                  placeholder="Status Guia"
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="bg-white">
            <div className="d-flex my-2 gap-1 justify-content-between">
              <Button
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={fecharModal}
              />
              <Button
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
              />
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      <ModalLoading show={loading} label={textoLoading} />
    </>
  );
}
