import { Card, Col, Row, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { getSessionData } from "../../../../../../utils/storageUtils";
import InputSemBorda from "../../../../../../components/Formularios/Inputs/InputsSemBorda";
import { Button as ButtonCustom } from "../../../../../../components/Formularios/Buttons/Button";
import { mdiCheckCircle, mdiKeyboardReturn } from "@mdi/js";
import {
  mascaraCNPJ,
  mascaraTelefoneCelular,
  mascaraTelefoneFixo,
} from "../../../../../../hooks/mascaras";
import { TextoFormatoCaptalize } from "../../../../../../components/Auxiliar/ConvTextoCaptalize";
import { TextoFormatoLowerCase } from "../../../../../../components/Auxiliar/ConvTextoLower";
import ApiPost from "../../../../../../api/endPoints/usePost";
import { toast } from "react-toastify";
import ModalLoading from "../../../../../../components/Formularios/Modal/ModalLoading";
import { showConfirmationDialog } from "../../../../../../components/Formularios/Modal/ModalExcluir";
interface FormPreCadastroPacientesProps {
  idpaciente?: string;
  acao?: string;
}
export function FormPreCadastro({
  idpaciente,
  acao,
}: FormPreCadastroPacientesProps) {
  const navigate = useNavigate();
  const id_clinica = getSessionData("DadosIdClinica") || "";

  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [textoCarregando, setTextoCarregando] = useState<string>("");

  const [id_paciente, setId_paciente] = useState<any>(idpaciente || "");
  const [cpf, setCpf] = useState<any>("");
  const [nome, setNome] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [fone, setFone] = useState<any>("");
  const [celular, setCelular] = useState<any>("");

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS
  const gravarDados = (
    id_clinica: string,
    cpf: string,
    nome: string,
    email: string,
    fone: string,
    celular: string,
    usuario: string
  ) => {
    setShowLoading(true);
    setTextoCarregando("Gravando dados do pré cadastro do Paciente");
    const dados = {
      id_clinica,
      cpf,
      nome: TextoFormatoCaptalize(nome.toLowerCase()),
      email: TextoFormatoLowerCase(email.toLowerCase()),
      fone,
      celular,
      usuario_cadastro: usuario,
    };
    ApiPost("/paciente/criar/precadastro", dados)
      .then((retorno) => {
        setShowLoading(false);
        setTextoCarregando("");
        toast.success("Pré Cadastro de Paciente realizado com sucesso!");
        showConfirmationDialog(
          "Enviar Email de Pré Cadastro",
          `Deseja enviar o E-mail de  pré cadastro do paciente \n ${TextoFormatoCaptalize(
            nome.toLowerCase()
          )}?`,
          "question",
          "Sim",
          "Não"
        )
          .then((result) => {
            if (result.confirmed) {
              setShowLoading(true);
              setTextoCarregando("Enviando E-mail de Pré Cadastro");
              const dados = {
                id_clinica,
                nome: TextoFormatoCaptalize(nome.toLowerCase()),
                email: TextoFormatoLowerCase(email.toLowerCase()),
                cpf,
              };
              ApiPost("/paciente/criar/precadastro/sendmail", dados)
                .then((retorno) => {
                  setShowLoading(false);
                  toast.success(retorno.message);
                  navigate(-1);
                })
                .catch((erro) => {
                  const msg =
                    erro.response?.data.message !== undefined
                      ? erro.response?.data.message
                      : erro.message;
                  // toast.error(
                  //   `Falha ao Enviar E-mail. Motivo: ${msg}`
                  // );
                  showConfirmationDialog(
                    "Falha ao Enviar Email de Pré Cadastro",
                    `Deseja enviar o E-mail de  pré cadastro do paciente \n ${TextoFormatoCaptalize(
                      nome.toLowerCase()
                    )}. Motivo: ${msg}.\n Deseja tentar novamente?`,
                    "error",
                    "Sim",
                    "Não"
                  )
                    .then((result) => {
                      if (result.confirmed) {
                        ApiPost("/paciente/criar/precadastro/sendmail", dados)
                          .then((retorno) => {
                            setShowLoading(false);
                            toast.success(retorno.message);
                            navigate(-1);
                          })
                          .catch((erro) => {
                            setShowLoading(false);
                            setTextoCarregando("");
                            const msg =
                              erro.response?.data.message !== undefined
                                ? erro.response?.data.message
                                : erro.message;
                            toast.error(
                              `Falha ao Enviar E-mail. Motivo: ${msg}`
                            );
                            navigate(-1);
                          });
                      } else {
                        navigate(-1);
                      }
                    })
                    .catch((erro) => {
                      setShowLoading(false);
                      setTextoCarregando("");
                      const msg =
                        erro.response?.data.message !== undefined
                          ? erro.response?.data.message
                          : erro.message;
                      toast.error(`Falha ao Enviar E-mail. Motivo: ${msg}`);
                    });
                });
            }
            else{
              navigate(-1);
            }
          })
          .catch((erro) => {
            setShowLoading(false);
            const msg =
              erro.response?.data.message !== undefined
                ? erro.response?.data.message
                : erro.message;
            toast.error(`Falha ao Enviar E-mail. Motivo: ${msg}`);
          });
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        setShowLoading(false);
        setTextoCarregando("");
        toast.error(msg);
      });
  };

  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    gravarDados(
      id_clinica,
      cpf.replace(/[^0-9]/g, ""),
      nome,
      email,
      fone.replace(/[^0-9]/g, ""),
      celular.replace(/[^0-9]/g, ""),
      getSessionData("DadosUsuario") || ""
    );
  };
  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Row>
              <Col sm={3}>
                <InputSemBorda
                  label="ID Paciente"
                  name="id_paciente"
                  type="text"
                  placeholder="ID Paciente"
                  readonly
                  value={id_paciente || ""}
                  onChange={setId_paciente}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                />
              </Col>
              <Col sm={3}>
                <InputSemBorda
                  label="CPF"
                  name="cpf"
                  type="text"
                  placeholder="CPF"
                  required
                  value={mascaraCNPJ(cpf) || ""}
                  onChange={setCpf}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                />
              </Col>
              <Col sm={6}>
                <InputSemBorda
                  label="Nome"
                  name="nome"
                  type="text"
                  placeholder="Nome"
                  required
                  value={nome || ""}
                  onChange={setNome}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <InputSemBorda
                  label="E-mail"
                  name="email"
                  type="email"
                  placeholder="E-mail"
                  required
                  value={email || ""}
                  onChange={setEmail}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                />
              </Col>
              <Col sm={3}>
                <InputSemBorda
                  label="Celular"
                  name="celular"
                  type="text"
                  placeholder="Celular"
                  required
                  value={mascaraTelefoneCelular(celular) || ""}
                  onChange={setCelular}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                />
              </Col>
              <Col sm={3}>
                <InputSemBorda
                  label="Telefone"
                  name="fone"
                  type="text"
                  placeholder="Telefone"
                  value={mascaraTelefoneFixo(fone) || ""}
                  onChange={setFone}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                />
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />

              <ButtonCustom
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
                disabled={acao === "Visualizar" ? true : false}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>
      <ModalLoading show={showLoading} label={textoCarregando} />
    </>
  );
}
