import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import { Row, Col, Card, Modal, Form } from "react-bootstrap";
import { getSessionData } from "../../../utils/storageUtils";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import {
    InputAdornment,
    InputLabel,
    // SpeedDial,
    // SpeedDialAction,
    // SpeedDialIcon,
    TextField,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { FaMoneyBill } from "react-icons/fa";
import { BsArrowDownCircleFill, BsArrowUpCircleFill } from "react-icons/bs";
import CardResumo from "../../../components/Formularios/CardResumo";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import Tabela from "../../../components/Formularios/Table";
import ApiGet from "../../../api/endPoints/useGet";
import { toast } from "react-toastify";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiReceiptTextCheckOutline,
} from "@mdi/js";
import { Button } from "../../../components/Formularios/Buttons/Button";
import SelectInput from "../../../shared/SelectInputs";
import ApiPost from "../../../api/endPoints/usePost";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Content, TDocumentDefinitions } from "pdfmake/interfaces";
import moment from "moment";
import axios from "axios";
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import { aB } from "@fullcalendar/core/internal-common";


function PendenteFaturamento() {

    interface Action {
        icon: JSX.Element;
        name: string;
    }

    const navigate = useNavigate();
    const id_clinica = getSessionData("DadosIdClinica") || "";
    const token = getSessionData("MultClinicaWebToken") || "";
    const usuario = getSessionData("DadosUsuario") || "";

    const [loading, setLoading] = useState(false);
    const [textoLoading, setTextoLoading] = useState("");


    const dataAtual = new Date();
    const mesAtual = (dataAtual.getMonth() + 1).toString().padStart(2, "0");
    const anoAtual = dataAtual.getFullYear();
    const _dataInicial = anoAtual + "-" + mesAtual + "-01";
    const [datainicio, setDataInicio] = useState<string>(_dataInicial)
    const [datafim, setDataFim] = useState<string>(
        dataAtual.toISOString().split("T")[0]
    );

    const colunas: ITabela[] = [
        { titulo: "Código", acesso: "id_agendamento" },
        { titulo: "Sala", acesso: "id_sala" },
        { titulo: "Profissional", acesso: "nome" },
        {titulo: "Tipo", acesso: "tipoconsulta"},
        { titulo: "Paciente", acesso: "paciente" },
        { titulo: "Data Agenda", acesso: "data_inicio" },
        { titulo: "Data Status", acesso: "data_edicao" },
        { titulo: "Status", acesso: "statusagendamento" },
        { titulo: "Fatura Gerada", acesso: "fatura_gerada" }
        //{ titulo: "Data Recebimento", acesso: "data_recebimento" },
    ];

    const [ListaPendentesFaturamento, setListaPendentesFaturamento] = useState([])
    function CarregarPendentesFaturamento(id_clinica: any, datainicio: any, datafim: any, statusFatura: any) {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/pendentes/faturamento/${id_clinica}/${datainicio}/${datafim}/${statusFatura}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            console.log(resposta.data)
            setListaPendentesFaturamento(resposta.data)
            setLoading(false)
        }).catch(function (erro) {

            setLoading(false)
            toast.error(erro.message || erro.response.data || erro.statusText || "Erro na rota desconhecido.")
        })
    }


    const [StatusFatura, set_StatusFatura] = useState("0")

    const [dadosFatura, set_dadosFatura] = useState<any>({})
    function gerarFaturamento(dados: any) {


        //comparando datas, a data de vencimento deve ser maior que a data corrente
        const dataVencimento: Date = new Date(datavencimento + "T00:00:00")

        if (dataVencimento > new Date()) {

            const dados_ = {
                id_clinica: dados.id_clinica,
                id_agendamento: dados.id_agendamento,
                id_paciente: dados.id_paciente,
                id_tipo_atendimento: dados.id_tipo_atendimento,
                id_tipo_receita: dados.id_tipo_receita,//"2"
                valor: dados.valor,
                valor_final: dados.valor_final,
                observacao: dados.observacao,
                id_faturado: dados.id_faturado,
                usuario_cadastro: usuario,
                desconto: dados.desconto,
                profissional: dados.nome,
                paciente: dados.paciente,
                data_vencimento: datavencimento
            }

            setLoading(true)
            axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/gerar/fatura`, dados_, {
                headers: {
                    Authorization: token
                }
            }).then(function (resposta) {

                toast.success(resposta.data)
                setLoading(false)
                CarregarPendentesFaturamento(id_clinica, datainicio, datafim, StatusFatura)
                AbrirModalFaturamento()
                set_DataVencimento("")
            }).catch(function (erro) {
                setLoading(false)
                AbrirModalFaturamento()
                set_DataVencimento("")
                toast.error(erro.response.data || erro.statusText || "Ocorreu um erro ao tentar criar fatura - desconhecido.")
            })
        }
        else {

            toast.error("Data de vencimento não pode ser menor ou igual a data corrente.")
        }
    }

    const [datavencimento, set_DataVencimento] = useState("")
    function AbrirModalFaturamento(dados: any = 0) {

        set_dadosFatura(dados)
        if (dados.fatura_gerada == true) {

            toast.info("Fatura já gerada, não é possível gerar novamente.")
        }
        else {

            const btn = document.querySelector("#btnModalDataVencimento")! as HTMLElement
            btn.click()
        }
    }


    //verifica permissões
    const token_ = getSessionData("MultClinicaWebToken")
    const isSuper = getSessionData("isPerfilSuper") || "";
    const [visualizar, set_visualizar] = useState(false)
    const [criar, set_criar] = useState(false)
    function verificaPermissoes(id_perfil: any) {

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/perfil/carregar/${id_perfil}`, {
            headers: {
                Authorization: token_
            }
        }).then(function (resposta) {
            console.log(resposta.data[0].visualizarpendentedefaturamento === "1" || isSuper === "1" ? true : false)
            set_visualizar(resposta.data[0].visualizarpendentedefaturamento === "1" || isSuper === "1" ? true : false)
            set_criar(resposta.data[0].criarpendentedefaturamento === "1" || isSuper === "1" ? true : false)
        }).catch(function (erro) {

            toast.error("Erro ao carregar permissão.")
        })
    }

    useEffect(function () {

        verificaPermissoes(getSessionData("DadosPerfilID"))
    }, [])

    useEffect(function () {

        const newDataInicial = new Date(new Date(new Date(new Date().setDate(1))).setMonth(new Date(new Date(new Date().setDate(0))).getMonth() + 1)).toISOString().split("T")[0]
        setDataInicio(newDataInicial)

        const newDataFim = new Date(new Date(new Date(new Date().setDate(0))).setMonth(new Date(new Date(new Date().setDate(0))).getMonth() + 1)).toISOString().split("T")[0]
        setDataFim(newDataFim)
        
        CarregarPendentesFaturamento(id_clinica, newDataInicial, newDataFim, StatusFatura)
    }, [visualizar])

    return (<>
        <div className="page-content">
            <ContainerTitulo titulo="Pendente de Faturamento" />

        </div>
        <Row>
            <Col sm={12}>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col sm={2}>
                                <br />
                                <InputSemBorda
                                    name="datainicio"
                                    type="date"
                                    value={datainicio}
                                    onChange={setDataInicio}
                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                    placeholder="Data Início"
                                    label="Data Início"
                                    required
                                />
                            </Col>
                            <Col sm={2}>
                                <br />
                                <InputSemBorda
                                    name="datafim"
                                    type="date"
                                    value={datafim}
                                    onChange={setDataFim}
                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                    placeholder="Data Fim"
                                    label="Data Fim"
                                    required
                                />
                            </Col>
                            <Col sm={3}>
                                <br />
                                <div className="form-floating">
                                    <select onChange={function (e: any) {
                                        set_StatusFatura(e.target.value)
                                    }} value={StatusFatura} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                                        <option value="1">Faturadas</option>
                                        <option value="0">N. Faturadas</option>
                                    </select>
                                    <label>Filtrar Faturas</label>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <br />
                                <button className="mt-1 btn btn-secondary w-25" type="button" onClick={function () { CarregarPendentesFaturamento(id_clinica, datainicio, datafim, StatusFatura) }}>Filtrar</button>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Tabela
                                coluna={colunas}
                                dados={ListaPendentesFaturamento}
                                itemsPerPage={10}
                                onVisuClick={function () { }}
                                usaDelete={false}
                                usaEdit={false}
                                usaVisu={false}
                                onEditClick={function () { }}
                                onDeleteClick={function () { }}
                                usaNovoBotao={criar}
                                onNovoBotaoClick={AbrirModalFaturamento}
                                descNovoBotao="Gerar Faturamento"
                                iconeNovoBotao={mdiReceiptTextCheckOutline}
                                onImprimirClick={function () { }}
                                usaImprimir={false}
                                id="codigo"
                            />
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row >

        <ModalLoading show={loading} label={textoLoading} />



        <button type="button" id="btnModalDataVencimento" className="btn btn-primary" data-toggle="modal" data-target="#setDataVencimento" hidden>
            Launch demo modal
        </button>


        <div className="modal fade" id="setDataVencimento" tabIndex={-1} role="dialog" aria-labelledby="setDataVencimentoLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content bg-white">
                    <div className="modal-header">
                        <h5 className="modal-title" id="setDataVencimentoLabel">Data de vencimento</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p className="text-center m-0">Defina a data de vencimento da fatura</p>

                        <InputSemBorda
                            name="datavencimento"
                            type="date"
                            value={datavencimento}
                            onChange={set_DataVencimento}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-black w-50 m-auto text-center"
                            placeholder="Data De Vencimento"
                            required
                        />

                    </div>
                    <div className="modal-footer">
                        <button onClick={function () {
                            gerarFaturamento(dadosFatura)
                        }} type="button" className="btn btn-primary">Criar Fatura</button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}


export default PendenteFaturamento