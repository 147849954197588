import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../../../../components/Formularios/Modal/ModalLoading";
import { useState, useEffect, ChangeEvent } from "react";
import { toast } from "react-toastify";
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../../components/Formularios/Inputs/InputsSemBorda";

import {
  Box,
  Switch,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tab,
  Avatar,
  Stack,
  Button,
  Paper,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button as ButtonCustom } from "../../../../../../components/Formularios/Buttons/Button";
import {
  mdiCheckCircle,
  mdiKeyboardReturn,
  mdiLockCheckOutline,
} from "@mdi/js";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ApiPost from "../../../../../../api/endPoints/usePost";
import ApiPut from "../../../../../../api/endPoints/usePut";
import ModalUploadImage from "../../../../../../components/Formularios/Modal/ModalUpload";
import { styled } from "@mui/material/styles";
import ConverterBase64 from "../../../../../../components/ConversorBase64";
import CarregarDados from "../CarregarDados";
// import { InterfaceDadosEmpresa } from "../../../../../types/EmpresaInteface";
import { url_CarregarDados_ID } from "../auxiliar";
import { dadosUF } from "../../../../../../components/dadosFixos/UF";
import { dadosPais } from "../../../../../../components/dadosFixos/Pais";
import { TextoFormatoCaptalize } from "../../../../../../components/Auxiliar/ConvTextoCaptalize";
import { TextoFormatoLowerCase } from "../../../../../../components/Auxiliar/ConvTextoLower";
import { TextoFormatoUpperCase } from "../../../../../../components/Auxiliar/ConvTextoUpper";
import useSelectOptions from "../../../../../../hooks/useSelectOptions";
import SelectInput from "../../../../../../shared/SelectInputs";
import { getSessionData } from "../../../../../../utils/storageUtils";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F0F0F0",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "#616161",
  borderRadius: "5px",
  fontWeight: "bold",
  fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
}));

interface FormSalaProps {
  idsala?: string;
  acao?: string;
}

const FormSala = ({ idsala, acao }: FormSalaProps) => {
  const navigate = useNavigate();
  const idclinica = getSessionData("DadosIdClinica") || "";

  //#region Controla as guias
  const [guia, setGuia] = useState("1");

  const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
    setGuia(novaGuia);
  };
  //#endregion

  const [showUpload, setShowUpload] = useState<boolean>(false);

  const [id_sala, setId_sala] = useState<any>(idsala || "");
  const [fantasia, setFantasia] = useState<string>("");
  const [status, setStatus] = useState<boolean>(true);
  const [tiposala, setTiposala] = useState<string>("");
  const [sala, setSala] = useState<string>("");
  const [cadastro, setCadastro] = useState<string>("");
  const [usuario_cadastro, setUsuario_cadastro] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  //#region ==> Select Options
  // Usando o hook useSelectOptions para obter as opções diretamente
  const tipoSalaOptions = useSelectOptions(`/sala/listartiposala`);
  const clinicaOptions = useSelectOptions(`/clinica/listarclinica`);
  const [selectedTipoSala, setSelectedTipoSala] = useState("");
  const [selectedClinica, setSelectedClinica] = useState("");

  //#endregion

  const carregar = (url: string, idsala: any, id_clinica: string) => {
    setLoading(true);
    CarregarDados(url, idsala, id_clinica)
      .then((retorno) => {
        setLoading(false);

        setId_sala(retorno[0].id_sala || "");

        // setId_clinica(retorno[0].id_clinica || '');
        setFantasia(retorno[0].fantasia || "");
        setStatus(retorno[0].status === 1 ? true : false);
        // setTiposala(retorno[0].tiposala || '');
        setSala(retorno[0].sala || "");
        setCadastro(retorno[0].cadastro || "");
        setUsuario_cadastro(retorno[0].usuario_cadastro || "");

        setSelectedTipoSala(retorno[0].tiposala || "");
      })
      .catch((erro) => {
        setLoading(false);
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error("Falha ao carregar dados. Motivo: "+ msg);
      });
  };
  useEffect(() => {
    if (idsala !== "novo") {
      carregar(url_CarregarDados_ID, idsala, idclinica);
    }
  }, []);

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS
  const atualizarDados = async (
    id_sala: string,
    id_clinica: string,
    fantasia: string,
    status: string,
    tiposala: string,
    sala: string,
    cadastro: string,
    usuario_cadastro: string
  ) => {
    const dados = {
      id_sala,
      id_clinica,
      // id_clinica: selectedClinica,
      fantasia,
      status,
      tiposala: selectedTipoSala,
      sala: TextoFormatoCaptalize(sala.toLowerCase()),
      cadastro,
      usuario_cadastro,
    };

    ApiPut(`/sala/atualizar/${idsala}`, dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        toast.error(`Erro ao atualizar. Motivo: ${erro.response.data.message}`);
      });
  };
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS
  const gravarDados = (
    id_sala: string,
    id_clinica: string,
    fantasia: string,
    status: string,
    tiposala: string,
    sala: string,
    cadastro: string,
    usuario_cadastro: string
  ) => {
    const dados = {
      id_sala,
      // id_clinica: selectedClinica,
      id_clinica,
      fantasia,
      status,
      tiposala: selectedTipoSala,
      sala: TextoFormatoCaptalize(sala.toLowerCase()),
      cadastro,
      usuario_cadastro,
    };
    ApiPost("/sala/criar", dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };
  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (idsala === "novo") {
      gravarDados(
        id_sala,
        idclinica,
        fantasia,
        status ? "1" : "0",
        tiposala,
        sala,
        cadastro,
        usuario_cadastro
      );
      toast.info("Inserindo novo registro...");
    } else {
      atualizarDados(
        id_sala,
        idclinica,
        fantasia,
        status ? "1" : "0",
        tiposala,
        sala,
        cadastro,
        usuario_cadastro
      );
      toast.info("Atualizando dados do registro...");
    }
  };

  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Box sx={{ width: "100%" }}>
              <TabContext value={guia}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
                <TabPanel value="1">
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="ID Sala"
                        name="id_sala"
                        type="text"
                        placeholder="ID Sala"
                        readonly
                        value={id_sala}
                        onChange={setId_sala}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={6}>
                      <InputSemBorda
                        label="Sala"
                        name="sala"
                        type="text"
                        placeholder="Sala"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={sala || ""}
                        onChange={setSala}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <SelectInput
                        disabed={acao === "Visualizar" ? true : false}
                        value={selectedTipoSala}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setSelectedTipoSala(e.target.value)
                        }
                        options={tipoSalaOptions}
                        placeholder="Tipo Sala"
                      />
                      {/* <p>Opção selecionada: {selectedTipoSala}</p> */}
                    </Col>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Ativo
                      </label>
                      <Switch
                        checked={status}
                        onChange={() => setStatus(!status)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                  </Row>
                  <Row hidden>
                    <Col sm={2}>
                      <InputSemBorda
                        label="ID Clínica"
                        name="id_clinica"
                        type="text"
                        placeholder="ID Clínica"
                        required
                        readonly
                        value={selectedClinica || ""}
                        onChange={setSelectedClinica}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={10}>
                      <SelectInput
                        value={selectedClinica || ""}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setSelectedClinica(e.target.value)
                        }
                        options={clinicaOptions}
                        placeholder="Clínica"
                      />
                      {/* <InputSemBorda
                        label="Clínica"
                        name="fantasia"
                        type="text"
                        placeholder="Clínica"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={fantasia || ""}
                        onChange={setFantasia}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      /> */}
                    </Col>
                  </Row>

                  {/* <Row>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Telefone"
                        name="telefone"
                        type="text"
                        placeholder="Telefone Fixo"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={telfixo || ""}
                        onChange={setTelfixo}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Celular"
                        name="telcelular"
                        type="text"
                        placeholder="Celular"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={telcelular || ""}
                        onChange={setTelcelular}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={6}>
                      <InputSemBorda
                        label="Responsável"
                        name="responsavel"
                        type="text"
                        placeholder="Responsável"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={responsavel || ""}
                        onChange={setResponsavel}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row> */}
                  {/* <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="CEP"
                        name="cep"
                        type="text"
                        placeholder="CEP"
                        readonly={acao === "Visualizar" ? true : false}
                        value={cep || ""}
                        onChange={setCep}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Logradouro"
                        name="endereco"
                        type="text"
                        placeholder="Logradouro"
                        readonly={acao === "Visualizar" ? true : false}
                        value={logradouro || ""}
                        onChange={setLogradouro}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Complemento"
                        name="compl"
                        type="text"
                        placeholder="Complemento"
                        readonly={acao === "Visualizar" ? true : false}
                        value={compl || ""}
                        onChange={setCompl}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Número"
                        name="numero"
                        type="text"
                        placeholder="Número"
                        readonly={acao === "Visualizar" ? true : false}
                        value={numero || ""}
                        onChange={setNumero}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row> */}
                  <Row>
                    {/* <Col sm={3}>
                      <InputSemBorda
                        label="Bairro"
                        name="bairro"
                        type="text"
                        placeholder="Bairro"
                        readonly={acao === "Visualizar" ? true : false}
                        value={bairro || ""}
                        onChange={setBairro}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col> */}
                    {/* <Col sm={3}>
                      <InputSemBorda
                        label="Cidade"
                        name="cnum"
                        type="text"
                        placeholder="Cidade"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={cnum || ""}
                        onChange={setCnum}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col> */}

                    {/* <Col sm={2}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1.5, width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          UF
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={cuf}
                          onChange={(e: SelectChangeEvent) =>
                            setCuf(e.target.value)
                          }
                          label="UF"
                          required
                          disabled={acao === "Visualizar" ? true : false}
                        >
                          <MenuItem value="">
                            <em>Selecione...</em>
                          </MenuItem>
                          {dadosUF.map((item: any) => (
                            <MenuItem value={item.uf}>{item.nome}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col> */}
                    {/* <Col sm={2}>
                      <InputSemBorda
                        label="Cód. IBGE"
                        name="codibge"
                        type="text"
                        placeholder="Cód. IBGE"
                        readonly={acao === "Visualizar" ? true : false}
                        value={codIbge || ""}
                        onChange={setCodIbge}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col> */}
                    {/* <Col sm={2}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1.5, width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          País
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={pais}
                          onChange={(e: SelectChangeEvent) =>
                            setPais(e.target.value)
                          }
                          label="País"
                          required
                          disabled={acao === "Visualizar" ? true : false}
                        >
                          <MenuItem value="">
                            <em>Selecione...</em>
                          </MenuItem>
                          {dadosPais.map((item: any) => (
                            <MenuItem value={item.codpais}>
                              {item.pais} ({item.codpais})
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col> */}
                  </Row>
                </TabPanel>
                <TabPanel value="2">
                  <Row>
                    <Stack spacing={3}>
                      <Item>Configurações Básicas.</Item>
                    </Stack>
                  </Row>
                  <Row>
                    <Col>
                      <table className="table-hover border-bottom hover-bg-blue">
                        <tr>
                          <th>Itens Por Página:</th>
                          <td>
                            <input
                              name="itenspagina"
                              value=""
                              type="number"
                              placeholder="Itens Por Página"
                              onChange={() => {}}
                              className="form-control bordasInferiorInput text-black text-capitalize"
                            />
                          </td>
                          <td>
                            Informe a quantidade de itens que serão exibidos nos
                            grid's. (Por padrão serão exibidos 5 itens por
                            página)
                          </td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                  <Row>
                    <Stack spacing={3}>
                      <Item>Logo - Informe a logomarca da empresa.</Item>
                    </Stack>
                  </Row>
                  <br />
                  {/* <Row className="mb-2">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      {avatar ? (
                        <img
                          alt=""
                          style={{
                            width: "70%",
                            height: "250px",
                            objectFit: "contain",
                            backgroundImage: `url(data:image/png;base64,${avatar})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                          }}
                        />
                      ) : (
                        <Avatar
                          src={""}
                          alt="Avatar"
                          sx={{ width: 240, height: 240 }}
                        />
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        id="avatar-input"
                        onChange={handleAvatarChange}
                        style={{ display: "none" }}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                      <label htmlFor="avatar-input">
                        <IconButton color="primary" component="span">
                          <PhotoCameraIcon />
                        </IconButton>
                      </label>
                      {avatar && acao !== "Visualizar" && (
                        <Button variant="outlined" onClick={handleRemoveAvatar}>
                          Remover Logo
                        </Button>
                      )}
                    </Stack>
                  </Row> */}
                  {/* <Row>
                    <Stack spacing={3}>
                      <Item>Dados de Integração</Item>
                    </Stack>
                  </Row> */}
                  <br />
                  {/* <Row> */}
                  {/* CAMPO PARA CLICAR NO BOTAO GERAR TOKEN E O MESMO SER GERADO */}
                  {/* <Col sm={12}>
                      <InputGroup>
                        <ButtonCustom
                          type="button"
                          className="btn btn-info "
                          icone={mdiLockCheckOutline}
                          descricaoBotao="Gerar Token"
                        />
                        <InputSemBorda
                          label="Token"
                          name="token"
                          type="text"
                          placeholder="token"
                          readonly
                          value={token}
                          onChange={setToken}
                          classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize "
                        />
                      </InputGroup>
                    </Col> */}
                  {/* </Row> */}
                </TabPanel>
              </TabContext>
            </Box>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <ButtonCustom
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
                disabled={acao === "Visualizar" ? true : false}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>
      <ModalUploadImage
        show={showUpload}
        label="Realizando Upload da Imagem ..."
      />
      <ModalLoading show={loading} label="Carregado dados da clinica ..." />
    </>
  );
};
export default FormSala;
