import Icon from "@mdi/react";
interface ButtonProps {
  descricaoBotao?: string;
  className?: string;
  onclick?: () => void;
  type?: "button" | "submit" | "reset" | undefined;
  icone?: string;
  children?: React.ReactNode;
  title?: string;
  disabled?: boolean;
  size?: number;
}

export function Button({
  descricaoBotao,
  className,
  onclick,
  type,
  icone,
  children,
  title,
  disabled,
  size,
}: ButtonProps) {
  return (
    <button className={className} type={type} onClick={onclick} title={title} disabled={disabled}>
      {icone && <Icon path={icone} size={size || 1} />} {descricaoBotao}
    </button>
  );
}
