import ContainerTitulo from "../../../../../components/Formularios/Container/ContainerTitulo";
import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../components/Formularios/Inputs/InputsSemBorda";
import { Button as ButtonCustom } from "../../../../../components/Formularios/Buttons/Button";
import { mdiCheckCircle, mdiCloseCircle, mdiKeyboardReturn } from "@mdi/js";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import {
  mascaraCEP,
  mascaraCPF,
  mascaraTelefoneCelular,
  mascaraTelefoneFixo,
} from "../../../../../hooks/mascaras";
import { toast } from "react-toastify";
import axios from "axios";
import { dadosUF } from "../../../../../components/dadosFixos/UF";
import SelectInput from "../../../../../shared/SelectInputs";
import useSelectOptions from "../../../../../hooks/useSelectOptions";
import AdicionarFotoWebCam from "../../../../../components/Auxiliar/AdicionarAvatar";
import { useParams } from "react-router-dom";
import ApiGet from "../../../../../api/endPoints/useGet";
import ModalLoading from "../../../../../components/Formularios/Modal/ModalLoading";
import ApiPut from "../../../../../api/endPoints/usePut";
import { TextoFormatoCaptalize } from "../../../../../components/Auxiliar/ConvTextoCaptalize";
import { TextoFormatoLowerCase } from "../../../../../components/Auxiliar/ConvTextoLower";
import ApiPost from "../../../../../api/endPoints/usePost";

export function FormPreCadastroExterno() {
  const id_clinica = useParams() || "";

  // Usando o hook useSelectOptions para obter as opções diretamente
  const tipoContratacaoOptions = useSelectOptions(
    `/clinica/listartipocontratacao`
  );
  const [selectedTipoContratacao, setSelectedTipoContratacao] = useState("");
  const [selectedTipoConselho, setSelectedTipoConselho] = useState("");

  const escolaridadeOptions = useSelectOptions(
    `/codigosistema/listar/ESCOLARIDADE`
  );

  const canalOptions = useSelectOptions(`/codigosistema/listar/CANAL`);
  //#endregion
  const [id_paciente, setId_paciente] = useState<any>("");

  const [cpf, setCpf] = useState<string>("");
  const [nome, setNome] = useState<string>("");
  const [dtnascimento, setDtnascimento] = useState<string>("");
  const [telfixo, setTelfixo] = useState<string>("");
  const [telcelular, setTelcelular] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [telemerggencia, setTelemerggencia] = useState<string>("");
  const [contemerggencia, setContemerggencia] = useState<string>("");
  const [telresponsavel, setTelresponsavel] = useState<string>("");
  const [responsavel, setResponsavel] = useState<string>("");
  const [logradouro, setLogradouro] = useState<string>("");
  const [numero, setNumero] = useState<string>("");
  const [bairro, setBairro] = useState<string>("");
  const [compl, setCompl] = useState<string>("");
  const [cnum, setCnum] = useState<string>("");
  const [cidade, setCidade] = useState<string>("");
  const [cuf, setCuf] = useState<string>("");
  const [cep, setCep] = useState<string>("");
  const [convenio, setConvenio] = useState<string>("");
  const [codconvenio, setCodconvenio] = useState<string>("");
  const [selectedEscolaridade, setSelectedEscolaridade] = useState("");
  const [selectedProfissao, setSelectedProfissao] = useState("");
  const [selectedCanal, setSelectedCanal] = useState("");
  const [escolaridade, setEscolaridade] = useState<string>("");
  const [profissao, setProfissao] = useState<string>("");
  const [terapia_anterior, setTerapia_anterior] = useState<boolean>(false);
  const [terapia_anterior_tempo, setTerapia_anterior_tempo] =
    useState<string>("");
  const [sabeabordagem, setSabeAbordagem] = useState<boolean>(false);
  const [terapia_anterior_abordagem, setTerapia_anterior_abordagem] =
    useState<string>("");
  const [motivo_pisicologo, setMotivo_pisicologo] = useState<string>("");
  const [acomp_med, setAcomp_med] = useState<boolean>(false);
  const [acomp_med_descricao, setAcomp_med_descricao] = useState<string>("");
  const [medicamento, setMedicamento] = useState<boolean>(false);
  const [medicamento_desc, setMedicamento_desc] = useState<string>("");
  const [canal, setCanal] = useState<string>("");
  const [grau_parentesco, setGrau_parentesco] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [textoCarregando, setTextoCarregando] = useState<string>("");
  const [aceitar, setAceitar] = useState<boolean>(false);

  const [senha, setSenha] = useState<string>("");

  const buscarCep = async (cep: string) => {
    const cepSemMascara = cep.replace(/\D/g, "");

    if (cepSemMascara === "") return;
    else if (cepSemMascara.length < 8) {
      toast.error("CEP inválido!");
    } else if (cepSemMascara.length === 8) {
      await axios
        .get(`https://viacep.com.br/ws/${cepSemMascara}/json/`)
        .then((retorno) => {
          if (retorno.data?.erro) {
            toast.error("CEP inválido ou Não Localizado!");
          } else {
            const dadosCep = retorno.data;

            setLogradouro(dadosCep.logradouro);
            setCompl(dadosCep.complemento);
            setBairro(dadosCep.bairro);
            setCidade(dadosCep.localidade);
            setCuf(dadosCep.uf);
            setCnum(dadosCep.ibge);
          }
        })
        .catch((erro) => {
          console.log("erro: ", erro);
          const msg =
            erro.response?.data.message === undefined
              ? erro.message
              : erro.response.data.message;
          toast.error(`Erro ao buscar cep. Motivo: ${msg}`);
        });
    }
  };

  const [buscarCpf, setBuscarCpf] = useState<string>("");
  const buscarCpfRef = useRef<HTMLInputElement>(null);
  const [abrirModalSenhaConfirmacao, setAbrirModalSenhaConfirmacao] =
    useState<boolean>(false);

  const validarCadastroPaciente = (cpfpaciente: any, id_clinica: any) => {
    setLoading(true);
    setTextoCarregando("Buscando dados do paciente...");
    const dados = {
      url: "/dadoscadastroinicial/validar/:cpf/:id_clinica",
      parametros: {
        id_clinica: id_clinica,
        cpf: cpfpaciente,
      },
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setAbrirModalSenhaConfirmacao(true);

        // setId_paciente(retorno[0].id_paciente || "");
        // setCpf(retorno[0].cpf || "");
        // setNome(retorno[0].nome || "");
        // setDtnascimento(retorno[0].dtnascimento || "");
        // setTelfixo(retorno[0].telfixo || "");
        // setTelcelular(retorno[0].telcelular || "");
        // setEmail(retorno[0].email || "");
        // setTelemerggencia(retorno[0].telemerggencia || "");
        // setContemerggencia(retorno[0].contemerggencia || "");
        // setTelresponsavel(retorno[0].telresponsavel || "");
        // setResponsavel(retorno[0].responsavel || "");
        // setLogradouro(retorno[0].logradouro || "");
        // setNumero(retorno[0].numero || "");
        // setBairro(retorno[0].bairro || "");
        // setCompl(retorno[0].compl || "");
        // setCnum(retorno[0].cnum || "");
        // setCidade(retorno[0].cidade || "");
        // setCuf(retorno[0].cuf || "");
        // setCep(retorno[0].cep || "");

        // setSelectedTipoContratacao(retorno[0].tipocontratacao || "");
        // setConvenio(retorno[0].convenio || "");
        // setCodconvenio(retorno[0].codconvenio || "");
        // setSelectedTipoConselho(retorno[0].tipoconselho || "");
      })
      .catch((erro) => {
        setLoading(false);
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao carregar dados. Motivo: ${msg}`);
      });
  };
  const buscarPaciente = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const _cpf = buscarCpf.replace(/[^0-9]/g, "");
    validarCadastroPaciente(_cpf, id_clinica.id);
  };

  const atualizarDadosPaciente = (
    id_clinica: string,
    id_paciente: string,
    cpf: string,
    nome: string,
    dtnascimento: string,
    email: string,
    celular: string,
    telefone: string,
    escolaridade: string,
    profissao: string,
    cep: string,
    logradouro: string,
    complemento: string,
    numero: string,
    bairro: string,
    cidade: string,
    codmunicipio: string,
    uf: string,
    telemergencia: string,
    contemergencia: string,
    grauparentesco: string,
    telresponsavel: string,
    responsavel: string,
    tipoconvenio: string,
    codconvenio: string,
    convenio: string,
    jafezterapia: number,
    tempoterapia: string,
    motivo: string,
    sabeabordagem: number,
    abordagem: string,
    acompanhamentomedico: number,
    motivomedico: string,
    medicamento: number,
    medicamentodesc: string,
    canal: string
  ) => {
    setLoading(true);
    setTextoCarregando("Atualizando dados do paciente...");
    const dados = {
      id_clinica,
      id_paciente,
      cpf,
      nome: TextoFormatoCaptalize(nome.toLocaleLowerCase()),
      dtnascimento,
      email: TextoFormatoLowerCase(email.toLocaleLowerCase()),
      celular,
      telefone,
      escolaridade,
      profissao: TextoFormatoCaptalize(profissao.toLocaleLowerCase()),
      cep,
      logradouro: TextoFormatoCaptalize(logradouro.toLocaleLowerCase()),
      complemento: TextoFormatoCaptalize(complemento.toLocaleLowerCase()),
      numero: TextoFormatoCaptalize(numero.toLocaleLowerCase()),
      bairro: TextoFormatoCaptalize(bairro.toLocaleLowerCase()),
      cidade: TextoFormatoCaptalize(cidade.toLocaleLowerCase()),
      codmunicipio,
      uf,
      telemergencia,
      contemergencia: TextoFormatoCaptalize(contemergencia.toLocaleLowerCase()),
      grauparentesco: TextoFormatoCaptalize(grauparentesco.toLocaleLowerCase()),
      telresponsavel,
      responsavel: TextoFormatoCaptalize(responsavel.toLocaleLowerCase()),
      tipoconvenio,
      codconvenio,
      convenio,
      jafezterapia,
      tempoterapia,
      motivo: TextoFormatoCaptalize(motivo.toLocaleLowerCase()),
      sabeabordagem,
      abordagem: TextoFormatoCaptalize(abordagem.toLocaleLowerCase()),
      acompanhamentomedico,
      motivomedico: TextoFormatoCaptalize(motivomedico.toLocaleLowerCase()),
      medicamento,
      medicamentodesc: TextoFormatoCaptalize(
        medicamentodesc.toLocaleLowerCase()
      ),
      canal,
    };
    ApiPut(`/dadoscadastroinicial/atualizacao/cadastral`, dados)
      .then((retorno) => {
        setLoading(false);
        setTextoCarregando("");
        toast.success("Dados atualizados com sucesso!");
        //limpar dados do formulário
        setId_paciente("");
        setCpf("");
        setNome("");
        setDtnascimento("");
        setTelfixo("");
        setTelcelular("");
        setEmail("");
        setTelemerggencia("");
        setContemerggencia("");
        setTelresponsavel("");
        setResponsavel("");
        setLogradouro("");
        setNumero("");
        setBairro("");
        setCompl("");
        setCnum("");
        setCidade("");
        setCuf("");
        setCep("");
        setSelectedTipoContratacao("");
        setConvenio("");
        setCodconvenio("");
        setSelectedTipoConselho("");
        setTerapia_anterior(false);
        setTerapia_anterior_tempo("");
        setSabeAbordagem(false);
        setTerapia_anterior_abordagem("");
        setMotivo_pisicologo("");
        setAcomp_med(false);
        setAcomp_med_descricao("");
        setMedicamento(false);
        setMedicamento_desc("");
        setSelectedCanal("");
        setBuscarCpf("");
      })
      .catch((erro) => {
        setLoading(false);
        setTextoCarregando("");
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao atualizar dados. Motivo: ${msg}`);
      });
  };

  const enviarDados = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const _cpf = cpf.replace(/[^0-9]/g, "");
    const _celular = telcelular.replace(/[^0-9]/g, "");
    const _telefone = telfixo.replace(/[^0-9]/g, "");
    const _cep = cep.replace(/[^0-9]/g, "");
    const _telemergencia = telemerggencia.replace(/[^0-9]/g, "");
    const _telresponsavel = telresponsavel.replace(/[^0-9]/g, "");

    atualizarDadosPaciente(
      id_clinica.id || "",
      id_paciente,
      _cpf,
      nome,
      dtnascimento,
      email,
      _celular,
      _telefone,
      selectedEscolaridade,
      profissao,
      _cep,
      logradouro,
      compl,
      numero,
      bairro,
      cidade,
      cnum,
      cuf,
      _telemergencia,
      contemerggencia,
      grau_parentesco,
      _telresponsavel,
      responsavel,
      selectedTipoContratacao,
      codconvenio,
      convenio,
      terapia_anterior ? 1 : 0,
      terapia_anterior_tempo,
      motivo_pisicologo,
      sabeabordagem ? 1 : 0,
      terapia_anterior_abordagem,
      acomp_med ? 1 : 0,
      acomp_med_descricao,
      medicamento ? 1 : 0,
      medicamento_desc,
      selectedCanal
    );
  };

  //#region REALIZA REQUISIÇÃO PARA LISTAR DADOS DA TABELA
  const [dadosConvenio, setDadosConvenio] = useState([]);
  const buscarDados = async () => {
    const dados = {
      url: "/convenio/listar/:id_clinica",
      parametros: {
        id_clinica: id_clinica.id || "",
      },
    };
    ApiGet(dados)
      .then((retorno) => {
        setDadosConvenio(retorno);
        setLoading(false);
      })
      .catch((erro) => {
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    buscarDados();
  }, []);
  //#endregion

  //INFORMAÇÕES CLINICA
  const [nomeSocial, setNomeSocial] = useState()
  const [emailClinica, setEmailClinica] = useState()
  const [telefoneClinica, setTelefoneClinica] = useState()
  const [logoClinica, setlogoClinica] = useState()

  const buscarCadastroPaciente = (
    cpfpaciente: any,
    id_clinica: any,
    codigoverificacao: string
  ) => {
    setLoading(true);
    setTextoCarregando("Validando Código de Verificação ...");
    const dados = {
      cpf: cpfpaciente,
      id_clinica,
      codigoverificacao,
    };
    ApiPost(`/dadoscadastroinicial/carregar/cadastro`, dados)
      .then((retorno) => {
        setLoading(false);

        console.log(retorno[0])

        setId_paciente(retorno[0].id_paciente || "");
        setCpf(retorno[0].cpf || "");
        setNome(retorno[0].nome || "");
        setDtnascimento(retorno[0].dtnascimento || "");
        setTelfixo(retorno[0].telfixo || "");
        setTelcelular(retorno[0].telcelular || "");
        setEmail(retorno[0].email || "");
        setTelemerggencia(retorno[0].telemerggencia || "");
        setContemerggencia(retorno[0].contemerggencia || "");
        setTelresponsavel(retorno[0].telresponsavel || "");
        setResponsavel(retorno[0].responsavel || "");
        setLogradouro(retorno[0].logradouro || "");
        setNumero(retorno[0].numero || "");
        setBairro(retorno[0].bairro || "");
        setCompl(retorno[0].compl || "");
        setCnum(retorno[0].cnum || "");
        setCidade(retorno[0].cidade || "");
        setCuf(retorno[0].cuf || "");
        setCep(retorno[0].cep || "");

        setSelectedTipoContratacao(retorno[0].tipocontratacao || "");
        setConvenio(retorno[0].convenio || "");
        setCodconvenio(retorno[0].codconvenio || "");
        setSelectedTipoConselho(retorno[0].tipoconselho || "");

        setAbrirModalSenhaConfirmacao(false);
        setSenha("");
        setBuscarCpf("");


        //INFORMAÇÕES DINAMICAS DA CLINICA

        setNomeSocial(retorno[0].fantasia)
        setEmailClinica(retorno[0].email_clinica)
        setTelefoneClinica(retorno[0].telfixo_clinica != "" ? retorno[0].telfixo_clinica : retorno[0].telcelular_clinica)
        setlogoClinica(retorno[0].avatar_clinica)
      })
      .catch((erro) => {
        setLoading(false);
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao carregar dados. Motivo: ${msg}`);
      });
  };

  const validarCodVerificacao = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    buscarCadastroPaciente(buscarCpf, id_clinica.id, senha);
  };
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Pré Cadastro - Pacientes" />
        <Card>
          {cpf ? (
            <Form onSubmit={enviarDados}>
              <Card.Body>
                <div className="">
                    <img src={`data:image/png;base64,${logoClinica}`}
                    alt="logo"
                    style={{
                        width: "150px",
                        height: "150px",
                        display: "block",
                        margin: "auto"
                    }} />
                </div>
                <fieldset className="border p-2">
                  <legend className="tamanhoLegend float-none w-auto p-2">
                    Identificação
                  </legend>
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="ID Paciente"
                        name="id_paciente"
                        type="text"
                        placeholder="ID Paciente"
                        readonly
                        value={id_paciente || ""}
                        onChange={setId_paciente}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="CPF"
                        name="cpf"
                        type="text"
                        placeholder="CPF"
                        readonly
                        value={mascaraCPF(cpf) || ""}
                        onChange={setCpf}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                        required
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Nome"
                        name="nome"
                        type="text"
                        placeholder="Nome"
                        required
                        value={nome || ""}
                        onChange={setNome}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Data Nascimento"
                        name="dtnascimento"
                        type="date"
                        placeholder="Data Nascimento"
                        required
                        value={dtnascimento}
                        onChange={setDtnascimento}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Email"
                        name="email"
                        type="text"
                        placeholder="Email"
                        required
                        readonly
                        value={email || ""}
                        onChange={setEmail}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Celular"
                        name="telcelular"
                        type="text"
                        placeholder="Celular"
                        required
                        value={mascaraTelefoneCelular(telcelular) || ""}
                        onChange={setTelcelular}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Telefone"
                        name="telefone"
                        type="text"
                        placeholder="Telefone Fixo"
                        value={mascaraTelefoneFixo(telfixo) || ""}
                        onChange={setTelfixo}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <SelectInput
                        value={selectedEscolaridade}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setSelectedEscolaridade(e.target.value)
                        }
                        options={escolaridadeOptions}
                        placeholder="Escolaridade"
                        required={true}
                      />
                    </Col>
                    <Col sm={6}>
                      <InputSemBorda
                        label="Profissão"
                        name="profissao"
                        type="text"
                        placeholder="Profissão"
                        required
                        value={profissao || ""}
                        onChange={setProfissao}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="CEP"
                        name="cep"
                        type="text"
                        placeholder="CEP"
                        required
                        value={mascaraCEP(cep) || ""}
                        onChange={setCep}
                        onBlur={() => buscarCep(cep)}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Logradouro"
                        name="endereco"
                        type="text"
                        placeholder="Logradouro"
                        required
                        value={logradouro || ""}
                        onChange={setLogradouro}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Complemento"
                        name="compl"
                        type="text"
                        placeholder="Complemento"
                        value={compl || ""}
                        onChange={setCompl}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Número"
                        name="numero"
                        type="text"
                        placeholder="Número"
                        required
                        value={numero || ""}
                        onChange={setNumero}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Bairro"
                        name="bairro"
                        type="text"
                        placeholder="Bairro"
                        required
                        value={bairro || ""}
                        onChange={setBairro}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={5}>
                      <InputSemBorda
                        label="Cidade"
                        name="cidade"
                        type="text"
                        placeholder="Cidade"
                        required
                        value={cidade || ""}
                        onChange={setCidade}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>

                    <Col sm={3}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1.5, width: "100%" }}
                      >
                        <InputLabel>UF</InputLabel>
                        <Select
                          value={cuf}
                          onChange={(e: SelectChangeEvent) =>
                            setCuf(e.target.value)
                          }
                          label="UF"
                          required
                        >
                          <MenuItem value="">
                            <em>Selecione...</em>
                          </MenuItem>
                          {dadosUF.map((item: any) => (
                            <MenuItem value={item.uf}>{item.nome}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Tel. Emergência"
                        name="telemerggencia"
                        type="text"
                        placeholder="Tel. Emergência"
                        required
                        value={mascaraTelefoneCelular(telemerggencia) || ""}
                        onChange={setTelemerggencia}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={6}>
                      <InputSemBorda
                        label="Contato Emergência"
                        name="contemerggencia"
                        type="text"
                        placeholder="Contato Emergência"
                        required
                        value={contemerggencia || ""}
                        onChange={setContemerggencia}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Grau de Parentesco"
                        name="grauparentesco"
                        type="text"
                        placeholder="Grau de Parentesco"
                        required
                        value={grau_parentesco || ""}
                        onChange={setGrau_parentesco}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Tel. Responsável"
                        name="telresponsavel"
                        type="text"
                        placeholder="Tel. Responsável"
                        value={mascaraTelefoneCelular(telresponsavel) || ""}
                        onChange={setTelresponsavel}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={8}>
                      <InputSemBorda
                        label="Nome Responsável"
                        name="responsavel"
                        type="text"
                        placeholder="Nome Responsável"
                        value={responsavel || ""}
                        onChange={setResponsavel}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <SelectInput
                        value={selectedTipoContratacao}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setSelectedTipoContratacao(e.target.value)
                        }
                        options={tipoContratacaoOptions}
                        placeholder="Tipo de Consulta"
                        required={true}
                      />
                    </Col>

                    <Col sm={3}>
                      <InputSemBorda
                        label="Número da carteirinha"
                        name="codconvenio"
                        type="text"
                        placeholder="Número da carteirinha"
                        required={
                          selectedTipoContratacao === "1" ||
                          selectedTipoContratacao === "3"
                            ? true
                            : false
                        }
                        readonly={
                          selectedTipoContratacao === "1" ||
                          selectedTipoContratacao === "3"
                            ? false
                            : true
                        }
                        value={codconvenio || ""}
                        onChange={setCodconvenio}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1.5, width: "100%" }}
                      >
                        <InputLabel>Convênio</InputLabel>
                        <Select
                          value={convenio}
                          onChange={(e: SelectChangeEvent) =>
                            setConvenio(e.target.value)
                          }
                          label="UF"
                          required={
                            selectedTipoContratacao === "1" ||
                            selectedTipoContratacao === "3"
                              ? true
                              : false
                          }
                          disabled={
                            selectedTipoContratacao === "1" ||
                            selectedTipoContratacao === "3"
                              ? false
                              : true
                          }
                        >
                          <MenuItem value="">
                            <em>Selecione...</em>
                          </MenuItem>
                          {dadosConvenio.map((item: any) => (
                            <MenuItem value={item.id_convenio}>
                              {item.fantasia}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>

                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Já fez terapia?
                      </label>
                      <Switch
                        checked={terapia_anterior}
                        onChange={() => setTerapia_anterior(!terapia_anterior)}
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Quantos Meses?"
                        name="terapia_anterior_tempo"
                        type="number"
                        placeholder="Quantos Meses?"
                        required={terapia_anterior}
                        readonly={!terapia_anterior}
                        value={terapia_anterior_tempo || ""}
                        onChange={setTerapia_anterior_tempo}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <InputSemBorda
                        label="Por qual motivo procurou um psicólogo?"
                        name="motivo_pisicologo"
                        type="text"
                        placeholder="Por qual motivo procurou um psicólogo?"
                        required
                        value={motivo_pisicologo || ""}
                        onChange={setMotivo_pisicologo}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black  text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Sabe a Abordagem?
                      </label>
                      <Switch
                        checked={sabeabordagem}
                        onChange={() => setSabeAbordagem(!sabeabordagem)}
                        // disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={10}>
                      <InputSemBorda
                        label="Qual abordagem?"
                        name="terapia_anterior_abordagem"
                        type="text"
                        placeholder="Qual abordagem?"
                        required={sabeabordagem}
                        readonly={!sabeabordagem}
                        value={terapia_anterior_abordagem || ""}
                        onChange={setTerapia_anterior_abordagem}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Faz acompanhamento médico?
                      </label>
                      <Switch
                        checked={acomp_med}
                        onChange={() => setAcomp_med(!acomp_med)}
                        // disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={10}>
                      <InputSemBorda
                        label="Qual é motivo do acompanhamento médico?"
                        name="acomp_med_descricao"
                        type="text"
                        placeholder="Por qual motivo procurou um psicólogo?"
                        required
                        readonly={!acomp_med}
                        value={acomp_med_descricao || ""}
                        onChange={setAcomp_med_descricao}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black  text-capitalize "
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Faz uso de medicamentos?
                      </label>
                      <Switch
                        checked={medicamento}
                        onChange={() => setMedicamento(!medicamento)}
                        // disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={7}>
                      <InputSemBorda
                        label="Qual é o medicamento?"
                        name="medicamento_desc"
                        type="text"
                        placeholder="Qual é o medicamento?"
                        required
                        readonly={!medicamento}
                        value={medicamento_desc || ""}
                        onChange={setMedicamento_desc}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black  text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <SelectInput
                        value={selectedCanal}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setSelectedCanal(e.target.value)
                        }
                        options={canalOptions}
                        placeholder="Como conheceu a clínica?"
                        required={true}
                      />
                    </Col>
                  </Row>
                  <div className="border-top my-3" />

                  <Row>
                    <Col sm={12}>
                      <p className="text-justify">
                        Em cumprimento a Lei Geral de Proteção de Dados (Lei
                        13.709/2018) ou LGPD, que tem como objetivo reforçar a
                        responsabilidade e a transparência no tratamento de
                        dados pessoais e/ou sensíveis, ao submeter esse
                        formulário você autoriza o controle dos seus dados
                        pessoais informados, à {nomeSocial} para serem
                        utilizados exclusivamente para entrar em contato com
                        você sobre questões relacionadas a marcação de
                        consultas, situações emergenciais, solicitação de
                        autorização ao seu convênio e análise do profissional de
                        saúde designado para o tratamento clínico. O
                        armazenamento dos dados será feito por {nomeSocial} com total responsabilidade e sigilo. A revogação dessa
                        autorização, bem como a exclusão dos dados poderá ser
                        realizada a qualquer momento mediante a solicitação para
                        o e-mail {emailClinica}.
                      </p>
                      <br />
                      <p>Para esclarecimentos adicionais, favor entrar em contato através do seguinte número: {telefoneClinica}</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Aceitar
                      </label>
                      <Switch
                        checked={aceitar}
                        onChange={() => setAceitar(!aceitar)}
                        required
                        // disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                  </Row>
                </fieldset>
              </Card.Body>

              <Card.Footer>
                <div className="d-flex my-2 gap-1 justify-end-content">
                  <ButtonCustom
                    type="submit"
                    className="btn btn-light text-info-emphasis bg-white border-0"
                    descricaoBotao="Gravar"
                    icone={mdiCheckCircle}
                  />
                </div>
              </Card.Footer>
            </Form>
          ) : (
            <Form onSubmit={buscarPaciente}>
              <Card.Body>
                <fieldset className="border p-2">
                  <legend className="tamanhoLegend float-none w-auto p-2">
                    Identificação
                  </legend>

                  <Row>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Informe o CPF do paciente para buscar"
                        name="cpf"
                        type="text"
                        placeholder="Informe o CPF do paciente para buscar"
                        required
                        value={mascaraCPF(buscarCpf) || ""}
                        onChange={setBuscarCpf}
                        useRef={buscarCpfRef}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <br />
                      <ButtonCustom
                        type="submit"
                        className="btn btn-light text-info-emphasis bg-white border-0"
                        descricaoBotao="Buscar"
                        icone={mdiCheckCircle}
                        disabled={loading}
                      />
                    </Col>
                  </Row>
                </fieldset>
              </Card.Body>
            </Form>
          )}
        </Card>
      </div>
      <Modal
        show={abrirModalSenhaConfirmacao}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header className="bg-white">
          <Modal.Title>
            Para continuar informe o código de verificação enviado para o e-mail
            informado no pré cadastro.
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={validarCodVerificacao}>
          <Modal.Body className="bg-white">
            <InputSemBorda
              label="Código de Verificação"
              name="senha"
              type="password"
              placeholder="Senha"
              required
              value={senha}
              onChange={setSenha}
              classNameInputsSemBorda="form-control bordasInferiorInput text-black"
            />
          </Modal.Body>
          <Modal.Footer className="bg-white ">
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Cancelar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  setBuscarCpf("");
                  setSenha("");
                  buscarCpfRef.current?.focus();
                  setAbrirModalSenhaConfirmacao(false);
                }}
              />
              <ButtonCustom
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Confirmar"
                icone={mdiCheckCircle}
              />
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      <ModalLoading show={loading} label={textoCarregando} />
    </>
  );
}
