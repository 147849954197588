// Pagination.tsx
import React from 'react';
import { Button } from '../Buttons/Button';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <div className='rodapeTabelaPaginacao'>
      {currentPage > 1 && (
        <Button className='botoesPaginacao' descricaoBotao='Anterior' onclick={() => handlePageChange(currentPage - 1)}/>
      )}

      {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
        <Button className='botoesPaginacao' descricaoBotao={page.toString()} key={page} onclick={() => handlePageChange(page)}/>
      ))}

      {currentPage < totalPages && (
        <Button className='botoesPaginacao' descricaoBotao='Próximo' onclick={() => handlePageChange(currentPage + 1)}/>
      )}
    </div>
  );
};

export default Pagination;
