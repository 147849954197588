import { Row, Col, Card } from "react-bootstrap";
import ContainerTitulo from "../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../components/Formularios/Inputs/InputsSemBorda";

import AddIcon from "@mui/icons-material/Add";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import Tabela from "../../components/Formularios/Table";

import { useEffect, useState } from "react";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ITabela } from "../../components/Formularios/Table/TabelaInterface";
import { InterfaceUsuario } from "../../types/UsuarioInterface";

import { toast } from "react-toastify";
import ApiGet from "../../api/endPoints/useGet";
import ModalLoading from "../../components/Formularios/Modal/ModalLoading";
import { showConfirmationDialog } from "../../components/Formularios/Modal/ModalExcluir";
import ApiDelete from "../../api/endPoints/useDelete";
import { getSessionData } from "../../utils/storageUtils";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Content, TDocumentDefinitions } from "pdfmake/interfaces";
import CarregarDados from "./components/CarregarDados";
import { url_CarregarDados_ID } from "./components/auxiliar";
import moment from "moment";
import axios from "axios";


export function Prontuario() {

  const navigate = useNavigate();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const id_clinica = getSessionData("DadosIdClinica") || "";
  const CPF = sessionStorage.getItem('Cpf') || '';
  const usuario = getSessionData("NomeUsuario");
  const avatarClinica = getSessionData("DadosAvatarClinica") || "";

  interface Action {
    icon: JSX.Element;
    name: string;
  }
  const actions: Action[] = [
    {
      icon: (
        <AddIcon
        // onClick={() => navigate("/prontuario/paciente/novo")}
        />
      ),
      name: "Incluir",
    },
    // { icon: <FileCopyIcon />, name: "Duplicar" },
    // { icon: <SaveIcon />, name: "Salvar" },
    // { icon: <PrintIcon />, name: "Imprimir" },
  ];

  const visualizar = (dados: any) => {
    const idprontuario = dados.id_prontuario;
    navigate("/prontuario/paciente/visu/" + idprontuario);
  };

  const editar = async (dados: any) => {
    const idprontuario = dados.id_prontuario;
    navigate("/prontuario/paciente/editar/" + idprontuario);
  };

  const excluir = async (dados: any) => {
    const idprontuario = dados.id_prontuario;
    await showConfirmationDialog(
      "Excluir Prontuario",
      `Tem certeza que deseja excluir o prontuario \n ${dados.nome}?`,
      "question",
      "Excluir",
      "Cancelar"
    )
      .then((result) => {
        if (result.confirmed) {
          const dados = {
            url: "/prontuario/excluir/:idprontuario/:id_clinica",
            parametros: {
              idprontuario: idprontuario,
              id_clinica: id_clinica,
            },
          };
          ApiDelete(dados)
            .then((retorno) => {
              toast.success(retorno.message);
              buscarDados();
            })
            .catch((erro) => {
              toast.error(
                `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
              );
            });
        }
      })
      .catch((erro) => {
        toast.error(
          `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
        );
      });
  };

  const colunas: ITabela[] = [
    { titulo: "ID Prontuário", acesso: "id_prontuario" },
    { titulo: "CPF", acesso: "cpf" },
    { titulo: "Paciente", acesso: "paciente" },
    { titulo: "Prodissional", acesso: "profissional" },
  ];

  //#region REALIZA REQUISIÇÃO PARA LISTAR DADOS DA TABELA
  const [idProntuario, setIdProntuario] = useState<string>("");
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const buscarDados = async () => {
    setLoading(true);
    const dados = {
      url: "/prontuario/listar/:id_clinica",
      parametros: { id_clinica: id_clinica },
    };
    ApiGet(dados)
      .then((retorno) => {
        setDados(retorno);
        //setIdProntuario(retorno[0].id_prontuario);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    buscarDados();
  }, []);
  //#endregion

  //#endregion IMPRIMIR
  const gerarPDF = async (dados: any) => {
    const idprontuario = dados.id_prontuario;
    const id_clinica = dados.id_clinica;
    const url = "/prontuario/impressao/:idprontuario/:id_clinica";

    CarregarDados(url, idprontuario, id_clinica)
      .then((retorno) => {

        const dados = retorno;

        const dataAtual = moment().format("DD/MM/YYYY HH:mm:ss");
        const avatarClinicaAtual = avatarClinica.replace(/^"|"$/g, '');

        let content: any[] = [];
        let isFirstIteration = true;
        let totalProfissionais = dados.reduce((total, prontuario) => total + Object.keys(prontuario.profissionais).length, 0);
        let countProfissionais = 0;

        for (const prontuario of dados) {
          for (const profissional in prontuario.profissionais) {
            countProfissionais++;
            // Imprime os dados do profissional
            content.push(
              { text: `Psicólogo (a): ${prontuario.profissionais[profissional].profissional}`, margin: [40, 0, 0, 0] },
              { text: `CRP: ${prontuario.profissionais[profissional].codconselho}`, margin: [40, 0, 0, 0] },
              { text: `Contato: ${prontuario.profissionais[profissional].telefoneprofissional}`, margin: [40, 0, 0, 0] },
              { text: '\n' }  // espaço entre os dados do profissional e as evoluções
            );

            // Imprime os dados do paciente apenas na primeira iteração
            if (isFirstIteration) {
              content.push(
                { text: `Identificação do paciente`, bold: true, margin: [40, 0, 0, 0], decoration: 'underline' },
                { text: `Nome: ${prontuario.paciente}`, margin: [40, 0, 0, 0] },
                { text: `Telefone: ${prontuario.telefonepaciente}`, margin: [40, 0, 0, 0] },
                { text: `Email: ${prontuario.emailpaciente}`, margin: [40, 0, 0, 0] },
                { text: '\n' },  // espaço entre os dados do paciente e do profissional
                { text: 'Contato de emergência:', margin: [40, 0, 0, 30] }  // espaço entre os dados do paciente e do profissional
              );
              isFirstIteration = false;
            }

            // Imprime os dados da avaliação
            content.push(
              { text: `Avaliação da demanda e objetivo do trabalho`, bold: true, margin: [40, 0, 0, 0], decoration: 'underline' },
              { text: `${prontuario.profissionais[profissional].avaliacao}`, margin: [40, 0, 0, 30], fontSize: 11 },
            );

            // Mapeia as evoluções
            let evolucoes = prontuario.profissionais[profissional].evolucoes.map((evolucao: any) => {
              return `${evolucao.data_ocorrencia} - ${evolucao.ocorrencia}`;
            });

            let evolucoesString = evolucoes.join('\n\n');
            content.push(
              {
                layout: "noBorders",
                table: {
                  widths: ["auto"],
                  body: [
                    [
                      {
                        text: "Evolução",
                        margin: [40, 0, 0, -5],
                        fontSize: 12,
                        bold: true,
                        border: [true, true, true, true],
                        decoration: 'underline',
                      },
                    ],
                    [
                      {
                        text: evolucoesString,
                        fontSize: 11,
                        margin: [40, 5, 0, 30], //margem: esquerda, superior,direita, rodape
                        border: [true, true, true, true],
                        alignment: 'justify',
                      },
                    ],
                  ],
                },
              },
              // { text: '\n' }  // espaço entre as evoluções
            );

            // Imprime os dado do Encerramento
            content.push(
              { text: `Encerramento ou Encaminhamento`, bold: true, margin: [40, 0, 0, 0], decoration: 'underline' },
              { text: `${prontuario.profissionais[profissional].data_encerramento} - ${prontuario.profissionais[profissional].encerramento}`, margin: [40, 0, 0, 30], fontSize: 11 },
            );

            // Pula para a próxima página para um novo profissional
            if (countProfissionais < totalProfissionais) {
              content.push({ text: '', pageBreak: 'after' });
            }
          }
        }

        const documentoImpressao: TDocumentDefinitions = {
          pageSize: 'A4',
          pageMargins: [20, 180, 20, 20],

          header: function (currentPage, pageCount) {
            return [
              {
                image: `data:image/png;base64,${avatarClinicaAtual}`,
                width: 100,
                height: 100,
                margin: [0, 15, 0, 10],
                alignment: 'center',
              },
              {
                text: 'PRONTUÁRIO PSICOLÓGICO',
                alignment: 'center',
                bold: true,
                margin: [0, 10, 0, 0], // margem: esquerda, superior, direita, rodapé
                fontSize: 12,
              }
            ]
          },

          content: content,

          styles: {
            tableHeader: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "center",
            },
          },
          footer(currentPage, pageCount) {
            const TelefoneClinica = getSessionData("TelefoneClinica") || ""
            const email = getSessionData("EmailClinica") || ""
            const nomeClinica = getSessionData("DadosNomeClinica") || ""
            return {
              layout: 'noBorders',
              margin: [0, 0, 0, 0], //margem: esquerda, superior,direita, rodape
              table: {
                widths: ['*'],
                body: [
                  [
                    {
                      text: `${TelefoneClinica.replace(/"/g, '')} | ${email.replace(/"/g, '')} | ${nomeClinica.replace(/"/g, '')}  | Gerado em TotalClínica ® pelo usuário ${usuario} em ${dataAtual} | Página ${currentPage.toString()} de ${pageCount}`,
                      fontSize: 8,
                      alignment: 'center',
                      fillColor: '#9EDDDC',
                      lineHeight: 1.5,
                      color: '#ffffff',
                      bold: true,
                    }
                  ],
                ],
              },
            };
          },
        };
        pdfMake.createPdf(documentoImpressao).open();
      })
      .catch((erro) => {
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  }



  //verifica permissões

  const token_ = getSessionData("MultClinicaWebToken")
  const isSuper = getSessionData("isPerfilSuper") || "";
  const [visualizar_prontuario, set_visualizar] = useState(false)
  const [criar, set_criar] = useState(false)
  const [editar_prontuario, set_editar] = useState(false)
  function verificaPermissoes(id_perfil: any) {

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/perfil/carregar/${id_perfil}`, {
      headers: {
        Authorization: token_
      }
    }).then(function (resposta) {

      set_visualizar(resposta.data[0].visualizarprontuario === "1" || isSuper === "1" ? true : false)
      set_editar(resposta.data[0].editarprontuario === "1" || isSuper === "1" ? true : false)
      set_criar(resposta.data[0].criarprontuario === "1" || isSuper === "1" ? true : false)
    }).catch(function (erro) {

      toast.error("Erro ao carregar permissão.")
    })
  }

  useEffect(function () {

    verificaPermissoes(getSessionData("DadosPerfilID"))
  }, [])

  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Listagem de Prontuários" />

        <Row>
          <Col sm={12}>
            <Card>
              <Card.Body>
                <Row>
                  <SpeedDial
                    ariaLabel="SpeedDial basic example"
                    direction="left"
                    sx={{
                      right: 0,
                      position: "absolute",
                    }}
                    icon={<SpeedDialIcon />}
                  >
                    {actions.map((action) => (
                      <SpeedDialAction
                        hidden={!criar}
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => {
                          if (action.name === 'Incluir') {
                            if (CPF.trim() === '' || CPF.trim() === null) {
                              toast.error('O CPF não está cadastrado em usuários.');
                            } else {
                              navigate("/prontuario/paciente/novo");
                            }
                          }
                        }}
                      />
                    ))}
                  </SpeedDial>
                </Row>
                <Row>
                  <div className="d-flex my-2 gap-1 ">
                    <Col sm={11}>
                      <InputSemBorda
                        name="nome"
                        type="text"
                        value=""
                        onChange={() => { }}
                        classNameInputsSemBorda="form-control bordasInferiorInput"
                        placeholder=" "
                        label="Prontuário"
                      />
                    </Col>
                  </div>
                </Row>

                <Row>
                  <Tabela
                    coluna={colunas}
                    dados={dados}
                    itemsPerPage={10}
                    onVisuClick={visualizar}
                    onEditClick={editar}
                    onDeleteClick={excluir}
                    usaDelete={false}
                    onImprimirClick={gerarPDF}
                    usaImprimir={visualizar_prontuario}
                    usaEdit={editar_prontuario}
                    id="codigo"
                  />
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <ModalLoading
        show={loading}
        label="Carregando Listagem de Prontuaarios..."
      />
    </>
  );
}
