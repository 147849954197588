import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import FormProcedimentoxProfissional from "./components/formulario";

export function ProcedimentosxProfissionalIncluir() {
  const id_sequencial = useParams();
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Incluir Procedimento x Profissional" />
        <FormProcedimentoxProfissional id_sequencial={id_sequencial.id} acao="Novo" />
      </div>
    </>
  );
}
