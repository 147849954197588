export function mascaraCPF(cpf: string): string {

    if (cpf != "" && cpf != " " && cpf != null) {
        // Remove todos os caracteres não numéricos
        const numerosCPF = cpf.replace(/\D/g, '');
        
        // Limita o CPF a 11 dígitos
        const cpfLimitado = numerosCPF.slice(0, 11);

        // Aplica a máscara
        return cpfLimitado.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
    }
    else{
        return cpf
    }

}

export function mascaraCEP(cep: string): string {
    // Remove todos os caracteres não numéricos
    const numerosCEP = cep.replace(/\D/g, '');

    // Limita o CEP a 8 dígitos
    const cepLimitado = numerosCEP.slice(0, 8);

    // Aplica a máscara
    return cepLimitado.replace(/^(\d{5})(\d{3})$/, '$1-$2');
}

export function mascaraTelefoneFixo(telefone: string): string {
    // Remove todos os caracteres não numéricos
    const numerosTelefone = telefone.replace(/\D/g, '');

    // Limita o telefone a 10 dígitos
    const telefoneLimitado = numerosTelefone.slice(0, 10);

    // Aplica a máscara
    return telefoneLimitado.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
}

export function mascaraTelefoneCelular(celular: string): string {
    // Remove todos os caracteres não numéricos
    const numerosTelefone = celular.replace(/\D/g, '');

    // Limita o telefone a 11 dígitos
    const telefoneLimitado = numerosTelefone.slice(0, 11);

    // Se o telefone tiver menos de 11 dígitos, retorne sem máscara
    if (telefoneLimitado.length < 11) {
        return telefoneLimitado;
    }

    // Aplica a máscara
    return telefoneLimitado.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
}


export function mascaraCNPJ(cnpj: string): string {
    // Remove todos os caracteres não numéricos
    const numerosCNPJ = cnpj.replace(/\D/g, '');

    // Limita o CNPJ a 14 dígitos
    const cnpjLimitado = numerosCNPJ.slice(0, 14);

    // Aplica a máscara
    return cnpjLimitado.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
}


