import { Row, Col, Card } from "react-bootstrap";
import ContainerTitulo from "../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../components/Formularios/Inputs/InputsSemBorda";
import Tabela from "../../components/Formularios/Tabela/Tabela";
import TabelaColunaInterface from "../../components/Formularios/Tabela/TabelaColunas";
import { Button } from "../../components/Formularios/Buttons/Button";
import { mdiFileEditOutline, mdiTrashCanOutline } from "@mdi/js";

export function ConsultasProcedimentos() {
  const colunas: TabelaColunaInterface[] = [
    { titulo: "Código", acesso: "codigo" },
    { titulo: "Procedimento", acesso: "procedimento" },
    { titulo: "Paciente", acesso: "paciente" },
    { titulo: "Telefone", acesso: "fone" },
    { titulo: "Data", acesso: "data" },
    { titulo: "Médico", acesso: "medico" },
    { titulo: "Tipo Atendimento", acesso: "tipo" },
    
    {
      titulo: "",
      acoes: [
        <Button
          onclick={() => {}}
          icone={mdiFileEditOutline}
          type="button"
          className="botaoTransparente"
          title="Editar"
        />,
        <Button
          onclick={() => {}}
          icone={mdiTrashCanOutline}
          type="button"
          className="botaoTransparente"
          title="Excluir"
        />,
      ],
      className: "acoes",
    },
  ];

  const dados = [
    {
      codigo: 1,
      procedimento: "Consulta",
        paciente: "João da Silva",
        fone: "(11) 99999-9999",
        data: "01/01/2021",
        medico: "Dr. José da Silva",
        tipo: "Particular",
    },
    {
        codigo: 2,
        procedimento: "Consulta",
        paciente: "João da Silva",
        fone: "(11) 99999-9999",
        data: "01/01/2021",
        medico: "Dr. José da Silva",
        tipo: "Particular",
        },

    // Adicione mais dados conforme necessário
  ];
  return (
    <>
      <div className="container-fluid">
        <ContainerTitulo titulo="Listagem de Agendamentos / Procedimentos" />
      </div>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Body>
              <Row>
                <div className="d-flex my-2 gap-1 ">
                  <Col sm={10}>
                    <InputSemBorda
                      name="pesquisa"
                      type="text"
                      value=""
                      onChange={() => {}}
                      classNameInputsSemBorda=" bordasInferiorInput"
                      placeholder="Procedimento"
                    />
                  </Col>
                  <Col sm={2}>
                    <InputSemBorda
                      name="pesquisa"
                      type="text"
                      value=""
                      onChange={() => {}}
                      classNameInputsSemBorda=" bordasInferiorInput"
                      placeholder="Convênio"
                    />
                  </Col>
                </div>
              </Row>
              <Row>
                <Tabela coluna={colunas} dados={dados} itemsPerPage={5} />
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
