import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../components/Formularios/Inputs/InputsSemBorda";
import {
  Switch,
  Avatar,
  Button,
  IconButton,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  Tab,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Button as ButtonCustom } from "../../../../../components/Formularios/Buttons/Button";
import {
  mdiCheckAll,
  mdiCheckCircle,
  mdiKeyboardReturn,
  mdiPlus,
} from "@mdi/js";
import { useNavigate, useParams } from "react-router-dom";
import ModalUploadImage from "../../../../../components/Formularios/Modal/ModalUpload";
import { toast } from "react-toastify";
import ModalLoading from "../../../../../components/Formularios/Modal/ModalLoading";
import ApiGet from "../../../../../api/endPoints/useGet";
import ApiPut from "../../../../../api/endPoints/usePut";
import ApiPost from "../../../../../api/endPoints/usePost";
import ConverterBase64 from "../../../../../components/ConversorBase64";
import { getSessionData } from "../../../../../utils/storageUtils";
import ValidaComplexidadeSenha from "../../../../../components/Formularios/ValidaComplexidadeSenha";
import ConsultaComplexidade from "../../../../../components/ComplexidadeSenha";
import { TextoFormatoCaptalize } from "../../../../../components/Auxiliar/ConvTextoCaptalize";
import { TextoFormatoLowerCase } from "../../../../../components/Auxiliar/ConvTextoLower";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ITabela } from "../../../../../components/Formularios/Table/TabelaInterface";
import Tabela from "../../../../../components/Formularios/Table";
import CarregarDadosEmpresa from "../CarregarEmpresa";
import { url_CarregarDados_Empresa } from "../auxiliar";
import { showConfirmationDialog } from "../../../../../components/Formularios/Modal/ModalExcluir";
import ApiDelete from "../../../../../api/endPoints/useDelete";
import axios from "axios";
import { mascaraCPF } from "../../../../../hooks/mascaras";

interface FormUsuarioProps {
  idusuario?: string;
  acao?: string;
}

const FormUsuario: React.FC<FormUsuarioProps> = ({ idusuario, acao }) => {
  const token = getSessionData("MultClinicaWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  const idperfil = getSessionData("DadosPerfilID") || "0";
  const isVinculo = getSessionData("isPerfilVinculo") || "";
  const usuario = getSessionData("NomeUsuario") || ""

  // const InformaVinculo = isSuper === "1" ? true : false;

  //#region Controla as guias
  const [guia, setGuia] = useState("1");

  const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
    setGuia(novaGuia);
  };
  //#endregion

  const navigate = useNavigate();
  const [nome, setNome] = useState<string>("");
  const [login, setLogin] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [senha, setSenha] = useState<string>("");
  const [confirmSenha, setConfirmSenha] = useState<string>("");
  const [telefone, setTelefone] = useState<string>("");
  const [avatar, setAvatar] = useState<string>("");
  const [ativo, setAtivo] = useState<boolean>(true);
  const [perfil, setPerfil] = useState<string>("");
  const [empresaVinculada, setEmpresaVinculada] = useState<string>("");
  const [cpf, setCpf] = useState<string>("");

  const [master, setMaster] = useState<number>(0);

  const senhaRef = useRef<HTMLInputElement>(null);

  const [dadosperfil, setDadosPerfil] = useState<any[]>([]);

  const [showUpload, setShowUpload] = useState<boolean>(false);

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      setShowUpload(true);
      ConverterBase64(file)
        .then((retorno) => {
          setShowUpload(false);
          setAvatar(retorno);
        })
        .catch((erro) => {
          setShowUpload(false);
          toast.error(erro);
        });
    }
  };
  const handleRemoveAvatar = () => {
    setAvatar("");
  };

  //#region REALIZA REQUISIÇÃO PARA EXIBIR OS DADOS DO USUÁRIO

  const carregarPerfil = async (idusuario: any) => {
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: `/perfil/listar/${isSuper}`,
      parametros: {},
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setDadosPerfil(retorno);
      })
      .catch((erro) => {
        toast.error(`Erro ao carregar registro. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    carregarPerfil(idusuario);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion
  //#region REALIZA REQUISIÇÃO PARA EXIBIR OS DADOS DO USUÁRIO
  const [loading, setLoading] = useState<boolean>(false);
  const carregarDados = async (idusuario: any) => {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/usuario/carregar/:idusuario",
      parametros: {
        idusuario: idusuario,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setNome(retorno[0].nome);
        setLogin(retorno[0].nome_usuario);
        setEmail(retorno[0].email);
        setSenha(retorno[0].senha);
        setConfirmSenha(retorno[0].senha);
        setTelefone(retorno[0].telefone);
        if (retorno[0].avatar !== null) {
          setAvatar(retorno[0].avatar);
        }
        if (retorno[0].situacao === 1) {
          setAtivo(true);
        } else {
          setAtivo(false);
        }
        setPerfil(retorno[0].id_perfil);
        setEmpresaVinculada(retorno[0].vinculo);
        setDadosFilial(retorno[0].filial);
        setCpf(retorno[0].cpf);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        toast.error(`Erro ao carregar dados. Motivo: ${erro.message}`);
      });
  };

  const [permVisualizar, setPermVisualizar] = useState<boolean>(false);
  const buscarDadosPermissao = async (perfil: string) => {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/perfil/carregar/:id_perfil",
      parametros: {
        id_perfil: perfil,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        const retornoApi = retorno[0];

        setPermVisualizar(
          retornoApi.admin_user_visu === "1" || isSuper === "1" ? true : false
        );

        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    if (idusuario !== "novo") {
      buscarDadosPermissao(idperfil);
      carregarDados(idusuario);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idusuario]);
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS DO USUÁRIO
  const atualizarDados = async (
    codigo: any,
    nome: string,
    email: string,
    senha: string,
    telefone: string,
    avatar: string,
    situacao: number,
    id_perfil: string,
    vinculo: string,
    cpf: string,
    usuarioEdicao: string
  ) => {
    const dados = {
      nome: TextoFormatoCaptalize(nome.toLowerCase()),
      email: TextoFormatoLowerCase(email.toLowerCase()),
      senha,
      telefone,
      avatar,
      situacao,
      id_perfil,
      vinculo,
      cpf,
      usuarioEdicao
    };

    ApiPut(`/usuario/atualizar/${codigo}`, dados, token)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => voltar(), 2000);
      })
      .catch((erro) => {
        toast.error(`Erro ao atualizar dados. Motivo: ${erro.message}`);
      });
  };

  //#endregion
  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS DO USUÁRIO
  const gravarDados = (
    nome: string,
    usuario: string,
    email: string,
    senha: string,
    telefone: string,
    avatar: string,
    situacao: number,
    id_perfil: string,
    vinculo: string,
    cpf: string,
    filiais: any,
    usuarioCadastro: any
  ) => {
    const dados = {
      nome: TextoFormatoCaptalize(nome.toLowerCase()),
      usuario: TextoFormatoLowerCase(usuario.toLowerCase()),
      email: TextoFormatoLowerCase(email.toLowerCase()),
      senha,
      telefone,
      avatar,
      situacao,
      id_perfil,
      vinculo,
      cpf,
      filiais,
      usuarioCadastro
    };
    ApiPost("/usuario/gravar", dados, token)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate("/administrativo/usuario"), 2000);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };

  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validaSenha = ConsultaComplexidade(senha);
    if (senha !== confirmSenha) {
      toast.error("A senha e a confirmação não conferem.");
      setSenha("");
      setConfirmSenha("");
      senhaRef.current?.focus();
    } else {
      if (validaSenha < 60 && acao === "Novo") {
        toast.error("A senha não atende aos requisitos de segurança.");
        setSenha("");
        setConfirmSenha("");
        senhaRef.current?.focus();
        return;
      } else {
        if (idusuario === "novo") {
          if (dadosFilial.length === 0 && master === 0) {
            toast.error(
              "Para cadastrar o usuário. Você deve informar pelo menos 1 clínica."
            );
            return;
          } else if (dadosFilial.length > 0 && master === 0) {
            gravarDados(
              nome,
              login,
              email,
              senha,
              telefone,
              avatar,
              ativo ? 1 : 0,
              perfil,
              isVinculo !== "" ? isVinculo : empresaVinculada,
              cpf,
              dadosFilial,
              usuario
            );
            toast.info("Inserindo novo registro...");
          } else if (master === 1) {
            gravarDados(
              nome,
              login,
              email,
              senha,
              telefone,
              avatar,
              ativo ? 1 : 0,
              perfil,
              empresaVinculada,
              cpf,
              dadosFilial,
              usuario
            );
            toast.info("Inserindo novo registro...");
          }
        } else {
          atualizarDados(
            idusuario,
            nome,
            email,
            senha,
            telefone,
            avatar,
            ativo ? 1 : 0,
            perfil,
            empresaVinculada,
            cpf,
            usuario
          );
          toast.info("Atualizando dados do registro...");
        }
      }
    }
  };

  //#endregion

  //#region Dados para vincular a filial do usuario
  const colunas: ITabela[] = [
    { titulo: "", acesso: "avatar" },
    { titulo: "Código", acesso: "id_clinica" },
    { titulo: "Clínica", acesso: "fantasia" },
    { titulo: "Bairro", acesso: "bairro" },
    { titulo: "UF", acesso: "cuf" },
    { titulo: "Responsável", acesso: "responsavel" },
  ];
  const [dadosFilial, setDadosFilial] = useState<any[]>([]);
  const selecionarFilial = (dados: any) => {
    if (acao === "Novo") {
      const filialSelecionada = dadosFilial.some(
        (item: any) => item.id_clinica === dados.id_clinica
      );

      if (!filialSelecionada) {
        setDadosFilial([...dadosFilial, dados]);
        setAbrirModalListarFilial(false);
      } else {
        toast.error("Clínica já vinculada ao usuário.");
      }
    } else if (acao === "Editar") {
      const filialSelecionada = dadosFilial.some(
        (item: any) => item.id_clinica === dados.id_clinica
      );

      if (!filialSelecionada) {
        const dadosClinica = {
          id_usuario: idusuario,
          id_clinica: dados.id_clinica,
        };
        //  ApiPost("/usuario/gravar", dados, token)
        ApiPost("/usuario/gravar/clinica", dadosClinica, token)
          .then((retorno) => {
            toast.success(retorno.message);
            carregarDados(idusuario);
            setAbrirModalListarFilial(false);
          })
          .catch((erro) => {
            toast.error(
              `Erro ao incluir registro. Motivo: ${erro.response.data.message}`
            );
          });
      } else {
        toast.error("Clínica já vinculada ao usuário.");
      }
    }
  };
  //#region LISTAR FILIAL PARA VINCULAR AO USUÁRIO
  const colunasListarFilial: ITabela[] = [
    { titulo: "", acesso: "avatar" },
    { titulo: "Código", acesso: "id_clinica" },
    { titulo: "Clínica", acesso: "fantasia" },
    { titulo: "Bairro", acesso: "bairro" },
    { titulo: "UF", acesso: "cuf" },
    { titulo: "Responsável", acesso: "responsavel" },
  ];
  const [dadosListarFilial, setDadosListarFilial] = useState([]);

  const listarFilial = async (empresavinculada: string) => {
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/clinica/listar/vinculada/:vinculo",
      parametros: {
        vinculo: empresavinculada,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setAbrirModalListarFilial(true);
        setDadosListarFilial(retorno);
      })
      .catch((erro) => {
        toast.error(`Erro ao carregar registro. Motivo: ${erro.message}`);
      });
  };

  //#endregion
  const [abrirModalListarFilial, setAbrirModalListarFilial] =
    useState<boolean>(false);
  const informarClinica = () => {
    if (
      (empresaVinculada === "" || empresaVinculada === null) &&
      isVinculo === ""
    ) {
      toast.error("Para Informar as Clínicas vincule o usuário a uma Empresa.");
    } else {
      listarFilial(empresaVinculada || isVinculo);
    }
  };
  const fecharModalFilial = () => setAbrirModalListarFilial(false);

  //#endregion

  //#region Listar Empresa Contratante
  const [empresa, setEmpresa] = useState<any[]>([]);
  const listarEmpresa = async () => {
    CarregarDadosEmpresa(url_CarregarDados_Empresa)
      .then((retorno) => {
        setEmpresa(retorno);
      })
      .catch((erro) => {
        const msg = erro.response?.data.message;
        toast.error(`Erro ao listar dados empresa. Motivo: ${msg}`);
      });
  };
  useEffect(() => {
    listarEmpresa();
  }, []);

  //#endregion

  const handlePerfilChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = e.target.value as string;

    // Encontrar o item de perfil correspondente aos dadosperfil
    const selectedPerfil = dadosperfil.find(
      (item) => item.id_perfil === selectedValue
    );

    if (selectedPerfil) {
      setPerfil(selectedPerfil.id_perfil);
      setMaster(selectedPerfil.master);
    } else {
      setPerfil("");
      setMaster(0);
    }
  };

  const params = useParams()

  //#region Excluir Filial do Usuário
  const excluirFilial = async (dados: any) => {
    if (acao === "Visualizar") return;
    else
      await showConfirmationDialog(
        "Excluir Filial",
        `Tem certeza que deseja excluir a Filial \n ${dados.fantasia}?`,
        "question",
        "Excluir",
        "Cancelar"
      )
        .then((result) => {
          if (result.confirmed) {
            if (acao === "Novo") {
              const dadosFiltrados = dadosFilial.filter(
                (item: any) => item.id_clinica !== dados.id_clinica
              );
              setDadosFilial(dadosFiltrados);
            } else if (acao === "Editar") {
              const headers = {
                Authorization: token,
              };
              const dadosClinica = {
                url: `/usuario/excluirclinica/?id_usuario=${idusuario}&id_clinica=${dados.id_clinica}`,
                parametros: {},
                headers,
              };
              ApiDelete(dadosClinica)
                .then((retorno) => {
                  toast.success(retorno.message);
                  carregarDados(idusuario);
                })
                .catch((erro) => {
                  toast.error(
                    `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
                  );
                });
            }
          }
        })
        .catch((erro) => {
          toast.error(
            `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
          );
        });
  };

  //#endregion

  const voltar = () => {
    if (permVisualizar) {
      navigate("/administrativo/usuario");
    } else {
      navigate("/");
    }
  };

  const [novaSenha, setNovaSenha] = useState("")
  async function DefinirNovaSenha() {

    try {
      const dados = {
        novaSenha
      }
      const resposta = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/usuario/alterarsenhaperfilmaster/${params.id}`, dados, {
        headers: {
          Authorization: token
        }
      })

    
      if (resposta.status == 200) {

        toast.success(resposta.data.message)
        setTimeout(() => {

          navigate(-1)
        }, 2000);
      }
      else {

        toast.error(resposta.data.message)
      }

    } catch (error) {

      console.log(error)
    }
  }
  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Box sx={{ width: "100%" }}>
              <TabContext value={guia}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={capturaGuia}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#000",
                        color: "#000",
                      },
                    }}
                  >
                    <Tab label="Dados Básicos" value="1" />
                    <Tab label="Clínicas" value="2" />
                  </TabList>
                </Box>

                <TabPanel value="1">
                  <Row>
                    <Col sm={7}>
                      <Row>
                        <Col sm={4}>
                          <InputSemBorda
                            name="codigo"
                            label="Código Usuário"
                            type="text"
                            placeholder="Código Usuário"
                            value={idusuario || ""}
                            onChange={() => { }}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                            readonly
                          />
                        </Col>
                        <Col sm={6}>
                          <InputSemBorda
                            name="nome"
                            label="Nome Usuário"
                            type="text"
                            placeholder="Nome"
                            value={nome || ""}
                            onChange={setNome}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-capitalize text-black"
                            required={true}
                            readonly={acao === "Visualizar" ? true : false}
                          />
                        </Col>
                        <Col sm={2}>
                          <br />
                          <label htmlFor="" className="form-label me-2">
                            Ativo
                          </label>
                          <Switch
                            checked={ativo}
                            onChange={() => setAtivo(!ativo)}
                            disabled={acao === "Visualizar" ? true : false}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={5}>
                          <InputSemBorda
                            name="login"
                            label="Login"
                            type="text"
                            placeholder="Login"
                            value={login || ""}
                            onChange={setLogin}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-lower text-black"
                            readonly={
                              acao === "Editar" || acao === "Visualizar"
                                ? true
                                : false
                            }
                            required={true}
                          />
                        </Col>
                        <Col sm={7}>
                          <InputSemBorda
                            name="email"
                            label="E-mail"
                            type="email"
                            placeholder="E-mail"
                            value={email || ""}
                            onChange={setEmail}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-lower text-black"
                            readonly={acao === "Visualizar" ? true : false}
                            required={true}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <InputSemBorda
                            name="senha"
                            label="Senha"
                            type="password"
                            placeholder="Senha"
                            value={senha || ""}
                            onChange={setSenha}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-capitalize text-black"
                            readonly={
                              acao === "Visualizar" || acao === "Editar"
                                ? true
                                : false
                            }
                            useRef={senhaRef}
                          />
                          {acao === "Novo" && (
                            <ValidaComplexidadeSenha senha={senha} />
                          )}
                        </Col>
                        <Col sm={6}>
                          <InputSemBorda
                            name="confirmsenha"
                            label="Confirmar Senha"
                            type="password"
                            placeholder="Confirmar Senha"
                            value={confirmSenha || ""}
                            onChange={setConfirmSenha}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-capitalize text-black"
                            readonly={
                              acao === "Visualizar" || acao === "Editar"
                                ? true
                                : false
                            }
                            required={true}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4}>
                          <InputSemBorda
                            name="fone"
                            label="Telefone"
                            type="tel"
                            placeholder="Telefone"
                            value={telefone || ""}
                            onChange={setTelefone}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-capitalize text-black"
                            readonly={acao === "Visualizar" ? true : false}
                          />
                        </Col>
                        <Col sm={4}>
                          <FormControl
                            variant="standard"
                            sx={{ m: 1.5, width: "100%" }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              Perfil
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={perfil}
                              onChange={handlePerfilChange as any}
                              label="Perfil"
                              required
                              disabled={acao === "Visualizar" ? true : false}
                            >
                              <MenuItem value="">
                                <em>Selecione...</em>
                              </MenuItem>
                              {dadosperfil.map((item: any) => (
                                <MenuItem
                                  value={item.id_perfil}
                                  data-master={item.master}
                                >
                                  {item.nome_perfil}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Col>
                        <Col sm={4}>
                          <InputSemBorda
                            name="cpf"
                            label="CPF"
                            type="text"
                            placeholder="CPF"
                            value={mascaraCPF(cpf) || ""}
                            onChange={setCpf}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-capitalize text-black"
                            readonly={acao === "Visualizar" ? true : false}
                            required={true}
                          />
                        </Col>
                      </Row>
                      {isSuper === "1" && (
                        <Row>
                          <Col>
                            <FormControl
                              variant="standard"
                              sx={{ m: 1.5, width: "100%" }}
                            >
                              <InputLabel>Empresa Vinculada</InputLabel>
                              <Select
                                value={empresaVinculada}
                                onChange={(e: SelectChangeEvent) =>
                                  setEmpresaVinculada(e.target.value)
                                }
                                label="Empresa Vinculada"
                                disabled={acao === "Visualizar" ? true : false}
                              >
                                <MenuItem value="">
                                  <em>Selecione...</em>
                                </MenuItem>
                                {empresa.map((item: any) => (
                                  <MenuItem value={item.id}>
                                    {item.fantasia}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Col>
                          <div className="w-100"></div>
                          <Col>
                            <div className="container p-0 m-2">
                              <div className="row">
                                <div className="col-sm col-md col-lg">
                                  <InputSemBorda
                                    name="fone"
                                    label="Nova Senha"
                                    type="password"
                                    placeholder="Nova Senha"
                                    value={novaSenha || ""}
                                    onChange={setNovaSenha}
                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                    readonly={acao === "Visualizar" ? true : false}
                                  />
                                </div>
                                <div className="col-sm col-md col-lg">
                                  <button type="button" className="btn btn-danger"
                                    onClick={function () {

                                      if (novaSenha != "" && novaSenha.length > 4 && novaSenha != null) {

                                        DefinirNovaSenha()
                                      }
                                      else {

                                        toast.error("A senha é muito curta, tente uma senha com mais de 4 caracter.")
                                      }
                                    }}>Salvar nova senha</button>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Col>
                    <Col sm={5}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        {avatar ? (
                          <Avatar
                            src={`data:image/png;base64,${avatar || null}`}
                            alt="Avatar"
                            sx={{ width: 240, height: 240 }}
                          />
                        ) : (
                          <Avatar
                            src={""}
                            alt="Avatar"
                            sx={{ width: 240, height: 240 }}
                          />
                        )}
                        <input
                          type="file"
                          accept="image/*"
                          id="avatar-input"
                          onChange={handleAvatarChange}
                          style={{ display: "none" }}
                          disabled={acao === "Visualizar" ? true : false}
                        />
                        <label htmlFor="avatar-input">
                          <IconButton color="primary" component="span">
                            <PhotoCameraIcon />
                          </IconButton>
                        </label>
                        {avatar && acao !== "Visualizar" && (
                          <Button
                            variant="outlined"
                            onClick={handleRemoveAvatar}
                          >
                            Remover Avatar
                          </Button>
                        )}
                      </Stack>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel value="2">
                  <ButtonCustom
                    type="button"
                    className="btn btn-light text-info-emphasis bg-white border-0"
                    descricaoBotao="Incluir"
                    icone={mdiPlus}
                    onclick={informarClinica}
                    disabled={acao === "Visualizar" ? true : false}
                  />
                  <hr />
                  <Row>
                    <Tabela
                      coluna={colunas}
                      dados={dadosFilial}
                      itemsPerPage={10}
                      onVisuClick={() => { }}
                      onEditClick={() => { }}
                      onDeleteClick={(item) => excluirFilial(item)}
                      usaVisu={false}
                      usaEdit={false}
                      onImprimirClick={() => { }}
                      usaImprimir={false}
                      id="codigo"
                    />
                  </Row>
                </TabPanel>
              </TabContext>
            </Box>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={voltar}
              />
              <ButtonCustom
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
                disabled={acao === "Visualizar" ? true : false}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>

      {/* MODAL LISTAR FILIAIS PARA VINCULAR AO USUÁRIO */}
      <Modal
        show={abrirModalListarFilial}
        className="modal-full-width "
        size="xl"
        keyboard={false}
        centered
      >
        <Modal.Header className="bg-white">
          <Modal.Title>Listagem de Clínicas</Modal.Title>
        </Modal.Header>
        <Form //onSubmit={handleSubmit}
        >
          <Modal.Body className="bg-white">
            <Row>
              <Tabela
                coluna={colunasListarFilial}
                dados={dadosListarFilial}
                itemsPerPage={10}
                usaVisu={false}
                usaEdit={true}
                usaDelete={false}
                onVisuClick={() => { }}
                onEditClick={selecionarFilial}
                onDeleteClick={() => { }}
                descEditar="Selecionar"
                iconeEditar={mdiCheckAll}
                onImprimirClick={() => { }}
                usaImprimir={false}
                id="codigo"
              />
            </Row>
          </Modal.Body>
          <Modal.Footer className="bg-white">
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={fecharModalFilial}
              />
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      <ModalUploadImage
        show={showUpload}
        label="Realizando Upload da Imagem ..."
      />
      <ModalLoading show={loading} label="Carregando dados do usuário ..." />
    </>
  );
};
export default FormUsuario;
