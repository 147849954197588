import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
  dados: any[]; // Substitua 'any[]' pelo tipo correto dos seus dados
}

const Paginacao: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  setCurrentPage,
  dados,
}) => {
  return (
    <div className="d-flex flex-column align-items-center p-3 gap-2">
      <Pagination className="center">
        <Pagination.First
          onClick={() => {
            setCurrentPage(1);
          }}
        />
        <Pagination.Prev
          onClick={() => {
            if (currentPage > 1) setCurrentPage(currentPage - 1);
          }}
        />
        <Pagination.Item>Página</Pagination.Item>
        <Pagination.Item>{currentPage}</Pagination.Item>
        <Pagination.Item>de</Pagination.Item>
        <Pagination.Item>{totalPages}</Pagination.Item>
        <Pagination.Item>|</Pagination.Item>
        <Pagination.Item>Qtde Registros</Pagination.Item>
        <Pagination.Item>{dados.length}</Pagination.Item>
        <Pagination.Next
          onClick={() => {
            if (currentPage < totalPages) setCurrentPage(currentPage + 1);
          }}
        />
        <Pagination.Last
          onClick={() => {
            if (currentPage < totalPages) setCurrentPage(totalPages);
          }}
        />
      </Pagination>
    </div>
  );
};

export default Paginacao;
