export function TextoFormatoUpperCase(text: string): string {
    const words = text.toUpperCase().split(" ");
  
    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return "";
    });
  
    return capitalizedWords.join(" ");
  }
  