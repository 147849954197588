import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { getSessionData } from "../../../../utils/storageUtils";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo";
import { Card, Col, Row } from "react-bootstrap";
import Tabela from "../../../../components/Formularios/Table";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import ApiGet from "../../../../api/endPoints/useGet";
import { toast } from "react-toastify";
import { mdiEmailFastOutline, mdiWhatsapp } from "@mdi/js";
import ApiPost from "../../../../api/endPoints/usePost";
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir";
import ApiPut from "../../../../api/endPoints/usePut";
import { TextoFormatoCaptalize } from "../../../../components/Auxiliar/ConvTextoCaptalize";
import { TextoFormatoLowerCase } from "../../../../components/Auxiliar/ConvTextoLower";
import { linkPreCadastro } from "../../../../api/api";

export function PreCadastro() {
  const navigate = useNavigate();
  const id_clinica = getSessionData("DadosIdClinica") || "";
  const token = getSessionData("MultClinicaWebToken") || "";
  const perfil = getSessionData("DadosPerfilID") || "0";
  const isSuper = getSessionData("isPerfilSuper") || "";

  const [textoCarregando, setTextoCarregando] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  interface Action {
    icon: JSX.Element;
    name: string;
  }
  const actions: Action[] = [
    {
      icon: (
        <AddIcon
          onClick={() => navigate("/cadastro/pacientes/precadastro/novo")}
        />
      ),
      name: "Incluir",
    },
    // { icon: <FileCopyIcon />, name: "Duplicar" },
    // { icon: <SaveIcon />, name: "Salvar" },
    // { icon: <PrintIcon />, name: "Imprimir" },
  ];
  const enviarEmail = (dadosPaciente: any) => {
    const idpaciente = dadosPaciente.id_paciente;
    showConfirmationDialog(
      "Enviar Email de Pré Cadastro",
      `Deseja enviar o E-mail de  pré cadastro do paciente \n ${TextoFormatoCaptalize(
        dadosPaciente?.nome.toLowerCase()
      )}?`,
      "question",
      "Confirmar",
      "Cancelar"
    ).then((result) => {
      if (result.confirmed) {
        setLoading(true);
        setTextoCarregando("Enviando E-mail de Pré Cadastro");
        const dados = {
          id_clinica,
          nome: TextoFormatoCaptalize(dadosPaciente?.nome.toLowerCase()),
          email: TextoFormatoLowerCase(dadosPaciente?.email.toLowerCase()),
          cpf: dadosPaciente?.cpf,
        };
        ApiPost("/paciente/criar/precadastro/sendmail", dados)
          .then((retorno) => {
            setLoading(false);
            setTextoCarregando(
              "Atualizando Status de Envio de E-mail de Pré Cadastro"
            );
            toast.success(retorno.message);
            buscarDados();
          })
          .catch((erro) => {
            const msg =
              erro.response?.data.message !== undefined
                ? erro.response?.data.message
                : erro.message;
            toast.error(`Falha ao Enviar E-mail. Motivo: ${msg}`);
            setLoading(false);
            setTextoCarregando("");
          });
      }
    });
  };

  const enviarWhatsApp = async (dados: any) => {
    const id_clinica = getSessionData("DadosIdClinica") || "";
    const dadosPaciente = {
      id_clinica,
      id_paciente: dados.id_paciente,
    };
    showConfirmationDialog(
      "Confirmação de Envio de WhatsApp",
      "Deseja realmente enviar o WhatsApp para o paciente?",
      "question",
      "Enviar",
      "Cancelar"
    )
      .then((result) => {
        if (result.confirmed) {
          ApiPut("/paciente/sendwhatsapp/precadastro", dadosPaciente).then(
            (retorno) => {
              toast.success("WhatsApp enviado com sucesso!");
              const clinica =
                getSessionData("DadosNomeClinica")?.replaceAll('"', "") || "";
              const telefone = dados.telcelular;
              const paciente = dados.nome;
              const linkCadastro: string = linkPreCadastro + `${id_clinica}`;

              const url = `https://api.whatsapp.com/send?phone=55${telefone}&text=Olá ${paciente}, tudo bem?%0A%0ASua solicitação de cadastro na clinica ${clinica} foi realizado com sucesso!%0A%0AFavor, acessar o link abaixo para completar as suas informações:%0A%0A${linkCadastro}%0A%0AAtenciosamente,%0A%0AEquipe - ${clinica}.`;
              window.open(url, "_blank");
              buscarDados();
            }
          );
        }
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.mensagem !== undefined
            ? erro.response?.data.mensagem
            : erro.message;
        toast.error(`Erro ao enviar WhatsApp. Motivo: ${msg}`);
      });

    //Abrir link do WhatsApp em uma nova pagina com o numero do paciente

    // const idpaciente = dados.id_paciente;
  };

  const colunas: ITabela[] = [
    { titulo: "ID Paciente", acesso: "id_paciente" },
    { titulo: "CPF", acesso: "cpf" },
    { titulo: "Nome", acesso: "nome" },
    { titulo: "E-mail", acesso: "email" },
    { titulo: "Celular", acesso: "telcelular" },
    { titulo: "Fone", acesso: "telfixo" },
    { titulo: "Status", acesso: "status" },
    { titulo: "Situação Envio E-mail", acesso: "enviou_email" },
    { titulo: "Situação Envio WhatsApp", acesso: "enviou_whatsapp" },
    { titulo: "Status Pré Cadastro", acesso: "status_pre_cad" },
  ];

  //#region REALIZA REQUISIÇÃO PARA LISTAR DADOS DA TABELA
  const [dados, setDados] = useState([]);

  const buscarDados = async () => {
    setLoading(true);
    setTextoCarregando("Carregando Listagem de Pré Cadastro de Pacientes");
    const dados = {
      url: "/paciente/listar/precadastro/:id_clinica",
      parametros: {
        id_clinica: id_clinica,
      },
    };
    ApiGet(dados)
      .then((retorno) => {
        setDados(retorno);
        setLoading(false);
        setTextoCarregando("");
      })
      .catch((erro) => {
        setLoading(false);
        setTextoCarregando("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };

  const [permIncluir, setPermIncluir] = useState<boolean>(false);
  // const [permAlterar, setPermAlterar] = useState<boolean>(false);
  // const [permExcluir, setPermExcluir] = useState<boolean>(false);
  const [permVisualizar, setPermVisualizar] = useState<boolean>(false);
  const [permUsaAcoes, setPermUsaAcoes] = useState<boolean>(false);

  const buscarDadosPermissao = async (perfil: string) => {
    setLoading(true);
    setTextoCarregando("Aguarde, Verificando Permissões...");
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/perfil/carregar/:id_perfil",
      parametros: {
        id_perfil: perfil,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoCarregando("");
        const retornoApi = retorno[0];
        setPermIncluir(
          retornoApi.cad_paciente_precadastro_incluir === "1" || isSuper === "1"
            ? true
            : false
        );
        // setPermAlterar(
        //   retornoApi.cad_paciente_precadastro_editar === "1" || isSuper === "1"
        //     ? true
        //     : false
        // );
        // setPermExcluir(
        //   retornoApi.cad_paciente_precadastro_excluir === "1" || isSuper === "1"
        //     ? true
        //     : false
        // );
        setPermVisualizar(
          retornoApi.cad_paciente_precadastro_visu === "1" || isSuper === "1"
            ? true
            : false
        );

        if (
          retornoApi.cad_paciente_precadastro_incluir === "1" 
          ||
          // retornoApi.cad_paciente_precadastro_editar === "1" ||
          // retornoApi.cad_paciente_precadastro_excluir === "1" ||
          retornoApi.cad_paciente_precadastro_visu === "1" ||
          isSuper === "1"
        ) {
          setPermUsaAcoes(true);
        } else {
          setPermUsaAcoes(false);
        }

        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoCarregando("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    buscarDadosPermissao(perfil);
    buscarDados();
  }, []);
  //#endregion
  //#endregion

  
  const filtrarDados = async (filtro: string, vinculo: string) => {
    setLoading(true);
    setTextoCarregando("Filtrando Listagem de Pré Cadastro...");

    const dados = {
      filtro,
    };
    ApiPost(`/paciente/filtro/precadastro/${vinculo}`, dados)
      .then((retorno) => {
        setLoading(false);
        setTextoCarregando("");
        setDados(retorno);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoCarregando("");
        setLoading(false);
        toast.error(`Erro ao filtrar dados. Motivo: ${erro.message}`);
      });
  };
  const [pesquisa, setPesquisa] = useState<string>("");
  const pesquisar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (pesquisa === "") return buscarDados();
    else {
      const _filtro = pesquisa.toLowerCase();
      filtrarDados(_filtro, id_clinica);
    }
  };
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Listagem de Pré Cadastro - Pacientes" />
        <Row>
          <Col sm={12}>
          <form onSubmit={pesquisar}>
            <Card>
              <Card.Body>
                {permIncluir &&<Row>
                  <SpeedDial
                    ariaLabel="SpeedDial basic example"
                    direction="left"
                    sx={{
                      right: 0,
                      position: "absolute",
                    }}
                    icon={<SpeedDialIcon />}
                  >
                    {actions.map((action) => (
                      <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                      />
                    ))}
                  </SpeedDial>
                </Row>}
                <Row>
                  <div className="d-flex my-2 gap-1 ">
                    <Col sm={permIncluir ? 11:12}>
                      <InputSemBorda
                        name="fantasia"
                        type="text"
                        value={pesquisa}
                        onChange={setPesquisa}
                        classNameInputsSemBorda="form-control bordasInferiorInput"
                        placeholder=" "
                        label="Pesquisar"
                      />
                    </Col>
                  </div>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Tabela
                      coluna={colunas}
                      dados={dados}
                      onVisuClick={enviarEmail}
                      iconeVisualizar={mdiEmailFastOutline}
                      descVisualizar="Enviar E-mail"
                      onEditClick={enviarWhatsApp}
                      descEditar="Enviar WhatsApp"
                      iconeEditar={mdiWhatsapp}
                      onDeleteClick={() => { }}
                      usaDelete={false}
                      itemsPerPage={10}
                      onImprimirClick={() => { }}
                      usaImprimir={false}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </form>
          </Col>
        </Row>
      </div>
      <ModalLoading show={loading} label={textoCarregando} />
    </>
  );
}
