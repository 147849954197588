import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo";
import FormClinica from "./components/formulario";

export function ClinicaEditar() {
  const idclinica = useParams();
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Editar Clínica" />
        <FormClinica idclinica={idclinica.id} acao="Editar" />
      </div>
    </>
  );
}
