import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo";
import FormAgendaSala from "./components/formulario";

export function AgendaSalaVisualizar() {
  const idagendasala = useParams();
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Visualizar Agenda Sala" />
        <FormAgendaSala idagendasala={idagendasala.id} acao="Visualizar" />
      </div>
    </>
  );
}
