import { Col, Form, Modal, Row } from "react-bootstrap";
import { Button } from "../../../../components/Formularios/Buttons/Button";
import { mdiCheckCircle, mdiKeyboardReturn } from "@mdi/js";
import { useEffect, useState } from "react";
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import { Switch } from "@mui/material";

interface DetalheModalProps {
    showModal: boolean;
    acao?: string;
    onClose: () => void;
    dadosId_Prontuario?: string;
    dadosId_Prontuario_Det?: string;
    dadosData_Ocorrencia?: string;
    medicamento_prescrito?: string;
    dadosOcorrencia?: string;
    onSubmit: (formData: any) => void;
    // onUpdate: (formData: any) => void; // Adicione esta linha
}
const ModalProntuario: React.FC<DetalheModalProps> = ({
    showModal,
    acao,
    onClose,
    dadosId_Prontuario,
    dadosId_Prontuario_Det,
    dadosData_Ocorrencia,
    dadosOcorrencia,
    medicamento_prescrito,
    onSubmit,
    // onUpdate, // Adicione esta linha
}) => {
    const [isEditMode, setIsEditMode] = useState(false); // Adicione esta linha
    const [id_prontuario, setId_Prontuario] = useState("");
    const [id_prontuario_det, setId_Prontuario_Det] = useState("");
    const [data_ocorrencia, setData_Ocorrencia] = useState("");
    const [ocorrencia, setOcorrencia] = useState("");
    const [medicamentoPrescrito, setMedicamentoPrescrito] = useState("")

    const formatarDataParaInput = (data: string | undefined): string => {
        if (!data) return "";

        // Converte a data do formato brasileiro para o formato padrão "YYYY-MM-DD"
        const [dia, mes, ano] = data.split('/');
        return `${ano}-${mes}-${dia}`;
    };

    const carregarDados = () => {
        setId_Prontuario(dadosId_Prontuario || "");
        setId_Prontuario_Det(dadosId_Prontuario_Det || "");
        setData_Ocorrencia(formatarDataParaInput(dadosData_Ocorrencia) || ""); // Formatar a data aqui
        setOcorrencia(dadosOcorrencia || "");
        setMedicamentoPrescrito(medicamento_prescrito || "")
        setIsEditMode(acao === "Editar"); // Adicione esta linha
    };

    useEffect(() => {
        if (showModal) {
            carregarDados();
        }
    }, [showModal]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Convertendo a string "YYYY-MM-DD" para um objeto Date
        const [ano, mes, dia] = data_ocorrencia.split('-');
        const dataObj = new Date(`${ano}-${mes}-${dia}`);

        // Verificando se a data é válida
        if (isNaN(dataObj.getTime())) {
            // A data é inválida, faça o tratamento apropriado
            console.error("Data inválida!");
            return;
        }

        // Adicionando um dia à data
        dataObj.setDate(dataObj.getDate() + 1);

        // Formatando a data para o formato desejado "DD/MM/YYYY"
        const dataFormatada = dataObj.toLocaleDateString('pt-BR');

        const dados = {
            id_prontuario: dadosId_Prontuario || "",
            id_prontuario_det,
            data_ocorrencia: dataFormatada,
            ocorrencia,
            medicamento_prescrito: medicamentoPrescrito
            // acao,
        };
        onSubmit(dados);
        setOcorrencia("");
        // if (isEditMode) {
        //     // console.log("🚀 ~ file: index.tsx:79 ~ handleSubmit ~ isEditMode:", isEditMode)

        //     onUpdate(dados); // Use onUpdate se estiver no modo de edição
        //   } else {
        //     onSubmit(dados);
        //   }
    };

    return (
        <>
            <Modal
                show={showModal}
                className="modal-full-width "
                size="xl"
                keyboard={false}
                centered
            >
                <Modal.Header className="bg-white">
                    <Modal.Title>Evolução</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body className="bg-white">
                        <Row>
                            <Col sm={2}>
                                <InputSemBorda
                                    label="Data Evolução"
                                    name="data_ocorrencia"
                                    type="date"
                                    placeholder="Data Evolução"
                                    // required
                                    readonly={acao === "Visualizar" ? true : false}
                                    value={data_ocorrencia || ""}
                                    onChange={setData_Ocorrencia}
                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                />
                            </Col>
                            <Col sm={10}>
                                <InputSemBorda
                                    label="Descrição da Evolução"
                                    name="ocorrencia"
                                    type="textarea"
                                    placeholder="Ocorrência"
                                    required
                                    readonly={acao === "Visualizar" ? true : false}
                                    value={ocorrencia || ""}
                                    onChange={setOcorrencia}
                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-uppercase custom-textarea"
                                    rows={20}
                                    height="300px" // Defina a altura desejada aqui
                                />
                            </Col>
                            <Col sm={12} className="float-right">
                                <InputSemBorda
                                    label="Medicação"
                                    name="medicacao"
                                    type="textarea"
                                    placeholder="Medicação"
                                    required={false}
                                    readonly={acao === "Visualizar" ? true : false}
                                    value={medicamentoPrescrito || ""}
                                    onChange={setMedicamentoPrescrito}
                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-uppercase custom-textarea"
                                    rows={20}
                                    height="300px" // Defina a altura desejada aqui
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="bg-white">
                        <div className="d-flex my-2 gap-1 justify-content-between">
                            <Button
                                type="button"
                                className="btn btn-light text-danger bg-white border-0"
                                descricaoBotao="Voltar"
                                icone={mdiKeyboardReturn}
                                onclick={onClose}
                            />
                            <Button
                                type="submit"
                                className="btn btn-light text-info-emphasis bg-white border-0"
                                descricaoBotao="Incluir"
                                icone={mdiCheckCircle}
                                onclick={onClose}
                            />
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};
export default ModalProntuario;
