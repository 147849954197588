import React from "react";
import { Card, Col } from "react-bootstrap";
import { Instance } from "@popperjs/core";
import { Tooltip } from "@mui/material";
interface CardResumoProps {
  icone: React.ReactNode;
  titulo: string;
  valor: string;
  corBorda?: string;
  textoTooltip?: string;
}

const CardResumo: React.FC<CardResumoProps> = ({
  icone,
  titulo,
  valor,
  corBorda = "grey",
  textoTooltip,
}) => {
  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef<Instance>(null);
  const areaRef = React.useRef<HTMLDivElement>(null);

  const handleMouseMove = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };
  return (
    <Tooltip
      title={textoTooltip}
      placement="top"
      arrow
      PopperProps={{
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => {
            return new DOMRect(
              positionRef.current.x,
              areaRef.current!.getBoundingClientRect().y,
              0,
              0
            );
          },
        },
      }}
    >
      <Col>
        <Card
          ref={areaRef}
          onMouseMove={handleMouseMove}
          style={{ border: `solid 1px ${corBorda}`, borderRadius: "20px" }}
        >
          <Card.Body>
            <div style={{ display: "flex", alignItems: "center" }}>
              {icone}
              <div>
                <Card.Title style={{ marginBottom: "5px", marginLeft: "10px" }}>
                  {titulo}
                </Card.Title>
                <Card.Text style={{ marginLeft: "10px", fontSize: "30px" }}>
                  {valor}
                </Card.Text>
              </div>
            </div>
          </Card.Body>
          {/* <Card.Footer>
          <small className="text-muted">
            Última atualização há 3 minutos
          </small>
        </Card.Footer> */}
        </Card>
      </Col>
    </Tooltip>
  );
};

export default CardResumo;
