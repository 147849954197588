import Cards from "../../../components/Formularios/Cards/Cards";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import { Row, Col, Card } from "react-bootstrap";
import { mdiCalendarMonthOutline, mdiAccountCheck } from "@mdi/js";
import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";
import { getSessionData } from "../../../utils/storageUtils";
import ApiGet from "../../../api/endPoints/useGet";
import { toast } from "react-toastify";
import BarChart from "./barChart";
import PieChart from "./PieChart";
import ApiPost from "../../../api/endPoints/usePost";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export function Dashboard() {
  const id_clinica = getSessionData("DadosIdClinica") || "";

  //Dados para filtrar pesquisa
  const [datainicio, setDataInicio] = useState<string>('');
  const [datafim, setDataFim] = useState<string>('');

  //Realiza a chamada da API para buscar os dados dos cards
  const [qtdePacientes, setQtdePacientes] = useState(0);
  const buscarQtdePacientes = async () => {
    const dados = {
      url: "/dashboard/card/pacientes/:id_clinica",
      parametros: { id_clinica: id_clinica },
    };
    ApiGet(dados)
      .then((retorno) => {
        setQtdePacientes(Number(retorno[0].qtdepacientes));
      })
      .catch((erro) => {
        toast.error("Erro ao buscar a quantidade de pacientes: " + erro);
      });
  };
  useEffect(() => {
    buscarQtdePacientes();
  }, []);

  const [limite, setLimite] = useState(5);
  const [profissional, setProfissional] = useState([]);
  const [qtdeagendamentos, setQtdeAgendamentos] = useState([]);
  const buscarQtdeAgendamentos = async () => {
    const dados = {
      url: `/dashboard/grafico/qtdeagendamentos/:id_clinica?datainicio=${datainicio}&datafim=${datafim}&limite=${limite}`,
      parametros: { id_clinica: id_clinica },
    };
    ApiGet(dados)
      .then((retorno) => {
        if (retorno.length > 0) {
          const dados = retorno;

          const _profissional = dados.map((item: any) => item.profissional);
          setProfissional(_profissional);

          const _qtdeagendamentos = dados.map((item: any) => item.qtdeagendamentos);
          setQtdeAgendamentos(_qtdeagendamentos);
        } else {
          setProfissional([]);
          setQtdeAgendamentos([]);
        }
      }
      )
      .catch((erro) => {
        toast.error("Erro ao buscar agendamentos: " + erro);
      });
  };

  const dadosProfissional = {
    series: [
      {
        name: "Profissional",
        data: profissional,
      },
    ],
  };

  const dadosQtdeAgendamentos = {
    series: [
      {
        name: "Qtde Agendamentos",
        data: qtdeagendamentos,
      },
    ],
  };

  useEffect(() => {
    buscarQtdeAgendamentos();
  }, []);

  const [dia, setDia] = useState([]);
  const [qtdeagendamentosdia, setQtdeAgendamentosdia] = useState([]);
  const buscarQtdeAgendamentosdia = async () => {
    const dados = {
      url: `/dashboard/grafico/qtdeagendamentosdia/:id_clinica?datainicio=${datainicio}&datafim=${datafim}`,
      parametros: { id_clinica: id_clinica },
    };
    ApiGet(dados)
      .then((retorno) => {
        if (retorno.length > 0) {
          const dados = retorno;

          const _dia = dados.map((item: any) => item.dia);
          setDia(_dia);

          const _qtdeagendamentosdia = dados.map((item: any) => item.qtdeagendamentosdia);
          setQtdeAgendamentosdia(_qtdeagendamentosdia);
        } else {
          setDia([]);
          setQtdeAgendamentosdia([]);
        }
      })
      .catch((erro) => {
        toast.error("Erro ao buscar agendamentos por dia: " + erro);
      });
  };

  const dadosDia = {
    series: [
      {
        name: "Dia",
        data: dia,
      },
    ],
  };

  const dadosQtdeAgendamentosdia = {
    series: [
      {
        name: "Qtde",
        data: qtdeagendamentosdia,
      },
    ],
  };


  useEffect(() => {
    buscarQtdeAgendamentosdia();
  }, []);

  const [status, setStatus] = useState([]);
  const [qtdeagendamentosStatus, setQtdeAgendamentosStatus] = useState([]);
  const buscarQtdeAgendamentosStatus = async () => {
    const dados = {
      url: `/dashboard/grafico/qtdeagendamentosstatus/:id_clinica?datainicio=${datainicio}&datafim=${datafim}`,
      parametros: { id_clinica: id_clinica },
    };
    ApiGet(dados)
      .then((retorno) => {
        if (retorno.length > 0) {
          const dados = retorno;

          const _status = dados.map((item: any) => item.status);
          setStatus(_status);

          // Converta a qtde de string para número aqui
          const _qtdeagendamentosStatus = dados.map((item: any) => parseInt(item.qtde));
          setQtdeAgendamentosStatus(_qtdeagendamentosStatus);
        } else {
          setStatus([]);
          setQtdeAgendamentosStatus([]);
        }
      })
      .catch((erro) => {
        toast.error("Erro ao buscar agendamentos por status: " + erro);
      });
  };

  const dadosStatus = {
    series: [
      {
        name: "Status",
        data: status,
      },
    ],
  };

  const dadosQtdeStatus = {
    series: [
      {
        name: "Qtde",
        data: qtdeagendamentosStatus,
      },
    ],
  };

  useEffect(() => {
    buscarQtdeAgendamentosStatus();
  }, []);

  const [qtdeatendimentoshoje, setQtdeatendimentoshoje] = useState(0);
  const buscarAtendimentosHoje = async () => {
    const dados = {
      url: `/dashboard/grafico/qtdeagendamentoshoje/:id_clinica?datainicio=${datainicio}&datafim=${datafim}`,
      parametros: { id_clinica: id_clinica },
    };
    ApiGet(dados)
      .then((retorno) => {
        // if (retorno.length > 0) {
        setQtdeatendimentoshoje(Number(retorno[0].qtde));
        // }
      })
      .catch((erro) => {
        toast.error("Erro ao buscar agendamentos de hoje: " + erro);
      });
  }

  useEffect(() => {
    buscarAtendimentosHoje();
  }, []);

  useEffect(() => {
    if (datainicio || datafim) {
      buscarQtdeAgendamentos();
      buscarQtdeAgendamentosdia();
      buscarQtdeAgendamentosStatus();
      buscarAtendimentosHoje();
    }
  }, [datainicio, datafim]);

  useEffect(() => {
    if (limite) {
      buscarQtdeAgendamentos();
    }
  }, [limite]);

  return (
    <>
      <div className="page-content">
        <ContainerTitulo titulo="Dashboard" />
      </div>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Body>
              <Row>
                <Col sm={2}>
                  <InputLabel style={{ color: '#000', fontSize: '16px', margin: '0 0 -6px 0' }}>
                    Data Início
                  </InputLabel>
                  <InputSemBorda
                    name="datainicio"
                    type="date"
                    value={datainicio}
                    onChange={setDataInicio}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                    placeholder="Data Início"
                  // label="Data Início"
                  />
                </Col>
                <Col sm={2}>
                  <InputLabel style={{ color: '#000', fontSize: '16px', margin: '0 0 -6px 0' }}>
                    Data Fim
                  </InputLabel>
                  <InputSemBorda
                    name="datafim"
                    type="date"
                    value={datafim}
                    onChange={setDataFim}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                    placeholder="Data Fim"
                  // label="Data Fim"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Cards
            titulosmall="Pacientes"
            dados={qtdePacientes.toString()}
            icone={mdiAccountCheck}
            rodape="Cadastros Ativos"
          />
        </Col>
        <Col sm={4}>
          <Cards
            titulosmall="Autorizações"
            dados="0"
            icone={mdiCalendarMonthOutline}
            rodape="Hoje"
          />
        </Col>
        <Col sm={4}>
          <Cards
            titulosmall="Críticas"
            dados="7"
            icone={mdiCalendarMonthOutline}
            rodape="Hoje"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Cards
            titulosmall="Agendamentos"
            dados={'+' + qtdeatendimentoshoje.toString()}
            icone={mdiCalendarMonthOutline}
            rodape="Hoje"
          />
        </Col>
        <Col sm={6}>
          <Card style={{ height: 248 }}>
            <Card.Header>
              <Card.Title as="h5">Agendamentos por Status</Card.Title>
            </Card.Header>
            <Card.Body>
              <BarChart
                categoriesData={dadosStatus.series[0].data}
                seriesData={dadosQtdeStatus.series}
                colors={['#FF4560', '#0090FF', '#FFEB3B', '#00E396']}
                height={170}
                distributed={true}
                labels={false}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Card style={{ height: 380 }}>
            <Card.Header>
              <Box display="flex" alignItems="center">
                <Card.Title as="h5">Agendamentos por Profissional</Card.Title>
                <FormControl size="small" style={{ marginLeft: 15 }}>
                  <InputLabel id="limite-label">TOP</InputLabel>
                  <Select
                    labelId="limite-label"
                    defaultValue={5}
                    value={limite}
                    onChange={(e) => setLimite(Number(e.target.value))}
                    label="TOP"
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={40}>40</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Card.Header>
            <Card.Body>
              <BarChart categoriesData={dadosProfissional.series[0].data} seriesData={dadosQtdeAgendamentos.series} height={290} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Card style={{ height: 380 }}>
            <Card.Header>
              <Card.Title as="h5">Agendamentos por Dia</Card.Title>
            </Card.Header>
            <Card.Body>
              <BarChart categoriesData={dadosDia.series[0].data} seriesData={dadosQtdeAgendamentosdia.series} height={300} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
