import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import { Row, Col, Card, Modal, Form } from "react-bootstrap";
import { getSessionData } from "../../../utils/storageUtils";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import {
  InputAdornment,
  InputLabel,
  // SpeedDial,
  // SpeedDialAction,
  // SpeedDialIcon,
  TextField,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { FaMoneyBill } from "react-icons/fa";
import { BsArrowDownCircleFill, BsArrowUpCircleFill } from "react-icons/bs";
import CardResumo from "../../../components/Formularios/CardResumo";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import Tabela from "../../../components/Formularios/Table";
import ApiGet from "../../../api/endPoints/useGet";
import { toast } from "react-toastify";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import {
  mdiCheckCircle,
  mdiKeyboardReturn,
  mdiReceiptTextCheckOutline,
} from "@mdi/js";
import { Button } from "../../../components/Formularios/Buttons/Button";
import SelectInput from "../../../shared/SelectInputs";
import ApiPost from "../../../api/endPoints/usePost";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Content, TDocumentDefinitions } from "pdfmake/interfaces";
import moment from "moment";
import axios from "axios";

interface Action {
  icon: JSX.Element;
  name: string;
}
export function FaturamentoReceitas() {
  const navigate = useNavigate();
  const id_clinica = getSessionData("DadosIdClinica") || "";
  const token = getSessionData("MultClinicaWebToken") || "";
  const usuario = getSessionData("DadosUsuario") || "";
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const [loading, setLoading] = useState(false);
  const [textoLoading, setTextoLoading] = useState("");


  const dataAtual = new Date();
  const mesAtual = (dataAtual.getMonth() + 1).toString().padStart(2, "0");
  const anoAtual = dataAtual.getFullYear();
  const _dataInicial = anoAtual + "-" + mesAtual + "-01";

  const [datainicio, setDataInicio] = useState<string>(_dataInicial);

  const [saldoPrevisto, setSaldoPrevisto] = useState<number>(0);
  const [recebido, setRecebido] = useState<number>(0);
  const [aReceber, setAReceber] = useState<number>(0);

  const dataFimFixa: string = dataAtual.toISOString().split("T")[0].split("-")[0] + "-" + dataAtual.toISOString().split("T")[0].split("-")[1] + "-" + "28"
  const [datafim, setDataFim] = useState<string>(dataFimFixa);

  const [abrirModalVisuReceita, setAbrirModalVisuReceita] = useState(false);

  // DADOS RECEBIMENTO DE RECEITA
  const [abrirModalReceber, setAbrirModalReceber] = useState(false);
  const [dadosReceita, setDadosReceita] = useState({
    id_clinica: "",
    id_receita: "",
    data_procedimento: "",
    data_vencimento: "",
    paciente: "",
    id_faturado: "",
    valor: 0,
    desconto: 0,
    valor_liquido: 0,
    procedimento: "",
    id_agendamento: "",
    id_sala: "",
  });
  const [dataRecebimento, setDataRecebimento] = useState<any>("");
  const [tipoPagamento, setTipoPagamento] = useState<string>("");
  const [obsRecebimento, setObsRecebimento] = useState<string>("");

  const actions: Action[] = [
    {
      icon: (
        <AddIcon onClick={() => navigate("/cadastro/filiais/salas/novo")} />
      ),
      name: "Incluir",
    },
  ];
  const colunas: ITabela[] = [
    { titulo: "Receita", acesso: "id_receita" },
    { titulo: "Status", acesso: "status_rec" },
    { titulo: "Origem", acesso: "descorigem" },
    // { titulo: "Tipo Agendamento", acesso: "desctiporeceita" },
    // { titulo: "Procedimento", acesso: "procedimento" },
    { titulo: "Data Procedimento", acesso: "data_procedimento" },
    { titulo: "Data Vencimento", acesso: "data_vencimento" },
    // { titulo: "Observação", acesso: "observacao" },
    { titulo: "Paciente", acesso: "paciente" },
    { titulo: "Tipo Cobrança", acesso: "id_faturado" },
    { titulo: "Valor", acesso: "valor" },
    { titulo: "Desconto", acesso: "desconto" },
    { titulo: "Valor Líquido", acesso: "valor_liquido" },
    { titulo: "Valor Recebido", acesso: "valor_recebido" },
    { titulo: "Data Recebimento", acesso: "data_recebimento" },
  ];
  //#region REALIZA REQUISIÇÃO PARA LISTAR DADOS DA TABELA
  const [dados, setDados] = useState<any[]>([]);
  const buscarDadosReceita = async (
    id_clinica: string,
    data_inicio: string,
    data_fim: string,
    status: string
  ) => {
    setLoading(true);
    setTextoLoading("Carregando Listagem de Receitas...");
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/faturamento/receita/listar/:id_clinica/:data_inicio/:data_fim/:status",
      parametros: {
        id_clinica: id_clinica,
        data_inicio: data_inicio,
        data_fim: data_fim,
        status: status
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        console.log(retorno)
        setLoading(false);
        setTextoLoading("");
        // captura a coluna valor soma ela e grava em saldo previsto
        let somaPrevisto = 0;
        retorno.map((item: any) => {
          return (somaPrevisto += item.valor_liquido);
        });
        setSaldoPrevisto(somaPrevisto);

        let somaRecebido = 0;
        retorno.map((item: any) => {
          return (somaRecebido += item.valor_recebido);
        });
        setRecebido(somaRecebido);

        let somaAReceber = 0;
        retorno.map((item: any) => {
          return (somaAReceber += item.valor_liquido - item.valor_recebido);
        });
        setAReceber(somaAReceber);
        setDados(retorno);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    if (!datainicio || !datafim) {
      toast.warning(
        "Informe a data de início e a data de fim para realizar a busca de receitas."
      );
      return;
    } else if (id_clinica) buscarDadosReceita(id_clinica, datainicio, datafim, statusFiltro);
  }, [id_clinica]);
  //#endregion

  const buscarReceitas = () => {
    if (!datainicio || !datafim) {
      toast.warning(
        "Informe a data de início e a data de fim para realizar a busca de receitas."
      );
      return;
    } else buscarDadosReceita(id_clinica, datainicio, datafim, statusFiltro);
  };
  const visualizar = (dados: any) => { };
  const editar = async (dados: any) => {
    const status = dados.status_rec;
    if (status === "1") {
      toast.warning("Não é possível editar uma receita que já foi recebida.");
      return;
    }
  };
  const excluir = async (dados: any) => {
    const status = dados.status_rec;
    if (status === "1") {
      toast.warning("Não é possível excluir uma receita que já foi recebida.");
      return;
    }
  };
  const receberReceita = async (dados: any) => {
    const status = dados.status_rec;

    if (status === "1") {
      toast.warning("Receita já foi recebida");
      return;
    } else {

      setDataRecebimento(new Date().toISOString().split("T")[0])
      buscarTipoPagamento();
      setDadosReceita({
        id_clinica: dados.id_clinica,
        id_receita: dados.id_receita,
        data_procedimento: dados.data_procedimento,
        data_vencimento: dados.data_vencimento,
        paciente: dados.paciente,
        id_faturado: dados.id_faturado,
        valor: dados.valor,
        desconto: dados.desconto,
        valor_liquido: dados.valor_liquido,
        procedimento: dados.procedimento,
        id_agendamento: dados.id_agendamento,
        id_sala: dados.id_sala,
      });
      setAbrirModalReceber(true);
    }
  };

  const [dadosTipoPagamento, setDadosTipoPagamento] = useState<any[]>([]);
  const buscarTipoPagamento = async () => {
    setLoading(true);
    setTextoLoading("Carregando Tipos de Pagamento...");
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/faturamento/receita/tipopagamento/:tabela",
      parametros: {
        tabela: "FORMAPAGAMENTO",
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        setDadosTipoPagamento(retorno);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };

  const realizarRecebimento = async (
    id_clinica: string,
    id_receita: string,
    data_recebimento: string,
    usuario: string,
    obsrecebimento: string,
    id_tipo_pagamento: string,
    valor_liquido: number,
    id_agendamento: string,
    id_sala: string,
    id_faturado: string
  ) => {
    setLoading(true);
    setTextoLoading("Gravando Recebimento...");

    const dados = {
      data_recebimento,
      usuario,
      obsrecebimento,
      id_tipo_pagamento,
      valor_liquido,
      id_agendamento,
      id_sala,
      id_faturado,
    };
    ApiPost(
      `/faturamento/receita/receber/${id_clinica}/${id_receita}`,
      dados,
      token
    )
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        const msg = retorno.message;
        toast.success(msg);
        setDadosReceita({
          id_clinica: "",
          id_receita: "",
          data_procedimento: "",
          data_vencimento: "",
          paciente: "",
          id_faturado: "",
          valor: 0,
          desconto: 0,
          valor_liquido: 0,
          procedimento: "",
          id_agendamento: "",
          id_sala: "",
        });
        setDataRecebimento("");
        setTipoPagamento("");
        setObsRecebimento("");
        setAbrirModalReceber(false);
        buscarDadosReceita(id_clinica, datainicio, datafim, statusFiltro);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao gravar recebimento. Motivo: ${erro.message}`);
      });
  };

  const gravarRecebimento = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    //  verificar se a dataRecebimento é menor que a data de hoje
    // if (dataRecebimento < new Date().toISOString().split("T")[0]) {
    //   toast.warning(
    //     "Data de Recebimento não pode ser menor que a data de hoje."
    //   );
    //   return;
    // } else {
    realizarRecebimento(
      dadosReceita.id_clinica,
      dadosReceita.id_receita,
      dataRecebimento,
      usuario,
      obsRecebimento,
      tipoPagamento,
      dadosReceita.valor_liquido,
      dadosReceita.id_agendamento,
      dadosReceita.id_sala,
      dadosReceita.id_faturado
    );
    // }
  };

  const fecharModalReceber = () => {
    setAbrirModalReceber(false);
    setDataRecebimento("");
    setTipoPagamento("");
    setObsRecebimento("");
    setAbrirModalReceber(false);
    setDadosReceita({
      id_clinica: "",
      id_receita: "",
      data_procedimento: "",
      data_vencimento: "",
      paciente: "",
      id_faturado: "",
      valor: 0,
      desconto: 0,
      valor_liquido: 0,
      procedimento: "",
      id_agendamento: "",
      id_sala: "",
    });
  };

  const gerarPDF = async (dados: any) => {

    const statusRec = dados.status_rec;
    if (statusRec === "0") {
      return toast.warning("Receita não foi recebida. Não é possível gerar recibo.");
    }
    const dataAtual = moment().format("DD/MM/YYYY HH:mm:ss");
    const id_clinica = dados.id_clinica;
    let content: any[] = [
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
          body: [
            [
              { text: "Status", style: "tableHeader" },
              { text: "Origem", style: "tableHeader" },
              { text: "Data Procedimento", style: "tableHeader" },
              { text: "Data Vencimento", style: "tableHeader" },
              { text: "Paciente", style: "tableHeader" },
              { text: "Tipo Cobrança", style: "tableHeader" },
              { text: "Valor", style: "tableHeader" },
              { text: "Desconto", style: "tableHeader" },
              { text: "Valor Líquido", style: "tableHeader" },
              { text: "Valor Recebido", style: "tableHeader" },
              { text: "Data Recebimento", style: "tableHeader" },
            ],
            [
              dados.status_rec === "1" ? "Recebido" : "Em Aberto",
              dados.descorigem,
              dados.data_procedimento,
              dados.data_vencimento,
              dados.paciente,
              dados.id_faturado,
              dados.valor,
              dados.desconto,
              dados.valor_liquido,
              dados.valor_recebido,
              dados.data_recebimento,
            ],
          ],
        },
      },
    ];
    const documentoImpressao: TDocumentDefinitions = {
      pageSize: "A4",
      pageMargins: [20, 180, 20, 20],

      header: function (currentPage, pageCount) {
        return [
          [
            {
              text: "Recibo de Pagamento",
              alignment: "center",
              bold: true,
              margin: [0, 10, 0, 0], // margem: esquerda, superior, direita, rodapé
              fontSize: 12,
            },
          ],
          [
            {
              text: "_____________________________________________________________________________________________________________",
              alignment: "center",
              bold: true,
              margin: [0, 10, 0, 0], // margem: esquerda, superior, direita, rodapé
              fontSize: 12,
            },
          ],
        ];
      },

      content: [
        [
          {
            text: `Declaro para os devidos fins que recebemos de ${dados.paciente
              } a quantia de ${dados.valor_recebido.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })} referente ao pagamento do procedimento ${dados.procedimento
              } realizado no dia ${dados.data_procedimento}.`,
            alignment: "center",
            bold: true,
            margin: [0, 10, 0, 0], // margem: esquerda, superior, direita, rodapé
            fontSize: 12,
          },
        ],
        [
          {
            text: `Observação: ${dados.observacao}`,
            alignment: "center",
            bold: true,
            margin: [0, 10, 0, 0], // margem: esquerda, superior, direita, rodapé
            fontSize: 12,
          },
        ],
        [
          {
            text: `Data do Recebimento: ${dados.data_recebimento}`,
            alignment: "center",
            bold: true,
            margin: [0, 10, 0, 0], // margem: esquerda, superior, direita, rodapé
            fontSize: 12,
          },
        ],
        [
          {
            text: `Forma de Pagamento: ${dados.formapagamento}`,
            alignment: "center",
            bold: true,
            margin: [0, 10, 0, 0], // margem: esquerda, superior, direita, rodapé
            fontSize: 12,
          },
        ],

        [
          {
            text: `Atenciosamente`,
            alignment: "left",
            bold: true,
            margin: [0, 10, 0, 0], // margem: esquerda, superior, direita, rodapé
            fontSize: 12,
          },
        ],
        [
          {
            text: getSessionData("DadosNomeClinica")?.replace(`"`, '').replace(`"`, '') + "." || "",
            alignment: "left",
            bold: true,
            margin: [0, 10, 0, 0], // margem: esquerda, superior, direita, rodapé
            fontSize: 12,
          },
        ],
      ],

      styles: {
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: "black",
          alignment: "center",
        },
      },
      footer(currentPage, pageCount) {
        const TelefoneClinica = getSessionData("TelefoneClinica") || "";
        const email = getSessionData("EmailClinica") || "";
        const nomeClinica = getSessionData("DadosNomeClinica") || "";
        return {
          layout: "noBorders",
          margin: [0, 0, 0, 0], //margem: esquerda, superior,direita, rodape
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: `${TelefoneClinica.replace(/"/g, "")} | ${email.replace(
                    /"/g,
                    ""
                  )} | ${nomeClinica.replace(
                    /"/g,
                    ""
                  )}  | Gerado em TotalClínica ® pelo usuário ${usuario} em ${dataAtual} | Página ${currentPage.toString()} de ${pageCount}`,
                  fontSize: 8,
                  alignment: "center",
                  //fillColor: "#9EDDDC",
                  lineHeight: 1.5,
                  //color: "#ffffff",
                  bold: true,
                },
              ],
            ],
          },
        };
      },
    };
    pdfMake.createPdf(documentoImpressao).open();
  };


  const [statusFiltro, setStatusFiltro] = useState("0")

  //verifica permissões
  const token_ = getSessionData("MultClinicaWebToken")
  const isSuper = getSessionData("isPerfilSuper") || "";
  const [visualizar_faturamento, set_visualizar] = useState(false)
  const [criar, set_criar] = useState(false)
  function verificaPermissoes(id_perfil: any) {

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/perfil/carregar/${id_perfil}`, {
      headers: {
        Authorization: token_
      }
    }).then(function (resposta) {

      set_visualizar(resposta.data[0].visualizarfaturamento === "1" || isSuper === "1" ? true : false)
      set_criar(resposta.data[0].criarfaturamento === "1" || isSuper === "1" ? true : false)
    }).catch(function (erro) {

      toast.error("Erro ao carregar permissão.")
    })
  }

  useEffect(function () {

    verificaPermissoes(getSessionData("DadosPerfilID"))
  }, [])

  return (
    <>
      <div className="page-content">
        <ContainerTitulo titulo="Faturamento" />
      </div>

      <Row>
        <Col sm={12}>
          <Card>
            <Card.Body>
              {/* <Row>
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  direction="left"
                  sx={{
                    right: 0,
                    position: "absolute",
                  }}
                  icon={<SpeedDialIcon />}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                    />
                  ))}
                </SpeedDial>
              </Row> */}
              <Row>
                <Col sm={2}>
                  <InputLabel
                    style={{
                      color: "#000",
                      fontSize: "16px",
                      margin: "0 0 -6px 0",
                    }}
                  >
                    Data Início
                  </InputLabel>
                  <InputSemBorda
                    name="datainicio"
                    type="date"
                    value={datainicio}
                    onChange={setDataInicio}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                    placeholder="Data Início"
                    required
                  />
                </Col>
                <Col sm={2}>
                  <InputLabel
                    style={{
                      color: "#000",
                      fontSize: "16px",
                      margin: "0 0 -6px 0",
                    }}
                  >
                    Data Fim
                  </InputLabel>
                  <InputSemBorda
                    name="datafim"
                    type="date"
                    value={datafim}
                    onChange={setDataFim}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                    placeholder="Data Fim"
                    required
                  />
                </Col>
                <Col sm={3}>
                  <br />
                  <div className="form-floating">
                    <select value={statusFiltro}
                      onChange={function (e) {

                        setStatusFiltro(e.target.value)
                      }}
                      className="form-select" id="floatingSelect" aria-label="Floating label select example">
                      <option value={"0"} selected>A Receber</option>
                      <option value={"1"}>Recebido</option>
                      <option value={"9"}>Cancelada</option>
                      <option value={"10"}>Todos</option>
                    </select>
                    <label>Status</label>
                  </div>
                </Col>
                <Col sm={2}>
                  <br />
                  <button onClick={function () {

                    buscarReceitas()
                  }} type="button" className="mt-1 w-100 btn btn-secondary">Filtrar</button>
                </Col>
              </Row>
              <br />
              <Row>
                <CardResumo
                  icone={<FaMoneyBill size={60} />}
                  titulo="Saldo Previsto"
                  valor={saldoPrevisto.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                  textoTooltip="Saldo Previsto = Receitas Em Aberto + Receitas Recebidas"
                />

                <CardResumo
                  icone={<BsArrowDownCircleFill size={60} color="#0ACF97" />}
                  titulo="Recebido"
                  valor={recebido.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                  corBorda="#0ACF97"
                  textoTooltip="Recebido = Saldo de Receitas Recebidas no Período"
                />

                <CardResumo
                  icone={<BsArrowUpCircleFill size={60} color="#f44336" />}
                  titulo="A Receber"
                  valor={aReceber.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                  corBorda="#f44336"
                  textoTooltip="A Receber = Saldo de Receitas Em Aberto no Período"
                />
              </Row>
              <Row>
                <Tabela
                  coluna={colunas}
                  dados={dados}
                  itemsPerPage={10}
                  onVisuClick={visualizar}
                  usaDelete={false}
                  usaEdit={false}
                  usaVisu={false}
                  onEditClick={editar}
                  onDeleteClick={excluir}
                  usaNovoBotao={criar}
                  onNovoBotaoClick={receberReceita}
                  descNovoBotao="Receber Receita"
                  iconeNovoBotao={mdiReceiptTextCheckOutline}
                  onImprimirClick={gerarPDF}
                  usaImprimir={visualizar_faturamento}
                  id="codigo"
                />
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* ABRIR MODAL PARA REALIZAR RECEBIMENTO DA RECEITA */}
      <Modal show={abrirModalReceber} size="xl" centered keyboard={false}>
        <Modal.Header className="bg-white">
          <Modal.Title>Recebimento de Receitas</Modal.Title>
        </Modal.Header>
        <Form onSubmit={gravarRecebimento}>
          <Modal.Body className="bg-white">
            <Row>
              <Col sm={4}>
                <InputSemBorda
                  name="paciente"
                  type="text"
                  value={dadosReceita.paciente}
                  onChange={() => { }}
                  // onBlur={buscarReceitas}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                  placeholder="Paciente"
                  label="Paciente"
                />
              </Col>
              <Col sm={4}>
                <InputSemBorda
                  name="dataprocedimento"
                  type="text"
                  value={dadosReceita.data_procedimento}
                  onChange={() => { }}
                  // onBlur={buscarReceitas}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                  placeholder="Data Procedimento"
                  label="Data Procedimento"
                />
              </Col>
              <Col sm={4}>
                <InputSemBorda
                  name="datavencimento"
                  type="text"
                  value={dadosReceita.data_vencimento}
                  onChange={() => { }}
                  // onBlur={buscarReceitas}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                  placeholder="Data Vencimento"
                  label="Data Vencimento"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <InputSemBorda
                  name="procedimento"
                  type="text"
                  value={dadosReceita.procedimento}
                  onChange={() => { }}
                  // onBlur={buscarReceitas}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                  placeholder="Procedimento"
                  label="Procedimento"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <TextField
                  label="Valor"
                  type="number"
                  value={dadosReceita.valor}
                  variant="standard"
                  InputProps={{
                    disabled: true,
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                  className="m-2 text-black"
                  style={{ fontWeight: "bold", color: "#000" }}
                />
              </Col>
              <Col sm={3}>
                <TextField
                  label="Desconto"
                  type="number"
                  value={dadosReceita.desconto}
                  variant="standard"
                  InputProps={{
                    disabled: true,
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                  className="m-2 text-black"
                  style={{ fontWeight: "bold", color: "#000" }}
                />
              </Col>
              <Col sm={3}>
                <TextField
                  label="Valor a Receber"
                  type="number"
                  value={dadosReceita.valor_liquido}
                  variant="standard"
                  InputProps={{
                    disabled: true,
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                  className="m-2 text-black"
                  style={{ fontSize: "20px" }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <SelectInput
                  value={tipoPagamento}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    setTipoPagamento(e.target.value)
                  }
                  options={dadosTipoPagamento}
                  placeholder="Forma de Pagamento"
                  required
                />
              </Col>
              <Col sm={8}>
                <InputSemBorda
                  name="observacao"
                  type="text"
                  value={obsRecebimento}
                  onChange={setObsRecebimento}
                  // onBlur={buscarReceitas}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                  placeholder="Observação - Recebimento"
                  label="Observação - Recebimento"
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="bg-white">
            <div className="d-flex my-2 gap-1 justify-content-between">
              <Button
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={fecharModalReceber}
              />
              <Button
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
              />
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      <ModalLoading show={loading} label={textoLoading} />
    </>
  );
}
