import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import { Row, Col, Card, Modal, Form } from "react-bootstrap";
import { getSessionData } from "../../../utils/storageUtils";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import {
  InputAdornment,
  InputLabel,
  // SpeedDial,
  // SpeedDialAction,
  // SpeedDialIcon,
  TextField,
} from "@mui/material";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { FaMoneyBill } from "react-icons/fa";
import { BsArrowDownCircleFill, BsArrowUpCircleFill } from "react-icons/bs";
import CardResumo from "../../../components/Formularios/CardResumo";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import Tabela from "../../../components/Formularios/Table";
import ApiGet from "../../../api/endPoints/useGet";
import { toast } from "react-toastify";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import {
  mdiCheckCircle,
  mdiKeyboardReturn,
  mdiReceiptTextCheckOutline,
} from "@mdi/js";
import { Button } from "../../../components/Formularios/Buttons/Button";
import SelectInput from "../../../shared/SelectInputs";
import ApiPost from "../../../api/endPoints/usePost";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Content, TDocumentDefinitions } from "pdfmake/interfaces";
import moment from "moment";
import axios from "axios";
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import { aB } from "@fullcalendar/core/internal-common";
import { mascaraCNPJ, mascaraCPF } from "../../../hooks/mascaras";
import { set } from "date-fns";

export function FaturamentoDespesas() {


  interface Action {
    icon: JSX.Element;
    name: string;
  }
  const navigate = useNavigate();
  const id_clinica = getSessionData("DadosIdClinica") || "";
  const token = getSessionData("MultClinicaWebToken") || "";
  const usuario = getSessionData("DadosUsuario") || "";

  const [loading, setLoading] = useState(false);

  const dataAtual = new Date();
  const mesAtual = (dataAtual.getMonth() + 1).toString().padStart(2, "0");
  const anoAtual = dataAtual.getFullYear();
  const _dataInicial = anoAtual + "-" + mesAtual + "-01";

  const colunas: ITabela[] = [
    { titulo: "Cód", acesso: "id" },
    { titulo: "Título do Lançamento", acesso: "titulo" },
    { titulo: "N° Doc", acesso: "nundoc" },
    { titulo: "R. Profissional", acesso: "is_profissional" },
    { titulo: "Profissional/Entidade", acesso: "entidade" },
    { titulo: "CPF/CNPJ", acesso: "cnpj_cpf" },
    { titulo: "Valor", acesso: "valor" },
    { titulo: "Mov.", acesso: "tipo_mov" },
    { titulo: "Emissão", acesso: "dt_emissao_formatada" },
    { titulo: "Vencimento", acesso: "dt_vencimento_formatada" },
    { titulo: "Recebimento", acesso: "dt_pagamento_formatada" },
    { titulo: "Status", acesso: "status_movimento" }
  ];

  const [ListaEntidades, setListaEntidades] = useState([])
  function CarregarEntidades(id_clinica: any) {

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/lista/entid/${id_clinica}`, {
      headers: {
        Authorization: token
      }
    }).then(function (resposta) {

      setListaEntidades(resposta.data)
    }).catch(function (erro) {

      toast.error(erro.response.data || erro.statusText || erro.message)
    })
  }

  const [ListaReceitaDespesas, setListaReceitaDespesas] = useState<any>([])
  function carregarReceitaDespesas(id_clinica: any, data_inicio: any, data_fim: any, entidade: any, status: any, formaPagamento: any, tipomovimento: any) {

    setLoading(true)
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/consultar/movimentos/${data_inicio}/${data_fim}/${status != "" ? status : 0}/${formaPagamento != "" ? formaPagamento : 0}/${tipomovimento != "" ? tipomovimento : 0}/${entidade != "" ? entidade : 0}/${id_clinica}/${tipoData}/${is_profissional}`, {
      headers: {
        Authorization: token
      }
    }).then(function (movimentos) {

      setLoading(false)
      setListaReceitaDespesas(movimentos.data)
      toast.info(movimentos.data.length + " Movimento(s) encontrado(s)")
    }).catch(function (erro) {

      setLoading(false)
      toast.error(erro.response.data || erro.statusText || erro.message)
    })
  }


  useEffect(function () {

    const newDataInicial = new Date(new Date(new Date(new Date().setDate(1))).setMonth(new Date(new Date(new Date().setDate(0))).getMonth() + 1)).toISOString().split("T")[0]
    setDataInicio(newDataInicial)

    const newDataFim = new Date(new Date(new Date(new Date().setDate(0))).setMonth(new Date(new Date(new Date().setDate(0))).getMonth() + 1)).toISOString().split("T")[0]
    setDataFim(newDataFim)


    ConsultarCODsistema("STATUSMOVIMENTO")
    ConsultarCODsistema("TIPODOC")
    ConsultarCODsistema("FORMAPAGAMENTO")
    CarregarEntidades(getSessionData("DadosIdClinica"))

    EncontrarEntidade()
  }, [])

  interface Action {
    icon: JSX.Element;
    name: string;
  }
  const actions: Action[] = [
    {
      icon: (
        <AddIcon
          onClick={AcaoModal}
        />
      ),
      name: "Incluir",
    },
    // { icon: <FileCopyIcon />, name: "Duplicar" },
    // { icon: <SaveIcon />, name: "Salvar" },
    // { icon: <PrintIcon />, name: "Imprimir" },
  ];


  //abrir ou fechar modal
  function AcaoModal(editar: any = 0) {

    const btn = document.querySelector("#btnModal")! as HTMLElement
    if (editar == 0) {

      set_entidade("")
      set_cpf_cnpj("")
      set_titulo("")
      set_descMovimento("")
      set_tipoDoc("")
      set_num_doc("")
      set_valor("")
      set_emissao("")
      set_vencimento("")
      set_pagamento("")
      set_formaPagamento("")
      set_status("")
      set_id_edicao("")
    }
    btn.click()
  }

  const [cpf_cnpj, set_cpf_cnpj] = useState("")
  const [entidade, set_entidade] = useState<any>("")
  const [tipo, set_tipo] = useState("S") //tipo do movimento
  const [titulo, set_titulo] = useState("")
  const [descMovimento, set_descMovimento] = useState("")
  const [tipoDoc, set_tipoDoc] = useState("")
  const [num_doc, set_num_doc] = useState("")
  const [valor, set_valor] = useState<any>("")
  const [emissao, set_emissao] = useState("")
  const [vencimento, set_vencimento] = useState("")
  const [pagamento, set_pagamento] = useState("")
  const [formaPagamento, set_formaPagamento] = useState("")
  const [status, set_status] = useState("")

  function formatCpfCnpj(info: string) {

    if (info.replace(/[.-]/g, "").length == 11) {

      set_cpf_cnpj(mascaraCPF(info))
    }
    else if (info.replace(/[.-]/g, "").length == 14) {

      set_cpf_cnpj(mascaraCNPJ(info))
    }
    else {

      set_cpf_cnpj(info)
    }
  }

  function formatValor(valorAformatar: string) {

    let valor = valorAformatar.replace(/\D/g, '')

    // Adiciona a vírgula para separar os centavos
    valor = valor.replace(/(\d)(\d{2})$/, '$1,$2')

    // Adiciona o ponto para separar os milhares
    valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '.')
    set_valor(valor)
  }

  const [ListaOptionEntidade, setListaOptionEntidade] = useState([])
  function EncontrarEntidade() {


    axios.get(`${process.env.REACT_APP_API_BASE_URL}/preencher/entidade/${id_clinica}`, {
      headers: {
        Authorization: token
      }
    }).then(function (resposta) {

      //set_entidade(resposta.data[0].entidade)
      setListaOptionEntidade(resposta.data)
    }).catch(function (erro) {

      if (erro.response.status != 406) {

        toast.error(erro.response.data || erro.message || erro.statusText)
      }
      else {

        toast.error(erro.response.data)
      }
    })
  }

  //cod sistemas
  const [listaCODtipoDoc, set_listaCODtipoDoc] = useState([])
  const [listaCODtipoPagamento, set_listaCODtipoPagamento] = useState([])
  const [listaCODstatusMov, set_listaCODstatusMov] = useState([])

  function ConsultarCODsistema(tabela: string) {

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/codigosistema/listar/${tabela}`, {
      headers: {
        Authorization: token
      }
    }).then(function (resposta) {

      if (tabela == "STATUSMOVIMENTO") {

        set_listaCODstatusMov(resposta.data)
      }
      else if (tabela == "TIPODOC") {

        set_listaCODtipoDoc(resposta.data)
      }
      else if (tabela == "FORMAPAGAMENTO") {

        set_listaCODtipoPagamento(resposta.data)
      }
    })
  }


  function CriarMovimento(e: any) {

    e.preventDefault()
    setLoading(true)
    const dados = {

      cpf_cnpj,
      entidade,
      tipo,
      titulo,
      descMovimento,
      tipoDoc,
      num_doc,
      valor,
      emissao,
      vencimento,
      pagamento,
      formaPagamento,
      statusMovimento: status,
      usuario_cadastro: getSessionData("DadosUsuario")
    }

    axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/movimento/despesa/receita/${getSessionData("DadosIdClinica")}`, dados, {
      headers: {
        Authorization: token
      }
    }).then(function (resposta) {

      setLoading(false)
      toast.success(resposta.data)
      AcaoModal()
      carregarReceitaDespesas(id_clinica, datainicio, datafim, FiltroEntidade, FiltroStatus, FiltroFormaPagamento, FiltroTipoMovimento)
    }).catch(function (erro) {

      setLoading(false)
      toast.error(erro.response.data || erro.message || erro.statusText)
    })
  }
  //INPUTS PARA FILTRO
  const [FiltroStatus, set_FiltroStatus] = useState("")
  const [datainicio, setDataInicio] = useState<string>(_dataInicial)
  const [datafim, setDataFim] = useState<string>(
    dataAtual.toISOString().split("T")[0]
  )
  const [FiltroFormaPagamento, set_FiltroFormaPagamento] = useState("")
  const [FiltroTipoMovimento, set_FiltroTipoMovimento] = useState("S")
  const [FiltroEntidade, set_FiltroEntidade] = useState("")

  const [busca, set_busca] = useState("")
  function ProcurarPorTituloDeFaturamento(titulo: string) {

    if (titulo == "") {


      const newDataInicial = new Date(new Date(new Date(new Date().setDate(1))).setMonth(new Date(new Date(new Date().setDate(0))).getMonth() + 1)).toISOString().split("T")[0]
      setDataInicio(newDataInicial)

      const newDataFim = new Date(new Date(new Date(new Date().setDate(0))).setMonth(new Date(new Date(new Date().setDate(0))).getMonth() + 1)).toISOString().split("T")[0]
      setDataFim(newDataFim)
      carregarReceitaDespesas(id_clinica, newDataInicial, newDataFim, FiltroEntidade, FiltroStatus, FiltroFormaPagamento, FiltroTipoMovimento)
    }
    else {

      const newLista: any = ListaReceitaDespesas.filter(function (pendente: any) {

        return pendente.titulo.toLocaleLowerCase().includes(titulo.toLocaleLowerCase())
      })

      setListaReceitaDespesas(newLista)
    }
  }

  useEffect(function () {

    ProcurarPorTituloDeFaturamento(busca)
  }, [busca])


  const [id_edicao, set_id_edicao] = useState("")
  function abrirEdicaoDeLançamento(dado: any) {

    set_id_edicao(dado.id)
    set_cpf_cnpj(dado.cnpj_cpf)
    set_entidade(dado.entidade)
    set_tipo(dado.tipo_mov)
    set_titulo(dado.titulo)
    set_descMovimento(dado.descricao)
    set_tipoDoc(dado.tipodoc)
    set_num_doc(dado.nundoc)
    set_valor(dado.valor.replace(".", ","))
    set_emissao(dado.dt_emissao_formatada != null ? dado.dt_emissao_formatada.split("/")[2] + "-" + dado.dt_emissao_formatada.split("/")[1] + "-" + dado.dt_emissao_formatada.split("/")[0] : "")
    set_vencimento(dado.dt_vencimento_formatada != null ? dado.dt_vencimento_formatada.split("/")[2] + "-" + dado.dt_vencimento_formatada.split("/")[1] + "-" + dado.dt_vencimento_formatada.split("/")[0] : "")
    set_pagamento(dado.dt_pagamento_formatada != null ? dado.dt_pagamento_formatada.split("/")[2] + "-" + dado.dt_pagamento_formatada.split("/")[1] + "-" + dado.dt_pagamento_formatada.split("/")[0] : "")
    set_formaPagamento(dado.forma_pagamento)
    set_status(dado.status)
    AcaoModal(1)
  }

  function EditarMov(e: any) {

    e.preventDefault()
    const dados = {

      cpf_cnpj,
      entidade,
      tipo,
      titulo,
      descMovimento,
      tipoDoc,
      num_doc,
      valor,
      emissao,
      vencimento,
      pagamento,
      formaPagamento,
      statusMovimento: status,
      usuario_cadastro: usuario
    }
    setLoading(true)

    axios.put(`${process.env.REACT_APP_API_BASE_URL}/atualizar/mov/${id_edicao}/${getSessionData("DadosIdClinica")}`, dados, {
      headers: {
        Authorization: token
      }
    }).then(function (resposta) {

      toast.success(resposta.data)
      setLoading(false)
      AcaoModal()
      carregarReceitaDespesas(id_clinica, datainicio, datafim, FiltroEntidade, FiltroStatus, FiltroFormaPagamento, FiltroTipoMovimento)
    }).catch(function (erro) {

      setLoading(false)
      toast.error(erro.response.data || erro.statusText || erro.message)
    })

  }

  const [tipoData, setTipoData] = useState("0")
  const [is_profissional, set_isprofissional] = useState<any>(false)


  function setEntidadeNoem(cpf_cnpj: string) {

    const entidade_selecionada: any = ListaOptionEntidade.find(function (entidade: any) {

      return entidade.cnpj_cpf == cpf_cnpj.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "")
    })


    set_entidade(entidade_selecionada != undefined ? entidade_selecionada!.entidade : "")
  }

  //verifica permissões
  const token_ = getSessionData("MultClinicaWebToken")
  const isSuper = getSessionData("isPerfilSuper") || "";
  const [visualizar, set_visualizar] = useState(false)
  const [criar, set_criar] = useState(false)
  const [editar, set_editar] = useState(false)
  function verificaPermissoes(id_perfil: any) {

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/perfil/carregar/${id_perfil}`, {
      headers: {
        Authorization: token_
      }
    }).then(function (resposta) {

      set_visualizar(resposta.data[0].visualizarlancamentodespesas === "1" || isSuper === "1" ? true : false)
      set_criar(resposta.data[0].criarlancamentodespesas === "1" || isSuper === "1" ? true : false)
      set_editar(resposta.data[0].editarlancamentodespesas === "1" || isSuper === "1" ? true : false)
    }).catch(function (erro) {

      toast.error("Erro ao carregar permissão.")
    })
  }

  useEffect(function () {

    verificaPermissoes(getSessionData("DadosPerfilID"))
  }, [])

  useEffect(function () {

    if (pagamento != "" && formaPagamento != "") {

      set_status("Executado")
    }
  }, [pagamento, formaPagamento])

  return (
    <>
      <div className="page-content">
        <ContainerTitulo titulo="Lançamento de Despesas e Outras Receitas" />
      </div>

      <Row>
        <Col sm={12}>
          <Card>
            <Card.Body>
              <Form onSubmit={function (e) {
                e.preventDefault()
                carregarReceitaDespesas(id_clinica, datainicio, datafim, FiltroEntidade, FiltroStatus, FiltroFormaPagamento, FiltroTipoMovimento)
              }}>
                <Row>
                  <Col sm={2}>
                    <div className="form-floating">
                      <select value={tipoData} onChange={function (e) {

                        setTipoData(e.target.value)
                      }} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                        <option selected value="0">Emissão</option>
                        <option value="1">Vencimento</option>
                      </select>
                      <label>Filtrar por data de:</label>
                    </div>
                  </Col>
                  <Col sm={2}>
                    <InputSemBorda
                      name="datainicio"
                      type="date"
                      value={datainicio}
                      onChange={setDataInicio}
                      classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      placeholder="Data Início"
                      label="Data Início"
                      required
                    />
                  </Col>
                  <Col sm={2}>

                    <InputSemBorda
                      name="datafim"
                      type="date"
                      value={datafim}
                      onChange={setDataFim}
                      classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      placeholder="Data Fim"
                      label="Data Fim"
                      required
                    />
                  </Col>
                  <Col sm={2}>
                    <div className="form-floating mt-1">
                      <select value={FiltroStatus} onChange={function (e) {

                        set_FiltroStatus(e.target.value)
                      }} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                        <option value="" selected>Selecione...</option>
                        {listaCODstatusMov.map(function (tipo: any) {

                          return (
                            <option value={tipo.label}>{tipo.label}</option>
                          )
                        })}
                      </select>
                      <label>Status</label>
                    </div>
                  </Col>
                  <Col sm={2}>
                    <div className="form-floating mt-1">
                      <select value={FiltroFormaPagamento} onChange={function (e) {

                        set_FiltroFormaPagamento(e.target.value)
                      }} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                        <option value="" selected>Selecione...</option>

                        {listaCODtipoPagamento.map(function (tipo: any) {

                          return (
                            <option value={tipo.label}>{tipo.label}</option>
                          )
                        })}
                      </select>
                      <label>Forma de Pagamento</label>
                    </div>
                  </Col>
                  <Col sm={2}>
                    <div className="form-floating mt-1">
                      <select value={FiltroTipoMovimento} onChange={function (e) {

                        set_FiltroTipoMovimento(e.target.value)
                      }} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                        <option value="S" selected>Saída</option>
                        <option value="E">Entrada</option>
                      </select>
                      <label>Tipo de Mov.</label>
                    </div>
                  </Col>
                  <Col sm={2}>
                    <div className="form-floating mt-1">
                      <select value={FiltroEntidade} onChange={function (e) {

                        set_FiltroEntidade(e.target.value)
                      }} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                        <option value="" selected>Selecione...</option>
                        {ListaEntidades.map(function (entidade: any) {

                          return (
                            <option value={entidade.cnpj_cpf}>{entidade.entidade}</option>
                          )
                        })}
                      </select>
                      <label>Profissional/Entidade</label>
                    </div>
                  </Col>
                  <Col sm={2}>
                    <div className="form-check">
                      <br />
                      <input className="form-check-input" type="checkbox" onChange={function (e) {
                        set_isprofissional(e.target.checked)
                      }} value={is_profissional} id="flexCheckDefault" />
                      <label className="form-check-label ms-2">
                        Repasse Profissional
                      </label>
                    </div>

                  </Col>
                  <Col sm={2}>
                    <br />
                    <button className="btn btn-secondary w-100" type="button" onClick={function () { carregarReceitaDespesas(id_clinica, datainicio, datafim, FiltroEntidade, FiltroStatus, FiltroFormaPagamento, FiltroTipoMovimento) }}>Filtrar</button>
                  </Col>
                </Row>
              </Form>
              <br />

              <Row>
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  direction="left"
                  sx={{
                    right: 0,
                    position: "absolute",
                  }}
                  icon={<SpeedDialIcon />}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      hidden={!criar}
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                    />
                  ))}
                </SpeedDial>
              </Row>

              <Row>
                <div className="d-flex my-2 gap-1 ">
                  <InputSemBorda
                    name="fantasia"
                    type="text"
                    value={busca}
                    onChange={set_busca}
                    classNameInputsSemBorda="form-control bordasInferiorInput"
                    placeholder=" "
                    label="Procurar por Título do movimento"
                  />
                </div>
              </Row>

              <Row>
                <Tabela
                  coluna={colunas}
                  dados={ListaReceitaDespesas}
                  itemsPerPage={10}
                  onVisuClick={function () { }}
                  usaDelete={false}
                  usaEdit={editar}
                  usaVisu={false}
                  onEditClick={abrirEdicaoDeLançamento}
                  onDeleteClick={function () { }}
                  onImprimirClick={function () { }}
                  usaImprimir={false}
                  id="codigo"
                />
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row >



      {/*MODAL PARA CRIAR DESPESA OU RECEITA */}
      <button hidden type="button" id="btnModal" className="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">Large modal</button>


      <div className="modal fade bd-example-modal-lg" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content bg-white">
            <div className="modal-header">
              <h4 className="modal-title" id="AbrirModalLabel">Preenchimento da despesa/receita</h4>
              <button onClick={function () {

                AcaoModal()
              }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form action="" onSubmit={id_edicao == "" ? CriarMovimento : EditarMov}>
              <div className="modal-body">
                <div className="container">
                  <div className="row">
                    <div className="col-sm col-md-6 col-lg-5">
                      <div className="form-floating">
                        <input value={cpf_cnpj} onChange={function (e) {
                          formatCpfCnpj(e.target.value)
                        }}
                          type="text" onBlur={function () {

                            setEntidadeNoem(cpf_cnpj)
                          }} list="complit" placeholder="" className="form-control bordasInferiorInput text-black" />
                        <label>Entidade - CPF/CNPJ</label>
                      </div>

                      {
                        cpf_cnpj.length > 2 ?

                          <datalist id="complit" className="bg-white">
                            {ListaOptionEntidade.map(function (entidade: any) {

                              return (
                                <>
                                  <option value={entidade.cnpj_cpf}>{entidade.entidade}</option>
                                </>
                              )
                            })}
                          </datalist>
                          : ""
                      }
                    </div>
                    <div className="col-sm col-md-6 col-lg-7">
                      <InputSemBorda
                        name="entidade"
                        type="text"
                        value={entidade}
                        onChange={set_entidade}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                        placeholder=" "
                        label="Entidade"
                        required
                      />
                    </div>
                    <div className="col-sm col-md col-lg">
                      <InputSemBorda
                        name="Titulo"
                        type="text"
                        value={titulo}
                        onChange={set_titulo}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                        placeholder=" "
                        label="Título do movimento"
                        required
                      />
                    </div>
                    <div className="p-1"></div>
                    <div className="col-sm col-md col-lg">
                      <div className="form-floating">
                        <select value={tipo} onChange={function (e) {

                          set_tipo(e.target.value)
                        }} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                          <option value="S" selected>Saída</option>
                          <option value="E">Entrada</option>
                        </select>
                        <label>Tipo de movimento</label>
                      </div>
                    </div>
                    <div className="form-floating">
                      <textarea style={{ height: "125px" }} value={descMovimento} onChange={function (e) {
                        set_descMovimento(e.target.value)
                      }} className="form-control bordasInferiorInput text-black" placeholder=""></textarea>
                      <label>Descrição do movimento</label>
                    </div>
                    <div className="p-1"></div>
                    <div className="col-sm col-md-6 col-lg-5">
                      <div className="form-floating">
                        <select required value={tipoDoc} onChange={function (e) {
                          set_tipoDoc(e.target.value)
                        }} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                          <option value="" selected>Selecione...</option>
                          {listaCODtipoDoc.map(function (tipo: any) {

                            return (
                              <option value={tipo.label}>{tipo.label}</option>
                            )
                          })}
                        </select>
                        <label>Tipo de Documento</label>
                      </div>
                    </div>
                    <div className="col-sm col-md-3 col-lg-3">
                      <InputSemBorda
                        name="num_documento"
                        type="text"
                        value={num_doc}
                        onChange={set_num_doc}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                        placeholder=" "
                        label="N° Doc"
                      />
                    </div>
                    <div className="col-sm col-md-3 col-lg-4">
                      <InputSemBorda
                        name="Valor"
                        type="text"
                        value={valor}
                        onChange={function (e) {
                          formatValor(e.replace(".", "").replace(",", "").replace(",00", ""))
                        }}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                        placeholder=" "
                        label="Valor R$"
                        required
                      />
                    </div>
                    <div className="p-1"></div>
                    <div className="col-sm col-md-4 col-lg-3">
                      <InputSemBorda
                        name="data_emissao"
                        type="date"
                        value={emissao}
                        onChange={set_emissao}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                        placeholder=" "
                        label="Data de Emissão"
                        required
                      />
                    </div>
                    <div className="col-sm col-md-4 col-lg-3">
                      <InputSemBorda
                        name="data_vencimento"
                        type="date"
                        value={vencimento}
                        onChange={set_vencimento}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                        placeholder=" "
                        label="Data de Vencimento"
                        required
                      />
                    </div>
                    <div className="col-sm col-md-4 col-lg-3">
                      <InputSemBorda
                        name="pagamento"
                        type="date"
                        value={pagamento}
                        onChange={set_pagamento}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                        placeholder=" "
                        label="Data de Pagamento"
                        required={status == "Executado" ? true : false}
                      />
                    </div>
                    <div className="col-sm col-md-6 col-lg-3">
                      <div className="form-floating">
                        <select required={pagamento != "" || status == "Executado" ? true : false} value={formaPagamento} onChange={function (e) {
                          set_formaPagamento(e.target.value)
                        }} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                          <option value="" selected>Selecione...</option>

                          {listaCODtipoPagamento.map(function (tipo: any) {

                            return (
                              <option value={tipo.label}>{tipo.label}</option>
                            )
                          })}
                        </select>
                        <label>Pagamento</label>
                      </div>
                    </div>
                    <div className="col-sm col-md col-lg">
                      <div className="form-floating">
                        <select value={status} onChange={function (e) {
                          set_status(e.target.value)
                        }} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                          <option value="" selected>Selecione...</option>
                          {listaCODstatusMov.map(function (tipo: any) {

                            return (
                              <option hidden={id_edicao == "" && tipo.label == "Cancelado" ? true : false} value={tipo.label}>{tipo.label}</option>
                            )
                          })}
                        </select>
                        <label>Status</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">{id_edicao == "" ? "Criar Movimento" : "Salvar Edição"} </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <ModalLoading show={loading} />
    </>
  );
}
